import { Close } from "@mui/icons-material"
import { Button } from "@mui/material"
import { useTranslation } from "react-i18next"

type CloseButtonProps = {
  onClick: () => void
}

export const CloseButton = ({ onClick }: CloseButtonProps) => {
  const { t } = useTranslation()
  return (
    <Button
      onClick={onClick}
      sx={{
        width: "fit-content",
        alignSelf: "flex-end",
        "& svg": {
          margin: "8px",
          fontSize: "28px",
          color: "text.primary",
        },
      }}
      aria-label={t("aria-labels.hamburger-menu-close")}
      title={t("aria-labels.hamburger-menu-close")}
    >
      <Close />
    </Button>
  )
}
