import { useMsal } from "@azure/msal-react"
import PeopleIcon from "@mui/icons-material/People"
import { Box, CircularProgress } from "@mui/material"
import CareOrganizationMobileViewBase from "components/CareOrganizationMobileViewBase"
import EntityListing from "components/EntityListing"
import GoBackButton from "components/GoBackButton"
import { CareOrgUserContext } from "contexts/careOrgUserContext"
import { userContext } from "contexts/userContext"
import { isEmpty } from "lodash/fp"
import { useContext, useEffect, useState } from "react"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import { fetchFamilies, fetchOrganizations } from "./apiRequests"
import FamilyListingHeader from "./styled/FamilyListingHeader"
import NoConnectedFamiliesHeader from "./styled/NoConnectedFamiliesHeader"

const CareOrganizationUserFamilyListing = () => {
  const { inProgress } = useMsal()
  const { ErrorSnackbar, handleError } = useApiRequestError()
  const { user } = useContext(userContext)
  const { state, dispatch } = useContext(CareOrgUserContext)
  const [isLoadingFamilies, setLoadingFamilies] = useState<boolean>(true)

  useEffect(
    function runEffectOnAuthenticationComplete() {
      const fetchUserFamilies = async () => {
        const userOrganizations = await fetchOrganizations(handleError)
        const userOrganizationId = userOrganizations?.items[0].id ?? ""
        const userFamilies = await fetchFamilies(userOrganizationId, setLoadingFamilies, handleError)
        dispatch({ type: "updateUserFamilies", families: userFamilies?.items })
      }
      fetchUserFamilies()
    },
    [handleError, inProgress, dispatch]
  )

  return (
    <CareOrganizationMobileViewBase>
      {isLoadingFamilies ? (
        <Box sx={{ mt: 5, mb: 4 }}>
          <CircularProgress size={100} />
        </Box>
      ) : isEmpty(state.families) ? (
        <>
          <NoConnectedFamiliesHeader userName={user?.firstName} />
          <GoBackButton url="/choose-mode" />
        </>
      ) : (
        <Box sx={{ mx: 2 }}>
          <FamilyListingHeader userName={user?.firstName} />
          <EntityListing entities={state.families} icon={<PeopleIcon />} url={`/care-org-user/family`} />
          <GoBackButton url="/choose-mode" />
          <ErrorSnackbar />
        </Box>
      )}
    </CareOrganizationMobileViewBase>
  )
}

export default CareOrganizationUserFamilyListing
