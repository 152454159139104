import { Box, useTheme } from "@mui/material"
import FeelingDoughnut from "components/FeelingDoughnut"
import { useEffect, useState } from "react"
import { FeelingService, GroupedFeelings } from "services/openapi"
import { matchFeelingIdWithColor } from "utils/functions/matchFeelingIdWithColor"
import { toDateFormat } from "utils/functions/toDateFormat"

type Props = {
  clientId: string
}

type FeelingState = {
  id: number
  strength: number
}

const ClientListingDoughnut = ({ clientId }: Props) => {
  const [fetchedUserFeelings, setFetchedUserFeelings] = useState<FeelingState[]>([])
  const theme = useTheme()

  useEffect(() => {
    const today = new Date()
    const weekAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000)

    async function fetchData() {
      await FeelingService.feelingGetAggregatedForUser(clientId, toDateFormat(weekAgo), toDateFormat(today)).then(
        (result) => {
          const converted = convertGroupedFeelingsToFeelingState(result)
          if (converted) {
            setFetchedUserFeelings(converted)
          }
        }
      )
    }

    fetchData()
  }, [clientId])

  const convertGroupedFeelingsToFeelingState = (feelings: GroupedFeelings[]) => {
    const data: FeelingState[] = feelings.map((feeling) => ({
      id: feeling.feelingId,
      strength: feeling.sum || 1,
    }))
    return data
  }

  const colors = fetchedUserFeelings.map((feeling) => matchFeelingIdWithColor(feeling.id, theme)!)
  const data = fetchedUserFeelings.map((feeling) => feeling.strength)
  return (
    <Box sx={{ width: 100 }}>
      <FeelingDoughnut chartColors={colors} chartData={data} showPercentaces={false} />
    </Box>
  )
}

export default ClientListingDoughnut
