import MobileViewBase from "components/MobileViewBase"
import MobileViewDescription from "components/MobileViewBase/MobileViewDescription"
import { useTranslation } from "react-i18next"
import TermsOfUseContent from "./TermsOfUseContent"

const TermsOfUseView = () => {
  const { t } = useTranslation()

  return (
    <MobileViewBase
      top={<MobileViewDescription header={t("terms-of-user-view.title")} text="" />}
      middle={<TermsOfUseContent />}
      bottom={<></>}
    />
  )
}

export default TermsOfUseView
