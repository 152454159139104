import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, TextField } from "@mui/material"
import { FormikProps } from "formik"
import { useTranslation } from "react-i18next"

type CreateCareOrgUserFieldsProps = {
  formik: FormikProps<{
    firstName: string
    lastName: string
    isForeman: boolean
    isWorker: boolean
  }>
}

const CreateCareOrgUserFields = ({ formik }: CreateCareOrgUserFieldsProps) => {
  const { t } = useTranslation()
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <TextField
        id="firstName"
        label={t("add-user.label.first-name")}
        sx={{ mb: 2, float: "left", clear: "both" }}
        variant="standard"
        color="primary"
        {...formik.getFieldProps("firstName")}
        helperText={formik.touched.firstName && formik.errors.firstName}
        required
      />
      <TextField
        id="lastName"
        label={t("add-user.label.last-name")}
        sx={{ mb: 2, clear: "both", float: "left" }}
        variant="standard"
        color="primary"
        {...formik.getFieldProps("lastName")}
        helperText={formik.touched.lastName && formik.errors.lastName}
        required
      />
      <FormControl sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
        <FormGroup>
          <FormLabel component="legend" sx={{ display: "flex" }}>
            {t("add-user.label.role")}
          </FormLabel>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <FormControlLabel
              control={<Checkbox id="isForeman" {...formik.getFieldProps("isForeman")} />}
              label={t("add-user.label.is-foreman")}
            />
            <FormControlLabel
              control={<Checkbox id="isWorker" {...formik.getFieldProps("isWorker")} />}
              label={t("add-user.label.is-worker")}
            />
          </Box>
        </FormGroup>
      </FormControl>
    </Box>
  )
}

export default CreateCareOrgUserFields
