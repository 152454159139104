import {
  ActivityService,
  ClientUserDetailsModel,
  ClientUserService,
  DiaryEntryListModelPagedResponseModel,
  DiaryEntryModel,
  DiaryService,
  FeelingService,
  GroupedFeelings,
  UserActivityListModel,
} from "services/openapi"

export const getAggregated = async (
  userId: string,
  from: string,
  to: string,
  setLoading: (isLoading: boolean) => void,
  onSuccess: (feelings: GroupedFeelings[]) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoading(true)
    const feelingResponse = await FeelingService.feelingGetAggregatedForUser(userId, from, to)
    const sorted = feelingResponse.sort((a, b) => (b.sum > 0 ? b.sum : b.total) - (a.sum > 0 ? a.sum : a.total))
    onSuccess(sorted)
  } catch (error) {
    onError(error, "care-org-mobile-view.client-details.feeling-fetch-error")
  }
  setLoading(false)
}

export const getActivityEntries = async (
  userId: string,
  from: string,
  to: string,
  setLoading: (isLoading: boolean) => void,
  onSuccess: (feelings: UserActivityListModel[]) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoading(true)
    const feelingResponse = await ActivityService.activityGetForUser(userId, from, to)
    onSuccess(feelingResponse)
  } catch (error) {
    onError(error, "care-org-mobile-view.client-details.activity-fetch-error")
  }
  setLoading(false)
}

export const getDiaryEntries = async (
  userId: string,
  from: string,
  to: string,
  setLoading: (isLoading: boolean) => void,
  onSuccess: (diaryPagedResponse: DiaryEntryListModelPagedResponseModel) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoading(true)
    const diaryResponse = await DiaryService.diaryGetDiaryEntries(userId, from, to)
    onSuccess(diaryResponse)
  } catch (error) {
    onError(error, "youth-view.diary-entry.snackbar.fetch-error")
  }
  setLoading(false)
}

export const getUser = async (
  userId: string,
  setLoading: (isLoading: boolean) => void,
  onSuccess: (user: ClientUserDetailsModel) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoading(true)
    const clientDetails = await ClientUserService.clientUserGetDetails(userId)
    onSuccess(clientDetails)
  } catch (error) {
    onError(error, "care-org-mobile-view.client-details.client-fetch-error")
  }
  setLoading(false)
}

export const getDiaryEntry = async (
  userId: string,
  date: string,
  setLoading: (isLoading: boolean) => void,
  onSuccess: (diaryEntry: DiaryEntryModel) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoading(true)
    const diaryResponse = await DiaryService.diaryGetDiaryEntry(userId, date)
    onSuccess(diaryResponse)
    setLoading(false)
    return diaryResponse
  } catch (error) {
    setLoading(false)
    onError(error, "youth-view.diary-entry.snackbar.fetch-error")
  }
}
