import {
  ActivityHelperModel,
  ActivityModel,
  ActivityService,
  UserActivityListModel,
  UserActivityModel,
} from "services/openapi"

export const getActivitiesOptionsForClientGroup = async (
  groupId: number,
  setLoading: (isLoading: boolean) => void,
  onSuccess: (activities: ActivityModel[]) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoading(true)
    const ActivityOptions = await ActivityService.activityGetActivitiesForClientGroup(groupId)
    onSuccess(ActivityOptions)
  } catch (error) {
    onError(error, "elderly-view.snackbar.fetch-feeling-options-error")
  }
  setLoading(false)
}

export const getActivityHelperOptionsForClientGroup = async (
  groupId: number,
  setLoading: (isLoading: boolean) => void,
  onSuccess: (activities: ActivityHelperModel[]) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoading(true)
    const ActivityOptions = await ActivityService.activityGetActivityHelpersForClientGroup(groupId)
    onSuccess(ActivityOptions)
  } catch (error) {
    onError(error, "elderly-view.snackbar.fetch-feeling-options-error")
  }
  setLoading(false)
}

export const getActivitiesForDate = async (
  userId: string,
  date: string,
  setLoading: (isLoading: boolean) => void,
  onSuccess: (activities: UserActivityModel) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoading(true)
    const activityResponse = await ActivityService.activityGetForUserSingleDay(userId, date)
    onSuccess(activityResponse)
  } catch (error) {
    onError(error, "elderly-view.activity-selection.snackbar.fetch-activity-error")
  }
  setLoading(false)
}

export const getActivityEntries = async (
  userId: string,
  from: string,
  to: string,
  setLoading: (isLoading: boolean) => void,
  onSuccess: (feelings: UserActivityListModel[]) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoading(true)
    const feelingResponse = await ActivityService.activityGetForUser(userId, from, to)
    onSuccess(feelingResponse)
  } catch (error) {
    onError(error, "care-org-mobile-view.client-details.activity-fetch-error")
  }
  setLoading(false)
}
