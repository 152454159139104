import { styled, Typography } from "@mui/material"
import { Box } from "@mui/system"
import logo_fi from "images/logo-fi.svg"
import logo_eng from "images/logo-en-v3.svg"
import i18n from "i18n"

const StyledH1 = styled(Typography)(() => ({
  fontSize: "24px",
  fontWeight: "bold",
  fontFamily: "SofiaProBold",
  margin: "5px",
}))

const AppTitle = () => {
  return (
    <Box sx={{ p: 1, display: "flex", flexDirection: "row" }}>
      <img src={i18n.language === "fi" ? logo_fi : logo_eng} alt="" width={"150px"} height={"50px"} aria-hidden="true" />
      <StyledH1 variant="h1"></StyledH1>
    </Box>
  )
}

export default AppTitle
