import { Box, styled } from "@mui/system"

export type MobileContentContainerProps = {
  content: JSX.Element
}

const MobileContentContainer = styled(Box)(() => ({
  display: "flex",
  flexFlow: "column",
  alignItems: "stretch",
  width: "100%",
  justifyContent: "flex-start",
}))

export default MobileContentContainer
