import { MoreVert } from "@mui/icons-material"
import { Button, Menu, MenuItem, TableCell, TableRow } from "@mui/material"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { OrganizationListModel } from "services/openapi"
import useConfirmationDialog from "utils/hooks/useConfirmationDialog"
import StyledTableLink from "../../../components/StyledTableLink"

// Map items on page to be displayed
const OrgTableRows = ({
  organizations,
  deactivateOrganization,
}: {
  organizations?: OrganizationListModel[] | null
  deactivateOrganization: (orgId: string) => Promise<boolean>
}) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)
  const [orgMenu, setOrgMenu] = useState<OrganizationListModel | null>(null)
  const { Dialog, showConfirmationDialog } = useConfirmationDialog()

  const handleClick = useCallback(
    (event: any, org: OrganizationListModel) => {
      setAnchorEl(event.currentTarget)
      setOrgMenu(org)
    },
    [setAnchorEl, setOrgMenu]
  )

  const handleClose = () => {
    setAnchorEl(null)
    setOrgMenu(null)
  }

  if (!organizations) return null

  const rows = organizations.map((org, i) => {
    return (
      <TableRow key={`org-${i}`}>
        <TableCell>
          <StyledTableLink to={`/admin/management-organization/${org.id}`}>{org.name}</StyledTableLink>
        </TableCell>
        <TableCell align="right">
          <Button
            onClick={(e) => handleClick(e, org)}
            id={`org-control-button-${i}`}
            aria-label={t("management-org-list.table.aria-controls", { name: `${org.name}` })}
          >
            <MoreVert />
          </Button>

          {/* 
          Check open status here instead of in the menu open property to avoid rendering extra menus unnecessarily.
          */}
          {org === orgMenu && (
            <Menu
              className="org-menu"
              open
              anchorEl={anchorEl}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": `org-button-${i}`,
              }}
            >
              <MenuItem
                onClick={() => {
                  showConfirmationDialog(
                    () => deactivateOrganization(org.id),
                    t("management-organization-table.deactivation-modal.header"),
                    t("management-organization-table.deactivation-modal.message", { name: org.name || "" })
                  )
                  handleClose()
                }}
              >
                {t("management-organization-table.menu.delete")}
              </MenuItem>
            </Menu>
          )}
        </TableCell>
      </TableRow>
    )
  })

  return (
    <>
      {rows}
      <Dialog />
    </>
  )
}

export default OrgTableRows
