import { Box, Paper, Typography } from "@mui/material"
import { SignOutButton } from "components/SignOutButton"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { UserRoleModel } from "services/openapi"
import { userContext } from "contexts/userContext"
import StyledMenuLink from "components/StyledMenuLink"
import logo_fi from "images/logo_fi.png"
import logo_eng from "images/logo_eng_v3.png"
import i18n from "i18n"
import { LanguageButtons } from "components/LanguageButtons"

const VERSION_NUMBER = "1.2.7"

const AdminNav = () => {
  const { t } = useTranslation()
  const { user } = useContext(userContext)

  return !user?.role ? null : (
    <Paper
      id="admin-nav"
      role="navigation"
      sx={{
        display: "flex",
        flexDirection: "column",
        minWidth: "250px",
        py: 2,
        marginRight: "30px",
        justifyContent: "space-between",
        height: "100%",
        backgroundColor: "#D5EDF9",
      }}
    >
      <LanguageButtons />
      <Box
        component="img"
        sx={{
          top: "auto",
          bottom: 0,
          minWidth: "250px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          paddingTop: "90px",
        }}
        alt={t("admin-nav.header")}
        src={i18n.language === "fi" ? logo_fi : logo_eng}
      />
      <Box
        sx={{
          textAlign: "center",
          fontSize: "20px",
          paddingTop: "20px",
          paddingBottom: "50px",
          marginLeft: "-25px",
          flexGrow: 0,
          color: "#4F5971",
        }}
      >
        <Typography fontWeight="bold">{t("admin-nav.header")}</Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 2,
        }}
      >
        {user?.role === UserRoleModel.SUPER_ADMIN && (
          <>
            <StyledMenuLink
              to={`/admin/super-admins`}
              activeClassName="selected"
              activeStyle={{
                backgroundColor: "#D1559A",
                color: "white",
              }}
            >
              {t("admin-nav.links.super-admins")}
            </StyledMenuLink>
            <StyledMenuLink
              to="/admin/management-organizations"
              activeClassName="selected"
              activeStyle={{
                backgroundColor: "#D1559A",
                color: "white",
              }}
            >
              {t("admin-nav.links.management-organizations")}
            </StyledMenuLink>
          </>
        )}

        {[UserRoleModel.MANAGEMENT_ORGANIZATION_ADMIN_USER, UserRoleModel.CARE_ORGANIZATION_USER].includes(
          user?.role!
        ) && (
            <StyledMenuLink
              to="/admin/care-organizations"
              activeClassName="selected"
              activeStyle={{
                backgroundColor: "#D1559A",
                color: "white",
              }}
            >
              {t("admin-nav.links.care-organizations")}
            </StyledMenuLink>
          )}

        {user?.role === UserRoleModel.CARE_ORGANIZATION_USER && (
          <StyledMenuLink
            to="/care-org-user"
            activeClassName="selected"
            activeStyle={{
              backgroundColor: "#D1559A",
              color: "white",
            }}
          >
            {t("admin-nav.links.mobile")}
          </StyledMenuLink>
        )}
      </Box>

      <SignOutButton />
      <Typography component="small" textAlign="center" mt={1} variant="body2">
        v{VERSION_NUMBER}
      </Typography>
    </Paper>
  )
}

export default AdminNav
