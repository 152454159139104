import { CareOrganizationCreateModel, CareOrganizationService, OrganizationListModel } from "services/openapi"

export const fetchOrganizations = async (
  managementOrgId: string,
  setLoading: (isLoading: boolean) => void,
  onSuccess: (organizations: OrganizationListModel[]) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoading(true)
    const orgs = await CareOrganizationService.careOrganizationGetAll(managementOrgId)
    onSuccess(orgs.items)
  } catch (e) {
    onError(e, "care-organization-list.snackbar.fetch-error")
  }
  setLoading(false)
}

export const createCareOrganization = async (
  organizationData: CareOrganizationCreateModel,
  onSuccess: () => void,
  onError: (error: any, message: string) => void
) => {
  try {
    await CareOrganizationService.careOrganizationCreateCareOrganization(organizationData)
    onSuccess()
    return true
  } catch (e) {
    onError(e, "care-organization-list.snackbar.create-error")
    return false
  }
}

export const deactivateCareOrganization = async (
  organizationId: string,
  onSuccess: () => void,
  onError: (error: any, message: string) => void
) => {
  try {
    await CareOrganizationService.careOrganizationDeactivateCareOrganization(organizationId)
    onSuccess()
    return true
  } catch (error) {
    onError(error, "care-organization-list.snackbar.deactivate-error")
    return false
  }
}
