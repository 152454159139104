import { Box, CircularProgress } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { ActivityHelperModel, ActivityModel, FeelingModel, FeelingTargetModel } from "services/openapi"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import MobileHistoryDaySummary from "scenes/MobileHistoryDaySummary"
import {
  getActivitiesOptionsForClientGroup,
  getActivityHelperOptionsForClientGroup,
} from "utils/apiRequests/fetchActivities"
import { getFeelingOptionsForClientGroup, getFeelingTargetsForClientGroup } from "utils/apiRequests/fetchFeelings"
import ClientGroupId from "utils/clientGroupId"
import { useHistory, useParams } from "react-router-dom"
import MobileViewDescription from "components/MobileViewBase/MobileViewDescription"
import { useTranslation } from "react-i18next"
import { toFinnishFormat } from "utils/functions/toDateFormat"
import { CareOrgUserContext } from "contexts/careOrgUserContext"
import CareOrganizationMobileViewBase from "components/CareOrganizationMobileViewBase"

const CareOrganizationUserDayDetails = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { clientId, date } = useParams<{ id: string; clientId: string; date: string }>()
  const { ErrorSnackbar, handleError } = useApiRequestError()
  const { state } = useContext(CareOrgUserContext)
  const [isLoadingFeelingOptions, setLoadingFeelingOptions] = useState<boolean>(true)
  const [isLoadingFeelingTargets, setLoadingFeelingTargets] = useState<boolean>(true)
  const [activityOptions, setActivityOptions] = useState<ActivityModel[]>([])
  const [activityHelperOptions, setActivityHelperOptions] = useState<ActivityHelperModel[]>([])
  const [feelingOptions, setFeelingOptions] = useState<FeelingModel[]>([])
  const [feelingTargetOptions, setFeelingTargetOptions] = useState<FeelingTargetModel[]>([])

  useEffect(
    function fetchData() {
      getActivitiesOptionsForClientGroup(ClientGroupId.ELDERLY, () => { }, setActivityOptions, handleError)
      getActivityHelperOptionsForClientGroup(ClientGroupId.ELDERLY, () => { }, setActivityHelperOptions, handleError)
      getFeelingOptionsForClientGroup(undefined, setLoadingFeelingOptions, setFeelingOptions, handleError)
      getFeelingTargetsForClientGroup(undefined, setLoadingFeelingTargets, setFeelingTargetOptions, handleError)
    },
    [handleError]
  )

  const closeDialog = () => {
    history.goBack()
  }

  return (
    <CareOrganizationMobileViewBase>
      <Box>
        <MobileViewDescription
          header={t("customer-mobile-view.feeling-history-day.title")}
          text={toFinnishFormat(date)}
        />
      </Box>

      {isLoadingFeelingOptions || isLoadingFeelingTargets ? (
        <CircularProgress size={100} />
      ) : (
        <MobileHistoryDaySummary
          clientId={clientId}
          clientGroupId={state.currentClient?.clientGroupId || 0}
          date={date}
          onClose={closeDialog}
          activityOptions={activityOptions}
          feelingOptions={feelingOptions}
          feelingTargetOptions={feelingTargetOptions}
          activityHelperOptions={activityHelperOptions}
        />
      )}
      <ErrorSnackbar />
    </CareOrganizationMobileViewBase>
  )
}

export default CareOrganizationUserDayDetails
