import { useEffect } from "react"

/*
 * Run any effect when the component mounts, and only then.
 */
function useMountEffect(effect: any) {
  useEffect(() => {
    effect()
    /*
     * We are running this effect only when the component mounts, so the dependencies of the
     * useEffect and changes to them are irrelevant. Thus, we ignore the eslint-rule warning about
     * useEffect dependencies.
     */
    /* eslint-disable-next-line */
  }, [])
}

export default useMountEffect
