/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CareOrganizationCreateModel } from "../models/CareOrganizationCreateModel"
import type { CareOrganizationDetailsModel } from "../models/CareOrganizationDetailsModel"
import type { CareOrganizationUpdateModel } from "../models/CareOrganizationUpdateModel"
import type { OrganizationListModelPagedResponseModel } from "../models/OrganizationListModelPagedResponseModel"
import { request as __request } from "../core/request"

export class CareOrganizationService {
  /**
   * @param managementOrganizationId
   * @param pageSize
   * @param page
   * @param searchString
   * @returns OrganizationListModelPagedResponseModel Success
   * @throws ApiError
   */
  public static async careOrganizationGetAll(
    managementOrganizationId?: string,
    pageSize?: number,
    page?: number,
    searchString?: string
  ): Promise<OrganizationListModelPagedResponseModel> {
    const result = await __request({
      method: "GET",
      path: `/CareOrganization/search`,
      query: {
        managementOrganizationId: managementOrganizationId,
        pageSize: pageSize,
        page: page,
        searchString: searchString,
      },
    })
    return result.body
  }

  /**
   * @param requestBody
   * @returns string Success
   * @throws ApiError
   */
  public static async careOrganizationCreateCareOrganization(
    requestBody?: CareOrganizationCreateModel
  ): Promise<string> {
    const result = await __request({
      method: "POST",
      path: `/CareOrganization/create`,
      body: requestBody,
    })
    return result.body
  }

  /**
   * Get organization details. If organization is not found, ArgumentException is thrown.
   * @param organizationId Organization id
   * @returns CareOrganizationDetailsModel Success
   * @throws ApiError
   */
  public static async careOrganizationGetDetails(organizationId: string): Promise<CareOrganizationDetailsModel> {
    const result = await __request({
      method: "GET",
      path: `/CareOrganization/details/${organizationId}`,
    })
    return result.body
  }

  /**
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public static async careOrganizationUpdateOrganization(requestBody?: CareOrganizationUpdateModel): Promise<any> {
    const result = await __request({
      method: "POST",
      path: `/CareOrganization/edit`,
      body: requestBody,
    })
    return result.body
  }

  /**
   * @param organizationId
   * @returns any Success
   * @throws ApiError
   */
  public static async careOrganizationDeactivateCareOrganization(organizationId: string): Promise<any> {
    const result = await __request({
      method: "DELETE",
      path: `/CareOrganization/deactivate/${organizationId}`,
    })
    return result.body
  }
}
