import { createTheme } from "@mui/material/styles"

export const adminTheme = createTheme({
  palette: {
    primary: {
      main: "#D1559A",
    },
    secondary: {
      main: "#FFFFFF",
    },
    text: {
      primary: "rgba(21,21,21,1)",
    },
    background: {
      default: "#FCFCFC",
    },
    warning: {
      main: "#C20514",
    },
    feelingColors: {},
  },
  typography: {
    fontFamily: "SofiaProLight",
    h1: {
      fontSize: "28px",
      fontFamily: "SofiaProMedium",
      marginTop: "0.67em",
      marginBottom: "0.67em",
    },
    h2: {
      fontSize: "28px",
      fontWeight: "bold",
    },
    button: {
      fontSize: "16px",
    },
  },
  components: {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: "24px",
          fontWeight: "bold",
          padding: "40px 40px 0px 40px",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "0px 40px 40px 40px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {},
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          margin: "30px",
        },
      },
    },
  },
})

declare module "@mui/material/styles" {
  interface Theme {
    /*
     * Custom theming variables here
     * status: {
     *  danger: string
     * }
     */
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    /*
     * And here as well
     * status?: {
     *  danger?: string
     * }
     */
  }
}
