import { Box, TextField } from "@mui/material"
import { FormikProps } from "formik"
import { useTranslation } from "react-i18next"

type CreateClientFieldsProps = {
  formik: FormikProps<{ firstName: string; lastName: string; familyId: string; clientGroupId: number }>
}

const CreateClientFields = ({ formik }: CreateClientFieldsProps) => {
  const { t } = useTranslation()
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <TextField
        id="firstName"
        label={t("create-client.first-name")}
        sx={{ mb: 2, float: "left", clear: "both" }}
        variant="standard"
        color="primary"
        {...formik.getFieldProps("firstName")}
        helperText={formik.touched.firstName && formik.errors.firstName}
        required
      />
      <TextField
        id="lastName"
        label={t("create-client.last-name")}
        sx={{ mb: 2, clear: "both", float: "left" }}
        variant="standard"
        color="primary"
        {...formik.getFieldProps("lastName")}
        helperText={formik.touched.lastName && formik.errors.lastName}
        required
      />
    </Box>
  )
}

export default CreateClientFields
