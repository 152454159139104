import { Box, Typography } from "@mui/material"
import DescriptionTitle from "./DescriptionTitle"

type Texts = {
  header: string
  text?: string | string[]
  backgroundColor?: string
}

const MobileViewDescription = ({ header, text, backgroundColor }: Texts) => {
  const effectiveBackGroundColor = backgroundColor ?? "rgba(0, 0, 0, 0.0)"
  const arrayFormat = text ? (!Array.isArray(text) ? [text] : text) : undefined

  return (
    <Box sx={{ backgroundColor: effectiveBackGroundColor, px: 6, py: 3 }}>
      <DescriptionTitle variant="h2">{header}</DescriptionTitle>
      {arrayFormat?.map((singleItem) => (
        <Typography key={singleItem} variant="body1" sx={{ textAlign: "center", fontSize: "1.2rem" }}>
          {singleItem}
        </Typography>
      ))}
    </Box>
  )
}

export default MobileViewDescription
