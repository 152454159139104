import { Box } from "@mui/material"
import { SignInButton } from "components/SignInButton"
import { useTranslation } from "react-i18next"
import { LanguageButtons } from "components/LanguageButtons"
import AppName from "components/AppName"
import WavyBackground from "components/WavyBackground"
import loginBackground from "images/loginBackground.svg"

const Login = () => {
  const { t } = useTranslation()
  return (
    <WavyBackground>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: "2",
          backgroundImage: `url(${loginBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "80% 80vh",
          backgroundPosition: "center bottom",
          minWidth: "100vw",
          width: "100%",
          margin: "0 auto",
          height: "100vh",
        }}
      >
        <LanguageButtons />
        <AppName variant="h1"> {t("login.welcome")}</AppName>
        <SignInButton />
      </Box>
    </WavyBackground>
  )
}

export default Login
