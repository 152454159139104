import { useMsal } from "@azure/msal-react"
import { Logout } from "@mui/icons-material"
import { Box, Button, styled } from "@mui/material"
import { useTranslation } from "react-i18next"

const StyledButton = styled(Button)(({ theme }) => ({
  display: "flex",
  position: "relative",
  left: theme.spacing(-5),
  height: theme.spacing(7),
  boxShadow: "5px 5px 10px 0px rgba(0,0,0,0.22)",
  borderRadius: "50px",
  backgroundColor: "white",
  margin: theme.spacing(3, 0),
  width: "100%",
  textDecoration: "none",
  color: "inherit",
  alignItems: "center",
  justifyContent: "space-between",
  fontWeight: "bold",
  fontFamily: "SofiaProBold",
}))

export const StyledSignOutButton = () => {
  const { instance } = useMsal()
  const { t } = useTranslation()

  const icon = (file: string) => (
    <Box sx={{ ml: 1, mt: 1 }}>
      <img src={`${process.env.PUBLIC_URL}/feelingIcons/${file}`} width={32} alt={""} aria-hidden="true" />
    </Box>
  )

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    })
  }
  return (
    <StyledButton
      sx={{ textTransform: "none", fontSize: 16 }}
      onClick={() => handleLogout()}
      startIcon={icon("Virkea.svg")}
      endIcon={<Logout sx={{ mx: 1 }} />}
    >
      {t("logout.button")}
    </StyledButton>
  )
}
