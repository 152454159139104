import { Button } from "@mui/material"
import { Link } from "react-router-dom"
import { useTheme } from "@mui/system"
import { ReactNode } from "react"

type HelpLinkProps = {
  to: string
  startIcon: JSX.Element
  children?: ReactNode
  onClick?: () => Promise<void>
}

const DidGetHelpLinkButton = ({ to, children, startIcon }: HelpLinkProps) => {
  const theme = useTheme()

  return (
    <Button
      component={Link}
      to={to}
      startIcon={startIcon}
      sx={{
        color: "black",
        backgroundColor: theme.palette.primary.main,
        borderRadius: "50px",
        width: "100%",
        fontFamily: "SofiaProSemiBold",
        minHeight: theme.spacing(7),
        boxShadow: "5px 5px 10px 0px rgba(0,0,0,0.22)",
        margin: theme.spacing(2, 0),
        justifyContent: "flex-start",
        paddingLeft: theme.spacing(4),
        fontSize: "1.25rem",
        textAlign: "left",
      }}
    >
      {children}
    </Button>
  )
}

export default DidGetHelpLinkButton
