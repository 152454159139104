import React from "react"

import { ProfileModel } from "services/openapi"

export const userContext = React.createContext<{
  user: ProfileModel | null
  setUser: (user: ProfileModel | null) => void
  isLoading: boolean
  setLoading: (isLoading: boolean) => void
}>({
  user: null,
  setUser: (user) => {},
  isLoading: true,
  setLoading: (isLoading) => {},
})
