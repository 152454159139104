import { InteractionStatus } from "@azure/msal-browser"
import { useMsal } from "@azure/msal-react"
import { Search } from "@mui/icons-material"
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import StyledButton from "components/StyledButton"
import { userContext } from "contexts/userContext"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { createFamily, deactivateFamily, fetchFamilies } from "scenes/admin/FamilyList/apiRequests"
import { FamilyUnitCreateModel, FamilyUnitListModel } from "services/openapi"
import TablePaginationActions from "utils/TablePaginationActions"
import FamilyTableRows from "./FamilyTableRows"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import useSnackbar from "utils/hooks/useSnackbar"
import CreateFamilyForm from "./CreateFamily"
import "./FamilyList.sass"
import LoadingIndicatorRow from "components/LoadingIndicatorRow"

type Props = {
  organizationId: string
}

const FamilyList = ({ organizationId }: Props) => {
  const { inProgress } = useMsal()
  const { t } = useTranslation()
  const { user: currentUser } = useContext(userContext)
  const { Snackbar, showSnackbar } = useSnackbar()

  const [isLoading, setLoading] = useState<boolean>(true)
  const [searchTerm, setSearchTerm] = useState("")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [families, setFamilies] = useState<FamilyUnitListModel[]>([])
  const [creatingFamily, setCreatingFamily] = useState(false)
  const [familyDialogOpen, setFamilyDialogOpen] = useState(false)
  const { ErrorSnackbar, handleError } = useApiRequestError()

  useEffect(
    function fetchFamiliesOnLoad() {
      if (inProgress === InteractionStatus.None) {
        fetchFamilies(organizationId, setLoading, setFamilies, handleError)
      }

      return () => {
        setLoading(true)
      }
    },
    [inProgress, handleError, organizationId]
  )

  const filteredFamilies =
    families?.filter((fam) => fam.name?.toLocaleUpperCase().includes(searchTerm.toLocaleUpperCase())) || []

  const slicedFamilies = filteredFamilies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage)
  }

  const onCreateFamilyButtonClick = async (newFamilyName: string, newFamilyDescription: string) => {
    const onFamilyCreationSuccess = (families: FamilyUnitListModel[]) => {
      setFamilies(families)
      showSnackbar(t("family-list.snackbar.create-success"), "success")
    }
    const newFamily: FamilyUnitCreateModel = {
      careOrganizationId: organizationId,
      familyUnitName: newFamilyName,
      description: newFamilyDescription,
    }

    setCreatingFamily(true)

    const success = await createFamily(newFamily, organizationId, onFamilyCreationSuccess, handleError)
    if (success) {
      setFamilyDialogOpen(false)
    }
    setCreatingFamily(false)
  }

  const onDeactivateFamilyMenuOptionClick = (familyId: string) => {
    const onDeactivationSuccess = (updatedFamilies: FamilyUnitListModel[]) => {
      setFamilies(updatedFamilies)
      showSnackbar(t("family-list.snackbar.deactivate-success"), "success")
    }
    return deactivateFamily(familyId, families, onDeactivationSuccess, handleError)
  }

  return (
    <>
      <Paper id="family-list">
        <div id="family-list-header">
          <Typography variant="h1">{t("family-list.header")}</Typography>

          {currentUser?.canManageFamilyUnits && (
            <StyledButton
              onClick={() => setFamilyDialogOpen(true)}
              variant="contained"
              endIcon={<span>+</span>}
              data-testid="create-family-button"
              sx={{ color: "#FFFFFF", bgcolor: "#D1559A" }}
            >
              {t("family-list.add-family")}
            </StyledButton>
          )}
        </div>

        <TextField
          id="family-search"
          label={t("family-list.search")}
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search role="img" aria-labelledby="family-search" />
              </InputAdornment>
            ),
          }}
        />

        <Table id="family-table" aria-label={t("family-list.table.label")}>
          <TableHead>
            <TableRow>
              <TableCell>{t("family-list.table.header.name")}</TableCell>
              <TableCell align="right">{t("family-list.table.header.controls")}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading ? (
              <LoadingIndicatorRow colSpan={2} />
            ) : (
              <FamilyTableRows families={slicedFamilies} deactivateFamily={onDeactivateFamilyMenuOptionClick} />
            )}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 25]}
                onRowsPerPageChange={(e: any) => {
                  setRowsPerPage(e.target.value)
                  setPage(0)
                }}
                page={page}
                count={filteredFamilies.length || 0}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={t("table-pagination.rows-per-page")}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>

      <Dialog open={familyDialogOpen} onClose={() => setFamilyDialogOpen(false)}>
        <DialogTitle>{t("family-list.create-family.header")}</DialogTitle>
        <DialogContent id="create-family">
          <Box id="create-family-inputs" sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <CreateFamilyForm creatingFamily={creatingFamily} onCreateFamilyButtonClick={onCreateFamilyButtonClick} />
          </Box>
        </DialogContent>
      </Dialog>

      <Snackbar />
      <ErrorSnackbar />
    </>
  )
}

export default FamilyList
