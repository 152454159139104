import { Box } from "@mui/system"
import { useTranslation } from "react-i18next"
import ActivityDetailsHeader from "./styled/ActivityDetailsHeader"

type ActivityDetailsTopBarProps = {
  activityName: string
  iconFilename: string
}

const ActivityDetailsTopBar = ({ activityName, iconFilename }: ActivityDetailsTopBarProps) => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "static",
        backgroundColor: "primary.main",
        zIndex: 3,
        height: "112px",
        top: "34px",
        mx: 4,
        mt: 4,
      }}
    >
      <Box sx={{ mr: 4 }}>
        <img
          src={`${process.env.PUBLIC_URL}/activityIcons/${iconFilename}`}
          width={68}
          alt={`${iconFilename}`}
          aria-hidden="true"
        />
      </Box>

      <ActivityDetailsHeader variant="h1">
        {t(`elderly-view.activity-selection.activity-options.${activityName}`)}
      </ActivityDetailsHeader>
    </Box>
  )
}

export default ActivityDetailsTopBar
