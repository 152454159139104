import { MoreVert } from "@mui/icons-material"
import { Button, Menu, MenuItem, TableCell, TableRow } from "@mui/material"
import { useCallback, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useRouteMatch } from "react-router-dom"
import { FamilyUnitListModel } from "services/openapi"
import { userContext } from "contexts/userContext"
import useConfirmationDialog from "utils/hooks/useConfirmationDialog"
import StyledTableLink from "../../../components/StyledTableLink"

type Props = {
  families: FamilyUnitListModel[]
  deactivateFamily: (familyId: string) => Promise<boolean>
}

// Map items on page to be displayed
const FamilyTableRows = ({ families, deactivateFamily }: Props) => {
  const { user } = useContext(userContext)
  const { Dialog, showConfirmationDialog } = useConfirmationDialog()
  const { t } = useTranslation()
  const { url } = useRouteMatch()

  const [anchorEl, setAnchorEl] = useState(null)
  const [famMenu, setFamMenu] = useState<FamilyUnitListModel | null>(null)

  const handleClick = useCallback(
    (event: any, fam: FamilyUnitListModel) => {
      setAnchorEl(event.currentTarget)
      setFamMenu(fam)
    },
    [setAnchorEl, setFamMenu]
  )

  const handleClose = () => {
    setAnchorEl(null)
    setFamMenu(null)
  }

  if (!families) return null

  const rows = families.map((fam, i) => {
    return (
      <TableRow key={`fam-${i}`}>
        <TableCell>
          <StyledTableLink to={`${url}/family/${fam.id}`}>{fam.name}</StyledTableLink>
        </TableCell>
        <TableCell align="right">
          {user?.canManageFamilyUnits && (
            <Button
              onClick={(e) => handleClick(e, fam)}
              id={`fam-control-button-${i}`}
              aria-label={t("family-list.table.aria-controls", { name: `${fam.name}` })}
            >
              <MoreVert />
            </Button>
          )}

          {/* 
          Check open status here instead of in the menu open property to avoid rendering extra menus unnecessarily.
          */}
          {fam === famMenu && (
            <Menu
              className="user-menu"
              open
              anchorEl={anchorEl}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": `family-button-${i}`,
              }}
            >
              <MenuItem
                onClick={() => {
                  showConfirmationDialog(
                    () => deactivateFamily(fam.id || ""),
                    t("family-list.deactivate.header"),
                    t("family-list.deactivate.message", { name: `${fam.name}` })
                  )
                  handleClose()
                }}
              >
                {t("family-list.deactivate-family")}
              </MenuItem>
            </Menu>
          )}
        </TableCell>
      </TableRow>
    )
  })

  return (
    <>
      {rows}
      <Dialog />
    </>
  )
}

export default FamilyTableRows
