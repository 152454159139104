import StyledListingHeader from "components/StyledListingHeader"
import { useTranslation } from "react-i18next"

const ClientListingHeader = () => {
  const { t } = useTranslation()

  return (
    <StyledListingHeader sx={{ mx: 1 }} variant="h1">
      {t("care-org-mobile-view.client-listing.title")}
    </StyledListingHeader>
  )
}

export default ClientListingHeader
