/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ManagementOrganizationUserCreateModel } from "../models/ManagementOrganizationUserCreateModel"
import type { UserCreateResultModel } from "../models/UserCreateResultModel"
import type { UserListModel } from "../models/UserListModel"
import type { UserListModelPagedResponseModel } from "../models/UserListModelPagedResponseModel"
import { request as __request } from "../core/request"

export class ManagementOrganizationUserService {
  /**
   * Creates a new management organization admin for organization
   * @param requestBody Created user
   * @returns UserCreateResultModel Success
   * @throws ApiError
   */
  public static async managementOrganizationUserCreateManagementOrganizationAdmin(
    requestBody?: ManagementOrganizationUserCreateModel
  ): Promise<UserCreateResultModel> {
    const result = await __request({
      method: "POST",
      path: `/ManagementOrganizationUser/createManagementOrganizationAdmin`,
      body: requestBody,
    })
    return result.body
  }

  /**
   * @param userId
   * @returns UserCreateResultModel Success
   * @throws ApiError
   */
  public static async managementOrganizationUserResetPassword(userId: string): Promise<UserCreateResultModel> {
    const result = await __request({
      method: "POST",
      path: `/ManagementOrganizationUser/resetPassword/${userId}`,
    })
    return result.body
  }

  /**
   * @param organizationId
   * @param pageSize
   * @param page
   * @param searchString
   * @returns UserListModelPagedResponseModel Success
   * @throws ApiError
   */
  public static async managementOrganizationUserGetOrganizationUsers(
    organizationId: string,
    pageSize?: number,
    page?: number,
    searchString?: string
  ): Promise<UserListModelPagedResponseModel> {
    const result = await __request({
      method: "GET",
      path: `/ManagementOrganizationUser/getOrganizationUsers`,
      query: {
        organizationId: organizationId,
        pageSize: pageSize,
        page: page,
        searchString: searchString,
      },
    })
    return result.body
  }

  /**
   * @param id
   * @returns UserListModel Success
   * @throws ApiError
   */
  public static async managementOrganizationUserGetDetails(id: string): Promise<UserListModel> {
    const result = await __request({
      method: "GET",
      path: `/ManagementOrganizationUser/get/${id}`,
    })
    return result.body
  }

  /**
   * @param userId
   * @returns any Success
   * @throws ApiError
   */
  public static async managementOrganizationUserDeactivateUser(userId: string): Promise<any> {
    const result = await __request({
      method: "DELETE",
      path: `/ManagementOrganizationUser/deactivate/${userId}`,
    })
    return result.body
  }
}
