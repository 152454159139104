import { Link } from "react-router-dom"
import { Button } from "@mui/material"

type BottomNavButtonProps = {
  to: string
  children: any
}

const BottomNavButton = ({ to, children }: BottomNavButtonProps) => {
  const toTop = () => {
    // We want to start next page from the top.
    window.scrollTo(0, 0)
  }

  return (
    <Button
      sx={{
        bgcolor: "none",
        color: "text.primary",
        fontFamily: "SofiaProMedium",
        fontSize: "1rem",
        boxShadow: "5px 5px 10px 0px rgba(0,0,0,0.22)",
        borderRadius: "50px",
      }}
      component={Link}
      onClick={toTop}
      to={to}
    >
      {children}
    </Button>
  )
}

export default BottomNavButton
