/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivityHelperModel } from "../models/ActivityHelperModel"
import type { ActivityModel } from "../models/ActivityModel"
import type { GroupedActivities } from "../models/GroupedActivities"
import type { UserActivityListModel } from "../models/UserActivityListModel"
import type { UserActivityModel } from "../models/UserActivityModel"
import type { UserActivitySaveModel } from "../models/UserActivitySaveModel"
import { request as __request } from "../core/request"

export class ActivityService {
  /**
   * Return supported activities for client group
   * @param groupId Group id
   * @returns ActivityModel Success
   * @throws ApiError
   */
  public static async activityGetActivitiesForClientGroup(groupId: number): Promise<Array<ActivityModel>> {
    const result = await __request({
      method: "GET",
      path: `/Activity/getActivityOptions/${groupId}`,
    })
    return result.body
  }

  /**
   * @param groupId
   * @returns ActivityHelperModel Success
   * @throws ApiError
   */
  public static async activityGetActivityHelpersForClientGroup(groupId: number): Promise<Array<ActivityHelperModel>> {
    const result = await __request({
      method: "GET",
      path: `/Activity/getActivityHelperOptions/${groupId}`,
    })
    return result.body
  }

  /**
   * @param userId
   * @param from
   * @param to
   * @returns UserActivityListModel Success
   * @throws ApiError
   */
  public static async activityGetForUser(
    userId: string,
    from?: string,
    to?: string
  ): Promise<Array<UserActivityListModel>> {
    const result = await __request({
      method: "GET",
      path: `/Activity/getForUser/${userId}`,
      query: {
        from: from,
        to: to,
      },
    })
    return result.body
  }

  /**
   * @param userId
   * @param date
   * @returns UserActivityModel Success
   * @throws ApiError
   */
  public static async activityGetForUserSingleDay(userId: string, date: string): Promise<UserActivityModel> {
    const result = await __request({
      method: "GET",
      path: `/Activity/getForUserDetails/${userId}/${date}`,
    })
    return result.body
  }

  /**
   * @param userId
   * @param from
   * @param to
   * @returns GroupedActivities Success
   * @throws ApiError
   */
  public static async activityGetAggregatedForUser(
    userId: string,
    from?: string,
    to?: string
  ): Promise<Array<GroupedActivities>> {
    const result = await __request({
      method: "GET",
      path: `/Activity/getAggregatedForUser/${userId}`,
      query: {
        from: from,
        to: to,
      },
    })
    return result.body
  }

  /**
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public static async activitySaveUserActivity(requestBody?: UserActivitySaveModel): Promise<any> {
    const result = await __request({
      method: "POST",
      path: `/Activity/saveUserActivity`,
      body: requestBody,
    })
    return result.body
  }
}
