import { ListItem, ListItemProps, Paper } from "@mui/material"
import { Theme } from "@mui/material/styles"

const EntityListItem = ({ children }: ListItemProps) => {
  return (
    <Paper elevation={9}>
      <ListItem
        sx={{
          borderLeft: (theme: Theme) => `16px solid ${theme.palette.secondary.main}`,
          marginBottom: 1,
        }}
      >
        {children}
      </ListItem>
    </Paper>
  )
}

export default EntityListItem
