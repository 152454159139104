import MobileViewBase from "components/MobileViewBase"
import { useTranslation } from "react-i18next"
import MobileViewDescription from "components/MobileViewBase/MobileViewDescription"
import ActivityHistory from "./ActivityHistory"
import HistorySection from "../MobileFeelingHistory/HistorySection"

const ElderlyDailySummary = () => {
  const { t } = useTranslation()

  return (
    <>
      <MobileViewBase
        top={
          <MobileViewDescription
            header={t("elderly-view.activities-history.title")}
            text={t("elderly-view.activities-history.description")}
          />
        }
        middle={
          <>
            <ActivityHistory />
            <HistorySection />
          </>
        }
        bottom={<></>}
      />
    </>
  )
}

export default ElderlyDailySummary
