import { styled, Typography } from "@mui/material"
import { TypographyProps } from "@mui/system"

const StyledHeader = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: "24px",
  minHeight: "10vh",
  textAlign: "center",
  paddingTop: theme.spacing(3),
  "& > strong": {
    fontSize: "75%",
    opacity: 0.75,
  },
}))

export default StyledHeader
