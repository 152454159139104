import { Box, Fab } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Theme } from "@mui/system"
import { Link as RouterLink } from "react-router-dom"

type GoBackButtonProps = {
  url: string
}

const GoBackButton = ({ url }: GoBackButtonProps) => {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: (theme: Theme) => theme.spacing(4),
        left: (theme: Theme) => theme.spacing(3),
        mt: "auto",
      }}
    >
      <Fab color="secondary" aria-label="Go back" component={RouterLink} to={url}>
        <ArrowBackIcon color="primary" />
      </Fab>
    </Box>
  )
}

export default GoBackButton
