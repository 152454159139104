import produce from "immer"
import { DiaryEntrySaveModel } from "services/openapi"
import { toDateFormat } from "utils/functions/toDateFormat"

export type DiarySate = {
  diary: DiaryEntrySaveModel
}

export type Action =
  | { type: "setInitialDiary"; diary: DiaryEntrySaveModel }
  | { type: "updateDiaryForm"; diary: DiaryEntrySaveModel }
  | { type: "updateDiaryGoodThings"; diary: DiaryEntrySaveModel }

export const initialDiaryState: DiarySate = {
  diary: {
    userId: "",
    date: toDateFormat(new Date()),
    message: "",
    isPublic: false,
    pick1: "",
    pick2: "",
    pick3: "",
  },
}

export const reducer = produce((draft: DiarySate, action: Action) => {
  switch (action.type) {
    case "setInitialDiary":
      draft.diary.date = action.diary.date
      draft.diary.userId = action.diary.userId
      draft.diary.message = action.diary.message
      draft.diary.isPublic = action.diary.isPublic
      draft.diary.pick1 = action.diary.pick1
      draft.diary.pick2 = action.diary.pick2
      draft.diary.pick3 = action.diary.pick3
      break
    case "updateDiaryForm":
      draft.diary.userId = action.diary.userId
      draft.diary.message = action.diary.message
      draft.diary.isPublic = action.diary.isPublic
      break
    case "updateDiaryGoodThings":
      draft.diary.userId = action.diary.userId
      draft.diary.pick1 = action.diary.pick1
      draft.diary.pick2 = action.diary.pick2
      draft.diary.pick3 = action.diary.pick3
      break
    default:
      break
  }
})
