import { NavLink, NavLinkProps } from "react-router-dom"
import { styled } from "@mui/material"

const StyledMenuLink = styled(NavLink)<NavLinkProps>(({ theme }) => ({
  textDecoration: "none",
  padding: "16px",
  borderRadius: "5px",
  margin: "0px 20px",
  fontFamily: "SofiaProLight, sans-serif",
}))

export default StyledMenuLink
