import { LinearProgress, TableCell, TableRow } from "@mui/material"

type LoadingIndicatorRowProps = {
  colSpan: number
}

const LoadingIndicatorRow = ({ colSpan }: LoadingIndicatorRowProps) => {
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <LinearProgress />
      </TableCell>
    </TableRow>
  )
}

export default LoadingIndicatorRow
