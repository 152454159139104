import { Dialog, DialogContent, DialogTitle } from "@mui/material"
import StyledButton from "components/StyledButton"
import { userContext } from "contexts/userContext"
import { Form, Formik } from "formik"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { OrganizationListModel, UserRoleModel } from "services/openapi"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import useSnackbar from "utils/hooks/useSnackbar"
import { createCareOrganization, fetchOrganizations } from "./apiRequests"
import * as Yup from "yup"
import CreateCareOrgFields from "./CreateCareOrgFields"

type CreateCareOrganizationProps = {
  organizationsListUpdateCallback: (organizations: OrganizationListModel[]) => void
}

type FormValues = {
  organizationName: string
  description: string
}

const CreateCareOrganization = ({ organizationsListUpdateCallback }: CreateCareOrganizationProps) => {
  const { t } = useTranslation()
  const { user } = useContext(userContext)
  // Currently profile should only have one managementOrg so we can assume the first array element to be correct
  // but on the chance that in the future profile can contain multiple orgs, the one is now wrapped in an array
  const managementOrgId = user?.managementOrganizationIds?.[0] || ""

  const [open, setOpen] = useState(false)
  const [creating, setCreating] = useState(false)
  const [, setLoading] = useState<boolean>(true)
  const { Snackbar, showSnackbar } = useSnackbar()
  const { ErrorSnackbar, handleError } = useApiRequestError()

  const onCreateButtonClick = async ({ organizationName, description }: FormValues) => {
    setCreating(true)
    await createCareOrganization(
      { managementOrganizationId: managementOrgId, organizationName, description },
      () => showSnackbar(t("care-organization-list.snackbar.create-success"), "success"),
      handleError
    ).then((success: boolean) => {
      if (success) {
        setOpen(false)
      }
      setCreating(false)
    })
    setOpen(false)
    await fetchOrganizations(managementOrgId, setLoading, organizationsListUpdateCallback, handleError)
  }

  return user?.role !== UserRoleModel.MANAGEMENT_ORGANIZATION_ADMIN_USER ? null : (
    <>
      <StyledButton
        onClick={() => setOpen(true)}
        variant="contained"
        endIcon={<span>+</span>}
        data-testid="create-care-organization-button"
      >
        {t("care-organization-list.add-organization")}
      </StyledButton>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{t("create-care-organization.title")}</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
          <Formik
            initialValues={{ organizationName: "", description: "" }}
            validationSchema={Yup.object({
              organizationName: Yup.string()
                .trim()
                .max(50, t("form-validation.too-long", { length: 50 }))
                .required(t("form-validation.required")),
              description: Yup.string()
                .trim()
                .max(500, t("form-validation.too-long", { length: 500 })),
            })}
            onSubmit={(values) => {
              onCreateButtonClick(values)
            }}
          >
            {(formik) => (
              <Form>
                <CreateCareOrgFields formik={formik}></CreateCareOrgFields>
                <StyledButton
                  sx={{
                    width: "fit-content",
                    alignSelf: "end",
                    float: "right",
                    clear: "both",
                    mt: 2,
                  }}
                  variant="contained"
                  type="submit"
                  disabled={creating}
                >
                  {t("create-care-organization.submit-button")}
                </StyledButton>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Snackbar />
      <ErrorSnackbar />
    </>
  )
}

export default CreateCareOrganization
