import { Box } from "@mui/system"
import { useTranslation } from "react-i18next"
import { ActivityHelperModel } from "services/openapi"
import StyledListing from "./styled/StyledListing"

type ActivityHelperBoxProps = {
  activityHelperId: number
  activityHelperOptions: ActivityHelperModel[]
}

const ActivityHelperBox = ({ activityHelperId, activityHelperOptions }: ActivityHelperBoxProps) => {
  const { t } = useTranslation()
  const helperDetails = activityHelperOptions.find((activityModel) => activityModel.id === activityHelperId)

  return (
    <Box
      sx={{
        mt: 1,
        ml: 3,
        mr: 3,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <img src={`${process.env.PUBLIC_URL}/helperIcons/${helperDetails?.imageLocation}`} width={45} alt={""} />
      <StyledListing>{t(`elderly-view.helper-selection.helper-options.${helperDetails?.name}`)}</StyledListing>
    </Box>
  )
}

export default ActivityHelperBox
