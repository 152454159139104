import StyledListingHeader from "components/StyledListingHeader"

type Props = {
  familyName: string
}

const ClientListingFamilyName = ({ familyName }: Props) => {
  return (
    <StyledListingHeader
      variant="h1"
      sx={{
        width: "100%",
        textAlign: "center",
      }}
    >
      {familyName}
    </StyledListingHeader>
  )
}

export default ClientListingFamilyName
