import { Form, Formik } from "formik"
import * as Yup from "yup"
import { TextField } from "@mui/material"
import { useTranslation } from "react-i18next"
import StyledButton from "components/StyledButton"

type Props = {
  creatingFamily: boolean
  onCreateFamilyButtonClick: (newFamilyName: string, newFamilyDescription: string) => Promise<void>
}

const CreateFamilyForm = ({ creatingFamily, onCreateFamilyButtonClick }: Props) => {
  const { t } = useTranslation()
  return (
    <Formik
      initialValues={{ newFamilyName: "", newFamilyDescription: "" }}
      validationSchema={Yup.object({
        newFamilyName: Yup.string()
          .trim()
          .max(50, t("form-validation.too-long", { length: 50 }))
          .required(t("form-validation.required")),
        newFamilyDescription: Yup.string()
          .trim()
          .max(500, t("form-validation.too-long", { length: 500 })),
      })}
      onSubmit={(values) => {
        onCreateFamilyButtonClick(values.newFamilyName, values.newFamilyDescription)
      }}
    >
      {(formik) => (
        <Form>
          <TextField
            id="newFamilyName"
            sx={{
              mb: 2,
              float: "left",
            }}
            label={t("family-list.create-family.label.family-name")}
            required
            {...formik.getFieldProps("newFamilyName")}
            helperText={formik.touched.newFamilyName && formik.errors.newFamilyName}
            variant="standard"
            color="primary"
          />
          <TextField
            id="newFamilyDescription"
            sx={{
              mb: 2,
              float: "left",
              clear: "both",
            }}
            label={t("family-list.create-family.label.family-description")}
            {...formik.getFieldProps("newFamilyDescription")}
            helperText={formik.touched.newFamilyDescription && formik.errors.newFamilyDescription}
            variant="standard"
            color="primary"
          />
          <StyledButton
            type="submit"
            sx={{
              width: "fit-content",
              alignSelf: "end",
              float: "left",
              clear: "both",
              marginLeft: 20,
            }}
            variant="contained"
            disabled={creatingFamily}
          >
            {t("family-list.create-family.save-button")}
          </StyledButton>
        </Form>
      )}
    </Formik>
  )
}

export default CreateFamilyForm
