const TOS = `
# BEDINGUNGEN FÜR NUTZER DES DIENSTES MOODPILOT

## 1 Allgemeine Informationen

Diese Bedingungen für das Recht des Dienstnutzers, den Dienst zu
nutzen (im Folgenden als "Nutzungsbedingungen" bezeichnet),
umfassen die Bedingungen für die Nutzung des Fiilisluotsi/MoodPilot-
Dienstes (im Folgenden als "Dienst" bezeichnet) von HämeenSpes Oy
(im Folgenden als "Spes-Dienste" bezeichnet) und etwaiger Add-Ons
zu diesem, denen Sie als Privatperson, die den Dienst nutzt (im
Folgenden als "Dienstnutzer" oder "Sie" bezeichnet), zustimmen, wenn
Sie sich als Nutzer des Dienstes registrieren, wenn Sie den Dienst
installieren oder wenn Sie den Dienst nutzen.

Die Nutzung des Dienstes erfordert eine Registrierung. Indem Sie sich
für den Dienst registrieren oder ihn nutzen, erklären Sie sich mit diesen
Nutzungsbedingungen einverstanden.

## 2 Zugangsrechte

Wenn Sie sich beim Fiilisluotsi/MoodPilot-Dienst anmelden oder als
Nutzer des Dienstes registrieren, gewährt Ihnen Spes Services ein
begrenztes, nicht übertragbares Recht zur Nutzung des Dienstes.

Das Nutzungsrecht ist so lange gültig, wie Sie den vom Dienstanbieter
bereitgestellten Dienst auf der Grundlage einer Vereinbarung oder eines
anderen Beschlusses in Bezug auf den Dienst nutzen. Das
Nutzungsrecht endet automatisch mit der Beendigung des Vertrags, der
Entscheidung oder einer anderen Vereinbarung zwischen den Spes-
Diensten und dem Dienstanbieter.

## 3 Ihre Verantwortlichkeiten und Verpflichtungen

Spes Services stellt die SaaS-Plattform (als Softwareservice) in
Übereinstimmung mit dem Service als Teil des Betriebs des Service
Providers zur Verfügung.

Die Implementierung des Dienstes hat keinen Einfluss auf das
Arbeits- oder Dienstverhältnis zwischen Ihnen und dem
Dienstanbieter und die sich daraus ergebenden Pflichten oder
Rechte (einschließlich der Nutzung personenbezogener Daten),
sondern stellt lediglich eine elektronische Plattform für diese
Tätigkeit zur Verfügung.

Es liegt in Ihrer Verantwortung, den Dienst in Übereinstimmung mit
diesen Nutzungsbedingungen zu nutzen und die mit der Nutzung des
Dienstes verbundenen Anweisungen und Vorschriften einzuhalten.

Sie sind verantwortlich für alle Materialien, die Sie an den Dienst
übermitteln, wie z. B. Texte, Informationen und Dokumente. Sie sind
dafür verantwortlich, dass das von Ihnen in den Dienst hochgeladene
Material nicht gegen die Urheberrechte Dritter verstößt und dass Sie die
Erlaubnis und das Recht haben, solches Material in den Dienst
einzubringen.

Sie verpflichten sich, über den Dienst kein Material zu speichern,
aufzubewahren, zu verteilen, zu übertragen oder zu verbreiten, das
gegen Gesetze und/oder die guten Sitten verstößt, dazu aufruft oder
diese fördert.

Sie verpflichten sich, über den Dienst kein Material zu speichern,
aufzubewahren, zu verteilen, zu übermitteln oder zu verbreiten, das die
persönlichen Daten und Angelegenheiten anderer betrifft, wie z. B.
Gesundheitsinformationen.

Der Dienst erkennt Sie durch eine Kunden-ID und ein Passwort. Es liegt
in Ihrer Verantwortung, dafür zu sorgen, dass Ihre Kunden-ID und Ihr
Passwort sorgfältig aufbewahrt und vertraulich behandelt werden. Sie
sind für jede Nutzung des Dienstes mit Ihrer Kundenkennung und Ihrem
Passwort verantwortlich. Sie müssen den Dienstanbieter unverzüglich
benachrichtigen, wenn Sie Ihre Kunden-ID oder Ihr Passwort verloren
haben oder den Verdacht haben, dass Ihre Kunden-ID ohne Ihre
Zustimmung verwendet wird.

Wenn Sie nicht mehr berechtigt sind, den Dienst zu nutzen, z. B. weil Sie
nicht mehr bei einem Dienstanbieter, der den Dienst nutzt, beschäftigt
sind oder einen Vertrag mit ihm haben, müssen Sie den Dienstanbieter
darüber informieren und die Nutzung des Dienstes einstellen. Sie haben
nicht das Recht, Ihre Kunden-IDs selbständig an einen Dritten zu
übertragen.

Sie verpflichten sich, den Dienst nicht zu nutzen:

1. sich an Aktivitäten zu beteiligen, die die Nutzung des Dienstes für
andere Nutzer erschweren oder erschweren könnten,
2. zur Übermittlung von Spam oder anderen unerlaubten Nachrichten;
3. Veröffentlichung oder Übermittlung von Material, das die Rechte
anderer Personen oder Organisationen verletzt, rechtswidrig,
entwürdigend, obszön oder anderweitig verletzend ist;
4. die Erhebung oder Speicherung von personenbezogenen Daten
anderer Nutzer ohne deren ausdrückliche Zustimmung; oder
5. illegale Handlungen.

Spes Services behält sich das Recht vor, alle seine gesetzlichen Rechte
geltend zu machen, die sich aus der Verletzung dieser Einschränkungen
ergeben. Spes Services hat das Recht, ohne Ihnen gegenüber zu haften,
Ihren Zugang zum Service zu sperren oder einzuschränken oder Ihren
Zugang zu widerrufen oder jede andere Maßnahme zu ergreifen, die
erforderlich ist, um eine Aktivität zu stoppen, die gegen die
Nutzungsbedingungen verstößt.

## 4 Einwilligung in die Verwendung personenbezogener Daten

Um den Dienst nutzen zu können, müssen Sie dem Dienst in Verbindung
mit der Erstellung Ihres Benutzernamens und Passworts persönliche
Informationen über sich selbst übermitteln. Diese Informationen sind in
der Datenschutzrichtlinie von Spes Services beschrieben. Der Betreiber
des Dienstes Spes Services agiert zusammen mit dem Dienstanbieter als
Datenverantwortlicher/Verarbeiter von personenbezogenen Daten im
Sinne der Datenschutzgesetzgebung. Mit dem Dienstanbieter wurde ein
Vertrag über die Verarbeitung personenbezogener Daten
abgeschlossen, nach dem wir arbeiten.

Die für das Register erforderlichen personenbezogenen Daten können
verwendet warden

-	Zu Ihrer Identifikation und für die Durchführung des Dienstes;
-	Verwaltung und Entwicklung des Dienstes auch durch
-	Softwareentwickler außerhalb der EU;
-	um Benachrichtigungen oder Nachrichten zu versenden;
-	zusätzliche Dienstleistungen oder Anweisungen zu erbringen,
-	umzusetzen und aufrechtzuerhalten;
-	Vermarktung von Produkten und Dienstleistungen;
-	Entwicklung des Kundendienstes und der Geschäftstätigkeit;
-	zu Analyse- und Statistikzwecken;
-	Austausch von Nachrichten zwischen dem Dienstbetreiber und dem
-	Dienstanbieter und Ihnen; und
-	für andere ähnliche Zwecke

Indem Sie sich für den Dienst registrieren und ihn nutzen, geben Sie
Spes Services und dem Dienstanbieter Ihre ausdrückliche
Zustimmung, Ihre in der Registerbeschreibung beschriebenen
personenbezogenen Daten für die Bereitstellung des Dienstes zu
verwenden.

Die gesamte Verarbeitung personenbezogener Daten im
Zusammenhang mit dem Dienst unterliegt der Beschreibung des
Registers der Spes-Dienste, die auf der Website
[https://www.spespalvelut.fi/rekisteri-jatitietosuojaseloste] verfügbar ist.

Sie haben das Recht, Ihre Einwilligung jederzeit ganz oder teilweise zu
widerrufen. Bitte beachten Sie jedoch, dass die Nutzung des Dienstes die
Weitergabe personenbezogener Daten im Zusammenhang mit dem
Dienst erfordert.

## 5 Beendigung der Nutzung des Dienstes

Wenn Sie die Nutzung des Dienstes beenden und Ihre Registrierung
löschen möchten, müssen Sie den Dienstanbieter schriftlich darüber
informieren. Der Dienstanbieter wird Ihren Benutzernamen unverzüglich,
spätestens jedoch innerhalb von 14 Tagen nach Ihrer schriftlichen
Aufforderung, löschen.

## 6 Beschränkung der Haftung

Spes Services stellt den Service "wie er ist" zur Verfügung. Es wird keine
Garantie oder Haftung für den Dienst übernommen. Spes Services
garantiert nicht, dass der Service ohne Unterbrechung oder fehlerfrei
genutzt werden kann. Spes Services behält sich das Recht vor, aus
berechtigten Gründen jederzeit und ohne vorherige Ankündigung
Änderungen am Service vorzunehmen oder den Zugang zum Service zu
beschränken.

Spes Services ist nicht verantwortlich für das Material, das Sie in den
Dienst eingegeben haben, für dessen Rechtmäßigkeit, Inhalt oder dafür,
ob das Material möglicherweise die geistigen Eigentumsrechte Dritter
verletzt.

Spes Services ist nicht verantwortlich für die Rückgabe oder
Aufbewahrung des Materials, das Sie dem Dienst nach Beendigung der
Nutzung des Dienstes zur Verfügung gestellt haben.

Sie sind in vollem Umfang verantwortlich für Schäden, die dem Spes-
Dienst, anderen Nutzern des Dienstes und Dritten durch die Verletzung
der Nutzungsbedingungen, anderer Vertragsbedingungen und durch
eine Nutzung des Dienstes, die gegen das Gesetz oder die guten Sitten
verstößt, entstehen.

## 7 Urheberrecht und andere Rechte

Das Eigentum am Service und das Urheberrecht sowie alle anderen
geistigen Eigentumsrechte (einschließlich, aber nicht beschränkt auf
Urheberrechte, nicht eingetragene und eingetragene Marken- und
Designrechte, Patente, Domainnamen, Geschäftsgeheimnisse und
Datenbankrechte) am Service und an jeglichem Material im Service, das
nicht von Ihnen hinzugefügt wurde, gehören den Spes-Diensten.

Alle nicht ausdrücklich in diesen Nutzungsbedingungen gewährten
Rechte sind vorbehalten. Spes Services gewährt Ihnen keine anderen
direkten oder indirekten Rechte zur Nutzung des Dienstes als das Recht,
den Dienst für seinen Zweck in Übereinstimmung mit diesen
Nutzungsbedingungen zu nutzen.

Das Kopieren, Übertragen, Verteilen oder Speichern des gesamten oder
eines Teils des Inhalts des Dienstes in irgendeiner Form ist ohne
vorherige schriftliche Zustimmung von Spes Services verboten.

## 8 Material von Dritten

Der Dienst kann Links zu Seiten und Inhalten enthalten, die Dritten
gehören oder von ihnen unterhalten werden. Wenn Sie auf solche Seiten
zugreifen, müssen Sie deren mögliche Nutzungsbedingungen lesen und
ihnen zustimmen, bevor Sie sie nutzen. Die verlinkten Seiten stehen nicht
unter der Kontrolle von Spes Services, und Spes Services hat keinen
Einfluss auf den Inhalt des dort veröffentlichten Materials. Spes Services
ist nicht verantwortlich für Material oder Dienstleistungen, die auf den
Websites Dritter enthalten sind.

## 9 Spes hat das Recht, Material zu entfernen oder zu zensieren, das der Nutzer des
Dienstes an den Dienst übermittelt.

Spes Services behält sich das Recht vor, nach eigenem Ermessen von
Ihnen erstellte Inhalte oder Materialien aus dem Service zu entfernen
oder zu zensieren, wenn die Übermittlung solcher Materialien nach
Ansicht von Spes Services gegen diese Nutzungsbedingungen verstößt
oder verstoßen würde.

## 10 Datenschutz und andere Bestimmungen über personenbezogene Daten

Diese Nutzungsbedingungen unterliegen der Datenschutzrichtlinie für die
Spes-Dienste.

Spes Services kann personenbezogene Daten im Zusammenhang mit
der Nutzung des Dienstes so verwenden, dass Sie nicht identifiziert oder
identifizierbar sind. Spes Services kann solche Informationen anonym
oder pseudonymisiert unter anderem für die folgenden Zwecke
verwenden:

-	Zusammenstellung von Statistiken
-	Entwicklung des Dienstes
-	Überwachung der Anzahl der Nutzer des Dienstes und darauf basierendes Marketing

Spes Services kann auch Informationen in dieser Form weiterleiten.

Die Bedingungen und Anweisungen für die Verwendung
personenbezogener Daten und die von Ihnen erteilten Einwilligungen
sind in der Datenschutzrichtlinie enthalten. Die Bedingungen der von den
Spes-Diensten verwendeten Datenschutzerklärung finden Sie unter
[https://www.spespalvelut.fi/rekisteri-ja-tietosuojaseloste].

Darüber hinaus können die Spes-Dienste die im Zusammenhang mit der
Nutzung des Dienstes erzeugten Daten gemäß den Anforderungen des
Gesetzes über die Zweitverwendung von Sozial- und
Gesundheitsinformationen (552/2019) verarbeiten und weitergeben.

## 11 Anwendbares Recht

Diese Nutzungsbedingungen unterliegen finnischem Recht, jedoch in der
Weise, dass das Übereinkommen der Vereinten Nationen über den
internationalen Warenkauf (CISG) und seine kollisionsrechtlichen
Bestimmungen nicht auf die Nutzung des Dienstes anwendbar sind.

Diese Nutzungsbedingungen schränken die Rechte der Verbraucher
nach zwingendem finnischem Recht nicht ein.
Im Falle eines Rechtsstreits ist das allgemeine Gericht am Sitz von Spes
Services zuständig.
`
export default TOS
