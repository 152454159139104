import BottomNavigationBar from "components/BottomNavigationBar"
import MobileViewBase from "components/MobileViewBase"
import MobileViewDescription from "components/MobileViewBase/MobileViewDescription"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import { getFeelingStreak } from "./apiRequests"
import SeeYouLaterSection from "./SeeYouLaterSection"
import { userContext } from "contexts/userContext"
import { FeelingStreakModel } from "services/openapi"
import ClientGroupId from "utils/clientGroupId"
import { toDateFormat, toFinnishFormat } from "utils/functions/toDateFormat"

const EndingView = () => {
  const { t } = useTranslation()
  const { user } = useContext(userContext)
  const [streakModel, setStreakModel] = useState<FeelingStreakModel>({
    streak: 0,
    userId: user?.id || "",
  })
  const { handleError } = useApiRequestError()

  useEffect(
    function fetchStreakOnMount() {
      const getStreak = async () => {
        const feelinStreak = await getFeelingStreak(user?.id || "", handleError)
        setStreakModel(
          feelinStreak || {
            streak: 0,
            userId: user?.id || "",
          }
        )
      }
      getStreak()
    },
    [handleError, user]
  )

  const titleText = () => {
    let text = t("ending-view.title")

    return text
  }

  const dailyText = () => {
    let text = [toFinnishFormat(toDateFormat(new Date()))]
    if (streakModel.streak > 0) {
      text.push(t("ending-view.streak", { streak: streakModel.streak }))
    }
    return text
  }

  const backNav = "/client/daily-summary"
  const startNav = user?.clientGroupId === ClientGroupId.ELDERLY ? "/client/activity" : "/client/feelings"
  const navLength = user?.clientGroupId === ClientGroupId.ELDERLY ? 5 : 4

  return (
    <>
      <MobileViewBase
        top={<MobileViewDescription header={titleText()} text={dailyText()} />}
        middle={<SeeYouLaterSection />}
        bottom={
          <BottomNavigationBar
            value={navLength}
            navLength={navLength}
            back={backNav}
            to={startNav}
            customNextLabel={t("ending-view.back-to-start")}
          />
        }
      />
    </>
  )
}

export default EndingView
