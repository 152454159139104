import { Box } from "@mui/material"

import AdminNav from "scenes/admin/AdminPanel/AdminNav"
import AdminRoutes from "routing/AdminRoutes"

function AdminPanel() {
  return (
    <Box sx={{ display: "flex", height: "100vh", p: 3, backgroundColor: "background.default" }}>
      <AdminNav />
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%", overflow: "auto" }} component="main">
        <AdminRoutes />
      </Box>
    </Box>
  )
}

export default AdminPanel
