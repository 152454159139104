import { Box, Divider, Rating, Typography } from "@mui/material"
import CircleIcon from "@mui/icons-material/Circle"
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined"
import { FeelingModel, FeelingTargetModel, SingleFeelingModel } from "services/openapi"
import { useTranslation } from "react-i18next"

type FeelingBoxProps = {
  userFeelings: SingleFeelingModel[]
  hasRating: boolean
  feelingOptions: FeelingModel[]
  feelingTargetOptions: FeelingTargetModel[]
}

const FeelingBox = ({ userFeelings, hasRating, feelingOptions, feelingTargetOptions }: FeelingBoxProps) => {
  const { t } = useTranslation()
  const alignment = hasRating ? 0 : 2

  const feelingList = userFeelings?.map((feeling) => {
    const addedFeelingDetails = feelingOptions.find((feelingModel) => feelingModel.id === feeling.feelingId)
    const addedTargetDetails = feelingTargetOptions.filter((target) => feeling.feelingTargetIds.includes(target.id))

    return (
      <Box key={feeling.feelingId.toString()} sx={{ pt: 5 }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <img
            src={`${process.env.PUBLIC_URL}/feelingIcons/${addedFeelingDetails?.imageLocation}`}
            width={68}
            alt={`${addedFeelingDetails?.imageLocation}`}
            aria-hidden="true"
          />
          <Box>
            <Typography sx={{ fontWeight: "bold", ml: 2, mt: alignment }}>
              {t(`youth-view.feeling-selection.feeling-options.${addedFeelingDetails?.name}`)}
            </Typography>
            {hasRating ? (
              <Rating
                icon={<CircleIcon />}
                emptyIcon={<CircleOutlinedIcon />}
                readOnly
                value={feeling.strength}
                sx={{
                  color: "secondary.dark",
                  pt: 1,
                  ml: 2,
                }}
              />
            ) : (
              <></>
            )}
          </Box>
        </Box>

        {addedTargetDetails?.map((target) => (
          <Typography key={target.id.toString()} sx={{ mt: 1 }}>
            {t(`youth-view.feeling-selection.feeling-target-options.${target.name}`)}
          </Typography>
        ))}
        <Divider sx={{ my: 2, p: 0.5 }} />
      </Box>
    )
  })

  return <Box>{feelingList}</Box>
}

export default FeelingBox
