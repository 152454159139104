import { Checkbox, Divider, FormControlLabel, TextField } from "@mui/material"
import { Form, FormikProps } from "formik"
import { useTranslation } from "react-i18next"

type DiaryTextFieldProps = {
  formik: FormikProps<{ diaryEntry: string; permission: boolean }>
}

const DiaryTextField = ({ formik }: DiaryTextFieldProps) => {
  const { t } = useTranslation()

  return (
    <Form>
      <TextField
        fullWidth
        id="diaryEntry"
        {...formik.getFieldProps("diaryEntry")}
        onBlur={formik.submitForm}
        placeholder={t("youth-view.diary-entry.diary-placeholder")}
        multiline
        rows={7}
        helperText={formik.errors.diaryEntry}
        variant="standard"
        InputProps={{
          disableUnderline: true,
        }}
        sx={{ height: "200px" }}
      />
      <Divider />
      <FormControlLabel
        sx={{ marginTop: 2, pointerEvents: "none" }}
        control={
          <Checkbox
            checked={formik.values.permission}
            id="permission"
            {...formik.getFieldProps("permission")}
            onBlur={formik.submitForm}
            sx={{
              pointerEvents: "auto",
              "& .MuiSvgIcon-root": { fontSize: 30 },
              "& svg": {
                color: "secondary.dark",
              },
            }}
          />
        }
        label={t("youth-view.diary-entry.share-permission")}
      />
    </Form>
  )
}

export default DiaryTextField
