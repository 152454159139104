import { CircularProgress, List } from "@mui/material"
import { userContext } from "contexts/userContext"
import { useContext, useReducer, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  activitiesSelectionStateReducer,
  initialActivitySelectionState,
} from "scenes/ElderlyActivitySelection/selectedActivitiesReducer"
import { ActivityService, UserActivitySaveModel } from "services/openapi"
import { saveUserActivities } from "utils/apiRequests/userActivities"
import { toDateFormat } from "utils/functions/toDateFormat"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import useMountEffect from "utils/hooks/useMountEffect"
import DidGetHelpLinkButton from "./DidGetHelpLinkButton"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"

const DidGetHelpOptions = () => {
  const { t } = useTranslation()
  const { user } = useContext(userContext)
  const { handleError } = useApiRequestError()
  const [state, dispatch] = useReducer(activitiesSelectionStateReducer, initialActivitySelectionState)
  const [isLoading, setLoading] = useState<boolean>(false)

  useMountEffect(async function fetchSavedFeelings() {
    const activityData = await ActivityService.activityGetForUserSingleDay(user?.id ?? "", toDateFormat(new Date()))
    dispatch({ type: "setActivities", stateData: activityData })
  })

  const noHelpers = async () => {
    const body: UserActivitySaveModel = {
      userId: user?.id ?? "",
      date: toDateFormat(new Date()),
      activities: state.activities,
      activityHelperIds: [],
      wouldHaveNeededHelp: false,
    }
    await saveUserActivities(body, setLoading, handleError)
  }

  const needHelpers = async () => {
    const body: UserActivitySaveModel = {
      userId: user?.id ?? "",
      date: toDateFormat(new Date()),
      activities: state.activities,
      activityHelperIds: [],
      wouldHaveNeededHelp: true,
    }
    await saveUserActivities(body, setLoading, handleError)
  }

  return isLoading ? (
    <CircularProgress
      size={100}
      sx={{
        margin: 5,
      }}
    />
  ) : (
    <List sx={{ mx: 3 }}>
      <DidGetHelpLinkButton to={"/client/who-helped"} startIcon={<CheckIcon sx={{ color: "green", fontSize: "35px !important" }} />}>
        {t("did-get-help-view.buttons-got-help")}
      </DidGetHelpLinkButton>
      <DidGetHelpLinkButton startIcon={<CloseIcon sx={{ color: "#4d95c6", fontSize: "35px !important" }} />} onClick={noHelpers} to="/client/feelings">
        {t("did-get-help-view.buttons-no-need-help")}
      </DidGetHelpLinkButton>
      <DidGetHelpLinkButton
        startIcon={<CloseIcon sx={{ color: "#4d95c6", fontSize: "35px !important" }} />}
        onClick={needHelpers}
        to="/client/feelings"
      >
        {t("did-get-help-view.buttons-no-help-need-help")}
      </DidGetHelpLinkButton>
    </List>
  )
}

export default DidGetHelpOptions
