import { Box } from "@mui/material"
import { Chart, registerables } from "chart.js"
import { useEffect, useRef, useState } from "react"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { sum } from "lodash/fp"

type FeelingDoughnutProps = {
  chartColors: string[]
  chartData: number[]
  showPercentaces: boolean
  useFullSize?: boolean
  chartLabels?: string[]
}

const FeelingDoughnut = ({ chartData, chartColors, showPercentaces, useFullSize, chartLabels }: FeelingDoughnutProps) => {
  const [chartWidth, setChartWidth] = useState("100%");

  const chartResize = () => {
    if (document.getElementById("ClientEntityList")) {
      setChartWidth("100%");
    } else {
      if (window.innerWidth < 570) {
        setChartWidth("100%");
      } else if (window.innerWidth >= 570 && window.innerWidth < 1100) {
        setChartWidth("50%");
      } else if (window.innerWidth >= 1100) {
        setChartWidth("35%");
      }
    }
  }

  useEffect(() => {
    if (useFullSize) {
      setChartWidth("100%");
    } else {
      chartResize(); //Called here to set correct chart width during first page load.
    }
    window.addEventListener('resize', chartResize);
    return () => {
      window.removeEventListener('resize', chartResize);
    };
  }, [useFullSize]);

  const chartRef = useRef<Chart | null>(null)
  Chart.register(...registerables)

  const canvasCallback = (canvas: HTMLCanvasElement | null) => {
    if (!canvas) return
    const ctx = canvas.getContext("2d")
    if (ctx && !chartRef.current) {
      chartRef.current = new Chart(ctx, {
        plugins: [ChartDataLabels],
        type: "doughnut",
        data: {
          datasets: [
            {
              data: chartData,
              backgroundColor: chartColors,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            datalabels: {
              backgroundColor: "white",
              borderColor: "white",
              borderRadius: 25,
              borderWidth: 2,
              color: "black",
              font: {
                weight: "bold",
              },
              padding: 6,
              formatter: (value: number, ctx) => {
                const summed = sum(ctx.chart.data.datasets[0].data)
                let percentage = ((value * 100) / summed).toFixed(0) + "%"
                return percentage
              },
              display: showPercentaces,
            },
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  const index = tooltipItem.dataIndex;
                  const labels = chartLabels ?? chartData.map(x => x.toString())
                  return labels[index];
                }
              }
            }
          },
        },
      })
    }
  }

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = {
        datasets: [
          {
            data: chartData,
            backgroundColor: chartColors,
          },
        ],
      }
      chartRef.current.update()
    }
  }, [chartData, chartColors])

  return (
    <Box sx={{ mx: useFullSize ? 0 : "auto", my: useFullSize ? 0 : "auto", maxWidth: chartWidth }}>
      <canvas ref={canvasCallback}></canvas>
    </Box>
  )
}

export default FeelingDoughnut
