import { Box, Card, CardContent, Divider, Typography, useTheme, Collapse } from "@mui/material"
import FeelingDoughnut from "components/FeelingDoughnut"
import FeelingLineChart from "components/FeelingLineChart"
import FeelingList from "./FeelingList"
import { getFeelingOptionsForClientGroup } from "utils/apiRequests/fetchFeelings"
import useMountEffect from "utils/hooks/useMountEffect"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import { FeelingModel } from "services/openapi"
import { matchFeelingIdWithColor } from "utils/functions/matchFeelingIdWithColor"
import { useTranslation } from "react-i18next"
import { GroupedFeelings, UserFeelingModel } from "services/openapi"
import { Timespan } from "components/TimeSpanSelectionButtons"
import { useState } from "react"
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableRowsTwoToneIcon from '@mui/icons-material/TableRowsTwoTone';

type FeelingDisplayProps = {
  userFeelings: GroupedFeelings[]
  userFeelingsAll: UserFeelingModel[]
  timespan?: Timespan
  elder?: boolean
}

const FeelingDisplay = ({ userFeelings, userFeelingsAll, timespan, elder }: FeelingDisplayProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false);
  const [feelingOptions, setFeelingOptions] = useState<FeelingModel[]>([])
  const [isLoading, setLoading] = useState<boolean>(true)
  const { handleError } = useApiRequestError()

  useMountEffect(async function fetchData() {
    await getFeelingOptionsForClientGroup(undefined, setLoading, setFeelingOptions, handleError)
  })

  const topN = 10
  const topUserFeelings = userFeelings.slice(0, topN)

  const colors = userFeelings.map((feeling) => matchFeelingIdWithColor(feeling.feelingId, theme)!)

  let feelingSum: number = 0
  const dataDoughnut = userFeelings.map((feeling) => {
    const feelingNumber: number = feeling.sum === 0 ? feeling.total : feeling.sum
    feelingSum += feelingNumber
    return feelingNumber
  })

  const dataDoughnutLabels: string[] = userFeelings.map((feeling) => {
    const feelingNumber: number = feeling.sum === 0 ? feeling.total : feeling.sum
    const feelingOption = feelingOptions.find((x) => x.id === feeling.feelingId)
    const feelingOptionName: string = t(`youth-view.feeling-selection.feeling-options.${feelingOption?.name}`) ?? ""
    const feelingPercentage: number = Math.round((feelingNumber / feelingSum) * 100)
    return `${feelingOptionName} (${feelingNumber}) (${feelingPercentage}%)`
  })

  const feelingIdSumOrderedMap: { [key: number]: number } = {};
  topUserFeelings.forEach(entry => {
    feelingIdSumOrderedMap[entry.feelingId] = entry.sum
  })

  const oneDayFeelings: boolean = userFeelingsAll?.length < 2
  const hasTopTen: boolean = userFeelings?.length >= 10
  const hideLineChart: boolean = elder || oneDayFeelings

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Card sx={{ mt: 2, mb: 2, mx: 2, maxWidth: "1000px" }}>
      <CardContent>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
          {!userFeelings.length && (
            <Typography variant="body1">{t("customer-mobile-view.feeling-history.no-feelings")}</Typography>
          )}
          <Box sx={{ maxWidth: { xs: "60%", md: "80%" } }}>
            {isLoading ? <></> : <FeelingDoughnut chartColors={colors} chartData={dataDoughnut} showPercentaces={false} useFullSize={true} chartLabels={dataDoughnutLabels} />}
          </Box>
          <Box>
            {hasTopTen ? <Typography variant="h6">Top 10</Typography> : <></>}
            <FeelingList feelings={topUserFeelings} />
          </Box>
        </Box>
        {hideLineChart ? <></> :
          <>
            <Divider orientation="horizontal" flexItem sx={{ my: 2 }} />
            {hasTopTen ? <Typography variant="h6">Top 10</Typography> : <></>}
            <FeelingLineChart chartData={userFeelingsAll} timespan={timespan} topFeelings={feelingIdSumOrderedMap} />
            {timespan?.name === "week" ?
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                <TableRowsTwoToneIcon sx={{ color: "rgba(255, 99, 132, 0.5)" }} />
                <Typography variant="caption">{"*" + t(`customer-mobile-view.feeling-history.weekend`)}</Typography>
              </Box>
              : <></>}
          </>
        }
        {userFeelings.length === 0 ? <></> :
          <>
            <Divider orientation="horizontal" flexItem sx={{ my: 2 }} />
            <Box onClick={handleToggle} sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-center", alignItems: "center" }}>
              <Typography variant={"h5"} sx={{ my: 2 }}>{t("customer-mobile-view.feeling-history.feeling-details")}</Typography>
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Box>
            <Collapse in={open}>
              <FeelingList feelings={userFeelings} targets={userFeelingsAll} />
            </Collapse>
          </>
        }
      </CardContent>
    </Card>
  )
}

export default FeelingDisplay
