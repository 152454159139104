import { SwipeableDrawer } from "@mui/material"
import DrawerHandle from "scenes/FeelingsSelection/styled/DrawerHandle"

type BottomDrawerProps = {
  drawerOpen: boolean
  onClose: () => void
  content: JSX.Element
}

const BottomDrawer = ({ drawerOpen, onClose, content }: BottomDrawerProps) => {
  return (
    <SwipeableDrawer
      ModalProps={{
        keepMounted: false,
      }}
      sx={{
        ".MuiDrawer-paper": {
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
          backgroundColor: "primary.main",
        },
      }}
      anchor="bottom"
      open={drawerOpen}
      onClose={onClose}
      onOpen={() => null}
      disableDiscovery
      disableSwipeToOpen
    >
      <DrawerHandle />
      {content}
    </SwipeableDrawer>
  )
}

export default BottomDrawer
