import { Box, Typography } from "@mui/material"
import { ActivityHelperModel, ActivityModel, UserActivityModel } from "services/openapi"
import { useContext, useEffect, useState } from "react"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import {
  getActivitiesOptionsForClientGroup,
  getActivityHelperOptionsForClientGroup,
} from "utils/apiRequests/fetchActivities"
import { userContext } from "contexts/userContext"
import ClientGroupId from "utils/clientGroupId"
import ActivityBox from "components/ActivityComponents/ActivityBox"
import ActivityHelperBox from "components/ActivityComponents/ActivityHelperBox"
import StyledListing from "components/ActivityComponents/styled/StyledListing"
import { useTranslation } from "react-i18next"

type ActivityDetailProps = {
  userActivities: UserActivityModel
}

const ActivityDetails = ({ userActivities }: ActivityDetailProps) => {
  const { ErrorSnackbar, handleError } = useApiRequestError()
  const { user } = useContext(userContext)
  const clientGroupId = user?.clientGroupId ?? ClientGroupId.ELDERLY
  const [activityOptions, setActivityOptions] = useState<ActivityModel[]>([])
  const [activityHelperOptions, setActivityHelperOptions] = useState<ActivityHelperModel[]>([])
  const { t } = useTranslation()

  useEffect(
    function fetchData() {
      getActivitiesOptionsForClientGroup(clientGroupId, () => {}, setActivityOptions, handleError)
      getActivityHelperOptionsForClientGroup(clientGroupId, () => {}, setActivityHelperOptions, handleError)
    },
    [clientGroupId, setActivityOptions, setActivityHelperOptions, handleError]
  )

  const helpersElements = userActivities.activityHelperIds.map((helperId) => (
    <ActivityHelperBox key={helperId} activityHelperId={helperId} activityHelperOptions={activityHelperOptions} />
  ))

  const activityList = userActivities.activities.map((activity) => (
    <ActivityBox key={activity.activityId} activity={activity} activityOptions={activityOptions} />
  ))

  return (
    <Box>
      <ErrorSnackbar />
      {activityList}
      <StyledListing>{t(`elderly-view.helper-selection.title`)}</StyledListing>
      {userActivities.activityHelperIds.length === 0 ? (
        <Typography
          variant="body1"
          sx={{
            ml: 2,
            mt: 0,
            mr: 4,
            mb: 1,
            display: "flex",
            flexDirection: "row",
            fontFamily: "SofiaProLight",
            fontSize: "20px",
          }}
        >
          {userActivities.wouldHaveNeededHelp
            ? t("elderly-view.helper-selection.no-help-need-help")
            : t("elderly-view.helper-selection.no-need-help")}
        </Typography>
      ) : (
        helpersElements
      )}
    </Box>
  )
}

export default ActivityDetails
