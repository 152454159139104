import { FeelingService } from "services/openapi"

export const getFeelingStreak = async (userId: string, onError: (error: any, message: string) => void) => {
  try {
    const response = await FeelingService.feelingGetFeelingSreak(userId)
    return response
  } catch (error) {
    onError(error, "ending-view.snackbar.fetch-error")
  }
}
