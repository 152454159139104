import { Card, CircularProgress, Grid, Typography, useTheme } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { fetchTopFeelingsForFamily } from "scenes/CareOrganizationUserClientListing/apiRequests"
import { FamilyFeelingAggregateModel, FeelingModel, FeelingTargetModel } from "services/openapi"
import { getFeelingOptionsForClientGroup, getFeelingTargetsForClientGroup } from "utils/apiRequests/fetchFeelings"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import useMountEffect from "utils/hooks/useMountEffect"
import TopItemList from "./TopItemList"

const FamilyTopFeelingsCard = () => {
  const { id } = useParams<{ id: string }>()
  const theme = useTheme()
  const { t } = useTranslation()
  const { ErrorSnackbar, handleError } = useApiRequestError()
  const [topFeelingsAndTargets, setTopFeelingsAndTargets] = useState<FamilyFeelingAggregateModel>({
    familyId: "",
    feelingIds: [],
    feelingTargetIds: [],
  })
  const [feelingOptions, setFeelingOptions] = useState<FeelingModel[]>([])
  const [targetOptions, setTargetOptions] = useState<FeelingTargetModel[]>([])
  const [isLoading, setLoading] = useState<boolean>(true)

  useMountEffect(async function fetchData() {
    await getFeelingOptionsForClientGroup(undefined, () => {}, setFeelingOptions, handleError)
    await getFeelingTargetsForClientGroup(undefined, () => {}, setTargetOptions, handleError)

    return () => {
      setLoading(true)
    }
  })

  useEffect(
    function fetchTopFeelings() {
      fetchTopFeelingsForFamily(id, setLoading, setTopFeelingsAndTargets, handleError)
    },
    [id, handleError]
  )

  const slicedFeelings = topFeelingsAndTargets.feelingIds.slice(0, 3)
  const slicedFeelingTargets = topFeelingsAndTargets.feelingTargetIds.slice(0, 3)

  return (
    <>
      <Card
        elevation={4}
        sx={{
          borderRadius: 3,
          width: theme.diaryView?.contentWidth,
          mx: "auto",
        }}
      >
        <Typography sx={{ fontWeight: "bold", pb: 1, textAlign: "center" }}>
          {t("care-org-mobile-view.client-listing.top-header")}
        </Typography>
        {isLoading ? (
          <CircularProgress size={100} />
        ) : (
          <Grid container>
            <Grid item xs>
              <Typography sx={{ fontWeight: "bold", pb: 1, textAlign: "center" }}>
                {t("care-org-mobile-view.client-listing.top-header-feelings")}
              </Typography>
              <TopItemList
                options={feelingOptions}
                slicedIdList={slicedFeelings}
                translationLocation="youth-view.feeling-selection.feeling-options"
              />
            </Grid>
            <Grid item xs>
              <Typography sx={{ fontWeight: "bold", pb: 1, textAlign: "center" }}>
                {t("care-org-mobile-view.client-listing.top-header-feelingtargets")}
              </Typography>
              <TopItemList
                options={targetOptions}
                slicedIdList={slicedFeelingTargets}
                translationLocation="youth-view.feeling-selection.feeling-target-options"
              />
            </Grid>
          </Grid>
        )}
      </Card>
      <ErrorSnackbar />
    </>
  )
}

export default FamilyTopFeelingsCard
