import { Alert, Snackbar as MuiSnackbar } from "@mui/material"

type SnackbarProps = {
  openState: boolean
  toggle: () => void
  message: string
  type: "error" | "warning" | "info" | "success"
}

const SnackbarComponent = ({ openState, toggle, message, type }: SnackbarProps) => {
  return (
    <MuiSnackbar
      open={openState}
      autoHideDuration={4000}
      onClose={toggle}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <Alert severity={type}>{message}</Alert>
    </MuiSnackbar>
  )
}

export default SnackbarComponent
