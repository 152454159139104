import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react"
import { CircularProgress, ThemeProvider } from "@mui/material"
import { adminTheme } from "adminTheme"
import { CareOrgUserStateProvider } from "contexts/careOrgUserContext"
import { userContext } from "contexts/userContext"
import { mobileTheme } from "mobileTheme"
import { useContext } from "react"
import { Redirect, Switch, useLocation } from "react-router-dom"
import PrivateRoute from "routing/utils/PrivateRoute"
import AdminPanel from "scenes/admin/AdminPanel"
import CareOrganizationUserFamilyListing from "scenes/CareOrganizationUserFamilyListing"
import CareOrganizationUserMobileRoutes from "scenes/CareOrganizationUserMobileView/CareOrganizationUserMobileRoutes"
import ChooseDesktopOrMobileView from "scenes/ChooseDesktopOrMobileView"
import DiaryEntry from "scenes/DiaryEntry"
import EndingView from "scenes/EndingView"
import FeelingsSelection from "scenes/FeelingsSelection"
import YouthDailySummary from "scenes/YouthDailySummary"
import MobileFeelingHistory from "scenes/MobileFeelingHistory"
import ElderlyDailySummary from "scenes/ElderlyDailySummary"
import { ProfileModel, UserRoleModel } from "services/openapi"
import Login from "./Login"
import { ClientGroupDataProvider } from "contexts/clientGroupDataContext"
import ClientGroupId from "utils/clientGroupId"
import ElderlyActivitySelection from "scenes/ElderlyActivitySelection"
import ElderlyActivityHistory from "scenes/ElderlyActivityHistory"
import DidGetHelpView from "scenes/DidGetHelpView"
import HelperSelection from "scenes/HelperSelection"
import MobileHistoryDayContainer from "scenes/MobileHistoryDaySummary/MobileHistoryDayContainer"
import AccessibilityDescription from "scenes/Accessibility"
import TermsOfUseView from "scenes/TermsOfUse"

const MainMenu = () => {
  const { user, isLoading } = useContext(userContext)
  const location = useLocation()

  const redirectToRootIfNeeded = () => {
    return location.pathname !== "/" ? <Redirect to="/" /> : <></>
  }

  return (
    <>
      <AuthenticatedTemplate>
        {isLoading ? (
          <CircularProgress
            size={100}
            sx={{
              margin: 5,
            }}
          />
        ) : (
          getAuthenticatedUserView(user)
        )}
        <Switch>
          <PrivateRoute exact path="/client/feelings" role={UserRoleModel.CLIENT}>
            <ClientGroupDataProvider>
              <ThemeProvider theme={mobileTheme}>
                <FeelingsSelection />
              </ThemeProvider>
            </ClientGroupDataProvider>
          </PrivateRoute>

          <PrivateRoute exact path="/client/activity" role={UserRoleModel.CLIENT}>
            <ClientGroupDataProvider>
              <ThemeProvider theme={mobileTheme}>
                <ElderlyActivitySelection />
              </ThemeProvider>
            </ClientGroupDataProvider>
          </PrivateRoute>

          <PrivateRoute path="/client/help" role={UserRoleModel.CLIENT}>
            <ClientGroupDataProvider>
              <ThemeProvider theme={mobileTheme}>
                <DidGetHelpView />
              </ThemeProvider>
            </ClientGroupDataProvider>
          </PrivateRoute>

          <PrivateRoute path="/client/who-helped" role={UserRoleModel.CLIENT}>
            <ClientGroupDataProvider>
              <ThemeProvider theme={mobileTheme}>
                <HelperSelection />
              </ThemeProvider>
            </ClientGroupDataProvider>
          </PrivateRoute>

          <PrivateRoute path="/client/diary" role={UserRoleModel.CLIENT}>
            <ClientGroupDataProvider>
              <ThemeProvider theme={mobileTheme}>
                <DiaryEntry />
              </ThemeProvider>
            </ClientGroupDataProvider>
          </PrivateRoute>

          <PrivateRoute path="/client/ending" role={UserRoleModel.CLIENT}>
            <ClientGroupDataProvider>
              <ThemeProvider theme={mobileTheme}>
                <EndingView />
              </ThemeProvider>
            </ClientGroupDataProvider>
          </PrivateRoute>
          <PrivateRoute path="/client/daily-summary" role={UserRoleModel.CLIENT}>
            <ClientGroupDataProvider>
              <ThemeProvider theme={mobileTheme}>
                {user?.clientGroupId === ClientGroupId.ELDERLY ? <ElderlyDailySummary /> : <YouthDailySummary />}
              </ThemeProvider>
            </ClientGroupDataProvider>
          </PrivateRoute>

          <PrivateRoute path="/client/feeling-history/:date" role={UserRoleModel.CLIENT}>
            <ThemeProvider theme={mobileTheme}>
              <MobileHistoryDayContainer />
            </ThemeProvider>
          </PrivateRoute>

          <PrivateRoute path="/client/feeling-history" role={UserRoleModel.CLIENT}>
            <ThemeProvider theme={mobileTheme}>
              {user?.clientGroupId === ClientGroupId.ELDERLY ? <ElderlyActivityHistory /> : <MobileFeelingHistory />}
            </ThemeProvider>
          </PrivateRoute>

          <PrivateRoute
            path="/client/accessibility"
            role={[UserRoleModel.CLIENT, UserRoleModel.CARE_ORGANIZATION_USER]}
          >
            <ClientGroupDataProvider>
              <ThemeProvider theme={mobileTheme}>
                <AccessibilityDescription />
              </ThemeProvider>
            </ClientGroupDataProvider>
          </PrivateRoute>

          <PrivateRoute path="/terms-of-use" role={[UserRoleModel.CLIENT, UserRoleModel.CARE_ORGANIZATION_USER]}>
            <ClientGroupDataProvider>
              <ThemeProvider theme={mobileTheme}>
                <TermsOfUseView />
              </ThemeProvider>
            </ClientGroupDataProvider>
          </PrivateRoute>

          <PrivateRoute path="/choose-mode" role={UserRoleModel.CARE_ORGANIZATION_USER}>
            <ThemeProvider theme={mobileTheme}>
              <ChooseDesktopOrMobileView />
            </ThemeProvider>
          </PrivateRoute>

          <PrivateRoute
            path="/admin"
            role={[
              UserRoleModel.SUPER_ADMIN,
              UserRoleModel.MANAGEMENT_ORGANIZATION_ADMIN_USER,
              UserRoleModel.CARE_ORGANIZATION_USER,
            ]}
          >
            <ThemeProvider theme={adminTheme}>
              <AdminPanel />
            </ThemeProvider>
          </PrivateRoute>

          <CareOrgUserStateProvider>
            <PrivateRoute exact path="/care-org-user" role={UserRoleModel.CARE_ORGANIZATION_USER}>
              <ThemeProvider theme={mobileTheme}>
                <CareOrganizationUserFamilyListing />
              </ThemeProvider>
            </PrivateRoute>
            <ThemeProvider theme={mobileTheme}>
              <CareOrganizationUserMobileRoutes />
            </ThemeProvider>
          </CareOrgUserStateProvider>

          <PrivateRoute path="/client" role={UserRoleModel.CLIENT}></PrivateRoute>
        </Switch>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        {redirectToRootIfNeeded()}
        <ThemeProvider theme={mobileTheme}>
          <Login />
        </ThemeProvider>
      </UnauthenticatedTemplate>
    </>
  )
}

const getAuthenticatedUserView = (user: ProfileModel | null) => {
  if (user?.role === UserRoleModel.CARE_ORGANIZATION_USER) {
    return <Redirect to="/choose-mode" />
  } else if (
    user?.role === UserRoleModel.SUPER_ADMIN ||
    user?.role === UserRoleModel.MANAGEMENT_ORGANIZATION_ADMIN_USER
  ) {
    return <Redirect to="/admin" />
  } else if (user?.role === UserRoleModel.CLIENT) {
    const nav = user.clientGroupId === ClientGroupId.ELDERLY ? "/client/activity" : "client/feelings"
    return <Redirect to={nav} />
  }
}

export default MainMenu
