/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ManagementOrganizationCreateModel } from "../models/ManagementOrganizationCreateModel"
import type { ManagementOrganizationDetailsModel } from "../models/ManagementOrganizationDetailsModel"
import type { ManagementOrganizationUpdateModel } from "../models/ManagementOrganizationUpdateModel"
import type { OrganizationListModelPagedResponseModel } from "../models/OrganizationListModelPagedResponseModel"
import { request as __request } from "../core/request"

export class ManagementOrganizationService {
  /**
   * @param pageSize
   * @param page
   * @param searchString
   * @returns OrganizationListModelPagedResponseModel Success
   * @throws ApiError
   */
  public static async managementOrganizationGetAll(
    pageSize?: number,
    page?: number,
    searchString?: string
  ): Promise<OrganizationListModelPagedResponseModel> {
    const result = await __request({
      method: "GET",
      path: `/ManagementOrganization/search`,
      query: {
        pageSize: pageSize,
        page: page,
        searchString: searchString,
      },
    })
    return result.body
  }

  /**
   * @param requestBody
   * @returns string Success
   * @throws ApiError
   */
  public static async managementOrganizationCreateManagementOrganization(
    requestBody?: ManagementOrganizationCreateModel
  ): Promise<string> {
    const result = await __request({
      method: "POST",
      path: `/ManagementOrganization/create`,
      body: requestBody,
    })
    return result.body
  }

  /**
   * Get organization details. If organization is not found, ArgumentException is thrown.
   * @param organizationId Organization id
   * @returns ManagementOrganizationDetailsModel Success
   * @throws ApiError
   */
  public static async managementOrganizationGetDetails(
    organizationId: string
  ): Promise<ManagementOrganizationDetailsModel> {
    const result = await __request({
      method: "GET",
      path: `/ManagementOrganization/details/${organizationId}`,
    })
    return result.body
  }

  /**
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public static async managementOrganizationUpdateManagementOrganization(
    requestBody?: ManagementOrganizationUpdateModel
  ): Promise<any> {
    const result = await __request({
      method: "POST",
      path: `/ManagementOrganization/update`,
      body: requestBody,
    })
    return result.body
  }

  /**
   * @param organizationId
   * @returns any Success
   * @throws ApiError
   */
  public static async managementOrganizationDeactivateManagementOrganization(organizationId: string): Promise<any> {
    const result = await __request({
      method: "DELETE",
      path: `/ManagementOrganization/deactivate/${organizationId}`,
    })
    return result.body
  }
}
