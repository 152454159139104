import MobileViewBase from "components/MobileViewBase"
import MobileViewDescription from "components/MobileViewBase/MobileViewDescription"
import { useTranslation } from "react-i18next"
import ActivityCards from "./ActivityCards"
import BottomNavigationBar from "components/BottomNavigationBar"

const ElderlyActivitySelection = () => {
  const { t } = useTranslation()

  return (
    <MobileViewBase
      top={
        <MobileViewDescription
          header={t("elderly-view.activity-selection.title")}
          text={t("elderly-view.activity-selection.description")}
        />
      }
      middle={<ActivityCards />}
      bottom={<BottomNavigationBar value={1} back={""} to={"help"} navLength={5} />}
    />
  )
}

export default ElderlyActivitySelection
