import { Box, List } from "@mui/material"
import CardName from "components/SpesCard/styled/CardName"
import { useTranslation } from "react-i18next"
import { FeelingModel } from "services/openapi"

type Props = {
  options: FeelingModel[]
  slicedIdList: number[]
  translationLocation: string
}

const TopItemList = ({ options, slicedIdList, translationLocation }: Props) => {
  const { t } = useTranslation()

  const topItemList = slicedIdList.map((itemId) => {
    const feelingDetails = options.find((feelingModel) => feelingModel.id === itemId)

    return (
      <Box key={itemId.toString()}>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", rowGap: 1 }}>
          <img src={`${process.env.PUBLIC_URL}/feelingIcons/${feelingDetails?.imageLocation}`} width={46} alt={""} />
          <CardName sx={{ fontSize: "12px" }}>{t(`${translationLocation}.${feelingDetails?.name}`)}</CardName>
        </Box>
      </Box>
    )
  })
  return <List>{topItemList}</List>
}

export default TopItemList
