import { Dialog } from "@mui/material"
import Calendar from "react-calendar"
import { useState } from "react"
import "react-calendar/dist/Calendar.css"

type CalendarDialogProps = {
  open: boolean
  selectedDate: string
  onClose: (value: string) => void
}

const CalendarDialog = ({ open, selectedDate, onClose }: CalendarDialogProps) => {
  const [calendarValue, setCalendarValue] = useState(new Date())

  const handleClose = () => {
    onClose(selectedDate)
  }

  const onChange = (value: Date) => {
    const zeroPad = (num: number) => String(num).padStart(2, "0")

    setCalendarValue(value)
    const date: string = value.getFullYear() + "-" + zeroPad(value.getMonth() + 1) + "-" + zeroPad(value.getDate())
    onClose(date)
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        width: "100%",
      }}
    >
      <Calendar onChange={onChange} value={calendarValue} />
    </Dialog>
  )
}

export default CalendarDialog
