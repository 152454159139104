import { AppBar, Toolbar } from "@mui/material"
import AppTitle from "components/AppTitle"
import HamburgerMenu from "components/HamburgerMenu"

const TopBar = () => {
  return (
    <AppBar position="static" sx={{ py: 3, boxShadow: "none", backgroundColor: "transparent " }}>
      <Toolbar>
        <AppTitle />
        <HamburgerMenu />
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
