import { IPublicClientApplication } from "@azure/msal-browser"
import { MsalProvider, useIsAuthenticated } from "@azure/msal-react"
import CssBaseline from "@mui/material/CssBaseline"
import { userContext } from "contexts/userContext"
import { useEffect, useState } from "react"
import { BrowserRouter, useHistory } from "react-router-dom"
import MainRoutes from "scenes/MainRoutes"
import { ConfigService } from "services/config"
import { OpenAPI, ProfileModel, ProfileService } from "services/openapi"
import { CustomNavigationClient } from "services/openapi/util/NavigationClient"
import { getToken } from "utils"
import "./App.sass"

type Props = {
  pca: IPublicClientApplication
}

function App(props: Props) {
  const { pca } = props

  const isAuthenticated = useIsAuthenticated()

  const [user, setUser] = useState<ProfileModel | null>(null)
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(function setAuthTokenOnAppLoad() {
    OpenAPI.TOKEN = getToken
    ConfigService.getConfig().then((config) => {
      OpenAPI.BASE = config.baseUrl || ""
    })
  }, [])

  // Authenticated status changes -> fetch current user data
  useEffect(() => {
    ConfigService.getConfig().then(() => {
      ProfileService.profileGetCurrentUserProfile()
        .then((value) => {
          setUser(value)
          setLoading(false)
        })
        .catch((_e) => {
          setUser(null)
          setLoading(false)
        })
    })
  }, [isAuthenticated])

  const history = useHistory()
  const navigationClient = new CustomNavigationClient(history)
  pca.setNavigationClient(navigationClient)

  return (
    <MsalProvider instance={pca}>
      <CssBaseline />
      <userContext.Provider value={{ user, setUser, isLoading, setLoading }}>
        <BrowserRouter>
          <MainRoutes />
        </BrowserRouter>
      </userContext.Provider>
    </MsalProvider>
  )
}

export default App
