import MobileViewBase from "components/MobileViewBase"
import MobileViewDescription from "components/MobileViewBase/MobileViewDescription"
import AccessibilityContent from "./AccessibilityContent"

const AccessibilityDescription = () => {
  return (
    <>
      <MobileViewBase
        top={<MobileViewDescription header="Moodpilot.fi-sivuston saavutettavuusseloste" text="" />}
        middle={<AccessibilityContent />}
        bottom={<></>}
      />
    </>
  )
}

export default AccessibilityDescription
