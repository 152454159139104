import { Box, styled } from "@mui/material"
import { NavLink } from "react-router-dom"
import { useTranslation } from "react-i18next"
import AppName from "components/AppName"
import WavyBackground from "components/WavyBackground"
import { LanguageButtons } from "components/LanguageButtons"
import i18n from "i18n"
import logo_fi from "images/logo_fi.png"
import logo_eng from "images/logo_eng_v3.png"

const ChooseNavLink = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "row",
  backgroundColor: theme.palette.primary.main,
  borderRadius: 20,
  margin: "0 auto",
  padding: "10px",
  width: "150px",
  marginBottom: "10px",
}))

function ChooseDesktopOrMobileView() {
  const { t } = useTranslation()
  return (
    <WavyBackground>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minWidth: "100vw",
          width: "100%",
          margin: "0 auto",
          height: "100vh",
        }}
      >
        <LanguageButtons />

        <Box
          component="img"
          sx={{
            top: "auto",
            bottom: 0,
            minWidth: "300px",
            maxWidth: "1000px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            margin: "auto",
            height: "auto",
            width: "70%"
          }}
          alt={t("admin-nav.header")}
          src={i18n.language === "fi" ? logo_fi : logo_eng}
        />

        <Box
          sx={{
            margin: "0 auto 42px auto",
          }}
        >
          <ChooseNavLink
            sx={{
              boxShadow: "0 2px 4px -1px rgb(0 0 0 / 16%), 0 4px 5px 0 rgb(0 0 0 / 12%), 0 1px 10px 0 rgb(0 0 0 / 8%);",
            }}
          >
            <NavLink to="/care-org-user" activeClassName="selected">
              {t("login.login")}
            </NavLink>
          </ChooseNavLink>
          <ChooseNavLink
            sx={{
              boxShadow: "0 2px 4px -1px rgb(0 0 0 / 16%), 0 4px 5px 0 rgb(0 0 0 / 12%), 0 1px 10px 0 rgb(0 0 0 / 8%);",
            }}
          >
            <NavLink to="/admin" activeClassName="selected">
              {t("login.admin")}
            </NavLink>
          </ChooseNavLink>
        </Box>
      </Box>
    </WavyBackground >
  )
}

export default ChooseDesktopOrMobileView
