import { Box, Card, CardContent, useTheme } from "@mui/material"
import { useContext, useState } from "react"
import { userContext } from "contexts/userContext"
import { getFeelingsForDate } from "scenes/YouthDailySummary/apiRequests"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import { SingleFeelingModel } from "services/openapi"
import FeelingDetails from "./FeelingDetails"
import useMountEffect from "utils/hooks/useMountEffect"
import { matchFeelingIdWithColor } from "utils/functions/matchFeelingIdWithColor"
import { toDateFormat } from "utils/functions/toDateFormat"
import ClientGroupId from "utils/clientGroupId"
import { useTranslation } from "react-i18next"
import FeelingDoughnut from "components/FeelingDoughnut"

const DailyFeelingSummary = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { user } = useContext(userContext)
  const { ErrorSnackbar, handleError } = useApiRequestError()
  const [feelings, setFeelings] = useState<SingleFeelingModel[]>([])

  useMountEffect(async function fetchData() {
    const today: string = toDateFormat(new Date())
    if (user?.id) {
      const userFeelings = await getFeelingsForDate(user.id, today, handleError)
      if (userFeelings) {
        setFeelings(userFeelings.feelings)
      }
    }
  })

  const chartData =
    user?.clientGroupId === ClientGroupId.ELDERLY
      ? Array(feelings.length).fill(1)
      : feelings.map((feeling) => (feeling.strength === 0 ? 1 : feeling.strength))

  const chartColors = feelings.map((feeling) => matchFeelingIdWithColor(feeling.feelingId, theme)!)

  return (
    <Card
      elevation={4}
      sx={{
        borderRadius: 3,
        width: theme.diaryView?.contentWidth,
        backgroundColor: theme.palette.primary.main,
        mb: 2,
        marginX: "auto",
      }}
    >
      {feelings.length ? (
        <CardContent>
          <ErrorSnackbar />
          <Box sx={{ mx: 5, my: 4, }}>
            <FeelingDoughnut chartColors={chartColors} chartData={chartData} showPercentaces={true} />
          </Box>
          <Box sx={{ pt: 2 }}>
            <FeelingDetails userFeelings={feelings} />
          </Box>
        </CardContent>
      ) : (
        <CardContent>
          <Box sx={{ mx: 7, my: 7 }}>{t("selection.no-feelings")}</Box>
        </CardContent>
      )}
    </Card>
  )
}

export default DailyFeelingSummary
