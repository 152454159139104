import MobileViewDescription from "components/MobileViewBase/MobileViewDescription"
import { userContext } from "contexts/userContext"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { Action, DiaryState } from "./DiaryStateReducer"
import GoodThingsForm from "components/MobileDiaryForms/GoodThingsForm"

type Props = {
  state: DiaryState
  dispatch: React.Dispatch<Action>
}

const GoodThingsSection = ({ state, dispatch }: Props) => {
  const { t } = useTranslation()
  const { user } = useContext(userContext)

  return (
    <>
      <MobileViewDescription
        header={t("youth-view.good-things.title")}
        text={t("youth-view.good-things.description")}
      />
      {user?.id && <GoodThingsForm userId={user.id} state={state} dispatch={dispatch} />}
    </>
  )
}

export default GoodThingsSection
