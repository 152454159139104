import PeopleIcon from "@mui/icons-material/People"
import ClientEntityListing from "components/EntityListing/ClientEntityListing"
import GoBackButton from "components/GoBackButton"
import { CareOrgUserContext } from "contexts/careOrgUserContext"
import { userContext } from "contexts/userContext"
import { isEmpty } from "lodash/fp"
import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import ClientListingHeader from "scenes/CareOrganizationUserFamilyListing/styled/ClientListingHeader"
import { FamilyUnitDetailsModel, UserListModel } from "services/openapi"
import { fetchFamilyClients } from "utils/apiRequests/fetchFamilyClients"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import NoClientsInFamilyHeader from "./styled/NoClientsInFamilyHeader"
import { fetchFamilyDetails } from "scenes/admin/FamilyDetails/apiRequests"
import ClientListingFamilyName from "scenes/CareOrganizationUserFamilyListing/styled/ClientListingFamilyName"
import FamilyTopFeelingsCard from "components/FamilyTopFeelingsCard/FamilyTopFeelingsCard"
import { Box, CircularProgress } from "@mui/material"
import CareOrganizationMobileViewBase from "components/CareOrganizationMobileViewBase"

const CareOrganizationUserClientListing = () => {
  const { id } = useParams<{ id: string }>()
  const { ErrorSnackbar, handleError } = useApiRequestError()
  const { user } = useContext(userContext)
  const { state, dispatch } = useContext(CareOrgUserContext)
  const [isLoading, setLoading] = useState<boolean>(true)
  const [familyDetails, setFamilyDetails] = useState<FamilyUnitDetailsModel>({ id: "", name: "", description: "" })

  useEffect(
    function fetchClientsOnMount() {
      if (state.currentFamilyId !== id) {
        dispatch({ type: "updateCurrentFamily", familyId: id })
      }
      const onClientsFetchedSuccess = (clients: UserListModel[]) =>
        dispatch({ type: "updateClientsOfCurrentFamily", clients: clients })
      fetchFamilyClients(id, setLoading, onClientsFetchedSuccess, handleError)
      fetchFamilyDetails(id, () => {}, setFamilyDetails, handleError)
    },
    [handleError, id, state.currentFamilyId, dispatch]
  )

  const entityListingObjects = state.clientsOfCurrentFamily.map((client) => {
    return { id: client.id, name: `${client.firstName} ${client.lastName}` }
  })

  return (
    <CareOrganizationMobileViewBase>
      {isLoading ? (
        <Box sx={{ mt: 5, mb: 4 }}>
          <CircularProgress size={100} />
        </Box>
      ) : isEmpty(state.clientsOfCurrentFamily) ? (
        <NoClientsInFamilyHeader userName={user?.firstName} />
      ) : (
        <>
          <ClientListingFamilyName familyName={familyDetails.name} />
          <FamilyTopFeelingsCard />
          <ClientListingHeader />
          <ClientEntityListing
            entities={entityListingObjects ?? []}
            icon={<PeopleIcon />}
            url={`/care-org-user/family/${id}/client`}
          />
        </>
      )}
      <GoBackButton url="/care-org-user" />
      <ErrorSnackbar />
    </CareOrganizationMobileViewBase>
  )
}

export default CareOrganizationUserClientListing
