/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FamilyFeelingAggregateModel } from "../models/FamilyFeelingAggregateModel"
import type { FeelingModel } from "../models/FeelingModel"
import type { FeelingStreakModel } from "../models/FeelingStreakModel"
import type { FeelingTargetModel } from "../models/FeelingTargetModel"
import type { GroupedFeelings } from "../models/GroupedFeelings"
import type { UserFeelingListModel } from "../models/UserFeelingListModel"
import type { UserFeelingModel } from "../models/UserFeelingModel"
import type { UserFeelingSaveModel } from "../models/UserFeelingSaveModel"
import { request as __request } from "../core/request"

export class FeelingService {
  /**
   * Return supported feelings for client group
   * @param groupId Group id
   * @returns FeelingModel Success
   * @throws ApiError
   */
  public static async feelingGetFeelingsForClientGroup(groupId?: number): Promise<Array<FeelingModel>> {
    const result = await __request({
      method: "GET",
      path: `/Feeling/getFeelingOptions`,
      query: {
        groupId: groupId,
      },
    })
    return result.body
  }

  /**
   * @param groupId
   * @returns FeelingTargetModel Success
   * @throws ApiError
   */
  public static async feelingGetFeelingTargetsForClientGroup(groupId?: number): Promise<Array<FeelingTargetModel>> {
    const result = await __request({
      method: "GET",
      path: `/Feeling/getFeelingTargetOptions`,
      query: {
        groupId: groupId,
      },
    })
    return result.body
  }

  /**
   * @param userId
   * @param from
   * @param to
   * @returns UserFeelingListModel Success
   * @throws ApiError
   */
  public static async feelingGetForUser(
    userId: string,
    from?: string,
    to?: string
  ): Promise<Array<UserFeelingListModel>> {
    const result = await __request({
      method: "GET",
      path: `/Feeling/getForUser/${userId}`,
      query: {
        from: from,
        to: to,
      },
    })
    return result.body
  }

  /**
   * @param userId
   * @param from
   * @param to
   * @returns UserFeelingModel Success
   * @throws ApiError
   */
  public static async feelingGetForUserAll(
    userId: string,
    from?: string,
    to?: string
  ): Promise<Array<UserFeelingModel>> {
    const result = await __request({
      method: "GET",
      path: `/Feeling/getForUserAllDetails/${userId}`,
      query: {
        from: from,
        to: to,
      },
    })
    return result.body
  }

  /**
   * @param userId
   * @param date
   * @returns UserFeelingModel Success
   * @throws ApiError
   */
  public static async feelingGetForUserSingleDay(userId: string, date: string): Promise<UserFeelingModel> {
    const result = await __request({
      method: "GET",
      path: `/Feeling/getForUserDetails/${userId}/${date}`,
    })
    return result.body
  }

  /**
   * @param userId
   * @param from
   * @param to
   * @returns GroupedFeelings Success
   * @throws ApiError
   */
  public static async feelingGetAggregatedForUser(
    userId: string,
    from?: string,
    to?: string
  ): Promise<Array<GroupedFeelings>> {
    const result = await __request({
      method: "GET",
      path: `/Feeling/getAggregatedForUser/${userId}`,
      query: {
        from: from,
        to: to,
      },
    })
    return result.body
  }

  /**
   * @param familyId
   * @param from
   * @param to
   * @returns FamilyFeelingAggregateModel Success
   * @throws ApiError
   */
  public static async feelingGetAggregatedForFamily(
    familyId: string,
    from?: string,
    to?: string
  ): Promise<FamilyFeelingAggregateModel> {
    const result = await __request({
      method: "GET",
      path: `/Feeling/getAggregatedForFamily/${familyId}`,
      query: {
        from: from,
        to: to,
      },
    })
    return result.body
  }

  /**
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public static async feelingSaveUserFeelings(requestBody?: UserFeelingSaveModel): Promise<any> {
    const result = await __request({
      method: "POST",
      path: `/Feeling/saveUserFeelings`,
      body: requestBody,
    })
    return result.body
  }

  /**
   * @param userId
   * @returns FeelingStreakModel Success
   * @throws ApiError
   */
  public static async feelingGetFeelingSreak(userId?: string): Promise<FeelingStreakModel> {
    const result = await __request({
      method: "GET",
      path: `/Feeling/getFeelingStreak`,
      query: {
        userId: userId,
      },
    })
    return result.body
  }
}
