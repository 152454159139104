import produce from "immer"
import { findIndex, negate } from "lodash/fp"
import { UserActivityModel } from "services/openapi"
import { SingleActivityModel } from "services/openapi/models/SingleActivityModel"
import activityIdEquals from "./utils/activityIdEquals"
import helperIdEquals from "./utils/helperIdEquals"

export type ActivitySelectionState = {
  activities: SingleActivityModel[]
  helpers: number[]
  wouldHaveNeededHelp: boolean
}

export const initialActivitySelectionState: ActivitySelectionState = {
  activities: [],
  helpers: [],
  wouldHaveNeededHelp: false,
}

export type Action =
  | { type: "createNewActivity"; activityData: SingleActivityModel }
  | { type: "deleteActivity"; activityId: number }
  | { type: "setActivities"; stateData: UserActivityModel }
  | { type: "updateActivitySuccess"; activityId: number; success: number | null }
  | { type: "createNewHelper"; helperId: number }
  | { type: "deleteHelper"; helperId: number }

export const activitiesSelectionStateReducer = produce((draft: ActivitySelectionState, action: Action) => {
  const getUpdatedActivityIndex = (activityId: number) => {
    return findIndex(activityIdEquals(activityId))(draft.activities)
  }

  switch (action.type) {
    case "createNewActivity": {
      draft.activities.push(action.activityData)
      break
    }

    case "deleteActivity": {
      const remainingActivities = draft.activities.filter(negate(activityIdEquals(action.activityId)))
      draft.activities = remainingActivities
      break
    }

    case "createNewHelper": {
      draft.helpers.push(action.helperId)
      break
    }

    case "deleteHelper": {
      const remainingHelpers = draft.helpers.filter(negate(helperIdEquals(action.helperId)))
      draft.helpers = remainingHelpers
      break
    }

    case "setActivities": {
      draft.activities = action.stateData.activities
      draft.helpers = action.stateData.activityHelperIds
      break
    }

    case "updateActivitySuccess": {
      const updatedFeelingIndex = getUpdatedActivityIndex(action.activityId)
      if (updatedFeelingIndex !== -1) {
        draft.activities[updatedFeelingIndex].success = action.success ?? 0
      }

      break
    }

    default:
      break
  }
})
