import { useCallback, useState } from "react"

/*
  From usehooks.com
  This hooks helps us to toggle a boolean variable. We can use this to easily open and close
  modals.
*/
const useToggle = (initialState: boolean = false): [boolean, any] => {
  const [state, setState] = useState<boolean>(initialState)
  const toggle = useCallback((): void => setState((state) => !state), [])
  return [state, toggle]
}

export default useToggle
