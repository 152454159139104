import { Fab } from "@mui/material"

type WideFabProps = {
  color: string
  children: any
  onClick: () => void
}

const WideFab = ({ color, children, onClick }: WideFabProps) => {
  return (
    <Fab
      sx={{
        position: "sticky",
        zIndex: 3,
        top: "92%",
        width: "305px",
        left: "calc(50% - 152.5px)",
        py: 3,
        backgroundColor: color,
        color: "white",
        fontFamily: "SofiaProMedium",
      }}
      variant="extended"
      onClick={onClick}
    >
      {children}
    </Fab>
  )
}

export default WideFab
