import { TextField } from "@mui/material"
import { FormikProps } from "formik"
import { useTranslation } from "react-i18next"

type CreateSuperAdminFieldsProps = {
  formik: FormikProps<{ firstName: string; lastName: string }>
}

const CreateSuperAdminFields = ({ formik }: CreateSuperAdminFieldsProps) => {
  const { t } = useTranslation()
  return (
    <>
      <TextField
        id="firstName"
        label={t("create-super-admin.first-name")}
        sx={{ mb: 2, float: "left", clear: "both" }}
        variant="standard"
        color="primary"
        {...formik.getFieldProps("firstName")}
        helperText={formik.touched.firstName && formik.errors.firstName}
        required
      />
      <TextField
        id="lastName"
        label={t("create-super-admin.last-name")}
        sx={{ mb: 2, clear: "both", float: "left" }}
        variant="standard"
        color="primary"
        {...formik.getFieldProps("lastName")}
        helperText={formik.touched.lastName && formik.errors.lastName}
        required
      />
    </>
  )
}

export default CreateSuperAdminFields
