import { InteractionStatus } from "@azure/msal-browser"
import { useMsal } from "@azure/msal-react"
import { Search } from "@mui/icons-material"
import {
  Box,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import LoadingIndicatorRow from "components/LoadingIndicatorRow"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  createOrganization,
  deactivateOrganization,
  fetchOrganizations,
} from "scenes/admin/ManagementOrganizationList/apiRequests"
import { ManagementOrganizationCreateModel, OrganizationListModel } from "services/openapi"
import useSnackbar from "utils/hooks/useSnackbar"
import TablePaginationActions from "utils/TablePaginationActions"
import CreateManagementOrganization from "./CreateManagementOrganization"
import "./ManagementOrganizationList.sass"
import OrgTableRows from "./TableRows"

const ManagementOrganizationList = () => {
  const { t } = useTranslation()
  const { instance, inProgress } = useMsal()
  const { Snackbar, showSnackbar } = useSnackbar()

  const [isLoading, setLoading] = useState<boolean>(true)
  const [searchTerm, setSearchTerm] = useState("")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [organizations, setOrganizations] = useState<OrganizationListModel[]>([])

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      fetchOrganizations(setLoading, setOrganizations, instance, showSnackbar, t)
    }
    return () => {
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [inProgress])

  const filteredOrgs =
    organizations?.filter((org) => org.name?.toLocaleUpperCase().includes(searchTerm.toLocaleUpperCase())) || []

  const slicedOrgs = filteredOrgs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%", overflow: "auto" }}>
      <Paper sx={{ padding: 4 }} id="management-org-list">
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography variant="h1">{t("management-organization-list.header")}</Typography>
          <CreateManagementOrganization
            createOrganization={(orgData: ManagementOrganizationCreateModel) =>
              createOrganization(orgData, setOrganizations, instance, showSnackbar, t)
            }
          />
        </Box>

        <TextField
          id="organization-search"
          sx={{ width: "100%" }}
          label={t("management-organization-list.search")}
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value)
            setPage(0)
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search role="img" aria-labelledby="organization-search" />
              </InputAdornment>
            ),
          }}
        />

        <Table id="management-org-table" sx={{ mt: 2 }} aria-label={t("management-organization-list.table.label")}>
          <TableHead>
            <TableRow>
              <TableCell>{t("management-organization-list.table.header.name")}</TableCell>
              <TableCell align="right">{t("management-organization-list.table.header.controls")}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading ? (
              <LoadingIndicatorRow colSpan={2} />
            ) : (
              <OrgTableRows
                organizations={slicedOrgs}
                deactivateOrganization={(orgId: string) =>
                  deactivateOrganization(orgId, organizations, setOrganizations, instance, showSnackbar, t)
                }
              />
            )}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 25]}
                onRowsPerPageChange={(e: any) => {
                  setRowsPerPage(e.target.value)
                  setPage(0)
                }}
                page={page}
                count={filteredOrgs.length || 0}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={t("table-pagination.rows-per-page")}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
      <Snackbar />
    </Box>
  )
}

export default ManagementOrganizationList
