import { Typography } from "@mui/material"
import { Box } from "@mui/system"
import FeelingRating from "components/FeelingRating"
import { useTranslation } from "react-i18next"

type FeelingIntensitySectionProps = {
  intensityState: {
    intensity: number
    onChange: (_event: React.SyntheticEvent<Element, Event>, newValue: number | null) => void
  }
  setChangesMade: (value: boolean) => void
}

const FeelingIntensitySection = ({ intensityState, setChangesMade }: FeelingIntensitySectionProps) => {
  const { t } = useTranslation()

  return (
    <Box sx={{ mx: 4, mb: 2, mt: 14 }}>
      <Typography variant="h2" sx={{ fontFamily: "SofiaProMedium", fontSize: "24px", mb: 2 }}>
        {t("youth-view.feeling-selection.intensity_query")}
      </Typography>
      <FeelingRating
        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: number | null) => {
          intensityState.onChange(event, newValue)
          setChangesMade(true)
        }}
        value={intensityState.intensity}
      />
    </Box>
  )
}

export default FeelingIntensitySection
