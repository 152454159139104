import { toDateFormat } from "./functions/toDateFormat"

const now = new Date()
const today = new Date(Date.UTC(now.getFullYear(), now.getUTCMonth(), now.getUTCDate()))

export const timeSpanOptions = {
  today: {
    name: "today",
    translationKey: "customer-mobile-view.feeling-history.feelings-today",
    start: toDateFormat(today),
    end: toDateFormat(today),
  },
  week: {
    name: "week",
    translationKey: "customer-mobile-view.feeling-history.feelings-week",
    start: toDateFormat(new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000)),
    end: toDateFormat(today),
  },
  month: {
    name: "month",
    translationKey: "customer-mobile-view.feeling-history.feelings-month",
    start: toDateFormat(new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000)),
    end: toDateFormat(today),
  },
  year: {
    name: "year",
    translationKey: "customer-mobile-view.feeling-history.feelings-year",
    start: toDateFormat(new Date(today.getTime() - 365 * 24 * 60 * 60 * 1000)),
    end: toDateFormat(today),
  },
  custom: {
    name: "custom",
    translationKey: "customer-mobile-view.feeling-history.custom",
  },
}

export const getTimeSpanOptions = () => [
  timeSpanOptions.today,
  timeSpanOptions.week,
  timeSpanOptions.month,
  timeSpanOptions.year,
]
