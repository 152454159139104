/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientUserCreateModel } from "../models/ClientUserCreateModel"
import type { ClientUserDetailsModel } from "../models/ClientUserDetailsModel"
import type { UserCreateResultModel } from "../models/UserCreateResultModel"
import type { UserListModelPagedResponseModel } from "../models/UserListModelPagedResponseModel"
import { request as __request } from "../core/request"

export class ClientUserService {
  /**
   * Returns all client users that are matching the search terms.
   * @param familyUnitId
   * @param pageSize
   * @param page
   * @param searchString
   * @returns UserListModelPagedResponseModel Success
   * @throws ApiError
   */
  public static async clientUserGetFamilyUsers(
    familyUnitId: string,
    pageSize?: number,
    page?: number,
    searchString?: string
  ): Promise<UserListModelPagedResponseModel> {
    const result = await __request({
      method: "GET",
      path: `/ClientUser/getFamilyUsers`,
      query: {
        familyUnitId: familyUnitId,
        pageSize: pageSize,
        page: page,
        searchString: searchString,
      },
    })
    return result.body
  }

  /**
   * Creates a new client for family
   * @param requestBody Created user
   * @returns UserCreateResultModel Success
   * @throws ApiError
   */
  public static async clientUserCreateClientUser(requestBody?: ClientUserCreateModel): Promise<UserCreateResultModel> {
    const result = await __request({
      method: "POST",
      path: `/ClientUser/createClient`,
      body: requestBody,
    })
    return result.body
  }

  /**
   * @param userId
   * @returns UserCreateResultModel Success
   * @throws ApiError
   */
  public static async clientUserResetPassword(userId: string): Promise<UserCreateResultModel> {
    const result = await __request({
      method: "POST",
      path: `/ClientUser/resetPassword/${userId}`,
    })
    return result.body
  }

  /**
   * @param familyUnitId
   * @returns number Success
   * @throws ApiError
   */
  public static async clientUserGetAllowedClientGroupIdsForFamily(familyUnitId?: string): Promise<Array<number>> {
    const result = await __request({
      method: "GET",
      path: `/ClientUser/getAllowedClientGroupIdsForFamily`,
      query: {
        familyUnitId: familyUnitId,
      },
    })
    return result.body
  }

  /**
   * @param userId
   * @returns any Success
   * @throws ApiError
   */
  public static async clientUserDeactivateUser(userId: string): Promise<any> {
    const result = await __request({
      method: "DELETE",
      path: `/ClientUser/deactivate/${userId}`,
    })
    return result.body
  }

  /**
   * @param userId
   * @returns ClientUserDetailsModel Success
   * @throws ApiError
   */
  public static async clientUserGetDetails(userId: string): Promise<ClientUserDetailsModel> {
    const result = await __request({
      method: "GET",
      path: `/ClientUser/details/${userId}`,
    })
    return result.body
  }
}
