import { ClientUserService, UserListModel } from "services/openapi"

export const fetchFamilyClients = async (
  familyId: string,
  setLoading: (isLoading: boolean) => void,
  onSuccess: (clients: UserListModel[]) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoading(true)
    const clientResponse = await ClientUserService.clientUserGetFamilyUsers(familyId)
    onSuccess(clientResponse.items)
  } catch (error) {
    onError(error, "family-details.snackbar.fetch-clients-error")
  }
  setLoading(false)
}
