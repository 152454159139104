import { Box, Button, Card, CardActionArea, CardContent, useTheme } from "@mui/material"
import CardName from "./styled/CardName"

type SpesCardProps = {
  name: string
  onClick: () => void
  height?: number
  selected: boolean
  iconUrl: string
}

const SpesCard = ({ name, onClick, height, selected, iconUrl }: SpesCardProps) => {
  const theme = useTheme()
  const heightSetting = height == null ? 159 : height
  const widthSetting = 162
  const border = selected ? `solid 5px ${theme.palette.secondary.dark}` : ""
  const elevation = selected ? 8 : 3

  return (
    <Card
      elevation={elevation}
      sx={{ width: widthSetting, height: heightSetting, borderRadius: 3, border: border, backgroundColor: "primary.main", mx: 0.3 }}
    >
      <CardActionArea component={Button} onClick={onClick} sx={{ height: "100%" }}>
        <CardContent>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", rowGap: 1 }}>
            <img src={iconUrl} height={70} alt={""} aria-hidden="true" />
            <CardName aria-label={name}>{name}</CardName>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default SpesCard
