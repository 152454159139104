import { Redirect, Switch } from "react-router-dom"

import CareOrganizationDetails from "scenes/admin/CareOrganizationDetails"
import CareOrganizationList from "scenes/admin/CareOrganizationList"
import SuperAdminList from "scenes/admin/SuperAdminList"
import FamilyDetails from "scenes/admin/FamilyDetails"
import ManagementOrganizationDetails from "scenes/admin/ManagementOrganizationDetails"
import ManagementOrganizationList from "scenes/admin/ManagementOrganizationList"
import PrivateRoute from "routing/utils/PrivateRoute"
import { UserRoleModel } from "services/openapi"
import ProfilePage from "scenes/admin/ProfilePage"

const AdminRoutes = () => (
  <Switch>
    <PrivateRoute
      path="/admin/user/:type/:userId"
      role={[
        UserRoleModel.SUPER_ADMIN,
        UserRoleModel.MANAGEMENT_ORGANIZATION_ADMIN_USER,
        UserRoleModel.CARE_ORGANIZATION_USER,
      ]}
    >
      <ProfilePage />
    </PrivateRoute>

    <PrivateRoute path="/admin/super-admins" role={UserRoleModel.SUPER_ADMIN}>
      <SuperAdminList />
    </PrivateRoute>

    <PrivateRoute path="/admin/management-organization/:organizationId" role={UserRoleModel.SUPER_ADMIN}>
      <ManagementOrganizationDetails />
    </PrivateRoute>

    <PrivateRoute path="/admin/management-organizations" role={UserRoleModel.SUPER_ADMIN}>
      <ManagementOrganizationList />
    </PrivateRoute>

    <PrivateRoute
      path={`/admin/care-organization/:organizationId/family/:familyId`}
      exact
      role={UserRoleModel.CARE_ORGANIZATION_USER}
    >
      <FamilyDetails />
    </PrivateRoute>

    <PrivateRoute
      path="/admin/care-organization/:organizationId"
      role={[UserRoleModel.MANAGEMENT_ORGANIZATION_ADMIN_USER, UserRoleModel.CARE_ORGANIZATION_USER]}
    >
      <CareOrganizationDetails />
    </PrivateRoute>

    <PrivateRoute
      path="/admin/care-organizations"
      role={[UserRoleModel.MANAGEMENT_ORGANIZATION_ADMIN_USER, UserRoleModel.CARE_ORGANIZATION_USER]}
    >
      <CareOrganizationList />
    </PrivateRoute>

    <Redirect to="/admin" />
  </Switch>
)

export default AdminRoutes
