import MobileViewBase from "components/MobileViewBase"
import MobileViewDescription from "components/MobileViewBase/MobileViewDescription"
import { useTranslation } from "react-i18next"
import HistorySection from "./HistorySection"

const MobileFeelingHistory = () => {
  const { t } = useTranslation()

  return (
    <MobileViewBase
      top={
        <MobileViewDescription
          header={t("customer-mobile-view.feeling-history.title")}
          text={t("customer-mobile-view.feeling-history.description")}
        />
      }
      middle={<HistorySection />}
      bottom={<></>}
    />
  )
}

export default MobileFeelingHistory
