import { Theme } from "@mui/material"

export const matchFeelingIdWithColor = (id: number, theme: Theme) => {
  switch (id) {
    case 1:
      return theme.feelingColors?.glad
    case 2:
      return theme.feelingColors?.happy
    case 3:
      return theme.feelingColors?.energetic
    case 4:
      return theme.feelingColors?.excited
    case 5:
      return theme.feelingColors?.hopeful
    case 6:
      return theme.feelingColors?.confident
    case 7:
      return theme.feelingColors?.serene
    case 8:
      return theme.feelingColors?.content
    case 9:
      return theme.feelingColors?.interested
    case 10:
      return theme.feelingColors?.amazed
    case 11:
      return theme.feelingColors?.ok
    case 12:
      return theme.feelingColors?.sleepy
    case 13:
      return theme.feelingColors?.crying
    case 14:
      return theme.feelingColors?.sad
    case 15:
      return theme.feelingColors?.disgusted
    case 16:
      return theme.feelingColors?.irritated
    case 17:
      return theme.feelingColors?.anxious
    case 18:
      return theme.feelingColors?.lonely
    case 19:
      return theme.feelingColors?.angry
    case 20:
      return theme.feelingColors?.hopeless
    case 21:
      return theme.feelingColors?.fearful
    case 22:
      return theme.feelingColors?.worried
    case 23:
      return theme.feelingColors?.empowered
    case 24:
      return theme.feelingColors?.social
    case 25:
      return theme.feelingColors?.bored
    case 26:
      return theme.feelingColors?.tired
    case 27:
      return theme.feelingColors?.frustratedOrAngry
    case 28:
      return theme.feelingColors?.calm
    case 29:
      return theme.feelingColors?.safe
    case 30:
      return theme.feelingColors?.spry
    case 31:
      return theme.feelingColors?.stressed
    default:
      return "#000000"
  }
}
