import { ActivityService, UserActivitySaveModel } from "services/openapi"

export const saveUserActivities = async (
  data: UserActivitySaveModel,
  setLoading: (isLoading: boolean) => void,
  onError: (error: any, message: string) => void
) => {
  setLoading(true)
  try {
    await ActivityService.activitySaveUserActivity(data)
  } catch (error) {
    onError(error, "elderly-view.activity-selection.snackbar.save-error")
  }
  setLoading(false)
}
