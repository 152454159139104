import { InteractionStatus } from "@azure/msal-browser"
import { useMsal } from "@azure/msal-react"
import { LinearProgress, MenuItem, Paper, TextField, Typography } from "@mui/material"
import UserTable from "components/UserTable"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import {
  connectUserToFamily,
  createClient,
  deactivateClient,
  disconnectUserFromFamily,
  fetchAllowedClientGroups,
  fetchFamilyDetails,
  fetchFamilyUsers,
  resetClientUserPassword,
  updateFamily,
} from "scenes/admin/FamilyDetails/apiRequests"
import {
  CareOrganizationUserListModel,
  ClientUserCreateModel,
  FamilyUnitDetailsModel,
  UserCreateResultModel,
  UserListModel,
} from "services/openapi"
import { userContext } from "contexts/userContext"
import { fetchFamilyClients } from "utils/apiRequests/fetchFamilyClients"
import { filterEntityWithId } from "utils/functions/filterEntityWithId"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import useConfirmationDialog from "utils/hooks/useConfirmationDialog"
import useSnackbar from "utils/hooks/useSnackbar"
import ConnectUser from "./ConnectUser"
import CreateClient from "./CreateClient"
import StyledButton from "components/StyledButton"
import "./FamilyDetails.sass"
import UserCreateResultDialog from "components/UserCreateResultDialog"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import PasswordResetResultDialog from "components/PasswordResetResultDialog"

type FamilyEditForm = {
  name: string
  description: string
}

const FamilyDetails = () => {
  const { organizationId, familyId } = useParams<{ organizationId: string; familyId: string }>()
  const { inProgress } = useMsal()
  const { t } = useTranslation()
  const { Snackbar, showSnackbar } = useSnackbar()
  const { ErrorSnackbar, handleError } = useApiRequestError()
  const { user: currentUser } = useContext(userContext)
  const { Dialog, showConfirmationDialog } = useConfirmationDialog()

  const [isLoadingDetails, setLoadingDetails] = useState<boolean>(true)
  const [isLoadingFamilyUsers, setLoadingFamilyUsers] = useState<boolean>(true)
  const [isLoadingFamilyClients, setLoadingFamilyClients] = useState<boolean>(true)
  const [isSaving, setSaving] = useState<boolean>(false)
  const [family, setFamily] = useState<FamilyUnitDetailsModel>({
    id: "",
    name: "",
    description: "",
  })
  const [clients, setClients] = useState<UserListModel[]>([])
  const [users, setUsers] = useState<UserListModel[]>([])
  const [unconnectedUsers, setUnconnectedUsers] = useState<UserListModel[]>([])
  const [allowedClientGroups, setAllowedClientGroups] = useState<number[]>([])

  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false)
  const [passwordResetDialogOpen, setPasswordResetDialogOpen] = useState(false)
  const [passwordResetResult, setPasswordResetResult] = useState<UserCreateResultModel>({
    databaseId: "",
    firstTimePassword: "",
    userLogin: "",
  })

  const onPasswordResetSuccess = (result: UserCreateResultModel) => {
    setPasswordResetResult(result)
    setPasswordResetDialogOpen(true)
  }

  const canEditFamilyUnit = currentUser?.canManageFamilyUnits

  const initialValues: FamilyEditForm = {
    name: family.name,
    description: family.description,
  }

  const onFetchFamilyUserDataSuccess = (connectedUsers: UserListModel[], unconnectedUsers: UserListModel[]) => {
    setUsers(connectedUsers)
    setUnconnectedUsers(unconnectedUsers)
  }

  useEffect(
    function fetchFamilyDetailsOnLoad() {
      if (inProgress === InteractionStatus.None) {
        fetchFamilyDetails(familyId, setLoadingDetails, setFamily, handleError)
        fetchFamilyUsers(organizationId, familyId, setLoadingFamilyUsers, onFetchFamilyUserDataSuccess, handleError)
        fetchFamilyClients(familyId, setLoadingFamilyClients, setClients, handleError)
        fetchAllowedClientGroups(familyId, setAllowedClientGroups, handleError)
      }
      return () => {
        setLoadingFamilyUsers(true)
        setLoadingFamilyClients(true)
      }
    },
    [inProgress, familyId, handleError, organizationId]
  )

  const roleChooser = (user: CareOrganizationUserListModel) => {
    return user.canManageOrganizationUsers || user.canManageFamilyUnits
      ? t(`user-list.user-role.foreman`)
      : t(`user-list.user-role.worker`)
  }

  const UserMenuItems = (user: UserListModel, handleClose: () => void) => {
    const onDisconnectUserConfirmationDialogSubmit = () => {
      const onDisconnectSuccess = (disconnectedUser: UserListModel) => {
        setUnconnectedUsers([...unconnectedUsers, disconnectedUser])
        setUsers(filterEntityWithId(users, user.id))
        showSnackbar(t("family-details.snackbar.disconnect-user-success"), "success")
      }

      return disconnectUserFromFamily(user.id, familyId, users, onDisconnectSuccess, handleError)
    }

    return [
      <MenuItem
        key={`disconnect-${user.id}`}
        onClick={() => {
          showConfirmationDialog(
            onDisconnectUserConfirmationDialogSubmit,
            t("family-details.confirm-disconnect.header"),
            t("family-details.confirm-disconnect.message", { name: `${user.firstName} ${user.lastName}` })
          )
          handleClose()
        }}
      >
        {t("family-details.user-table.disconnect-user")}
      </MenuItem>,
    ]
  }

  const ClientMenuItems = (client: UserListModel, handleClose: () => void) => {
    const onClientDeactivationSuccess = (updatedClients: UserListModel[]) => {
      setClients(updatedClients)
      showSnackbar(t("family-details.snackbar.deactivate-client-success"), "success")
    }

    const items = []
    if (currentUser?.canManageFamilyUnits) {
      items.push(
        <MenuItem
          key={`user-${client.id}-deactive`}
          onClick={() => {
            showConfirmationDialog(
              () => deactivateClient(client.id, clients, onClientDeactivationSuccess, handleError),
              t("family-details.confirm-client-deactivation.header"),
              t("family-details.confirm-client-deactivation.message", {
                name: `${client.firstName} ${client.lastName}`,
              })
            )
            handleClose()
          }}
        >
          {t("family-details.user-table.deactivate-client")}
        </MenuItem>
      )
    }

    items.push(
      <MenuItem
        key={`user-${client.id}-reset`}
        onClick={() => {
          showConfirmationDialog(
            () => resetClientUserPassword(client.id, onPasswordResetSuccess, handleError),
            t("user-list.reset-password.header"),
            t("user-list.reset-password.message", { name: `${client.firstName} ${client.lastName}` })
          )
          handleClose()
        }}
      >
        {t("user-list.table.reset-password")}
      </MenuItem>
    )
    return items
  }

  const onFormSubmit = (value: FamilyEditForm, { resetForm }: any) => {
    const model: FamilyUnitDetailsModel = {
      id: family.id,
      name: value.name,
      description: value.description,
    }

    updateFamily(
      model,
      setSaving,
      () => {
        showSnackbar(t("family-details.snackbar.update-family-success"), "success")
        fetchFamilyDetails(familyId, setLoadingDetails, setFamily, handleError)
        resetForm()
      },
      handleError
    )
  }

  const [createResult, setCreateResult] = useState<UserCreateResultModel>({
    databaseId: "",
    firstTimePassword: "",
    userLogin: "",
  })

  const connectUserToFamilyCallback = (userId: string) => {
    const onUserConnectedToFamilySuccess = (connectedUsers: UserListModel[], unconnectedUsers: UserListModel[]) => {
      setUsers(connectedUsers)
      setUnconnectedUsers(unconnectedUsers)
      showSnackbar(t("family-details.snackbar.connect-user-success"), "success")
    }

    return connectUserToFamily(
      userId,
      familyId,
      users,
      unconnectedUsers,
      onUserConnectedToFamilySuccess,
      setUnconnectedUsers
    )
  }

  const clientCreationCallback = (client: ClientUserCreateModel) => {
    const onClientCreationSuccess = (updatedClientsList: UserListModel[], result: UserCreateResultModel) => {
      setClients(updatedClientsList)
      setCreateResult(result)
      setPasswordDialogOpen(true)
      showSnackbar(t("family-details.snackbar.create-client-success"), "success")
    }
    return createClient(client, familyId, onClientCreationSuccess, handleError)
  }

  return (
    <>
      <UserCreateResultDialog
        open={passwordDialogOpen}
        onClose={() => setPasswordDialogOpen(false)}
        createResult={createResult}
      />

      <div id="family-details">
        <Paper id="family-basic-info">
          <PasswordResetResultDialog
            open={passwordResetDialogOpen}
            onClose={() => setPasswordResetDialogOpen(false)}
            createResult={passwordResetResult}
          />
          {isLoadingDetails ? (
            <LinearProgress />
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={Yup.object({
                name: Yup.string()
                  .trim()
                  .max(50, t("form-validation.too-long", { length: 500 }))
                  .required(t("form-validation.required")),
                description: Yup.string()
                  .trim()
                  .max(50, t("form-validation.too-long", { length: 2000 }))
                  .required(t("form-validation.required")),
              })}
              onSubmit={onFormSubmit}
            >
              {(formik) => (
                <Form>
                  <Typography variant="h1">{family.name}</Typography>
                  <TextField
                    id="name"
                    label={t("family-details.name")}
                    disabled={!canEditFamilyUnit || isSaving}
                    sx={{ my: 2, width: "100%" }}
                    variant="standard"
                    color="primary"
                    {...formik.getFieldProps("name")}
                    helperText={formik.touched.name && formik.errors.name}
                  />

                  <TextField
                    id="description"
                    label={t("family-details.description")}
                    disabled={!canEditFamilyUnit || isSaving}
                    sx={{ my: 2, width: "100%" }}
                    variant="standard"
                    color="primary"
                    {...formik.getFieldProps("description")}
                    helperText={formik.touched.description && formik.errors.description}
                  />

                  {canEditFamilyUnit && (
                    <StyledButton
                      variant="contained"
                      id="save-button"
                      disabled={!formik.dirty || !formik.isValid || isSaving}
                      sx={{ width: "fit-content", alignSelf: "end" }}
                      type="submit"
                    >
                      {t("family-details.save-changes-button")}
                    </StyledButton>
                  )}
                </Form>
              )}
            </Formik>
          )}
        </Paper>

        <Paper sx={{ padding: 4, mb: 4 }} id="family-users">
          <UserTable
            isLoading={isLoadingFamilyUsers}
            users={users}
            menuItems={currentUser?.canManageFamilyUnits ? UserMenuItems : undefined}
            headerButton={
              currentUser?.canManageFamilyUnits ? (
                <ConnectUser unconnectedUsers={unconnectedUsers} connectUserToFamily={connectUserToFamilyCallback} />
              ) : undefined
            }
            roleRowContent={roleChooser}
          />
        </Paper>

        <Paper id="family-clients">
          <UserTable
            isLoading={isLoadingFamilyClients}
            users={clients}
            header={t("family-details.clients-header")}
            menuItems={ClientMenuItems}
            headerButton={
              currentUser?.canManageFamilyUnits ? (
                <CreateClient
                  familyId={familyId}
                  createClient={clientCreationCallback}
                  allowedClientGroups={allowedClientGroups ?? []}
                />
              ) : undefined
            }
            roleRowContent={undefined}
          />
        </Paper>
      </div>
      <Snackbar />
      <Dialog />
      <ErrorSnackbar />
    </>
  )
}

export default FamilyDetails
