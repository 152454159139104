import { useMsal } from "@azure/msal-react"
import { Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import { loginRequest } from "../authConfig"

export const SignInButton = () => {
  const { instance } = useMsal()
  const { t } = useTranslation()

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => console.error(e))
  }

  return (
    <Button
      className="ml-auto"
      onClick={handleLogin}
      sx={{
        alignSelf: "flex-end",
        fontFamily: "SofiaProMedium",
        borderRadius: "15px",
        backgroundColor: "primary.main",
        width: "200px",
        height: "50px",
        margin: "0 auto 152px auto",
        textAlign: "center",
        "&:hover": {
          backgroundColor: "primary.main",
        },
        boxShadow: "0 2px 4px -1px rgb(0 0 0 / 16%), 0 4px 5px 0 rgb(0 0 0 / 12%), 0 1px 10px 0 rgb(0 0 0 / 8%);",
        color: "secondary.dark",
      }}
    >
      {t("login.sign-in")}
    </Button>
  )
}
