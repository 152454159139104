import { Box } from "@mui/system"
import MobileContentContainer from "components/MobileContentContainer"
import WavyBackground from "components/WavyBackground"
import TopBar from "./TopBar"

export type MobileContentBaseProps = {
  top: JSX.Element
  middle: JSX.Element
  bottom: JSX.Element
}

const MobileViewBase = ({ top, middle, bottom }: MobileContentBaseProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "100vh",
      }}
    >
      <WavyBackground>
        <TopBar />
        <MobileContentContainer>
          {top}
          {middle}
        </MobileContentContainer>
      </WavyBackground>
      {bottom}
    </Box>
  )
}

export default MobileViewBase
