import { CareOrganizationService, FamilyUnitService } from "services/openapi"

export const fetchFamilies = async (
  organizationId: string,
  setLoadingFamilies: (isLoadingFamilies: boolean) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoadingFamilies(true)
    const families = await FamilyUnitService.familyUnitGetOrganizationFamilyUnits(organizationId)
    if (families) {
      setLoadingFamilies(false)
      return families
    }
  } catch (error) {
    setLoadingFamilies(false)
    onError(error, "family-list.snackbar.fetch-error")
  }
}

export const fetchOrganizations = async (onError: (error: any, message: string) => void, managementOrgId?: string) => {
  try {
    return await CareOrganizationService.careOrganizationGetAll(managementOrgId)
  } catch (e) {
    onError(e, "care-organization-list.snackbar.fetch-error")
  }
}
