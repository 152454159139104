import { Button, ButtonGroup } from "@mui/material"
import CalendarDialog from "components/CalendarDialog"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toDateFormat } from "utils/functions/toDateFormat"
import { Timespan } from "./Timespan"
import { getTimeSpanOptions, timeSpanOptions } from "../../utils/timeSpanUtils"
import { compose, defaultTo, get } from "lodash/fp"
import { useQueryParameters } from "../../utils/hooks/useQueryParameters"

type TimeSpanSelectionButtonsProps = {
  onSpanSelected: (timespan: Timespan) => void
}

export const TimeSpanSelectionButtons = ({ onSpanSelected }: TimeSpanSelectionButtonsProps) => {
  const now = new Date()
  const today = new Date(Date.UTC(now.getFullYear(), now.getUTCMonth(), now.getUTCDate()))

  const customDateTranslationKey = "customer-mobile-view.feeling-history.custom"
  const { t } = useTranslation()
  const [selectedTimeSpan, setSelectedTimeSpan] = useState<Timespan>(timeSpanOptions.week)
  const [selectedDate, setSelectedDate] = useState(toDateFormat(today))
  const [open, setOpen] = useState(false)
  const query = useQueryParameters()

  const updateSpan = (span: Timespan) => {
    setSelectedTimeSpan(span)
    onSpanSelected(span)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const onCalendarClosed = async (value: string) => {
    setOpen(false)
    const customTimespan = {
      name: "custom",
      translationKey: customDateTranslationKey,
      start: value,
      end: value,
    }
    setSelectedTimeSpan(customTimespan)
    onSpanSelected(customTimespan)
    setSelectedDate(value)
  }

  const spanSelection = getTimeSpanOptions().map((span) => (
    <Button
      key={span.translationKey}
      color={span.translationKey === selectedTimeSpan.translationKey ? "info" : "primary"}
      sx={{ display: "block", borderRadius: "7px" }}
      onClick={() => updateSpan(span)}
    >
      {t(span.translationKey)}
    </Button>
  ))

  useEffect(() => {
    const timeSpanOption = compose(defaultTo(timeSpanOptions.week), get(query.get("timespan") ?? ""))(timeSpanOptions)
    const timeSpan = { ...timeSpanOption }
    if (timeSpan.name === timeSpanOptions.custom.name) {
      timeSpan.start = query.get("start")
      timeSpan.end = query.get("end")
    }
    setSelectedTimeSpan(timeSpan)
    onSpanSelected(timeSpan)
  }, [onSpanSelected, query])

  return (
    <>
      <CalendarDialog selectedDate={selectedDate} open={open} onClose={onCalendarClosed} />
      <ButtonGroup
        variant="contained"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          width: "100%",
        }}
        disableElevation
      >
        {spanSelection}
        <Button
          variant="contained"
          color={customDateTranslationKey === selectedTimeSpan.translationKey ? "info" : "primary"}
          onClick={handleClickOpen}
          sx={{
            borderRadius: "7px",
          }}
        >
          {t("customer-mobile-view.feeling-history.custom")}
        </Button>
      </ButtonGroup>
    </>
  )
}
