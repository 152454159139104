const TOS = `
# ANVÄNDARVILLKOR FÖR TJÄNSTEANVÄNDAREN AV MOODPILOT

## 1 Allmänt

Dessa villkor för Tjänsteanvändarens användarrätt att använda Tjänsten (hädanefter ”Användarvillkoren”) innehåller Hämeen Spes Oy:s (hädanefter ”Spes Palvelut”) Fiilisluotsi/MoodPilot-tjänst (hädanefter "Tjänsten") samt villkoren gällande eventuella tillägg, som Du som privatperson som använder Tjänsten (hädanefter ”Tjänsteanvändaren” eller ”Du”) förbinder Dig att följa när Du registrerar Dig som användare av Tjänsten, när Du installerar Tjänsten eller använder Tjänsten.
Användning av Tjänsten kräver registrering. Genom att registrera Dig för eller använda Tjänsten godkänner Du dessa Användarvillkor.

## 2 Användarrätt 

När Du loggar in eller registrerar Dig som användare av tjänsten Fiilisluotsi/MoodPilot, ger Spes Palvelut Dig en begränsad, ej-överlåtbar användarrätt.
Användningsrätten gäller så länge Du använder Tjänsten som tillhandahålls av Tjänsteleverantören på grundval av ett avtal eller annat beslut om Tjänsten. Användningsrätten upphör automatiskt vid uppsägning av Tjänsteleverantörens avtal, beslut eller annat avtal mellan Spes Palvelut och Tjänsteleverantören.

## 3 Ditt ansvar och Dina åtaganden

Spes Palvelut tillhandahåller SaaS-plattformen (som en programvarutjänst) i enlighet med Tjänsten som en del av Tjänsteleverantörens verksamhet.
Implementeringen av Tjänsten påverkar inte anställnings- eller tjänsteförhållandet mellan Dig och tjänsteleverantören och de påföljande skyldigheterna eller rättigheterna (inklusive användningen av personuppgifter), utan Tjänsten tillhandahåller endast en elektronisk plattform för denna aktivitet.
Det är Ditt ansvar att använda Tjänsten i enlighet med dessa Användarvillkor och att följa de instruktioner och föreskrifter som rör användningen av Tjänsten. 
Du är ansvarig för allt material som du skickar in eller överför till Tjänsten, såsom texter, information och dokument. Du ansvarar för att det material du laddar upp till Tjänsten inte kränker tredje parts upphovsrätt och att du har tillstånd och rätt att skicka sådant material till Tjänsten.
Du förbinder Dig att inte lagra, förvara, distribuera, skicka eller överföra något material i Tjänsten, som strider mot lagen och/eller god praxis, uppmuntra eller främja sådan aktivitet.  
Du förbinder Dig att inte lagra, förvara, distribuera, skicka eller överföra något material i Tjänsten, som rör andras personuppgifter och ärenden, t.ex. hälsouppgifter. 
Tjänsten identifierar Dig genom ett kund-ID och lösenord. Det är Ditt ansvar att ta hand om omsorgsfull lagring och konfidentialitet för Ditt kund-ID och lösenord. Du är ansvarig för all användning av Tjänsten med Ditt kund-ID och lösenord. Du måste utan onödigt dröjsmål meddela Tjänsteleverantören om ditt kund-ID eller lösenord går förlorat eller om du misstänker att Ditt kund-ID används utan ditt tillstånd.

Om du inte har rätt att använda Tjänsten, till exempel för att du inte längre är anställd, har en verksamhetsrelation eller enligt ett avtal med Tjänsteleverantör som använder Tjänsten, måste Du meddela Tjänsteleverantören och sluta använda Tjänsten. Du har inte rätt att självständigt överföra Dina kund-ID:n till en tredje part.
Du förbinder Dig att inte använda Tjänsten:
1.	för någon aktivitet som gör det svårt eller kan göra det svårt för andra Tjänsteanvändare att använda Tjänsten;
2.	för överföring av skräppost eller annan otillåten datakommunikation;
3.	för att publicera eller överföra material som är rättighetskränkande, är olagligt, förnedrande, obscent eller på annat sätt kränker andra personers eller organs rättigheter;
4.	för insamling eller lagring av andra användares personuppgifter utan dessa användares uttryckliga tillstånd; eller
5.	för olagliga handlingar.
Spes Palvelut förbehåller sig rätten att åberopa sina juridiska rättigheter som härrör från brott mot dessa begränsningar. Spes Palvelut har rätt att blockera utan att vara ansvarig för handlingen, blockera eller begränsa Din tillgång till tjänsten eller återkalla Din åtkomst eller vidta andra åtgärder i den utsträckning som är nödvändig för att stoppa Din aktivitet som bryter mot Användarvillkoren.

## 4 Samtycke till användning av personuppgifter

Användning av Tjänsten kräver att Dina personuppgifter överlämnas till Tjänsten i samband med skapandet av ett användar-ID och lösenord. Denna information beskrivs i registerbeskrivningen för Spes Palvelut. Tjänsteleverantören Spes Palvelut agerar som personuppgiftsansvarig/personuppgiftsbiträde i den mening som avses i dataskyddslagstiftningen tillsammans med Tjänsteleverantören. Ett personuppgiftsbiträdesavtal har upprättats med Tjänsteleverantören i enlighet med vilket vi bedriver verksamhet.
De personuppgifter som krävs för registret får användas
-	för Din identifiering och för genomförandet av Tjänsten;
-	för att hantera och utveckla Tjänsten även av programvaruutvecklare utanför EU;
-	för att skicka infomeddelanden eller meddelanden;
-	för att tillhandahålla, implementera och underhålla ytterligare tjänster eller instruktioner;
-	för marknadsföring av produkter och tjänster;
-	för utveckling av kundservice och affärsverksamhet;
-	för analyser och statistiska ändamål;
-	för utbyte av meddelanden mellan Tjänsteleverantören och Tjänstens upprätthållare och Dig, samt
-	för andra liknande användningsändamål

Genom att registrera Dig och använda Tjänsten ger Du Ditt uttryckliga samtycke till Spes Palvelut och Tjänsteleverantören att använda Dina personuppgifter, såsom beskrivs i registerbeskrivningen för tillhandahållandet av Tjänsten.
All behandling av personuppgifter i samband med Tjänsten är föremål för Spes Palveluts registerbeskrivning som finns tillgänglig på webbplatsen [https://www.spespalvelut.fi/rekisteri-jatitietosuojaseloste].
Du har rätt att när som helst återkalla ditt samtycke helt eller delvis. Observera dock att användningen av Tjänsten kräver överlämnande av personuppgifter relaterade till Tjänsten.

## 5 Uppsägning av användningen av Tjänsten

Om Du vill sluta använda Tjänsten och avbryta Din registrering måste du skriftligen meddela Tjänsteleverantören om saken. Tjänsteleverantören kommer att radera Ditt användarnamn utan onödigt dröjsmål, men senast inom 14 dagar efter Din skriftliga begäran.

## 6 Ansvarsbegränsning

Spes Palvelut tillhandahåller Tjänsten ”i befintligt skick”. Ingen garanti eller inget ansvar ges för Tjänsten. Spes Palvelut garanterar inte att Tjänsten kan användas utan avbrott eller felfritt. Spes Palvelut förbehåller sig rätten att, av berättigade skäl, göra ändringar i Tjänsten eller att begränsa tillgången till Tjänsten när som helst utan föregående meddelande till Dig. 
Spes Palvelut ansvarar inte för det material du har matat in i Tjänsten, dess laglighet, innehåll eller om materialet kan bryta mot tredje parts immateriella rättigheter. 
Tjänsten är inte ansvarig för retur eller lagring av det material som Du har tillhandahållit Tjänsten efter att användningen av Tjänsten har upphört.  
Du är till ansvarig för eventuella skador, orsakade av användning i strid med användarvillkoren, andra avtalsvillkor och lagen eller god praxis, vilka uppstår för Spes Palvelut, andra användare av Tjänsten och tredje parter.

## 7 Upphovsrätt och andra rättigheter
Äganderätten till Tjänsten, liksom upphovsrätt och alla andra immateriella rättigheter relaterade till Tjänsten och det material som finns i Tjänsten, annat än det som lagts till av Dig (inklusive, men inte begränsat till, upphovsrätt, oregistrerade och registrerade varumärkes- och designrättigheter, patent, domännamn, affärshemligheter och databasrättigheter) tillhör Spes Palvelut.  

Alla rättigheter som inte uttryckligen beviljas i dessa Användarvillkor är förbehållna. Tjänsten ger Dig inte några andra direkta eller indirekta rättigheter att använda Tjänsten annat än rätten att använda Tjänsten i dess syfte i enlighet med dessa Användarvillkor.

Kopiering, överföring, distribution eller lagring av hela eller delar av innehållet i Tjänsten i någon form är förbjuden utan föregående skriftligt medgivande från Spes Palvelut. 

## 8 Tredjepartsmaterial
Tjänsten kan innehålla länkar till webbplatser och innehåll som ägs eller upprätthålls av tredje part. När går till sådana webbplatser måste Du läsa och godkänna deras eventuella användarvillkor innan du börjar använda dem. De länkade webbplatserna kontrolleras inte av Spes Palvelut, och Spes Palvelut har inget inflytande över innehållet i det material som publiceras på dem. Spes Palvelut ansvarar inte för material eller tjänster som finns på tredje parts webbplatser.

## 9 Spes Palvelut har rätt att ta bort eller censurera material som överförs till Tjänsten av Tjänsteanvändaren
Spes Palvelut förbehåller sig rätten att ta bort eller censurera från Tjänsten allt innehåll eller material som produceras av Dig efter eget gottfinnande om, enligt Spes Palvelut, inlämnandet av sådant material skulle vara emot eller strider mot dessa Användarvillkor. 

## 10 Sekretesspolicy och andra villkor avseende personuppgifter
Dessa användarvillkor regleras av sekretesspolicyn för Spes Palvelut.
Spes Palvelut kan använda personuppgifter relaterade till användningen av Tjänsten på ett sådant sätt att Du inte kan identifieras. Spes Palvelut kan använda sådana uppgifter i anonymiserad eller pseudonymiserad form för bland annat följande användningssyften:
-	sammanställning av statistik
-	utveckling av tjänsten
-	övervaka antalet användare av Tjänsten och marknadsföring baserad på den

Spes Palvelut kan också vidareöverlämna information i denna form.
Villkoren och anvisningarna för användningen av personuppgifter och de samtycken Du har gett finns i sekretesspolicyn. Villkoren för den sekretesspolicy som används av Spes Palvelut är tillgängliga [https://www.spespalvelut.fi/rekisteri-jatioprotection-description]. 
Spes Palvelut får ytterligare behandla och överlämna de uppgifter som genereras i samband med användningen av Tjänsten i enlighet med de förutsättningar som anges i lagen om sekundär användning av personuppgifter inom social- och hälsovården (552/2019).  

## 11 Tillämplig lagstiftning 
Dessa Användarvillkor regleras av finsk lag, men på ett sådant sätt att FN: s konvention om internationell försäljning av varor (CISG) och dess lagkonfliktbestämmelser inte gäller för användningen av Tjänsten. Dessa Användarvillkor begränsar inte konsumenternas rättigheter enligt tvingande finsk lagstiftning.  
I händelse av tvist är den behöriga domstolen den allmänna domstolen på Spes Palveluts hemort.
`
export default TOS
