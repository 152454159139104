import { Box } from "@mui/system"
import { useTranslation } from "react-i18next"
import FeelingDetailsHeader from "./styled/FeelingDetailsHeader"

type FeelingDetailsTopBarProps = {
  feelingName: string
  iconFilename: string
  viewIsScrollable: boolean
}

const FeelingDetailsTopBar = ({ feelingName, iconFilename, viewIsScrollable }: FeelingDetailsTopBarProps) => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: viewIsScrollable ? "fixed" : "static",
        backgroundColor: "primary.main",
        zIndex: 3,
        width: "100%",
        height: "112px",
        top: "34px",
        px: 4,
        mt: viewIsScrollable ? 0 : 4,
      }}
    >
      <Box sx={{ mr: 4 }}>
        <img
          src={`${process.env.PUBLIC_URL}/feelingIcons/${iconFilename}`}
          width={68}
          alt={`${iconFilename}`}
          aria-hidden="true"
        />
      </Box>
      <FeelingDetailsHeader variant="h1">
        {t(`youth-view.feeling-selection.feeling-options.${feelingName}`)}
      </FeelingDetailsHeader>
    </Box>
  )
}

export default FeelingDetailsTopBar
