import produce from "immer"
import { findIndex, negate } from "lodash/fp"
import { SingleFeelingModel } from "services/openapi"
import feelingIdEquals from "./utils/feelingIdEquals"

export type FeelingSelectionState = {
  feelings: SingleFeelingModel[]
}

export const initialFeelingSelectionState: FeelingSelectionState = {
  feelings: [],
}

export type Action =
  | { type: "updateFeelingStrength"; feelingId: number; strength: number | null }
  | { type: "createNewFeeling"; feelingData: SingleFeelingModel }
  | { type: "addFeelingTargetId"; feelingId: number; targetId: number }
  | { type: "deleteFeelingTargetId"; feelingId: number; targetId: number }
  | { type: "deleteFeeling"; feelingId: number }
  | { type: "setFeelings"; feelings: SingleFeelingModel[] }

export const feelingSelectionStateReducer = produce((draft: FeelingSelectionState, action: Action) => {
  const getUpdatedFeelingIndex = (feelingId: number) => {
    return findIndex(feelingIdEquals(feelingId))(draft.feelings)
  }

  switch (action.type) {
    case "updateFeelingStrength": {
      const updatedFeelingIndex = getUpdatedFeelingIndex(action.feelingId)
      if (updatedFeelingIndex !== -1) {
        draft.feelings[updatedFeelingIndex].strength = action.strength ?? 0
      }

      break
    }

    case "createNewFeeling": {
      draft.feelings.push(action.feelingData)
      break
    }

    case "addFeelingTargetId": {
      const updatedFeelingIndex = getUpdatedFeelingIndex(action.feelingId)
      draft.feelings[updatedFeelingIndex].feelingTargetIds.push(action.targetId)
      break
    }

    case "deleteFeelingTargetId": {
      const updatedFeelingIndex = getUpdatedFeelingIndex(action.feelingId)
      const indexOfTargetToBeDeleted = draft.feelings[updatedFeelingIndex].feelingTargetIds.indexOf(action.targetId)
      draft.feelings[updatedFeelingIndex].feelingTargetIds.splice(indexOfTargetToBeDeleted, 1)
      break
    }

    case "deleteFeeling": {
      const remainingFeelings = draft.feelings.filter(negate(feelingIdEquals(action.feelingId)))
      draft.feelings = remainingFeelings
      break
    }

    case "setFeelings": {
      draft.feelings = action.feelings
      break
    }

    default:
      break
  }
})
