import { SuperAdminUserService, UserCreateResultModel, UserEditModel, UserListModel } from "services/openapi"

export const fetchSuperAdmins = async (
  setLoading: (isLoading: boolean) => void,
  onSuccess: (superAdmins: UserListModel[]) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoading(true)
    const adminResponse = await SuperAdminUserService.superAdminUserGetSuperAdmins()
    onSuccess(adminResponse.items)
  } catch (error) {
    onError(error, "fetch-super-admin.snackbar.fetch-error")
  }
  setLoading(false)
}

export const createSuperAdmin = async (
  admin: UserEditModel,
  setSuperAdmins: React.Dispatch<React.SetStateAction<UserListModel[]>>,
  onSuccess: (createResult: UserCreateResultModel) => void,
  showSnackbar: (message: string, type: "error" | "warning" | "info" | "success") => void,
  t: (key: string) => string,
  onError: (error: any, message: string) => void
) => {
  try {
    const newUserResponse = await SuperAdminUserService.superAdminUserCreateSuperAdmin(admin)
    onSuccess(newUserResponse)
    const adminResponse = await SuperAdminUserService.superAdminUserGetSuperAdmins()
    setSuperAdmins(adminResponse.items)
    showSnackbar(t("super-admins.snackbar.create-success"), "success")

    return true
  } catch (e) {
    onError(e, "super-admins.snackbar.create-error")
    return false
  }
}

export const deactivateSuperAdmin = async (
  adminId: string,
  onSuccess: () => void,
  onError: (error: any, message: string) => void
) => {
  try {
    await SuperAdminUserService.superAdminUserDeactivateUser(adminId)
    onSuccess()
    return true
  } catch (e) {
    onError(e, "super-admins.snackbar.deactivate-error")
    return false
  }
}

export const resetSuperAdminPassword = async (
  adminId: string,
  onSuccess: (createResult: UserCreateResultModel) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    const result = await SuperAdminUserService.superAdminUserResetPassword(adminId)
    onSuccess(result)
    return true
  } catch (e) {
    onError(e, "super-admins.snackbar.reset-password-error")
    return false
  }
}
