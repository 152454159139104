import { InteractionStatus } from "@azure/msal-browser"
import { useMsal } from "@azure/msal-react"
import { Search } from "@mui/icons-material"
import {
  Box,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import LoadingIndicatorRow from "components/LoadingIndicatorRow"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { deactivateCareOrganization, fetchOrganizations } from "scenes/admin/CareOrganizationList/apiRequests"
import { OrganizationListModel } from "services/openapi"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import useSnackbar from "utils/hooks/useSnackbar"
import TablePaginationActions from "utils/TablePaginationActions"
import "./CareOrganizationList.sass"
import CreateCareOrganization from "./CreateCareOrganization"
import OrgTableRows from "./TableRows"

type Props = {
  managementOrgId?: string
}

const CareOrganizationList = (props: Props) => {
  const { managementOrgId = "" } = props
  const { t } = useTranslation()
  const { inProgress } = useMsal()
  const { Snackbar, showSnackbar } = useSnackbar()

  const [isLoading, setLoading] = useState<boolean>(true)
  const [searchTerm, setSearchTerm] = useState("")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [organizations, setOrganizations] = useState<OrganizationListModel[]>([])

  const { ErrorSnackbar, handleError } = useApiRequestError()

  useEffect(
    function fetchOrganizationsOnLoad() {
      if (inProgress === InteractionStatus.None) {
        fetchOrganizations(managementOrgId, setLoading, setOrganizations, handleError)
      }
    },
    [inProgress, handleError, managementOrgId]
  )

  const organizationSearchResults = getSearchResults(organizations, searchTerm)
  const displayedOrganizations = getDisplayedOrganizations(organizationSearchResults, page, rowsPerPage)

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage)
  }

  const deactivateCareOrg = (orgId: string) => {
    const onCareOrganizationDeactivationSuccess = () => {
      showSnackbar(t("care-organization-list.snackbar.deactivate-success"), "success")
      const filteredOrganizations = organizations.filter(
        (organization: OrganizationListModel) => organization.id !== orgId
      )
      setOrganizations(filteredOrganizations)
    }

    return deactivateCareOrganization(orgId, onCareOrganizationDeactivationSuccess, handleError)
  }

  return (
    <>
      <Paper id="care-org-list">
        <Box id="care-org-list-header" sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h1">{t("care-organization-list.header")}</Typography>
          <CreateCareOrganization organizationsListUpdateCallback={setOrganizations} />
        </Box>

        <TextField
          id="organization-search"
          label={t("care-organization-list.search")}
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search role="img" aria-labelledby="organization-search" />
              </InputAdornment>
            ),
          }}
        />

        <Table id="care-org-table" aria-label={t("care-organization-list.table.label")}>
          <TableHead>
            <TableRow>
              <TableCell>{t("care-organization-list.table.header.name")}</TableCell>
              <TableCell align="right">{t("care-organization-list.table.header.controls")}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading ? (
              <LoadingIndicatorRow colSpan={2} />
            ) : (
              <OrgTableRows organizations={displayedOrganizations} deactivateCareOrg={deactivateCareOrg} />
            )}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 25]}
                onRowsPerPageChange={(e: any) => {
                  setRowsPerPage(e.target.value)
                  setPage(0)
                }}
                page={page}
                count={organizationSearchResults.length || 0}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={t("table-pagination.rows-per-page")}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
      <Snackbar />
      <ErrorSnackbar />
    </>
  )
}

const getSearchResults = (organizations: OrganizationListModel[] | null | undefined, searchTerm: string) => {
  return organizations?.filter((org) => org.name?.toLocaleUpperCase().includes(searchTerm.toLocaleUpperCase())) || []
}

const getDisplayedOrganizations = (organizations: OrganizationListModel[], page: number, rowsPerPage: number) => {
  const fromIndex = page * rowsPerPage
  const toIndex = fromIndex + rowsPerPage
  return organizations.slice(fromIndex, toIndex)
}

export default CareOrganizationList
