const TOS = `
# TERMS AND CONDITIONS FOR USERS OF THE FIILISLUOTSI SERVICE

## 1 General Information

These terms and conditions for the Service User's right to use the Service (hereinafter referred to as the "Terms of Use") include the terms and conditions for the use of the Fiilisluotsi/MoodPilot service (hereinafter referred to as the "Service") of HämeenSpes Oy (hereinafter referred to as the "Spes Services") and any add-ons thereto, to which you as a private person using the Service (hereinafter referred to as the "ServiceUser" or "You") agree to comply when registering as a user of the Service, when installing the Service or when using the Service.
Use of the Service requires registration. By registering for or using the Service, you agree to these Terms of Use.

## 2 Access rights 

When you log in or register as a user of the Service to the Fiilisluotsi/MoodPilot service, Spes Services grants you a limited, non-transferable right to use the Service.
The right of use is valid for as long as you use the Service provided by the Service Provider on the basis of an agreement or other decision concerning the Service. The right of use shall automatically terminate upon termination of the Service Provider's agreement, decision or other agreement between the Spes Services and the Service Provider.

## 3 Your Responsibilities and Commitments

Spes Services provides the SaaS platform (as a software service) in accordance with the Service as part of the Service Provider's operations.
The implementation of the Service does not affect the employment or service relationship between You and the Service Provider and the resulting obligations or rights (including the use of personal data), but only provides an electronic platform for this activity.
It is your responsibility to use the Service in accordance with these Terms of Use and to comply with the instructions and regulations related to the use of the Service. 
You are responsible for all material you submit or transfer to the Service, such as texts, information and documents. You are responsible for ensuring that the material you upload to the Service does not infringe the copyrights of third parties and that you have permission and the right to submit such material to the Service.
You agree not to store, preserve, distribute, transmit or disseminate through the Service any material that violates, incites or promotes any law and/or morality. 
You agree not to store, preserve, distribute, transmit or disseminate through the Service any material regarding the personal data and matters of others, such as health information. 
The service recognizes you through a customer ID and password. It is your responsibility to ensure that your customer ID and password are carefully stored and kept confidential. You are responsible for all use of the Service with your customer ID and password. You must notify the Service Provider without undue delay if your customer ID or password is lost or you suspect that your customer ID is being used without your permission.

If you do not have the right to use the Service, for example because you are no longer employed, employed or under a contract with a Service Provider who uses the Service, you must notify the Service Provider and stop using the Service. You do not have the right to independently transfer your customer IDs to a third party.
You agree not to use the Service:
1.	in any activity that makes it difficult or could make it difficult for other Service Users to use the Service,
2.	to the transmission of spam or other unauthorised communications;
3.	publishing or transmitting material infringing, unlawful, degrading, obscene or otherwise infringing the rights of other persons or entities;
4.	the collection or storage of personal data of other users without the express permission of those users; or
5.	illegal actions.
Spes Services reserves the right to invoke any of its legal rights arising from the violation of these restrictions. Spes Services shall have the right, without liability to you, to block or restrict your access to the Service or revoke your access or take any other action necessary to stop any activity that violates the Terms of Use.

## 4 Consent to the use of personal data

In order to use the Service, you must submit personal information about yourself to the Service in connection with the creation of your username and password. This information is described in the Spes Services' Privacy Policy. The operator of the Service Spes Services acts as a data controller/processor of personal data within the meaning of data protection legislation together with the Service Provider. A personal data processing agreement has been drawn up with the service provider, in accordance with which we operate.

The personal data required for the register may be used

-	For your identification and for the implementation of the Service;
-	To manage and develop the Service also by software developers outside the EU;
-	to send notices or messages;
-	to provide, implement and maintain additional services or instructions;
-	marketing of products and services;
-	development of customer service and business;
-	for analysis and statistical purposes;
-	Exchange of messages between the Service Operator and the Service Provider and You; and
-	for other similar uses

By registering for and using the Service, you give your explicit consent to Spes Services and the Service Provider to use your personal data described in the register description for the provision of the Service.
All processing of personal data related to the Service is subject to the Spes Services' register description, which is available on the website [https://www.spespalvelut.fi/rekisteri-jatitietosuojaseloste].
You have the right to withdraw your consent in whole or in part at any time. Please note, however, that the use of the Service requires the disclosure of personal data related to the Service.

## 5 Termination of use of the Service

If you wish to stop using the Service and cancel your registration, you must notify the Service Provider in writing. The Service Provider will delete your username without undue delay, but no later than within 14 days of your written request.

## 6 Limitation of Liability

Spes Services provides the Service “as is”. No guarantee or liability is given for the service. Spes Services does not guarantee that the Service can be used without interruption or error-free. Spes Services reserves the right, for justified reasons, to make changes to the Service or to restrict access to the Service at any time without prior notice to You. 
Spes Services is not responsible for the material you have entered into the Service, its legality, content or whether the material potentially infringes the intellectual property rights of a third party. 
Spes Services is not responsible for the return or storage of the material you have provided to the Service after the end of use of the Service.  
You are fully responsible for any damage caused to the Spes Service, other users of the Service and third parties in violation of the Terms of Use, any other terms of agreement and any use of the Service contrary to the law or good practice.

##  7 Copyright and other rights
Ownership of the Service and copyright and all other intellectual property rights (including, without limitation, copyrights, unregistered and registered trademark and design rights, patents, domain names, trade secrets and database rights) in the Service and any material on the Service other than material added by you belong to the Spes Services.  
All rights not expressly granted in these Terms of Use are reserved. Spes Services does not grant you any direct or indirect rights to use the Service other than the right to use the Service for its purpose in accordance with these Terms of Use.
Copying, transferring, distributing or storing all or part of the content of the Service in any form is prohibited without the prior written consent of Spes Services. 

## 8 Third Party Material
The Service may contain links to pages and content owned or maintained by third parties. When you access such pages, you must read and agree to their possible terms of use before you start using them. The linked pages are not under the control of the Spes Services, and the Spes Services have no influence on the content of the material published with them. Spes Services is not responsible for any material or service contained in the third-party websites.

## 9 Spes has theright to remove or censor material transferred to the Service by the Service User
Spes Services reserves the right to remove or censor any content or material you have produced from the Service at its sole discretion if, in the opinion of Spes Services , the submission of such material would be or is in violation of these Terms of Use. 

## 10 Privacy policy and other terms concerning personal data
These Terms of Use are subject to the Privacy Policy for the Spes Services.
Spes Services may use personal data related to the use of the Service in such a way that you cannot be identified or identified. Spes Services may use such information anonymously or pseudonymously for the following purposes, among others:

-	compiling statistics
-	Development of the Service
-	Monitoring the number of users of the service and marketing based on it

Spes Services may also forward information in this form.
The terms and conditions and instructions concerning the use of personal data and the consents you have given are available in the privacy policy. The terms of the data protection statement used by the Spes Services are available [https://www.spespalvelut.fi/rekisteri-ja-tietosuojaseloste]. 
In addition, the Spes Services may process and disclose the data generated in connection with the use of the Service in accordance with the requirements laid down in the Act on the Secondary Use of Social and Health Information (552/2019).  

## 11 Applicable law 
These Terms of Use are subject to Finnish law, however, in such a way that the United Nations Convention on the International Sale of Goods (CISG) and its conflict of law provisions do not apply to the use of the Service. These Terms of Use do not restrict the rights of consumers under mandatory Finnish legislation.  
In the event of a dispute, the competent court is the general court of the Spes Services' domicile.
`
export default TOS
