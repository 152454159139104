import { Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

export const MenuHeader = () => {
  const { t } = useTranslation()

  return (
    <Typography variant="h2" sx={{ mx: 3, mb: 2, fontSize: "28px", fontWeight: "bold", fontFamily: "SofiaproBold" }}>
      {t("hamburger-menu.header")}
    </Typography>
  )
}
