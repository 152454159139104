import { Box } from "@mui/material"
import mobileBackground from "images/mobileBackground.svg"

const WavyBackground = ({ children }: { children: any }) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${mobileBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 74vh",
        minWidth: "100vw",
        flex: "2",
      }}
    >
      {children}
    </Box>
  )
}

export default WavyBackground
