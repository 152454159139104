/* istanbul ignore file */
/* tslint:disable */

/* eslint-disable */

/**
 * User role.
 *
 * * Unknown - Feature status is not known.
 * This status is not allowed.
 * This may be returned by API if new feature status is added to
 * database and not yet implemented by API.
 */
export enum UserRoleModel {
  UNKNOWN = "Unknown",
  SUPER_ADMIN = "SuperAdmin",
  MANAGEMENT_ORGANIZATION_ADMIN_USER = "ManagementOrganizationAdminUser",
  CARE_ORGANIZATION_USER = "CareOrganizationUser",
  CLIENT = "Client",
}
