import ReactMarkdown from "react-markdown"
import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"

const TermsOfUseContent = () => {
  const { t } = useTranslation()
  const src = t("terms-of-user-view.content")

  return (
    <Box
      sx={{
        mx: 3,
        overflow: "hidden",
        wordBreak: "break-word",
      }}
    >
      <ReactMarkdown children={src} />
    </Box>
  )
}

export default TermsOfUseContent
