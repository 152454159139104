import { Box, Card, CardContent, useTheme } from "@mui/material"
import useMountEffect from "utils/hooks/useMountEffect"
import { useContext, useState } from "react"
import { userContext } from "contexts/userContext"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import ActivityDetails from "./ActivityDetails"
import { UserActivityModel } from "services/openapi"
import { toDateFormat } from "utils/functions/toDateFormat"
import { useTranslation } from "react-i18next"
import { getActivitiesForDate } from "utils/apiRequests/fetchActivities"

const DailyActivitySummary = () => {
  const { user } = useContext(userContext)
  const theme = useTheme()
  const { t } = useTranslation()
  const { handleError } = useApiRequestError()
  const [activities, setActivities] = useState<UserActivityModel>({
    activities: [],
    activityHelperIds: [],
    date: "",
    userId: "",
    wouldHaveNeededHelp: false,
  })

  useMountEffect(async function fetchData() {
    const today: string = toDateFormat(new Date())
    if (user?.id) {
      await getActivitiesForDate(user.id, today, () => {}, setActivities, handleError)
    }
  })

  return (
    <Card
      elevation={4}
      sx={{
        borderRadius: 3,
        width: theme.diaryView?.contentWidth,
        mb: 2,
        marginX: "auto",
        backgroundColor: "primary.main",
      }}
    >
      {activities.activities.length ? (
        <CardContent>
          <Box sx={{ pt: 2 }}>
            <ActivityDetails userActivities={activities} />
          </Box>
        </CardContent>
      ) : (
        <CardContent>
          <Box sx={{ mx: 7, my: 7 }}>{t("selection.no-activities")}</Box>
        </CardContent>
      )}
    </Card>
  )
}

export default DailyActivitySummary
