import { MoreVert } from "@mui/icons-material"
import { Button, Menu, MenuItem, TableCell, TableRow } from "@mui/material"
import { useCallback, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { OrganizationListModel, ProfileModel, UserRoleModel } from "services/openapi"
import { userContext } from "contexts/userContext"
import useConfirmationDialog from "utils/hooks/useConfirmationDialog"
import StyledTableLink from "../../../components/StyledTableLink"

type OrgTableRowsProps = {
  organizations: OrganizationListModel[]
  deactivateCareOrg: (orgId: string) => Promise<boolean>
}

// Map items on page to be displayed
const OrgTableRows = ({ organizations, deactivateCareOrg }: OrgTableRowsProps) => {
  const { t } = useTranslation()
  const { Dialog, showConfirmationDialog } = useConfirmationDialog()

  const [anchorEl, setAnchorEl] = useState(null)
  const [orgMenu, setOrgMenu] = useState<OrganizationListModel | null>(null)

  const { user: currentUser } = useContext(userContext)

  const showControlsButton = currentUser?.role === UserRoleModel.MANAGEMENT_ORGANIZATION_ADMIN_USER

  const handleClick = useCallback(
    (event: any, org: OrganizationListModel) => {
      setAnchorEl(event.currentTarget)
      setOrgMenu(org)
    },
    [setAnchorEl, setOrgMenu]
  )

  const handleClose = () => {
    setAnchorEl(null)
    setOrgMenu(null)
  }

  const onDeleteMenuItemClick = (organizationId: string, orgName: string) => {
    showConfirmationDialog(
      () => deactivateCareOrg(organizationId),
      t("delete-care-organization.warning-title"),
      t("delete-care-organization.warning-text", { name: `${orgName}` })
    )
  }

  if (!organizations) return null

  const rows = organizations.map((org, i) => {
    return (
      <TableRow key={`org-${i}`}>
        <TableCell>
          {isAllowedToReadCareOrgs(currentUser) ? (
            <StyledTableLink to={`/admin/care-organization/${org.id}`}>{org.name}</StyledTableLink>
          ) : (
            <p>{org.name}</p>
          )}
        </TableCell>
        <TableCell align="right">
          {showControlsButton && (
            <Button
              onClick={(e) => handleClick(e, org)}
              id={`org-control-button-${i}`}
              aria-label={t("care-org-list.table.aria-controls", { name: `${org.name}` })}
            >
              <MoreVert />
            </Button>
          )}

          {/* 
          Check open status here instead of in the menu open property to avoid rendering extra menus unnecessarily.
          */}
          {org === orgMenu && (
            <Menu
              className="user-menu"
              open
              anchorEl={anchorEl}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": `org-button-${i}`,
              }}
            >
              <MenuItem
                onClick={() => {
                  onDeleteMenuItemClick(org.id ?? "", org.name)
                  handleClose()
                }}
              >
                {t("delete-care-organization.delete")}
              </MenuItem>
            </Menu>
          )}
        </TableCell>
      </TableRow>
    )
  })

  return (
    <>
      {rows}
      <Dialog />
    </>
  )
}

const isAllowedToReadCareOrgs = (user: ProfileModel | null) => user?.role !== UserRoleModel.SUPER_ADMIN

export default OrgTableRows
