import { ThemeProvider } from "@emotion/react"
import { Box } from "@mui/system"
import { mobileTheme } from "mobileTheme"
import TopBar from "./MobileViewBase/TopBar"
import WavyBackground from "./WavyBackground"

const CareOrganizationMobileViewBase = ({ children }: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "100vh",
      }}
    >
      <WavyBackground>
        <TopBar />
        <ThemeProvider theme={mobileTheme}>{children}</ThemeProvider>
      </WavyBackground>
    </Box>
  )
}

export default CareOrganizationMobileViewBase
