import { useTheme } from "@mui/material"
import { ClientGroupDataContext } from "contexts/clientGroupDataContext"
import { isEmpty } from "lodash/fp"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import FeelingDetailsTopBar from "./FeelingDetailsTopBar"
import FeelingIntensitySection from "./FeelingIntensitySection"
import FeelingTargetSection from "./FeelingTargetSection"
import { SelectedFeeling } from "./SelectedFeeling"
import { Action, FeelingSelectionState } from "./selectedFeelingsReducer"
import WideFab from "./styled/WideFab"
import feelingIdEquals from "./utils/feelingIdEquals"

type FeelingDetailsViewProps = {
  selectedFeelingData: SelectedFeeling
  drawerToggle: () => void
  feelingsState: [FeelingSelectionState, React.Dispatch<Action>]
}

const FeelingDetailsView = ({ selectedFeelingData, drawerToggle, feelingsState }: FeelingDetailsViewProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [changesMade, setChangesMade] = useState(false)
  const [state, dispatch] = feelingsState
  const { feelingTargetOptions } = useContext(ClientGroupDataContext)

  const getSelectedFeeling = () => {
    return state.feelings.find(feelingIdEquals(selectedFeelingData.id))
  }

  const onFeelingTargetClicked = (feelingTargetId: number) => {
    createNewFeelingIfNotAlreadyCreated()

    const targetAlreadySelected = () =>
      getSelectedFeeling()?.feelingTargetIds.some((targetId) => targetId === feelingTargetId)

    if (targetAlreadySelected()) {
      dispatch({ type: "deleteFeelingTargetId", feelingId: selectedFeelingData.id, targetId: feelingTargetId })
    } else {
      dispatch({ type: "addFeelingTargetId", feelingId: selectedFeelingData.id, targetId: feelingTargetId })
    }
  }

  /*
   * We create a new feeling when the user either sets an intensity of a target for a feeling.
   */
  const onFeelingIntensityChange = (_event: React.SyntheticEvent<Element, Event>, newValue: number | null) => {
    createNewFeelingIfNotAlreadyCreated()
    dispatch({ type: "updateFeelingStrength", feelingId: selectedFeelingData.id, strength: newValue })
  }

  const feelingTargetState = {
    feelingTargetIds: getSelectedFeeling()?.feelingTargetIds ?? [],
    onClick: onFeelingTargetClicked,
  }

  const intensityState = {
    intensity: getSelectedFeeling()?.strength ?? 0,
    onChange: onFeelingIntensityChange,
  }

  const feelingAlreadyAdded = (feelingId: number) => state.feelings.some(feelingIdEquals(feelingId))

  const createNewFeelingIfNotAlreadyCreated = () => {
    if (!feelingAlreadyAdded(selectedFeelingData.id)) {
      dispatch({
        type: "createNewFeeling",
        feelingData: { feelingId: selectedFeelingData.id, strength: 0, feelingTargetIds: [] },
      })
    }
  }

  const onSaveFeelingDetails = () => {
    setChangesMade(false)
    drawerToggle()
  }

  const onDeleteFeelingDetails = () => {
    dispatch({ type: "deleteFeeling", feelingId: selectedFeelingData.id })
    drawerToggle()
  }

  const feelingDetailsEmpty = isEmpty(feelingTargetState.feelingTargetIds) && intensityState.intensity === 0
  const getFabState = () => {
    if (feelingDetailsEmpty) {
      return {
        text: t("youth-view.feeling-selection.cancel"),
        onClick: drawerToggle,
        color: theme.palette.warning.main,
      }
    } else if (!feelingDetailsEmpty && changesMade) {
      return {
        text: t("youth-view.feeling-selection.save"),
        onClick: onSaveFeelingDetails,
        color: theme.palette.secondary.dark,
      }
    } else {
      return {
        text: t("youth-view.feeling-selection.delete-selection"),
        onClick: onDeleteFeelingDetails,
        color: theme.palette.warning.main,
      }
    }
  }

  const fabState = getFabState()
  const viewIsScrollable = feelingTargetOptions.length > 2
  return (
    <>
      <WideFab color={fabState.color} onClick={fabState.onClick}>
        {fabState.text}
      </WideFab>
      <FeelingDetailsTopBar
        feelingName={selectedFeelingData.name}
        iconFilename={selectedFeelingData.imageLocation}
        viewIsScrollable={viewIsScrollable}
      />
      <FeelingIntensitySection intensityState={intensityState} setChangesMade={setChangesMade} />
      <FeelingTargetSection feelingTargetState={feelingTargetState} setChangesMade={setChangesMade} />
    </>
  )
}

export default FeelingDetailsView
