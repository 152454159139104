import { InputAdornment, TextField, useTheme } from "@mui/material"
import { FormikProps } from "formik"
import { useTranslation } from "react-i18next"

type GoodThingsTextFieldProps = {
  formik: FormikProps<{ firstThing: string; secondThing: string; thirdThing: string }>
  id: string
}

const GoodThingsTextField = ({ formik, id }: GoodThingsTextFieldProps) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <TextField
      id={id}
      sx={{
        width: theme.diaryView?.contentWidth,
        borderRadius: 2,
        "& .MuiOutlinedInput-root fieldset, & .Mui-focused": {
          border: `1px solid ${theme.palette.secondary.dark}`,
          borderRadius: 2,
        },
        boxShadow:
          "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        backgroundColor: "primary.main",
      }}
      placeholder={t("youth-view.good-things.placeholder")}
      {...formik.getFieldProps(id)}
      onBlur={formik.submitForm}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <img
              src={`${process.env.PUBLIC_URL}/feelingIcons/Innostunut.svg`}
              width={32}
              alt={"Feeling selection"}
              aria-hidden="true"
            />
          </InputAdornment>
        ),
      }}
    />
  )
}

export default GoodThingsTextField
