import ListingHeaderProps from "components/EntityListing/ListingHeaderProps"
import StyledListingHeader from "components/StyledListingHeader"
import { useTranslation } from "react-i18next"

const FamilyListingHeader = ({ userName }: ListingHeaderProps) => {
  const { t } = useTranslation()

  return (
    <StyledListingHeader variant="h1">
      <strong>{t("care-org-mobile-view.family-listing.title", { userName: userName || "" })}</strong>
      <br />
      {t("care-org-mobile-view.family-listing.subtitle")}
    </StyledListingHeader>
  )
}

export default FamilyListingHeader
