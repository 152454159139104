import { FamilyFeelingAggregateModel, FeelingService } from "services/openapi"

export const fetchTopFeelingsForFamily = async (
  familyId: string,
  setLoading: (isLoading: boolean) => void,
  onSuccess: (topFeelings: FamilyFeelingAggregateModel) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoading(true)
    const topFeelingsData = await FeelingService.feelingGetAggregatedForFamily(familyId)
    onSuccess(topFeelingsData)
  } catch (error) {
    onError(error, "client-listing.snackbar.fetch-top-feelings-feelingtargets-error")
  }
  setLoading(false)
}
