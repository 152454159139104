import { Box, Divider, Rating, Typography } from "@mui/material"
import CircleIcon from "@mui/icons-material/Circle"
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined"
import { FeelingModel, FeelingTargetModel, SingleFeelingModel } from "services/openapi"
import { useContext, useState } from "react"
import { userContext } from "contexts/userContext"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import { useTranslation } from "react-i18next"
import useMountEffect from "utils/hooks/useMountEffect"
import { getFeelingOptionsForClientGroup, getFeelingTargetsForClientGroup } from "utils/apiRequests/fetchFeelings"
import ClientGroupId from "utils/clientGroupId"

type FeelingDetailProps = {
  userFeelings: SingleFeelingModel[]
}

const FeelingDetails = ({ userFeelings }: FeelingDetailProps) => {
  const { ErrorSnackbar, handleError } = useApiRequestError()
  const { t } = useTranslation()
  const { user } = useContext(userContext)
  let canSeeRating = user?.clientGroupId === ClientGroupId.ADULTS
  const [feelingOptions, setFeelingOptions] = useState<FeelingModel[]>([])
  const [targetOptions, setTargetOptions] = useState<FeelingTargetModel[]>([])
  const alignment = canSeeRating ? 0 : 2

  useMountEffect(async function fetchData() {
    await getFeelingOptionsForClientGroup(undefined, () => {}, setFeelingOptions, handleError)
    await getFeelingTargetsForClientGroup(undefined, () => {}, setTargetOptions, handleError)
  })

  const feelingList = userFeelings?.map((feeling) => {
    let addedFeelingDetails
    let addedTargetDetails

    if (feelingOptions !== undefined && targetOptions !== undefined) {
      addedFeelingDetails = feelingOptions.find((feelingModel) => feelingModel.id === feeling.feelingId)
      addedTargetDetails = targetOptions.filter((target) => feeling.feelingTargetIds.includes(target.id))
    }

    return (
      <Box key={feeling.feelingId.toString()} sx={{ pt: 5 }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <img
            src={`${process.env.PUBLIC_URL}/feelingIcons/${addedFeelingDetails?.imageLocation}`}
            width={68}
            alt={`${addedFeelingDetails?.imageLocation}`}
            aria-hidden="true"
          />
          <Box>
            <Typography sx={{ fontWeight: "bold", ml: 2, mt: alignment }}>
              {t(`youth-view.feeling-selection.feeling-options.${addedFeelingDetails?.name}`)}
            </Typography>
            {canSeeRating ? (
              <Rating
                icon={<CircleIcon />}
                emptyIcon={<CircleOutlinedIcon />}
                readOnly
                value={feeling.strength}
                sx={{
                  color: "secondary.dark",
                  pt: 1,
                  ml: 2,
                }}
              />
            ) : (
              <></>
            )}
          </Box>
        </Box>

        {addedTargetDetails?.map((target) => (
          <Typography key={target.id.toString()} sx={{ mt: 1 }}>
            {t(`youth-view.feeling-selection.feeling-target-options.${target.name}`)}
          </Typography>
        ))}
        <Divider sx={{ my: 2, p: 0.5 }} />
      </Box>
    )
  })

  return (
    <Box>
      <ErrorSnackbar />
      {feelingList}
    </Box>
  )
}

export default FeelingDetails
