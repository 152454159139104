import MobileViewBase from "components/MobileViewBase"
import DailySummaryTabs from "./DailySummaryTabs"
import BottomNavigationBar from "components/BottomNavigationBar"
import MobileViewDescription from "components/MobileViewBase/MobileViewDescription"
import { toDateFormat, toFinnishFormat } from "utils/functions/toDateFormat"
import { useTranslation } from "react-i18next"

const ElderlyDailySummary = () => {
  const { t } = useTranslation()

  return (
    <>
      <MobileViewBase
        top={
          <MobileViewDescription
            header={t("elderly-view.daily-summary.title")}
            text={toFinnishFormat(toDateFormat(new Date()))}
          />
        }
        middle={<DailySummaryTabs />}
        bottom={<BottomNavigationBar value={4} back={"/client/feelings"} to={"/client/ending"} navLength={5} />}
      />
    </>
  )
}

export default ElderlyDailySummary
