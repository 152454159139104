import { ClientUserService, DiaryService, FeelingService } from "services/openapi"

export const getDiaryEntry = async (userId: string, date: string, onError: (error: any, message: string) => void) => {
  try {
    const diaryResponse = await DiaryService.diaryGetDiaryEntry(userId, date)
    return diaryResponse
  } catch (error) {
    onError(error, "Diary entry fetch error")
  }
}

export const getFeelingsForDate = async (
  userId: string,
  date: string,
  onError: (error: any, message: string) => void
) => {
  try {
    const feelingResponse = await FeelingService.feelingGetForUserSingleDay(userId, date)
    feelingResponse.feelings = feelingResponse.feelings.sort((a, b) => b.strength - a.strength)
    return feelingResponse
  } catch (error) {
    onError(error, "Could not fetch feelings for the date")
  }
}

export const getClientDetails = async (userId: string, onError: (error: any, message: string) => void) => {
  try {
    const detailsResponse = await ClientUserService.clientUserGetDetails(userId)
    return detailsResponse
  } catch (error) {
    onError(error, "Could not fetch client name")
  }
}
