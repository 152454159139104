import { Box, useTheme } from "@mui/material"
import { Chart, registerables } from "chart.js"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next";
import { FeelingModel } from "services/openapi";
import { getFeelingOptionsForClientGroup } from "utils/apiRequests/fetchFeelings";
import { matchFeelingIdWithColor } from "utils/functions/matchFeelingIdWithColor"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling";
import useMountEffect from "utils/hooks/useMountEffect";
import { Timespan } from "components/TimeSpanSelectionButtons"
import { daysBetween } from "utils/functions/toDateFormat";
import { normalize } from "utils/functions/normalizeValueToRange"

type Nullable<T> = T | null;

type FeelingLineChartProps = {
  chartData: any[]
  timespan?: Timespan
  topFeelings?: { [key: number]: number }
}

type Feeling = {
  feelingId: number;
  strength: number;
  feelingTargetIds: number[];
};

type DataEntry = {
  feelings: Feeling[];
  date: string;
};

type DataSet = {
  label: string;
  data: Nullable<number>[];
  borderColor: string;
  backgroundColor?: string;
  fill: boolean;
  borderWidth?: number;
  pointRadius?: number;
  pointHoverRadius?: number;
}

type FormattedData = {
  labels: string[];  // X-axis labels (dates)
  datasets: DataSet[];
};

const customBackgroundPlugin = (labels: string[]) => {
  return {
    id: 'customBackground',
    beforeDraw: (chart: any) => {
      const ctx = chart.ctx;
      const chartArea = chart.chartArea;
      const xAxis = chart.scales.x;

      // Define the specific section you want to color
      const section = {
        startLabel: labels[0], // Start label for the fill section
        endLabel: labels[1],   // End label for the fill section
        color: 'rgba(255, 99, 132, 0.1)' // Background color for the section
      };

      // Get the pixel position of the start and end labels
      const start = xAxis?.getPixelForValue(section.startLabel);
      const end = xAxis?.getPixelForValue(section.endLabel);

      // Fill the background if the start and end points are valid
      if (start && end) {
        ctx.save();
        ctx.fillStyle = section.color;
        ctx.fillRect(start, chartArea.top, end - start, chartArea.bottom - chartArea.top);
        ctx.restore();
      }
    }
  }
};

const FeelingLineChart = ({ chartData, timespan, topFeelings }: FeelingLineChartProps) => {

  const [feelingOptions, setFeelingOptions] = useState<FeelingModel[]>([])
  const [isLoading, setLoading] = useState<boolean>(true)
  const { handleError } = useApiRequestError()

  useMountEffect(async function fetchData() {
    await getFeelingOptionsForClientGroup(undefined, setLoading, setFeelingOptions, handleError)
  })

  const theme = useTheme()
  const { t } = useTranslation()

  const [chartWidth, setChartWidth] = useState("100%");
  const chartResize = () => {
    setChartWidth("100%");
  }

  const weekAveragedKeys: string[] = ["28", "24", "21", "17", "14", "10", "7", "3", "0"]
  const pastWeekDays: string[] = ["7", "6", "5", "4", "3", "2", "1", "0"]

  function getPast12Months(): string[] {
    const past12Months: string[] = [];
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0 for January, so add 1 for correct month number
    const currentYear: number = currentDate.getFullYear();

    // Loop through the past 12 months starting from the oldest
    for (let i = 11; i >= 0; i--) {
      // Calculate the month by subtracting 'i' months and wrapping around with modulo 12
      const month = (currentMonth - i - 1 + 12) % 12 + 1;
      past12Months.push(month.toString() + "-" + (month > currentMonth ? currentYear - 1 : currentYear));
    }

    return past12Months;
  }

  function getTimeKey(date: Date): string {
    switch (timespan?.name) {
      case "year":
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        return month.toString() + "-" + year.toString()
      case "month":
        const dayLimit: number = parseInt(weekAveragedKeys[0])
        const daysAgo: number = daysBetween(new Date(), date)
        if (daysAgo > dayLimit) {
          return dayLimit.toString()
        }
        const weekAvgKeyIndex = weekAveragedKeys.length - Math.ceil(normalize(daysAgo, 0, dayLimit, 0, weekAveragedKeys.length - 1)) - 1
        return weekAveragedKeys[weekAvgKeyIndex]
      default:
        return "0"
    }
  }

  function getTimeKeysList(): string[] {
    switch (timespan?.name) {
      case "year":
        return getPast12Months()
      case "month":
        return weekAveragedKeys
      default:
        return []
    }
  }

  function getTimeLabels(): string[] {
    switch (timespan?.name) {
      case "year":
        const months = getPast12Months()
        return months.map(date => {
          const monthAndYear = date.split("-")
          const year: number = parseInt(monthAndYear[1])
          const month: number = parseInt(monthAndYear[0]) - 1
          const parsedDate = new Date(year, month);
          return parsedDate.toLocaleDateString("fi-FI", { month: 'numeric', year: 'numeric' });
        })
      case "month":
        return weekAveragedKeys.map(n => {
          const currentDate = new Date();
          currentDate.setDate(currentDate.getDate() - parseInt(n));
          return currentDate.toLocaleDateString("fi-FI", { day: 'numeric', month: 'numeric' }) + "-";
        })
      case "week":
        return pastWeekDays.map(n => {
          const currentDate = new Date();
          currentDate.setDate(currentDate.getDate() - parseInt(n));
          const weekday: string = new Date(currentDate).toLocaleDateString("en-EN", { weekday: 'short' })
          const weekdayText: string = t(`weekdays.short.${weekday.toLowerCase()}`)
          const date: string = weekdayText + " " + new Date(currentDate).toLocaleDateString("fi-FI", { day: 'numeric', month: 'numeric' }) // Format date as 'weekday DD.MM'
          return ["sat", "sun"].includes(weekday.toLowerCase()) ? "*" + date : date
        })
      default:
        return []
    }
  }

  const labels: string[] = getTimeLabels();

  // Function to format the data for Chart.js
  const formatDataForChart = (inputData: DataEntry[]): FormattedData => {
    let processedEntriesCount = 0

    const datasetsMap: { [key: number]: DataSet } = {};

    const feelingIdNameMap: { [key: number]: string } = {};
    feelingOptions.forEach(option => {
      feelingIdNameMap[option.id] = option.name
    })

    const feelingsGroupedByTimeMap: { [key: number]: { [key: string]: { sum: number, count: number } } } = {}

    const modifiedInputData: DataEntry[] = timespan?.name === "week" ? [] : inputData
    if (timespan?.name === "week") {
      let i = 0
      for (let n in pastWeekDays) {
        const entry: DataEntry = inputData[parseInt(n) - i]
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - parseInt(pastWeekDays[n]));
        const entryDate = new Date(entry?.date)
        const emptyDayEntry: DataEntry = {
          feelings: [],
          date: currentDate.toISOString()
        }
        if (currentDate.toDateString() === entryDate.toDateString()) {
          modifiedInputData.push(entry)
        } else {
          modifiedInputData.push(emptyDayEntry)
          i++
        }
      }
    }

    // Iterate over the input data to process each entry
    modifiedInputData.forEach(entry => {
      processedEntriesCount++
      const timeKey: string = getTimeKey(new Date(entry.date))

      entry.feelings.forEach(feeling => {
        const feelingId = feeling.feelingId
        const color: string = matchFeelingIdWithColor(feelingId, theme) ?? ""
        if (topFeelings?.[feelingId]) {
          if (!datasetsMap[feelingId]) {
            datasetsMap[feelingId] = {
              label: t(`youth-view.feeling-selection.feeling-options.${feelingIdNameMap[feelingId]}`),
              data: Array(processedEntriesCount - 1).fill(null), // Fill with null for previous dates if no data
              borderColor: color,
              backgroundColor: color,
              fill: false,
              borderWidth: 2, // Adjust line thickness
              pointRadius: 3, // Adjust dot size
              pointHoverRadius: 12, // Adjust dot size on hover
            };
          }
          if (timespan?.name === "year" || timespan?.name === "month") {
            // Initialize feelingsGroupedByTimeMap[feelingId] if it's undefined
            feelingsGroupedByTimeMap[feelingId] = feelingsGroupedByTimeMap[feelingId] || {};
            // Initialize feelingsGroupedByTimeMap[feelingId][month] if it's undefined
            feelingsGroupedByTimeMap[feelingId][timeKey] = feelingsGroupedByTimeMap[feelingId][timeKey] || { sum: 0, count: 0 };

            feelingsGroupedByTimeMap[feelingId][timeKey].sum += feeling.strength
            feelingsGroupedByTimeMap[feelingId][timeKey].count += 1
          }
          datasetsMap[feelingId].data.push(feeling.strength);
        }
      });

      // For feelings not present in this entry, fill their data with null
      Object.keys(datasetsMap).forEach(feelingId => {
        const dataset = datasetsMap[parseInt(feelingId)];
        if (dataset.data.length < processedEntriesCount) {
          dataset.data.push(null); // Push null if no data for this feeling on the current date
        }
      });
    });

    if (timespan?.name === "year" || timespan?.name === "month") {
      const timeKeyList = getTimeKeysList()
      Object.keys(datasetsMap).forEach(feelingId => {
        const averagedFeelings: Nullable<number>[] = []
        const dataset = datasetsMap[parseInt(feelingId)]
        timeKeyList.forEach(time => {
          const data = feelingsGroupedByTimeMap[parseInt(feelingId)][time] ?? null
          const averagedData = data === null ? null : Math.round(data.sum / data.count)
          averagedFeelings.push(averagedData)
        })
        dataset.data = averagedFeelings
      });

    }

    // Convert the map to an array of datasets
    const datasets = Object.values(datasetsMap);

    return {
      labels,
      datasets,
    };
  };

  // Call the function with the sample data
  const formattedData = formatDataForChart(chartData);
  const weekendLabels = labels.filter(item => item.startsWith('*'))
  if (weekendLabels[0]?.startsWith("*" + t(`weekdays.short.sun`))) weekendLabels.shift()

  useEffect(() => {
    chartResize(); //Called here to set correct chart width during first page load.
    window.addEventListener('resize', chartResize);
    return () => {
      window.removeEventListener('resize', chartResize);
    };
  }, []);

  const chartRef = useRef<Chart | null>(null)
  Chart.register(...registerables)

  const canvasCallback = (canvas: HTMLCanvasElement | null) => {
    if (!canvas) return
    const ctx = canvas.getContext("2d")
    if (ctx && !chartRef.current) {
      chartRef.current = new Chart(ctx, {
        type: "line",
        data: formattedData,
        options: {
          responsive: true,
          maintainAspectRatio: true, // Allow the height to adjust freely
          spanGaps: true,
          scales: {
            y: {
              suggestedMin: 1, // Set the minimum suggested value
              suggestedMax: 5, // Set the maximum suggested value
              ticks: {
                stepSize: 1, // Ensure the ticks increment by 1
                callback: function (value: string | number) {
                  if (typeof value === 'number' && value >= 1 && value <= 5 && Number.isInteger(value)) {
                    return value.toString(); // Return the number as a string
                  }
                  return null; // Hide non-integer or out-of-range values
                }
              }
            }
          },
          plugins: {
            legend: {
              display: false, // This will hide the dataset labels (legend)
            }
          }
        },
        plugins: timespan?.name === "week" && weekendLabels.length >= 2 ? [customBackgroundPlugin(weekendLabels)] : []
      })
    }
  }

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = formattedData
      chartRef.current.update()
    }
  }, [formattedData])

  return (
    <Box sx={{ mx: 0, my: 0, maxWidth: chartWidth }}>
      {isLoading ? <></> : <canvas ref={canvasCallback}></canvas>}
    </Box>
  )
}

export default FeelingLineChart
