/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from "../core/request"

export class CareOrganizationUserFamilyConnectionService {
  /**
   * @param userId
   * @param familyUnitId
   * @returns any Success
   * @throws ApiError
   */
  public static async careOrganizationUserFamilyConnectionConnectUserToFamily(
    userId?: string,
    familyUnitId?: string
  ): Promise<any> {
    const result = await __request({
      method: "POST",
      path: `/CareOrganizationUserFamilyConnection/connectUserToFamily`,
      query: {
        userId: userId,
        familyUnitId: familyUnitId,
      },
    })
    return result.body
  }

  /**
   * @param userId
   * @param familyUnitId
   * @returns any Success
   * @throws ApiError
   */
  public static async careOrganizationUserFamilyConnectionRemoveUserFromFamily(
    userId?: string,
    familyUnitId?: string
  ): Promise<any> {
    const result = await __request({
      method: "DELETE",
      path: `/CareOrganizationUserFamilyConnection/removeUserFromFamily`,
      query: {
        userId: userId,
        familyUnitId: familyUnitId,
      },
    })
    return result.body
  }
}
