import { ChevronRight } from "@mui/icons-material"
import { Link, styled, Typography } from "@mui/material"

type DrawerLinkProps = {
  url: string
  icon: JSX.Element
  text: string
}

const StyledLink = styled(Link)(({ theme }) => ({
  display: "flex",
  position: "relative",
  minHeight: theme.spacing(7),
  boxShadow: "5px 5px 10px 0px rgba(0,0,0,0.22)",
  borderRadius: "50px",
  backgroundColor: "white",
  margin: theme.spacing(1, 0),
  width: "90%",
  textDecoration: "none",
  color: "inherit",
  alignItems: "center",
  justifyContent: "space-between",
  fontWeight: "bold",
  fontFamily: "SofiaProSemiBold",
  whiteSpace: "pre-wrap"
}))

const RoundEndingViewLinkButton = ({ url, icon, text }: DrawerLinkProps) => {
  return (
    <StyledLink rel="noreferrer" href={url} target="_blank">
      <Typography sx={{ color: "#4557B7", fontFamily: "SofiaProSemiBold", marginLeft: 3 }}>{text}</Typography>
      <ChevronRight sx={{ my: 0, mx: 1, fontSize: "32px" }} />
    </StyledLink>
  )
}

export default RoundEndingViewLinkButton
