import MobileItemCardsView from "components/MobileItemsCardsView"
import { ClientGroupDataContext } from "contexts/clientGroupDataContext"
import { userContext } from "contexts/userContext"
import { useContext, useEffect, useReducer, useState } from "react"
import { ActivityService, UserActivitySaveModel } from "services/openapi"
import { saveUserActivities } from "utils/apiRequests/userActivities"
import { toDateFormat } from "utils/functions/toDateFormat"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import useMountEffect from "utils/hooks/useMountEffect"
import ActivityDetails from "./ActivityDetails"
import { activitiesSelectionStateReducer, initialActivitySelectionState } from "./selectedActivitiesReducer"
import activityIdEquals from "./utils/activityIdEquals"

const ActivityCards = () => {
  const { ErrorSnackbar, handleError } = useApiRequestError()
  const [selectedActivityData, setSelectedActivityData] = useState({ id: -1, name: "", imageLocation: "Siivosin.svg" })
  const [changesMade, setChangesMade] = useState(false)
  const [state, dispatch] = useReducer(activitiesSelectionStateReducer, initialActivitySelectionState)
  const { user } = useContext(userContext)
  const { activityOptions } = useContext(ClientGroupDataContext)

  useMountEffect(async function fetchSavedActivities() {
    const activityData = await ActivityService.activityGetForUserSingleDay(user?.id ?? "", toDateFormat(new Date()))
    dispatch({ type: "setActivities", stateData: activityData })
  })

  useEffect(
    function saveActivityDataOnStateChange() {
      const postActivityData = async () => {
        const body: UserActivitySaveModel = {
          userId: user?.id ?? "",
          date: toDateFormat(new Date()),
          activities: state.activities,
          activityHelperIds: state.helpers,
          wouldHaveNeededHelp: false,
        }

        await saveUserActivities(body, () => { }, handleError)
      }
      if (changesMade) {
        postActivityData()
      }
    },
    [handleError, state, user?.id, changesMade]
  )

  const activityAlreadyAdded = (activityId: number) => state.activities.some(activityIdEquals(activityId))

  return (
    <>
      <MobileItemCardsView
        items={activityOptions}
        cardNamePrefix="elderly-view.activity-selection.activity-options"
        cardIconFolder="activityIcons"
        showDialogOnClick={true}
        callbacks={{
          changesMadeCallback: setChangesMade,
          onItemSelected: setSelectedActivityData,
          itemAlreadyAdded: activityAlreadyAdded,
          itemDetailsViewCallback: (drawerToggle: () => void) => (
            <ActivityDetails
              activitiesState={[state, dispatch]}
              selectedActivityData={selectedActivityData}
              drawerToggle={drawerToggle}
            />
          ),
        }}
        bottomNavigation={undefined}
      />
      <ErrorSnackbar />
    </>
  )
}

export default ActivityCards
