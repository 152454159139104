import { Box, Typography } from "@mui/material"
import SpesCard from "components/SpesCard"
import { ClientGroupDataContext } from "contexts/clientGroupDataContext"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import FeelingTargetCardsBackground from "./styled/FeelingTargetCardsBackground"

export type FeelingTargetState = {
  feelingTargetIds: number[]
  onClick: (feelingTargetId: number) => void
}

type FeelingTargetSectionProps = {
  feelingTargetState: FeelingTargetState
  setChangesMade: (value: boolean) => void
}

const FeelingTargetSection = ({ feelingTargetState, setChangesMade }: FeelingTargetSectionProps) => {
  const { t } = useTranslation()
  const { feelingTargetOptions, ErrorSnackbar } = useContext(ClientGroupDataContext)

  const feelingTargetCardsList = feelingTargetOptions.map((feelingTargetOption) => {
    return (
      <SpesCard
        key={feelingTargetOption.id}
        name={t(`youth-view.feeling-selection.feeling-target-options.${feelingTargetOption.name}`)}
        onClick={() => {
          feelingTargetState.onClick(feelingTargetOption.id)
          setChangesMade(true)
        }}
        height={190}
        selected={feelingTargetState.feelingTargetIds.includes(feelingTargetOption.id)}
        iconUrl={`${process.env.PUBLIC_URL}/feelingIcons/${feelingTargetOption.imageLocation}`}
      />
    )
  })

  return (
    <Box sx={{ mx: 4, marginBottom: "80px" }}>
      <Typography variant="h2" sx={{ fontFamily: "SofiaProMedium", fontSize: "24px", mb: 2 }}>
        {t("youth-view.feeling-selection.feeling-target-query")}
      </Typography>
      <FeelingTargetCardsBackground>{feelingTargetCardsList}</FeelingTargetCardsBackground>
      <ErrorSnackbar />
    </Box>
  )
}

export default FeelingTargetSection
