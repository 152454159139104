import ReactMarkdown from "react-markdown"
import { Box } from "@mui/material"

const AccessibilityContent = () => {
  const src = `
Hämeen Spes Oy pyrkii takaamaan [moodpilot.fi](www.moodpilot.fi)
saavutettavuuden Euroopan parlamentin ja neuvoston direktiivi (EU) 2016/2102,
annettu 26. päivänä lokakuuta 2016, julkisen sektorin elinten verkkosivustojen
ja mobiilisovellusten saavutettavuudesta (EUVL L 327, 2.12.2016, s. 1.)
täytäntöönpanemiseksi annetun kansallisen lainsäädännön mukaisesti. Tämä
saavutettavuusseloste koskee [moodpilot.fi](www.moodpilot.fi) verkkopalvelua.  

## Vaatimusten&shy;mukaisuus&shy;tilanne  

### Osittain-saavutettava
#### WCAG 2.1  1.4.3 Kontrasti  
- Osa palvelun käyttämistä kuvakkeista ei täytä vaatimuksia. Kuvakkeet pyritään
korjaamaan vuoden 2022 aikana.
#### WCAG 2.1 1.4.1 Värien käyttö
- Fiilishistoria -näkymässä kuvataan piirakkakaaviossa väreillä informaatiota.
Tämä pyritään korjaamaan vuoden 2022 aikana. 
#### WCAG 2.1  3.1.1 Sivun kieli
- Osa palvelun osista on englanninkielisiä. Tämä johtuu osaksi siitä, että ne
ovat valmiita komponentteja eikä niille ole vielä käännöksiä. Osien kieli
pyritään vaihtamaan vuoden 2022 aikana. 
#### WCAG 4.1.3 Tilasta kertovat viestit
- Fiilishistoria -näkymässä olevat päivä, viikko, kuukausi, muu -painikkeet eivät
anna käyttäjälle tarpeellista tietoa, mikä näistä on valittuna. 
- Tallenna -painikkeen painaminen ei anna palautetta fiiliksiä tallenettaessa.
- Palvelu ei anna palautetta valitusta fiiliksestä ruudunlukijaa käyttävälle
käyttäjälle. Näkevä käyttäjä saa palautteen fiiliksen ympärille tulevalla kehyksellä

### EI saavutettava sisältö  
- Fiilishistorian piirakkakaavio ei ole saavutettavissa näppäimistörajapintaa
tai kosketusnäytön ruudunlukijaa käyttämällä. Tähän pyritään löytämään ratkaisu
vuoden 2022 aikana.

### Verkkopalvelun osissa on haasteita saavutettavuudessa. 
- Fiiliksen syiden ja tason valinnassa käyttäjä joutuu menemään näkymässä
takaisinpäin, kun hän tekee valintansa, jotta hän voi jatkaa palvelussa
eteenpäin. Rakenteelliset muutokset pyritään tekemään vuoden 2022 aikana. 

## Tämän saavutettavuusselosteen laatiminen  

Tämä seloste on laadittu 20.1.2022. Selosteen on laatinut Pinja Group
saavutettavuusasiantuntijan saavutettavuuskatselmoinin perusteella.  

### Palaute ja yhteystiedot  
Huomasitko saavutettavuuspuutteen verkkopalvelussamme? Ilmoita siitä meille ja
teemme parhaamme,jotta voimme tarjota tiedot ja sisällöt saavutettavassa muodossa
niin pian kuin mahdollista! Voit ottaa yhteyttä sähköpostilla.
Sähköpostiosoite palautteen antamiseksi: info@spespalvelut.fi

### Täytäntöönpanomenettely 
Jos huomaat sivustolla saavutettavuusongelmia, anna ensin palautetta sivuston
ylläpitäjälle. Vastauksessa voi mennä 14 päivää. Jos et ole tyytyväinen
saamaasi vastaukseen tai et saa vastausta lainkaan kahden viikon aikana, voit
antaa palautteen Etelä-Suomen aluehallintovirastoon. Etelä-Suomen
aluehallintoviraston [saavutettavuusvaatimukset.fi-sivustolla](wwww.saavutettavuusvaatimukset.fi)
kerrotaan, miten valituksen voi tehdä ja miten asia käsitellään.  

### Valvontaviranomaisen yhteystiedot  
Etelä-Suomen aluehallintovirasto  
Saavutettavuuden valvonnan yksikkö  
[www.saavutettavuusvaatimukset.fi](www.saavutettavuusvaatimukset.fi-sivustolla)
saavutettavuus@avi.fi  
Puhelinnumero vaihde 0295 016 000
    `

  return (
    <Box
      sx={{
        mx: 3,
        overflow: "hidden",
        wordBreak: "break-word",
      }}
    >
      <ReactMarkdown children={src} />
    </Box>
  )
}

export default AccessibilityContent
