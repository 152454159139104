import { List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { ReactElement } from "react"
import { Link as RouterLink } from "react-router-dom"
import EntityListItem from "./styled/EntityListItem"

type EntityListingProps = {
  entities: { id: string; name: string }[]
  url: string
  icon?: ReactElement
}

const EntityListing = ({ entities, url, icon }: EntityListingProps) => {
  const mapEntityToListItem = (entity: { id: string; name: string }) => {
    return (
      <EntityListItem key={entity.id}>
        <ListItemButton component={RouterLink} to={`${url}/${entity.id}`}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={entity.name} />
        </ListItemButton>
      </EntityListItem>
    )
  }
  return <List sx={{ mb: 13 }}>{entities?.map(mapEntityToListItem)}</List>
}

export default EntityListing
