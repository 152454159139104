import { compose, filter, get, includes, map, negate } from "lodash/fp"
import {
  CareOrganizationUserFamilyConnectionService,
  CareOrganizationUserListModel,
  CareOrganizationUserService,
  ClientUserCreateModel,
  ClientUserService,
  FamilyUnitDetailsModel,
  FamilyUnitService,
  UserCreateResultModel,
  UserListModel,
} from "services/openapi"
import { entityIdEqualsId } from "utils/functions/entityIdEqualsId"
import { filterEntityWithId } from "utils/functions/filterEntityWithId"

export const fetchFamilyDetails = async (
  familyId: string,
  setLoading: (isLoading: boolean) => void,
  onSuccess: (family: FamilyUnitDetailsModel) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoading(true)
    const famResponse = await FamilyUnitService.familyUnitGetFamilyUnitDetails(familyId)
    onSuccess(famResponse)
  } catch (error) {
    onError(error, "family-details.snackbar.fetch-details-error")
  }
  setLoading(false)
}

export const fetchFamilyUsers = async (
  organizationId: string,
  familyId: string,
  setLoading: (isLoading: boolean) => void,
  onSuccess: (
    connectedUsers: CareOrganizationUserListModel[],
    unconnectedUsers: CareOrganizationUserListModel[]
  ) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoading(true)
    const usersConnectedToFamilyResponse = await CareOrganizationUserService.careOrganizationUserGetOrganizationUsers(
      organizationId,
      familyId
    )

    const careOrganizationUsersResponse = await CareOrganizationUserService.careOrganizationUserGetOrganizationUsers(
      organizationId,
      undefined,
      undefined,
      undefined,
      true
    )
    const filterUsersAlreadyConnectedToFamily = (careOrgUser: CareOrganizationUserListModel) => {
      return compose(negate(includes(careOrgUser.id)), map(get("id")))(usersConnectedToFamilyResponse.items)
    }

    const usersUnconnectedToFamily = filter(filterUsersAlreadyConnectedToFamily)(careOrganizationUsersResponse.items)

    onSuccess(usersConnectedToFamilyResponse.items, usersUnconnectedToFamily)
  } catch (error) {
    onError(error, "family-details.snackbar.fetch-users-error")
  }
  setLoading(false)
}

export const updateFamily = async (
  family: FamilyUnitDetailsModel,
  setLoading: (isLoading: boolean) => void,
  onSuccess: () => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoading(true)
    const updatedFamily = { ...family, familyUnitName: family.name, id: family.id }
    await FamilyUnitService.familyUnitUpdateFamilyUnit(updatedFamily)
    onSuccess()
    setLoading(false)
    return true
  } catch (error) {
    onError(error, "family-details.snackbar.update-family-error")

    setLoading(false)
    return false
  }
}

export const connectUserToFamily = async (
  userId: string,
  familyId: string,
  users: UserListModel[],
  unconnectedUsers: UserListModel[],
  onSuccess: (connectedUsers: UserListModel[], unconnectedUsers: UserListModel[]) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    const connectedUser = unconnectedUsers.find(entityIdEqualsId(userId))
    if (!connectedUser) {
      throw new Error("User not found")
    }

    await CareOrganizationUserFamilyConnectionService.careOrganizationUserFamilyConnectionConnectUserToFamily(
      userId,
      familyId
    )
    onSuccess([connectedUser, ...users], filterEntityWithId(unconnectedUsers, userId))
    return true
  } catch (error) {
    onError(error, "family-details.snackbar.connect-user-error")
    return false
  }
}

export const disconnectUserFromFamily = async (
  userId: string,
  familyId: string,
  connectedUsers: UserListModel[],
  onSuccess: (disconnectedUser: UserListModel) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    const disconnectedUser = connectedUsers.find(entityIdEqualsId(userId))
    if (!disconnectedUser) {
      throw new Error("User not found")
    }

    await CareOrganizationUserFamilyConnectionService.careOrganizationUserFamilyConnectionRemoveUserFromFamily(
      userId,
      familyId
    )
    onSuccess(disconnectedUser)
    return true
  } catch (error) {
    onError(error, "family-details.snackbar.disconnect-user-error")
    return false
  }
}

export const createClient = async (
  client: ClientUserCreateModel,
  familyId: string,
  onSuccess: (updatedClient: UserListModel[], createResult: UserCreateResultModel) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    const createResult = await ClientUserService.clientUserCreateClientUser(client)
    const clientResponse = await ClientUserService.clientUserGetFamilyUsers(familyId)
    onSuccess(clientResponse.items, createResult)
    return true
  } catch (e) {
    onError(e, "family-details.snackbar.create-client-error")
    return false
  }
}

export const deactivateClient = async (
  clientId: string,
  clients: UserListModel[],
  onSuccess: (updatedClient: UserListModel[]) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    await ClientUserService.clientUserDeactivateUser(clientId)
    const updatedClients = filterEntityWithId(clients, clientId)
    onSuccess(updatedClients)
    return true
  } catch (error) {
    onError(error, "family-details.snackbar.deactivate-client-error")
    return false
  }
}

export const fetchAllowedClientGroups = async (
  familyId: string,
  onSuccess: (groups: number[]) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    const allowedClientGroups = await ClientUserService.clientUserGetAllowedClientGroupIdsForFamily(familyId)
    onSuccess(allowedClientGroups)
  } catch (error) {
    onError(error, "family-details.snackbar.fetch-allowed-client-groups-error")
  }
}

export const resetClientUserPassword = async (
  userId: string,
  onSuccess: (createResult: UserCreateResultModel) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    const result = await ClientUserService.clientUserResetPassword(userId)
    onSuccess(result)
    return true
  } catch (e) {
    onError(e, "family-details.snackbar.reset-password-error")
    return false
  }
}
