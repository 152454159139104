import CircleIcon from "@mui/icons-material/Circle"
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined"
import { Box, Rating, styled, Typography, TypographyProps } from "@mui/material"

type FeelingRatingProps = {
  onChange: (event: React.SyntheticEvent<Element, Event>, newValue: number | null) => void
  value: number
}

const StyledFeelingIntensity = styled(Typography)<TypographyProps>(() => ({
  fontSize: "40px",
  marginLeft: 1.5,
  marginRight: 1.5,
}))

const FeelingRating = ({ onChange, value }: FeelingRatingProps) => {
  return (
    <Box
      sx={{
        display: "inline-block",
      }}
    >
      <Rating
        size="large"
        icon={<CircleIcon fontSize="large" />}
        emptyIcon={<CircleOutlinedIcon fontSize="large" />}
        onChange={onChange}
        value={value}
        sx={{
          "& .MuiRating-icon": {
            color: "#D1559A",
            mr: 3,
            mb: 1,
          },
          "& .MuiRating-icon > svg": {
            fontSize: "45px",
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          ml: 2,
          mr: 4,
          color: "#D1559A",
        }}
      >
        <StyledFeelingIntensity>1</StyledFeelingIntensity>
        <StyledFeelingIntensity>2</StyledFeelingIntensity>
        <StyledFeelingIntensity>3</StyledFeelingIntensity>
        <StyledFeelingIntensity>4</StyledFeelingIntensity>
        <StyledFeelingIntensity>5</StyledFeelingIntensity>
      </Box>
    </Box>
  )
}

export default FeelingRating
