import { ChevronRight } from "@mui/icons-material"
import { Box, styled } from "@mui/material"
import { Link } from "react-router-dom"

type DrawerLinkProps = {
  url: string
  icon: JSX.Element
  text: string
}

const StyledLink = styled(Link)(({ theme }) => ({
  display: "flex",
  position: "relative",
  left: theme.spacing(-5),
  height: theme.spacing(7),
  boxShadow: "5px 5px 10px 0px rgba(0,0,0,0.22)",
  borderRadius: "50px",
  backgroundColor: "white",
  margin: theme.spacing(3, 0),
  width: "100%",
  textDecoration: "none",
  color: "inherit",
  alignItems: "center",
  justifyContent: "space-between",
  fontWeight: "bold",
  fontFamily: "SofiaProBold",
}))

const RoundLinkButton = ({ url, icon, text }: DrawerLinkProps) => {
  return (
    <StyledLink to={url}>
      <Box sx={{ "& svg": { mt: 1, mx: 1, fontSize: "32px" } }} aria-hidden="true">
        {icon}
      </Box>
      <span>{text}</span>
      <ChevronRight sx={{ my: 0, mx: 1, fontSize: "32px" }} aria-hidden="true" />
    </StyledLink>
  )
}

export default RoundLinkButton
