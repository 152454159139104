import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material"
import StyledButton from "components/StyledButton"
import { Form, Formik } from "formik"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { ClientUserCreateModel } from "services/openapi"
import CreateClientFields from "./CreateClientFields"
import * as Yup from "yup"

type Props = {
  familyId: string
  createClient: (client: ClientUserCreateModel) => Promise<boolean>
  allowedClientGroups: number[]
}

const CreateClient = ({ familyId, createClient, allowedClientGroups }: Props) => {
  const { t } = useTranslation()

  const [dialogOpen, setDialogOpen] = useState(false)
  const [callingApi, setCallingApi] = useState(false)

  const radioClientGroups = allowedClientGroups.map((group, i) => {
    return <FormControlLabel key={i} value={group} control={<Radio />} label={t(`create-client.radio-${group}`)} />
  })

  return (
    <>
      <StyledButton
        variant="contained"
        onClick={() => {
          setDialogOpen(true)
        }}
        endIcon={<span>+</span>}
      >
        {t("create-client.create-client-button")}
      </StyledButton>

      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false)
        }}
      >
        <DialogTitle>{t("create-client.dialog-header")}</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              familyId: familyId,
              clientGroupId: allowedClientGroups.length ? allowedClientGroups[0] : 1,
            }}
            validationSchema={Yup.object({
              firstName: Yup.string()
                .trim()
                .max(50, t("form-validation.too-long", { length: 50 }))
                .required(t("form-validation.required")),
              lastName: Yup.string()
                .trim()
                .max(50, t("form-validation.too-long", { length: 50 }))
                .required(t("form-validation.required")),
            })}
            onSubmit={(values) => {
              setCallingApi(true)
              createClient(values).then((success) => {
                if (success) {
                  setDialogOpen(false)
                }
                setCallingApi(false)
              })
            }}
            validateOnBlur
          >
            {(formik) => (
              <Form>
                <CreateClientFields formik={formik}></CreateClientFields>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <FormControl>
                    <FormLabel>{t("create-client.radio-label")}</FormLabel>
                    <RadioGroup
                      onChange={formik.handleChange}
                      name="clientGroupId"
                      defaultValue={allowedClientGroups.length ? allowedClientGroups[0] : 1}
                    >
                      {radioClientGroups}
                    </RadioGroup>
                  </FormControl>
                </Box>
                <StyledButton
                  type="submit"
                  variant="contained"
                  disabled={callingApi}
                  sx={{
                    width: "fit-content",
                    alignSelf: "end",
                    float: "right",
                    clear: "both",
                    mt: 2,
                  }}
                >
                  {t("create-client.create-confirm")}
                </StyledButton>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default CreateClient
