import { Typography } from "@mui/material"
import { Box, useTheme } from "@mui/system"
import { useTranslation } from "react-i18next"
import { successOptions } from "utils/successOptions"
import RoundButton from "./styled/RoundButton"

type ActivitySuccessSectionProps = {
  successState: {
    success: number
    onChange: (_event: React.SyntheticEvent<Element, Event>, newValue: number | null) => void
  }
  setChangesMade: (value: boolean) => void
}

const ActivitySuccessSection = ({ successState, setChangesMade }: ActivitySuccessSectionProps) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const handleClick = (event: React.SyntheticEvent<Element, Event>, newValue: number | null) => {
    successState.onChange(event, newValue)
    setChangesMade(true)
  }

  return (
    <Box sx={{ mx: 4, mb: 20, mt: 14 }}>
      <Typography variant="h2" sx={{ fontFamily: "SofiaProMedium", fontSize: "20px", mb: 2 }}>
        {t("elderly-view.activity-selection.success-query")}
      </Typography>

      {successOptions.map((item) => {
        const border = successState.success === item.value ? `solid 3px ${theme.palette.secondary.dark}` : ""

        return (
          <RoundButton
            key={item.translationKey}
            onClick={(event) => handleClick(event, item.value)}
            variant="contained"
            style={{ border: border }}
          >
            <Box sx={{ mr: -4, mb: -1 }}>
              <img src={`${process.env.PUBLIC_URL}/feelingIcons/${item.icon}`} alt="" width={38} aria-hidden="true" />
            </Box>
            <Typography sx={{ width: "100%" }}>
              {t(`elderly-view.activity-selection.success-option.${item.translationKey}`)}
            </Typography>
          </RoundButton>
        )
      })}
    </Box>
  )
}

export default ActivitySuccessSection
