import { useTheme } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import WideFab from "scenes/FeelingsSelection/styled/WideFab"
import ActivityDetailsTopBar from "./ActivityDetailsTopBar"
import ActivitySuccessSection from "./ActivitySuccessSection"
import { SelectedActivity } from "./SelectedActivity"
import { Action, ActivitySelectionState } from "./selectedActivitiesReducer"
import activityIdEquals from "./utils/activityIdEquals"

type ActivityDetailsViewProps = {
  selectedActivityData: SelectedActivity
  drawerToggle: () => void
  activitiesState: [ActivitySelectionState, React.Dispatch<Action>]
}

const ActivityDetails = ({ drawerToggle, selectedActivityData, activitiesState }: ActivityDetailsViewProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [changesMade, setChangesMade] = useState(false)
  const [state, dispatch] = activitiesState

  const onSaveActivityDetails = () => {
    setChangesMade(false)
    drawerToggle()
  }

  const getSelectedActivity = () => {
    return state.activities.find(activityIdEquals(selectedActivityData.id))
  }

  const onActivitySuccessChange = (_event: React.SyntheticEvent<Element, Event>, newValue: number | null) => {
    createNewActivityIfNotAlreadyCreated()
    dispatch({ type: "updateActivitySuccess", activityId: selectedActivityData.id, success: newValue })
  }

  const onDeleteActivityDetails = () => {
    dispatch({ type: "deleteActivity", activityId: selectedActivityData.id })
    drawerToggle()
  }

  const activityAlreadyAdded = (activityId: number) => state.activities.some(activityIdEquals(activityId))

  const createNewActivityIfNotAlreadyCreated = () => {
    if (!activityAlreadyAdded(selectedActivityData.id)) {
      dispatch({
        type: "createNewActivity",
        activityData: { activityId: selectedActivityData.id, success: 0 },
      })
    }
  }

  const successState = {
    success: getSelectedActivity()?.success ?? 0,
    onChange: onActivitySuccessChange,
  }

  const getFabState = () => {
    if (!changesMade) {
      return {
        text: t("elderly-view.activity-selection.delete-selection"),
        onClick: onDeleteActivityDetails,
        color: theme.palette.warning.main,
      }
    }

    return {
      text: t("elderly-view.activity-selection.save"),
      onClick: onSaveActivityDetails,
      color: theme.palette.secondary.dark,
    }
  }

  const fabState = getFabState()
  return (
    <>
      <WideFab color={fabState.color} onClick={fabState.onClick}>
        {fabState.text}
      </WideFab>
      <ActivityDetailsTopBar
        activityName={selectedActivityData.name}
        iconFilename={selectedActivityData.imageLocation}
      />
      <ActivitySuccessSection successState={successState} setChangesMade={setChangesMade} />
    </>
  )
}

export default ActivityDetails
