import { Dialog, DialogContent, DialogTitle, Link, TextField } from "@mui/material"
import { useTranslation } from "react-i18next"
import { UserCreateResultModel } from "services/openapi"
import "./PasswordResetResultsDialog.sass"

type Props = {
  open: boolean
  onClose: React.Dispatch<React.SetStateAction<boolean>>
  createResult: UserCreateResultModel
}

const PasswordResetResultDialog = ({ open, onClose, createResult }: Props) => {
  const { t } = useTranslation()

  const bodytext = t("password-email.reset-password-email-body", { userlogin: createResult.userLogin, firsttimepassword: createResult.firstTimePassword })
  const body = encodeURIComponent(bodytext)
  const href = `mailto:?subject=${t("password-email.subject-reset-password")}&body=${body}`

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle>{t("user-password-reset.credentials")}</DialogTitle>
      <DialogContent id="user-credentials">
        <div id="result-dialog-inputs">
          <TextField
            id="userLogin"
            disabled={true}
            value={createResult.userLogin}
            label={t("user-password-reset.userLogin")}
            variant="standard"
            color="primary"
          />
          <TextField
            id="firstTimePassword"
            disabled={true}
            value={createResult.firstTimePassword}
            label={t("user-password-reset.password")}
            variant="standard"
            color="primary"
          />
        </div>
        <Link href={href}>{t("password-email.link-text")}</Link>
      </DialogContent>
    </Dialog>
  )
}

export default PasswordResetResultDialog
