import { compose, eq, filter, get, negate } from "lodash/fp"
import { FamilyUnitCreateModel, FamilyUnitListModel, FamilyUnitService } from "services/openapi"

export const fetchFamilies = async (
  organizationId: string,
  setLoading: (isLoading: boolean) => void,
  onSuccess: (organizations: FamilyUnitListModel[]) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoading(true)
    const familiesResponse = await FamilyUnitService.familyUnitGetOrganizationFamilyUnits(organizationId)
    onSuccess(familiesResponse.items)
  } catch (error) {
    onError(error, "family-list.snackbar.fetch-error")
  }
  setLoading(false)
}

export const createFamily = async (
  newFamily: FamilyUnitCreateModel,
  organizationId: string,
  onSuccess: (families: FamilyUnitListModel[]) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    await FamilyUnitService.familyUnitCreateFamilyUnit(newFamily)
    const familiesResponse = await FamilyUnitService.familyUnitGetOrganizationFamilyUnits(organizationId)
    onSuccess(familiesResponse.items)
    return true
  } catch (error) {
    onError(error, "family-list.snackbar.create-family-error")
    return false
  }
}

export const deactivateFamily = async (
  familyId: string,
  families: FamilyUnitListModel[],
  onSuccess: (families: FamilyUnitListModel[]) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    await FamilyUnitService.familyUnitDeactivateFamilyUnit(familyId)
    const familyIdDoesNotMatchDeactivatedFamilyId = compose(negate(eq(familyId)), get("id"))
    const updatedFamilies = filter(familyIdDoesNotMatchDeactivatedFamilyId)(families)
    onSuccess(updatedFamilies)
    return true
  } catch (error) {
    onError(error, "family-list.snackbar.deactivate-error")
    return false
  }
}
