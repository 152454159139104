/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProfileModel } from "../models/ProfileModel"
import { request as __request } from "../core/request"

export class ProfileService {
  /**
   * Profile of currently logged in user. If user doesn't have a connected
   * user database, UnauthorizedAccessException is thrown which is mapped to 401.
   * @returns ProfileModel Success
   * @throws ApiError
   */
  public static async profileGetCurrentUserProfile(): Promise<ProfileModel> {
    const result = await __request({
      method: "GET",
      path: `/Profile/current`,
    })
    return result.body
  }
}
