import { IPublicClientApplication } from "@azure/msal-browser"

import {
  ManagementOrganizationDetailsModel,
  ManagementOrganizationService,
  ManagementOrganizationUpdateModel,
  ManagementOrganizationUserCreateModel,
  ManagementOrganizationUserService,
  UserCreateResultModel,
  UserListModel,
} from "services/openapi"
import { handleApiRequestError } from "utils"

export const fetchDetails = async (
  organizationId: string,
  setLoading: (isLoading: boolean) => void,
  setLoadingOrg: (isLoadingOrg: boolean) => void,
  setOrg: React.Dispatch<React.SetStateAction<ManagementOrganizationDetailsModel>>,
  setUsers: React.Dispatch<React.SetStateAction<UserListModel[]>>,
  instance: IPublicClientApplication,
  showSnackbar: (message: string, type: "error" | "warning" | "info" | "success") => void,
  t: (key: string) => string
) => {
  try {
    setLoading(true)
    setLoadingOrg(true)
    const orgResponse = await ManagementOrganizationService.managementOrganizationGetDetails(organizationId)
    setOrg(orgResponse)
    const userResponse = await ManagementOrganizationUserService.managementOrganizationUserGetOrganizationUsers(
      orgResponse.id
    )
    setUsers(userResponse.items)
  } catch (e) {
    handleApiRequestError(e, instance, showSnackbar, t, t("management-organization-details.snackbar.org-fetch-error"))
  }
  setLoading(false)
  setLoadingOrg(false)
}

export const updateManagementOrganization = async (
  updatedOrganization: ManagementOrganizationDetailsModel,
  instance: IPublicClientApplication,
  setLoading: (isLoading: boolean) => void,
  showSnackbar: (message: string, type: "error" | "warning" | "info" | "success") => void,
  t: (key: string) => string
) => {
  setLoading(true)
  const updatedOrg: ManagementOrganizationUpdateModel = {
    ...updatedOrganization,
    organizationName: updatedOrganization.name,
  }
  try {
    await ManagementOrganizationService.managementOrganizationUpdateManagementOrganization(updatedOrg)
    showSnackbar(t("management-organization-details.snackbar.org-update-success"), "success")
    setLoading(false)
    return true
  } catch (e) {
    handleApiRequestError(e, instance, showSnackbar, t, t("management-organization-details.snackbar.org-update-error"))
    setLoading(false)
    return false
  }
}

export const createManagementOrgUser = async (
  user: ManagementOrganizationUserCreateModel,
  organizationId: string,
  setUsers: React.Dispatch<React.SetStateAction<UserListModel[]>>,
  onSuccess: (createResult: UserCreateResultModel) => void,
  instance: IPublicClientApplication,
  showSnackbar: (message: string, type: "error" | "warning" | "info" | "success") => void,
  t: (key: string) => string
) => {
  try {
    const newUserResponse =
      await ManagementOrganizationUserService.managementOrganizationUserCreateManagementOrganizationAdmin(user)
    const userListResponse = await ManagementOrganizationUserService.managementOrganizationUserGetOrganizationUsers(
      organizationId
    )
    onSuccess(newUserResponse)
    setUsers(userListResponse.items)
    showSnackbar(t("management-organization-details.snackbar.create-user-success"), "success")
    return true
  } catch (e) {
    handleApiRequestError(e, instance, showSnackbar, t, t("management-organization-details.snackbar.user-create-error"))
    return false
  }
}

export const deactivateUser = async (
  userId: string,
  users: UserListModel[],
  setUsers: React.Dispatch<React.SetStateAction<UserListModel[]>>,
  showSnackbar: (message: string, type: "error" | "warning" | "info" | "success") => void,
  t: (key: string) => string,
  onError: (error: any, message: string) => void
) => {
  try {
    await ManagementOrganizationUserService.managementOrganizationUserDeactivateUser(userId)
    const updatedUsers = users.filter((x) => x.id !== userId)
    setUsers(updatedUsers)
    showSnackbar(t("management-organization-details.snackbar.user-deactivate-success"), "success")
    return true
  } catch (e) {
    onError(e, "management-organization-details.snackbar.user-deactivate-error")
    return false
  }
}

export const resetManageOrgAdminPassword = async (
  adminId: string,
  onSuccess: (createResult: UserCreateResultModel) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    const result = await ManagementOrganizationUserService.managementOrganizationUserResetPassword(adminId)
    onSuccess(result)
    return true
  } catch (e) {
    onError(e, "management-organization-details.snackbar.reset-password-error")
    return false
  }
}
