import { useMsal } from "@azure/msal-react"
import { Logout } from "@mui/icons-material"
import { Button } from "@mui/material"
import { useTranslation } from "react-i18next"

export const SignOutButton = () => {
  const { instance } = useMsal()
  const { t } = useTranslation()

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    })
  }
  return (
    <Button sx={{ textTransform: "none" }} onClick={() => handleLogout()} startIcon={<Logout />}>
      {t("logout.button")}
    </Button>
  )
}
