import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import CircleIcon from "@mui/icons-material/Circle"
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined"
import { Box, Rating, useTheme } from "@mui/material"
import AppBar from "@mui/material/AppBar"
import { isEmpty, isNil } from "lodash/fp"
import { useTranslation } from "react-i18next"
import BottomNavButton from "./styled/BottomNavButton"

export type BottomNavigationStepValue = 1 | 2 | 3 | 4 | 5

type BottomNavigationBarProps = {
  value: BottomNavigationStepValue
  to: string
  back: string
  customNextLabel?: string
  navLength: number
}

const BottomNavigationBar = ({ value, to, back, customNextLabel, navLength }: BottomNavigationBarProps) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <AppBar
      position="sticky"
      color="primary"
      sx={{
        top: "auto",
        bottom: 0,
        height: "78px",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      {!isEmpty(back) ? (
        <BottomNavButton to={back}>
          <ArrowBackIosIcon sx={{ fontSize: 16, mr: 1, color: theme.accentColors?.pink }} />
          {t("youth-view.feeling-selection.back")}
        </BottomNavButton>
      ) : (
        <Box
          sx={{
            width: "110px",
          }}
        ></Box>
      )}
      <Rating
        icon={<CircleIcon />}
        readOnly
        emptyIcon={<CircleOutlinedIcon />}
        max={navLength}
        defaultValue={value}
        sx={{
          "& .MuiRating-icon": {
            color: "secondary.dark",
            mr: 1,
          },
          "& .MuiRating-icon > svg": {
            fontSize: "15px",
          },
        }}
      />
      <BottomNavButton to={to}>
        {isNil(customNextLabel) ? t("youth-view.feeling-selection.next") : customNextLabel}
        <ArrowForwardIosIcon sx={{ fontSize: 16, ml: 1, color: theme.accentColors?.pink }} />
      </BottomNavButton>
    </AppBar>
  )
}

export default BottomNavigationBar
