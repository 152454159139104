import { useCallback, useState } from "react"
import SnackbarComponent from "utils/SnackbarComponent"
import useToggle from "./useToggle"

const useSnackbar = () => {
  const [isMessageVisible, toggleMessageVisibility] = useToggle()
  const [type, setType] = useState<"error" | "warning" | "info" | "success">("error")
  const [message, setMessage] = useState("")

  const showSnackbar = useCallback(
    (message: string, type: "error" | "warning" | "info" | "success") => {
      setType(type)
      setMessage(message)
      toggleMessageVisibility()
    },
    [toggleMessageVisibility]
  )

  const Snackbar = () => {
    return (
      <SnackbarComponent openState={isMessageVisible} toggle={toggleMessageVisibility} message={message} type={type} />
    )
  }

  return {
    showSnackbar,
    Snackbar,
  }
}

export default useSnackbar
