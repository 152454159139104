import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material"
import { Box, IconButton } from "@mui/material"

type Props = {
  count: number
  page: number
  rowsPerPage: number
  onPageChange: (event: any, newPage: number) => void
}

/**
 * Controls for handling paginate tables.
 * https://mui.com/components/tables/#custom-pagination-options
 */
const TablePaginationActions = (props: Props) => {
  const { count, page, rowsPerPage, onPageChange } = props

  const handleBackButtonClick = (event: any) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event: any) => {
    onPageChange(event, page + 1)
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  )
}

export default TablePaginationActions
