import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { DiaryDisplayProps } from "./DiaryDisplayProps"

const DiaryCard = ({ diaryContent }: DiaryDisplayProps) => {
  const { t } = useTranslation()

  const dailyPicksDefined = diaryContent.pick1 || diaryContent.pick2 || diaryContent.pick3

  return (
    <Box sx={{ textAlign: "left" }}>
      <Typography sx={{ fontWeight: "bold", pt: 2, pb: 1 }}>{t("youth-view.daily-summary.diary-title")}</Typography>
      <Typography variant="body1">{diaryContent.message}</Typography>

      {dailyPicksDefined ? (
        <>
          <Typography sx={{ fontWeight: "bold", pt: 2, pb: 1 }}>
            {t("youth-view.daily-summary.thankful-title")}
          </Typography>
          <Typography variant="body1">{diaryContent.pick1}</Typography>
          <Typography variant="body1">{diaryContent.pick2}</Typography>
          <Typography variant="body1">{diaryContent.pick3}</Typography>
        </>
      ) : (
        <></>
      )}
    </Box>
  )
}

export default DiaryCard
