import { CircularProgress } from "@mui/material"
import { BottomNavigationStepValue } from "components/BottomNavigationBar"
import MobileItemCardsView from "components/MobileItemsCardsView"
import { ClientGroupDataContext } from "contexts/clientGroupDataContext"
import { userContext } from "contexts/userContext"
import { useContext, useEffect, useReducer, useState } from "react"
import { FeelingService, UserFeelingSaveModel } from "services/openapi"
import ClientGroupId from "utils/clientGroupId"
import { toDateFormat } from "utils/functions/toDateFormat"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import useMountEffect from "utils/hooks/useMountEffect"
import FeelingDetailsView from "./FeelingDetails"
import { SelectedFeeling } from "./SelectedFeeling"
import { feelingSelectionStateReducer, initialFeelingSelectionState } from "./selectedFeelingsReducer"
import feelingIdEquals from "./utils/feelingIdEquals"

const defaultFeeling: SelectedFeeling = { id: -1, name: "", imageLocation: "Iloinen.svg" }

type FeelingCardsProps = {
  canSelectTargets: boolean
}

const FeelingCards = ({ canSelectTargets }: FeelingCardsProps) => {
  const { ErrorSnackbar, handleError } = useApiRequestError()
  const [selectedFeelingData, setSelectedFeelingData] = useState(defaultFeeling)
  const [changesMade, setChangesMade] = useState(false)
  const [state, dispatch] = useReducer(feelingSelectionStateReducer, initialFeelingSelectionState)
  const { user } = useContext(userContext)
  const { feelingOptions, isLoading } = useContext(ClientGroupDataContext)

  useMountEffect(async function fetchSavedFeelings() {
    const feelingData = await FeelingService.feelingGetForUserSingleDay(user?.id ?? "", toDateFormat(new Date()))
    dispatch({ type: "setFeelings", feelings: feelingData.feelings })
  })

  useEffect(
    function saveFeelingDataOnStateChange() {
      const postFeelingData = async () => {
        const body: UserFeelingSaveModel = {
          userId: user?.id ?? "",
          date: toDateFormat(new Date()),
          feelings: state.feelings,
        }

        try {
          await FeelingService.feelingSaveUserFeelings(body)
        } catch (error) {
          handleError(error, "youth-view.feeling-selection.save-error")
        }
      }
      if (changesMade) {
        postFeelingData()
      }
    },
    [handleError, state, user?.id, changesMade]
  )

  const feelingAlreadyAdded = (feelingId: number) => state.feelings.some(feelingIdEquals(feelingId))

  const toggleFeelingSelection = (feeling: SelectedFeeling) => {
    if (feelingAlreadyAdded(feeling.id)) {
      dispatch({ type: "deleteFeeling", feelingId: feeling.id })
    } else {
      dispatch({
        type: "createNewFeeling",
        feelingData: { feelingId: feeling.id, strength: 0, feelingTargetIds: [] },
      })
    }
  }

  return isLoading ? (
    <CircularProgress
      size={100}
      sx={{
        margin: 5,
      }}
    />
  ) : (
    <>
      <MobileItemCardsView
        items={feelingOptions}
        cardNamePrefix="youth-view.feeling-selection.feeling-options"
        cardIconFolder="feelingIcons"
        showDialogOnClick={canSelectTargets}
        callbacks={{
          changesMadeCallback: setChangesMade,
          onItemSelected: canSelectTargets ? setSelectedFeelingData : toggleFeelingSelection,
          itemAlreadyAdded: feelingAlreadyAdded,
          itemDetailsViewCallback: (drawerToggle: () => void) => (
            <FeelingDetailsView
              selectedFeelingData={selectedFeelingData}
              drawerToggle={drawerToggle}
              feelingsState={[state, dispatch]}
            />
          ),
        }}
        bottomNavigation={undefined}
      />
      <ErrorSnackbar />
    </>
  )
}

export default FeelingCards
