import { Button, ButtonProps, styled } from "@mui/material"

const RoundButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "secondary.main",
  backgroundColor: "primary.main",
  borderRadius: "50px",
  textTransform: "none",
  width: "90%",
  margin: "5px",
}))

export default RoundButton
