import { useState } from "react"
import { Dialog, DialogContent, DialogTitle } from "@mui/material"
import { useTranslation } from "react-i18next"

import { UserCreateResultModel, UserListModel } from "services/openapi"
import { createSuperAdmin } from "./apiRequests"
import StyledButton from "../../../components/StyledButton"
import UserCreateResultDialog from "components/UserCreateResultDialog"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import CreateSuperAdminFields from "./CreateSuperAdminFields"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"

type Props = {
  setSuperAdmins: React.Dispatch<React.SetStateAction<UserListModel[]>>
  showSnackbar: (message: string, type: "error" | "warning" | "info" | "success") => void
}

const CreateSuperAdmin = ({ setSuperAdmins, showSnackbar }: Props) => {
  const { t } = useTranslation()

  const [createResult, setCreateResult] = useState<UserCreateResultModel>({
    databaseId: "",
    firstTimePassword: "",
    userLogin: "",
  })
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [callingApi, setCallingApi] = useState(false)
  const { ErrorSnackbar, handleError } = useApiRequestError()

  const onSuccess = (result: UserCreateResultModel) => {
    setCreateResult(result)
    setPasswordDialogOpen(true)
  }

  return (
    <>
      <StyledButton
        variant="contained"
        onClick={() => {
          setDialogOpen(true)
        }}
        endIcon={<span>+</span>}
      >
        {t("create-super-admin.create-admin-button")}
      </StyledButton>

      <UserCreateResultDialog
        open={passwordDialogOpen}
        onClose={() => setPasswordDialogOpen(false)}
        createResult={createResult}
      />

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>{t("create-super-admin.dialog-header")}</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
          <Formik
            initialValues={{ firstName: "", lastName: "" }}
            validationSchema={Yup.object({
              firstName: Yup.string()
                .trim()
                .max(50, t("form-validation.too-long", { length: 50 }))
                .required(t("form-validation.required")),
              lastName: Yup.string()
                .trim()
                .max(50, t("form-validation.too-long", { length: 50 }))
                .required(t("form-validation.required")),
            })}
            onSubmit={(values) => {
              setCallingApi(true)
              createSuperAdmin(values, setSuperAdmins, onSuccess, showSnackbar, t, handleError).then((success) => {
                if (success) {
                  setDialogOpen(false)
                }
                setCallingApi(false)
              })
            }}
            validateOnBlur
          >
            {(formik) => (
              <Form>
                <CreateSuperAdminFields formik={formik}></CreateSuperAdminFields>
                <StyledButton
                  type="submit"
                  variant="contained"
                  disabled={callingApi}
                  sx={{
                    width: "fit-content",
                    alignSelf: "end",
                    float: "left",
                    clear: "both",
                    marginLeft: 17,
                  }}
                >
                  {t("create-super-admin.create-confirm")}
                </StyledButton>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <ErrorSnackbar />
    </>
  )
}

export default CreateSuperAdmin
