import { Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useTranslation } from "react-i18next"
import LinkSection from "./LinkSection"

const SeeYouLaterSection = () => {
  const { t } = useTranslation()

  return (
    <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
      <Typography sx={{ fontFamily: "SofiaProBold" }}>{t("ending-view.see-you-later-in-spesfeel")}</Typography>
      <Box sx={{ ml: "auto", mr: "auto", mt: 1 }}>
        <img
          id="good-bye-image"
          src={`${process.env.PUBLIC_URL}/feelingIcons/Iloinen.svg`}
          width={182}
          alt={t("ending-view.see-you-later")}
          aria-hidden="true"
        />
      </Box>
      <LinkSection />
    </Box>
  )
}

export default SeeYouLaterSection
