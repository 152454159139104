/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FamilyUnitCreateModel } from "../models/FamilyUnitCreateModel"
import type { FamilyUnitDetailsModel } from "../models/FamilyUnitDetailsModel"
import type { FamilyUnitListModelPagedResponseModel } from "../models/FamilyUnitListModelPagedResponseModel"
import type { FamilyUnitUpdateModel } from "../models/FamilyUnitUpdateModel"
import { request as __request } from "../core/request"

export class FamilyUnitService {
  /**
   * @param organizationId
   * @param pageSize
   * @param page
   * @param searchString
   * @returns FamilyUnitListModelPagedResponseModel Success
   * @throws ApiError
   */
  public static async familyUnitGetOrganizationFamilyUnits(
    organizationId: string,
    pageSize?: number,
    page?: number,
    searchString?: string
  ): Promise<FamilyUnitListModelPagedResponseModel> {
    const result = await __request({
      method: "GET",
      path: `/FamilyUnit/getOrganizationFamilyUnits`,
      query: {
        organizationId: organizationId,
        pageSize: pageSize,
        page: page,
        searchString: searchString,
      },
    })
    return result.body
  }

  /**
   * @param familyUnitId
   * @returns FamilyUnitDetailsModel Success
   * @throws ApiError
   */
  public static async familyUnitGetFamilyUnitDetails(familyUnitId: string): Promise<FamilyUnitDetailsModel> {
    const result = await __request({
      method: "GET",
      path: `/FamilyUnit/details/${familyUnitId}`,
    })
    return result.body
  }

  /**
   * @param requestBody
   * @returns string Success
   * @throws ApiError
   */
  public static async familyUnitCreateFamilyUnit(requestBody?: FamilyUnitCreateModel): Promise<string> {
    const result = await __request({
      method: "POST",
      path: `/FamilyUnit/create`,
      body: requestBody,
    })
    return result.body
  }

  /**
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public static async familyUnitUpdateFamilyUnit(requestBody?: FamilyUnitUpdateModel): Promise<any> {
    const result = await __request({
      method: "POST",
      path: `/FamilyUnit/update`,
      body: requestBody,
    })
    return result.body
  }

  /**
   * @param familyId
   * @returns any Success
   * @throws ApiError
   */
  public static async familyUnitDeactivateFamilyUnit(familyId: string): Promise<any> {
    const result = await __request({
      method: "DELETE",
      path: `/FamilyUnit/deactivate/${familyId}`,
    })
    return result.body
  }
}
