import { MenuItem } from "@mui/material"

import { UserCreateResultModel, UserListModel } from "services/openapi"
import { deactivateSuperAdmin, resetSuperAdminPassword } from "scenes/admin/SuperAdminList/apiRequests"

const SuperAdminMenuItems = (
  admin: UserListModel,
  handleClose: () => void,
  superAdmins: UserListModel[],
  setSuperAdmins: React.Dispatch<React.SetStateAction<UserListModel[]>>,
  showSnackbar: (message: string, type: "error" | "warning" | "info" | "success") => void,
  showConfirmationDialog: (
    onSubmit: () => Promise<boolean>,
    headerText?: string | undefined,
    messageText?: string | undefined
  ) => void,
  t: (key: string, options?: any) => string,
  onResetSuccess: (result: UserCreateResultModel) => void,
  onError: (error: any, message: string) => void
) => {
  const onUserDeactivationSuccess = () => {
    const updatedAdmins = superAdmins.filter((x) => x.id !== admin.id)
    setSuperAdmins(updatedAdmins)
    showSnackbar(t("super-admins.snackbar.deactivate-success"), "success")
  }

  return [
    <MenuItem
      key={`admin-${admin.id}-deactive`}
      onClick={() => {
        showConfirmationDialog(
          () => deactivateSuperAdmin(admin.id, onUserDeactivationSuccess, onError),
          t("user-list.deactivate.header"),
          t("user-list.deactivate.message", { name: `${admin.firstName} ${admin.lastName}` })
        )
        handleClose()
      }}
    >
      {t("user-list.table.delete-user")}
    </MenuItem>,
    <MenuItem
      key={`admin-${admin.id}-reset`}
      onClick={() => {
        showConfirmationDialog(
          () => resetSuperAdminPassword(admin.id, onResetSuccess, onError),
          t("user-list.reset-password.header"),
          t("user-list.reset-password.message", { name: `${admin.firstName} ${admin.lastName}` })
        )
        handleClose()
      }}
    >
      {t("user-list.table.reset-password")}
    </MenuItem>,
  ]
}

export default SuperAdminMenuItems
