import { useMsal } from "@azure/msal-react"
import { Dialog, DialogContent, DialogTitle } from "@mui/material"
import StyledButton from "components/StyledButton"
import UserCreateResultDialog from "components/UserCreateResultDialog"
import { Form, Formik } from "formik"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { UserCreateResultModel, UserListModel } from "services/openapi"
import { createManagementOrgUser } from "./apiRequests"
import * as Yup from "yup"
import CreateManagementOrgUserFields from "./CreateManagementOrgUserFields"

type Props = {
  organizationId: string
  setUsers: React.Dispatch<React.SetStateAction<UserListModel[]>>
  showSnackbar: (message: string, type: "error" | "warning" | "info" | "success") => void
}

const CreateManagementUser = ({ organizationId, setUsers, showSnackbar }: Props) => {
  const { t } = useTranslation()
  const { instance } = useMsal()

  const [createResult, setCreateResult] = useState<UserCreateResultModel>({
    databaseId: "",
    firstTimePassword: "",
    userLogin: "",
  })
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false)

  const [dialogOpen, setDialogOpen] = useState(false)

  const [callingApi, setCallingApi] = useState(false)

  const onSuccess = (result: UserCreateResultModel) => {
    setCreateResult(result)
    setPasswordDialogOpen(true)
  }

  return (
    <>
      <StyledButton onClick={() => setDialogOpen(true)} variant="contained" endIcon={<span>+</span>}>
        {t("management-organization-details.create-admin-user-button")}
      </StyledButton>

      <UserCreateResultDialog
        open={passwordDialogOpen}
        onClose={() => setPasswordDialogOpen(false)}
        createResult={createResult}
      ></UserCreateResultDialog>

      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false)
        }}
      >
        <DialogTitle>{t("add-admin-user.header")}</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column" }} id="create-user">
          <Formik
            initialValues={{ firstName: "", lastName: "" }}
            validationSchema={Yup.object({
              firstName: Yup.string()
                .trim()
                .max(50, t("form-validation.too-long", { length: 50 }))
                .required(t("form-validation.required")),
              lastName: Yup.string()
                .trim()
                .max(50, t("form-validation.too-long", { length: 50 }))
                .required(t("form-validation.required")),
            })}
            onSubmit={(values) => {
              setCallingApi(true)
              createManagementOrgUser(
                { ...values, organizationId: organizationId },
                organizationId,
                setUsers,
                onSuccess,
                instance,
                showSnackbar,
                t
              ).then((success: boolean) => {
                if (success) {
                  setDialogOpen(false)
                }
                setCallingApi(false)
              })
            }}
            validateOnBlur
          >
            {(formik) => (
              <Form>
                <CreateManagementOrgUserFields formik={formik} />
                <StyledButton
                  type="submit"
                  variant="contained"
                  disabled={callingApi}
                  sx={{
                    width: "fit-content",
                    alignSelf: "end",
                    float: "left",
                    clear: "both",
                    marginLeft: 17,
                  }}
                >
                  {t("add-user.save")}
                </StyledButton>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default CreateManagementUser
