import { LinearProgress, Paper } from "@mui/material"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { UserListModel, UserRoleModel } from "services/openapi"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import {
  fetchCareOrganizationUser,
  fetchClientUser,
  fetchManageOrganizationAdmin,
  fetchSuperAdmin,
} from "./apiRequests"
import UserFormFields from "./UserFormFields"

const ProfilePage = () => {
  const { userId, type } = useParams<{ userId: string; type: string }>()

  const [isLoading, setLoading] = useState<boolean>(true)
  const [user, setUser] = useState<UserListModel>()
  const { ErrorSnackbar, handleError } = useApiRequestError()

  useEffect(
    function () {
      if (type === UserRoleModel.SUPER_ADMIN) {
        fetchSuperAdmin(userId, setLoading, setUser, handleError)
      }

      if (type === UserRoleModel.MANAGEMENT_ORGANIZATION_ADMIN_USER) {
        fetchManageOrganizationAdmin(userId, setLoading, setUser, handleError)
      }

      if (type === UserRoleModel.CARE_ORGANIZATION_USER) {
        fetchCareOrganizationUser(userId, setLoading, setUser, handleError)
      }

      if (type === UserRoleModel.CLIENT) {
        fetchClientUser(userId, setLoading, setUser, handleError)
      }

      return () => {
        setLoading(true)
      }
    },
    [userId, type, handleError]
  )

  return (
    <>
      <Paper sx={{ padding: 4, mb: 4 }}>
        {isLoading && !user ? <LinearProgress /> : <UserFormFields user={user!} />}
      </Paper>

      <ErrorSnackbar />
    </>
  )
}

export default ProfilePage
