export type SuccessOption = {
  value: number
  icon: string
  translationKey: string
}

export const successOptions: SuccessOption[] = [
  {
    value: 5,
    icon: "Toiveikas.svg",
    translationKey: "very-well",
  },
  {
    value: 4,
    icon: "Iloinen.svg",
    translationKey: "well",
  },
  {
    value: 3,
    icon: "Tyytyvainen.svg",
    translationKey: "neutral",
  },
  {
    value: 2,
    icon: "Surullinen.svg",
    translationKey: "bad",
  },
  {
    value: 1,
    icon: "Ahdistunut.svg",
    translationKey: "very-bad",
  },
]
