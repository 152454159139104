import MobileViewBase from "components/MobileViewBase"
import DailyFeelingSummary from "scenes/DailyFeelingSummary/DailyFeelingSummary"
import DailyDiarySummary from "./DailyDiarySummary"
import BottomNavigationBar from "components/BottomNavigationBar"
import MobileViewDescription from "components/MobileViewBase/MobileViewDescription"
import { useTranslation } from "react-i18next"
import { toDateFormat, toFinnishFormat } from "utils/functions/toDateFormat"

const YouthDailySummary = () => {
  const { t } = useTranslation()

  return (
    <MobileViewBase
      top={
        <MobileViewDescription
          header={t("youth-view.daily-summary.title")}
          text={toFinnishFormat(toDateFormat(new Date()))}
        />
      }
      middle={
        <>
          <DailyFeelingSummary />
          <DailyDiarySummary />
        </>
      }
      bottom={<BottomNavigationBar value={3} back={"/client/diary"} to={"/client/ending"} navLength={4} />}
    />
  )
}

export default YouthDailySummary
