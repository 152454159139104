/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CareOrganizationUserCreateModel } from "../models/CareOrganizationUserCreateModel"
import type { CareOrganizationUserListModelPagedResponseModel } from "../models/CareOrganizationUserListModelPagedResponseModel"
import type { CareOrganizationUserUpdateModel } from "../models/CareOrganizationUserUpdateModel"
import type { UserCreateResultModel } from "../models/UserCreateResultModel"
import type { UserListModel } from "../models/UserListModel"
import { request as __request } from "../core/request"

export class CareOrganizationUserService {
  /**
   * Creates a new organization user for organization
   * @param requestBody Created user
   * @returns UserCreateResultModel Success
   * @throws ApiError
   */
  public static async careOrganizationUserCreateOrganizationUser(
    requestBody?: CareOrganizationUserCreateModel
  ): Promise<UserCreateResultModel> {
    const result = await __request({
      method: "POST",
      path: `/CareOrganizationUser/createOrganizationUser`,
      body: requestBody,
    })
    return result.body
  }

  /**
   * Updates existing organization user
   * @param requestBody Updated user
   * @returns any Success
   * @throws ApiError
   */
  public static async careOrganizationUserUpdateCareOrganizationUser(
    requestBody?: CareOrganizationUserUpdateModel
  ): Promise<any> {
    const result = await __request({
      method: "POST",
      path: `/CareOrganizationUser/updateOrganizationUser`,
      body: requestBody,
    })
    return result.body
  }

  /**
   * @param userId
   * @returns UserCreateResultModel Success
   * @throws ApiError
   */
  public static async careOrganizationUserResetPassword(userId: string): Promise<UserCreateResultModel> {
    const result = await __request({
      method: "POST",
      path: `/CareOrganizationUser/resetPassword/${userId}`,
    })
    return result.body
  }

  /**
   * @param organizationId
   * @param familyId
   * @param canManageOrganizationUsers
   * @param canManageFamilyUnits
   * @param canBeConnectedToFamily
   * @param pageSize
   * @param page
   * @param searchString
   * @returns CareOrganizationUserListModelPagedResponseModel Success
   * @throws ApiError
   */
  public static async careOrganizationUserGetOrganizationUsers(
    organizationId: string,
    familyId?: string,
    canManageOrganizationUsers?: boolean,
    canManageFamilyUnits?: boolean,
    canBeConnectedToFamily?: boolean,
    pageSize?: number,
    page?: number,
    searchString?: string
  ): Promise<CareOrganizationUserListModelPagedResponseModel> {
    const result = await __request({
      method: "GET",
      path: `/CareOrganizationUser/getOrganizationUsers`,
      query: {
        organizationId: organizationId,
        familyId: familyId,
        CanManageOrganizationUsers: canManageOrganizationUsers,
        CanManageFamilyUnits: canManageFamilyUnits,
        CanBeConnectedToFamily: canBeConnectedToFamily,
        pageSize: pageSize,
        page: page,
        searchString: searchString,
      },
    })
    return result.body
  }

  /**
   * @param id
   * @returns UserListModel Success
   * @throws ApiError
   */
  public static async careOrganizationUserGetDetails(id: string): Promise<UserListModel> {
    const result = await __request({
      method: "GET",
      path: `/CareOrganizationUser/get/${id}`,
    })
    return result.body
  }

  /**
   * @param userId
   * @returns any Success
   * @throws ApiError
   */
  public static async careOrganizationUserDeactivateUser(userId: string): Promise<any> {
    const result = await __request({
      method: "DELETE",
      path: `/CareOrganizationUser/deactivate/${userId}`,
    })
    return result.body
  }
}
