import { Box } from "@mui/system"
import BottomDrawer from "components/BottomDrawer"
import BottomNavigationBar, { BottomNavigationStepValue } from "components/BottomNavigationBar"
import SpesCard from "components/SpesCard"
import { useTranslation } from "react-i18next"
import { FeelingModel } from "services/openapi"
import useToggle from "utils/hooks/useToggle"

type CardItem = {
  id: number
  name: string
  imageLocation: string
}

type MobileItemCardsViewProps = {
  items: CardItem[]
  cardNamePrefix: string
  cardIconFolder: string
  showDialogOnClick: boolean
  bottomNavigation: {
    to: string
    back: string
    stepValue: BottomNavigationStepValue
    navLength: number
  } | undefined
  callbacks: {
    changesMadeCallback: (changesMade: boolean) => void
    itemDetailsViewCallback: (drawerToggle: () => void) => JSX.Element
    onItemSelected: (value: { id: number; name: string; imageLocation: string }) => void
    itemAlreadyAdded: (item: any) => boolean
  }
}

const MobileItemCardsView = ({
  items,
  bottomNavigation,
  cardNamePrefix,
  cardIconFolder,
  showDialogOnClick,
  callbacks,
}: MobileItemCardsViewProps) => {
  const [drawerOpen, toggleDrawer] = useToggle()
  const { t } = useTranslation()

  const onItemCardClick = (cardData: FeelingModel) => {
    callbacks.onItemSelected({ id: cardData.id, name: cardData.name, imageLocation: cardData.imageLocation })
    callbacks.changesMadeCallback(true)
    if (showDialogOnClick) {
      toggleDrawer()
    }
  }

  const itemCardList = items?.map((item) => {
    return (
      <SpesCard
        key={item.id}
        name={t(`${cardNamePrefix}.${item.name}`)}
        onClick={() => onItemCardClick(item)}
        selected={callbacks.itemAlreadyAdded(item.id)}
        iconUrl={`${process.env.PUBLIC_URL}/${cardIconFolder}/${item.imageLocation}`}
      />
    )
  })

  const bottomNavigator = () => {
    if (bottomNavigation !== undefined) {
      return (
        <BottomNavigationBar
          value={bottomNavigation.stepValue}
          to={bottomNavigation.to}
          back={bottomNavigation.back}
          navLength={bottomNavigation.navLength}
        />
      )
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          rowGap: 3,
        }}
      >
        {itemCardList}
      </Box>
      <BottomDrawer
        drawerOpen={drawerOpen}
        onClose={toggleDrawer}
        content={callbacks.itemDetailsViewCallback(toggleDrawer)}
      />
      {bottomNavigator()}
    </>
  )
}

export default MobileItemCardsView
