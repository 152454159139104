import { CircularProgress } from "@mui/material"
import MobileItemCardsView from "components/MobileItemsCardsView"
import { ClientGroupDataContext } from "contexts/clientGroupDataContext"
import { userContext } from "contexts/userContext"
import { useContext, useEffect, useReducer, useState } from "react"
import {
  activitiesSelectionStateReducer,
  initialActivitySelectionState,
} from "scenes/ElderlyActivitySelection/selectedActivitiesReducer"
import { ActivityService, UserActivitySaveModel } from "services/openapi"
import { saveUserActivities } from "utils/apiRequests/userActivities"
import { toDateFormat } from "utils/functions/toDateFormat"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import useMountEffect from "utils/hooks/useMountEffect"

export type SelectedHelper = {
  id: number
  name: string
  imageLocation: string
}

const HelperCards = () => {
  const { user } = useContext(userContext)
  const { ErrorSnackbar, handleError } = useApiRequestError()
  const { helperOptions, isLoading } = useContext(ClientGroupDataContext)
  const [changesMade, setChangesMade] = useState(false)
  const [state, dispatch] = useReducer(activitiesSelectionStateReducer, initialActivitySelectionState)

  useMountEffect(async function fetchSavedFeelings() {
    const activityData = await ActivityService.activityGetForUserSingleDay(user?.id ?? "", toDateFormat(new Date()))
    dispatch({ type: "setActivities", stateData: activityData })
  })

  useEffect(
    function saveHelperDataOnStateChange() {
      const postHelperData = async () => {
        const body: UserActivitySaveModel = {
          userId: user?.id ?? "",
          date: toDateFormat(new Date()),
          activities: state.activities,
          activityHelperIds: state.helpers,
          wouldHaveNeededHelp: false,
        }

        await saveUserActivities(body, () => { }, handleError)
      }
      if (changesMade) {
        postHelperData()
      }
    },
    [handleError, user?.id, changesMade, state.activities, state.helpers]
  )

  const helperAlreadyAdded = (helperId: number) => (state.helpers ? state.helpers.includes(helperId) : false)

  const toggleHelperSelection = (helper: SelectedHelper) => {
    if (helperAlreadyAdded(helper.id)) {
      dispatch({ type: "deleteHelper", helperId: helper.id })
    } else {
      dispatch({ type: "createNewHelper", helperId: helper.id })
    }
  }

  return isLoading ? (
    <CircularProgress
      size={100}
      sx={{
        margin: 5,
      }}
    />
  ) : (
    <>
      <MobileItemCardsView
        items={helperOptions}
        cardNamePrefix="elderly-view.helper-selection.helper-options" //check the correct prefix
        cardIconFolder="helperIcons"
        showDialogOnClick={false}
        callbacks={{
          changesMadeCallback: setChangesMade,
          onItemSelected: toggleHelperSelection,
          itemAlreadyAdded: helperAlreadyAdded,
          itemDetailsViewCallback: (drawerToggle: () => void) => <></>,
        }}
        bottomNavigation={undefined}
      />
      <ErrorSnackbar />
    </>
  )
}

export default HelperCards
