import { Dialog, DialogContent, DialogTitle } from "@mui/material"
import StyledButton from "components/StyledButton"
import UserCreateResultDialog from "components/UserCreateResultDialog"
import { Form, Formik } from "formik"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { createNewCareOrgUser, fetchOrganizationUsers } from "scenes/admin/CareOrganizationDetails/apiRequests"
import { UserCreateResultModel, UserListModel } from "services/openapi"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import useSnackbar from "utils/hooks/useSnackbar"
import * as Yup from "yup"
import CreateCareOrgUserFields from "./CreateCareOrgUserFields"

type Props = {
  organizationId: string
  setUsers: React.Dispatch<React.SetStateAction<UserListModel[]>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

type FormValues = {
  firstName: string
  lastName: string
  isForeman: boolean
  isWorker: boolean
}

const CreateCareOrgUser = ({ organizationId, setUsers, setLoading }: Props) => {
  const { t } = useTranslation()
  const { Snackbar, showSnackbar } = useSnackbar()

  const [createResult, setCreateResult] = useState<UserCreateResultModel>({
    databaseId: "",
    firstTimePassword: "",
    userLogin: "",
  })
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [callingApi, setCallingApi] = useState(false)
  const { ErrorSnackbar, handleError } = useApiRequestError()

  const onCreateNewUserButtonClick = async ({ firstName, lastName, isForeman, isWorker }: FormValues) => {
    const onCreateNewUserSuccess = (result: UserCreateResultModel) => {
      setCreateResult(result)
      setPasswordDialogOpen(true)
      showSnackbar(t("care-organization-details.snackbar.create-user-success"), "success")
    }

    setCallingApi(true)

    const success = await createNewCareOrgUser(
      {
        organizationId,
        firstName,
        lastName,
        canManageOrganizationUsers: isForeman,
        canManageFamilyUnits: isForeman,
        canBeConnectedToFamily: isForeman || isWorker,
      },
      onCreateNewUserSuccess,
      handleError
    )
    if (success) {
      await fetchOrganizationUsers(organizationId, setLoading, setUsers, handleError)
      setDialogOpen(false)
    }
    setCallingApi(false)
  }

  return (
    <>
      <StyledButton onClick={() => setDialogOpen(true)} variant="contained" endIcon={<span>+</span>}>
        {t("care-organization-details.create-user-button")}
      </StyledButton>

      <UserCreateResultDialog
        open={passwordDialogOpen}
        onClose={() => setPasswordDialogOpen(false)}
        createResult={createResult}
      ></UserCreateResultDialog>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>{t("add-user.header")}</DialogTitle>
        <DialogContent id="create-care-user">
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              isForeman: false,
              isWorker: false,
            }}
            validationSchema={Yup.object({
              firstName: Yup.string()
                .trim()
                .max(50, t("form-validation.too-long", { length: 50 }))
                .required(t("form-validation.required")),
              lastName: Yup.string()
                .trim()
                .max(50, t("form-validation.too-long", { length: 50 }))
                .required(t("form-validation.required")),
            })}
            onSubmit={(values) => {
              onCreateNewUserButtonClick(values)
            }}
            validateOnBlur
          >
            {(formik) => (
              <Form>
                <CreateCareOrgUserFields formik={formik}></CreateCareOrgUserFields>
                <StyledButton
                  type="submit"
                  sx={{
                    width: "fit-content",
                    alignSelf: "end",
                    float: "right",
                    clear: "both",
                    mt: 2,
                  }}
                  variant="contained"
                  disabled={callingApi}
                >
                  {t("add-user.save")}
                </StyledButton>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      <Snackbar />
      <ErrorSnackbar />
    </>
  )
}

export default CreateCareOrgUser
