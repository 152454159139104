import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import reportWebVitals from "./reportWebVitals"
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"

import "./i18n"
import { msalConfig } from "authConfig"
import { ConfigService } from "services/config"

ConfigService.getConfig().then((config) => {
  const msalInstance = new PublicClientApplication(msalConfig(config))

  const accounts = msalInstance.getAllAccounts()
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0])
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult
      const account = payload.account
      msalInstance.setActiveAccount(account)
    }
  })

  ReactDOM.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <App pca={msalInstance} />
      </MsalProvider>
    </React.StrictMode>,
    document.getElementById("root")
  )
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
