import { Typography } from "@mui/material"
import ActivityBox from "components/ActivityComponents/ActivityBox"
import ActivityHelperBox from "components/ActivityComponents/ActivityHelperBox"
import StyledListing from "components/ActivityComponents/styled/StyledListing"
import { useTranslation } from "react-i18next"
import { ActivityHelperModel, ActivityModel, UserActivityModel } from "services/openapi"

type ActivitySectionCardProps = {
  activityOptions: ActivityModel[]
  activityHelperOptions: ActivityHelperModel[]
  userActivityDay: UserActivityModel
}

const ActivitySectionCard = ({ activityOptions, activityHelperOptions, userActivityDay }: ActivitySectionCardProps) => {
  const { t } = useTranslation()

  const helpersElements = userActivityDay.activityHelperIds.map((helperId) => (
    <ActivityHelperBox key={helperId} activityHelperId={helperId} activityHelperOptions={activityHelperOptions} />
  ))

  const activityElements = userActivityDay.activities.map((activity) => (
    <ActivityBox key={activity.activityId} activity={activity} activityOptions={activityOptions} />
  ))

  return (
    <>
      {activityElements}
      <StyledListing>{t(`elderly-view.helper-selection.title`)}</StyledListing>
      {userActivityDay.activityHelperIds.length === 0 ? (
        <Typography
          variant="body1"
          sx={{
            ml: 2,
            mt: 0,
            mr: 4,
            mb: 1,
            display: "flex",
            flexDirection: "row",
            fontFamily: "SofiaProLight",
            fontSize: "20px",
          }}
        >
          {userActivityDay.wouldHaveNeededHelp
            ? t("elderly-view.helper-selection.no-help-need-help")
            : t("elderly-view.helper-selection.no-need-help")}
        </Typography>
      ) : (
        helpersElements
      )}
    </>
  )
}

export default ActivitySectionCard
