import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import translationEn from "locales/en/translation.json"
import translationFi from "locales/fi/translation.json"
import translationSe from "locales/se/translation.json"
import translationDe from "locales/de/translation.json"
import { default as tosEn } from "locales/en/tos"
import { default as tosFi } from "locales/fi/tos"
import { default as tosSe } from "locales/se/tos"
import { default as tosDe } from "locales/de/tos"

// To automatically update translation files, run
// "yarn extract-translations"

/**
 * Collection of translations. To add a new language, create a new
 * json file based on existing localizations and add it to this object.
 */
const translations = {
  en: {
    translation: translationEn,
  },
  fi: {
    translation: translationFi,
  },
  se: {
    translation: translationSe,
  },
  de: {
    translation: translationDe,
  }
}

//Add Terms of Service text to translations
translations["en"]["translation"]["terms-of-user-view"]["content"] = tosEn
translations["fi"]["translation"]["terms-of-user-view"]["content"] = tosFi
translations["se"]["translation"]["terms-of-user-view"]["content"] = tosSe
translations["de"]["translation"]["terms-of-user-view"]["content"] = tosDe

const storageLanguage = JSON.stringify(localStorage.getItem("language"))
const lang = storageLanguage !== null ? JSON.parse(storageLanguage) : "{}"
// By default uses browser language or english
const lng = lang
  ? Object.keys(translations).includes(lang)
    ? lang
    : Object.keys(translations).includes(navigator.language.substring(0, 2))
      ? navigator.language.substring(0, 2)
      : "en"
  : "en"

// Initialization
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: translations,
    lng,
    fallbackLng: "en",
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
