const TOS = `
# PALVELUNKÄYTTÄJÄN FIILISLUOTSI-PALVELUN KÄYTTÖEHDOT

## 1 Yleistä

Nämä Palvelunkäyttäjän käyttöoikeutta koskevat ehdot (jäljempänä "Käyttöehdot") sisältää Hämeen Spes Oy:n (jäljempänä ”Spes Palvelut”) Fiilisluotsi/MoodPilot-palvelun (jäljempänä "Palvelu") sekä sen mahdollisten lisäosien käyttöä koskevat ehdot, joihin Palvelua käyttävänä yksityishenkilönä sinä (”Palvelunkäyttäjä” tai ”Sinä”) sitoudut noudattamaan rekisteröityessäsi Palvelun käyttäjäksi, asentaessasi Palvelun tai käyttäessäsi Palvelua.
Palvelun käyttö edellyttää rekisteröitymistä. Rekisteröitymällä Palveluun tai käyttämällä Palvelua hyväksyt nämä Käyttöehdot.

## 2 Käyttöoikeus 

Sinun kirjautuessasi tai rekisteröityessäsi Palvelun käyttäjäksi Fiilisluotsi/MoodPilot-palveluun, niin Spes Palvelut antaa sinulle rajoitetun, ei-siirrettävissä olevan käyttöoikeuden.
Käyttöoikeus on voimassa niin kauan kuin käytät Palvelua Palveluntarjoajan tarjoamana Palvelua koskevan sopimuksen tai muun päätöksen perusteella. Käyttöoikeus päättyy ilman eri toimenpiteitä Palveluntarjoajan sopimuksen, päätöksen tai muun Spes Palveluiden ja Palveluntarjoajan välisen sopimuksen päättyessä.

## 3 Sinun vastuusi ja sitoumuksesi

Spes Palvelut tarjoaa käyttöön Palvelun mukaisen (ohjelmistopalvelun) SaaS-alustan (ohjelmistopalveluna) osana Palveluntarjoajan toimintaa.
Palvelun käyttöönotto ei vaikuta Sinun ja Palveluntarjoajan väliseen työ- tai toimisuhteeseen sekä siitä seuraaviin velvoitteisiin tai oikeuksiin (mukaan lukien henkilötietojen käyttö), vaan Palvelu ainoastaan tarjoaa sähköisen alustan tälle toiminnalle.
Sinun vastuullasi on käyttää Palvelua näiden Käyttöehtojen tarkoittamalla tavalla sekä noudattaa Palvelun käytössä siihen liittyviä ohjeita ja määräyksiä. 
Sinä vastaat itse kaikesta Palveluun jättämästäsi tai siirtämästäsi aineistosta kuten teksteistä, tiedoista ja dokumenteista. Sinä olet velvollinen huolehtimaan siitä, ettei Sinun Palveluun siirtämäsi aineisto loukkaa kolmannen osapuolen tekijänoikeuksia ja että Sinulla on lupa ja oikeus tällaisen aineiston jättämiseen Palveluun.
Sinä sitoudut olemaan tallentamatta, säilyttämättä, levittämättä, lähettämättä tai välittämättä Palvelussa aineistoa, joka on lain ja/tai hyvän tavan vastaista, yllyttää tällaiseen toimintaan tai edistää sitä.  
Sinä sitoudut olemaan tallentamatta, säilyttämättä, levittämättä, lähettämättä tai välittämättä Palvelussa aineistoa, joka koskee muiden henkilötietoja ja asioita esim. terveystietoja. 
Palvelu tunnistaa Sinut asiakastunnuksen ja salasanan kautta. Sinun vastuullasi on huolehtia asiakastunnuksesi ja salasanasi huolellisesta säilyttämisestä ja salassapidosta. Sinä vastaat kaikesta asiakastunnuksellasi ja salasanallasi tapahtuneesta Palvelun käytöstä. Sinun on ilmoitettava ilman aiheetonta viivytystä Palveluntarjoajalle, jos Sinun asiakastunnuksesi tai salasanasi joutuu kadoksiin tai epäilet asiakastunnustasi käytettävän ilman lupaasi.

Jos Sinulla ei ole oikeutta käyttää Palvelua, esimerkiksi koska et ole enää työ-, toimi- tai sopimussuhteessa Palveluntuottajan kanssa, joka käyttää Palvelua, Sinun on ilmoitettava asiasta Palveluntarjoajalle ja lakattava käyttämästä Palvelua. Sinulla ei ole oikeutta luovuttaa itsenäisesti asiakastunnuksiasi kolmannelle osapuolelle.
Sinä sitoudut olemaan käyttämättä Palvelua:
1.	mihinkään toimintaan, joka vaikeuttaa tai voisi vaikeuttaa toisten Palvelunkäyttäjien mahdollisuutta käyttää Palvelua,
2.	roskapostin tai muun luvattoman tietoliikenteen välittämiseen;
3.	muiden henkilöiden tai tahojen oikeuksia loukkaavan, lainvastaisen, halventavan, siveettömän tai muutoin loukkaavan aineiston julkaisemiseen tai välittämiseen;
4.	muiden käyttäjien henkilötietojen keräämisen tai tallentamiseen ilman kyseisten käyttäjien nimenomaista lupaa; tai
5.	lainvastaisiin toimiin.
Spes Palvelut pidättää oikeuden vedota kaikkiin näiden rajoitusten rikkomisesta johtuviin laillisiin oikeuksiinsa. Spes Palveluilla on oikeus estää ilman, että se olisi vastuussa asiasta, estää tai rajoittaa Sinun pääsysi Palveluun tai perua Sinun käyttöoikeutesi tai ryhtyä muihin toimenpiteisiin siltä osin kuin se on tarpeellista Käyttöehtojen vastaisen toiminnan lopettamiseksi.


## 4 Suostumus henkilötietojen käyttöön

Palvelun käyttäminen edellyttää Sinua koskevien, käyttäjätunnuksen ja salasanan luomiseen liittyvienhenkilötietojen luovuttamista Palveluun. Nämä tiedot on kuvattu Spes Palveluiden rekisteriselosteessa. Palvelun ylläpitäjä Spes Palvelut toimii tietosuojalainsäädännön tarkoittamana rekisterinpitäjänä/henkilötietojen käsittelijänä yhdessä Palveluntarjoajan kanssa. Palveluntarjoajan kanssa on laadittu henkilötietojenkäsittelysopimus, jonka mukaisesti toimitaan.
Rekisteriin tarvittavia henkilötietoja voidaan käyttää

-	Sinun tunnistautumiseen sekä Palvelun toteuttamiseen;
-	Palvelun hoitamiseen ja kehittämiseen myös EU:n ulkopuolisten ohjelmistokehittäjien toimesta;
-	tiedotteiden tai viestien lähettämiseen;
-	lisäpalveluiden tai ohjeiden tarjoamiseksi, toteuttamiseksi ja ylläpitämiseksi;
-	tuotteiden ja palveluiden markkinointiin;
-	asiakaspalvelun ja liiketoiminnan kehittämiseen;
-	analysointi ja tilastointitarkoituksiin;
-	Palvelun ylläpitäjän ja Palveluntarjoajan sekä Sinun väliseen viestien vaihtoon sekä
-	muihin vastaaviin käyttötarkoituksiin

Rekisteröitymällä ja käyttämällä Palvelua annat nimenomaisen suostumuksesi Spes Palveluille sekä Palveluntarjoajalle käyttää Sinua koskevia, rekisteriselosteessa kuvattuja henkilötietoja Palvelun tuottamista varten.
Kaikkeen Palveluun liittyvään henkilötietojen käsittelyyn sovelletaan Spes Palveluiden rekisteriselostetta, joka on saatavilla verkkosivulla [https://www.spespalvelut.fi/rekisteri-jatietosuojaseloste].
Sinulla on oikeus peruuttaa suostumuksesi milloin tahansa kokonaan tai osittain. Huomaathan kuitenkin, että Palvelun käyttäminen edellyttää Palveluun liittyvien henkilötietojen luovuttamista.

## 5 Palvelun käytön päättäminen

Jos haluat lopettaa Palvelun käytön ja peruuttaa rekisteröitymisesi, Sinun tulee ilmoittaa tästä kirjallisesti Palveluntarjoajalle. Palveluntarjoaja poistaa käyttäjätunnuksesi ilman aiheetonta viivytystä, kuitenkin viimeistään 14 päivän kuluessa kirjallisesta pyynnöstäsi.

## 6 Vastuunrajoitus

Spes Palvelut tuottaa Palvelua ”sellaisena kuin on”. Palvelulle ei myönnetä mitään takuita tai vastuita. Spes Palvelut ei takaa sitä, että Palvelua voidaan käyttää keskeytyksettä tai virheettömästi. Spes Palvelut pidättää itselleen oikeuden perustellusta syystä tehdä Palveluun muutoksia tai rajoittaa Palveluun pääsyä milloin tahansa ilman etukäteisilmoitusta Sinulle. 
Spes Palvelut ei vastaa Sinun Palveluun syöttämästäsi aineistosta, sen lainmukaisuudesta, sisällöstä taikka siitä, loukkaako aineisto mahdollisesti kolmannen osapuolen immateriaalioikeuksia. 
Spes Palvelut ei vastaa Palveluun toimittamasi aineiston palauttamisesta tai säilyttämisestä Palvelun käytön päätyttyä.  
Sinä vastaat rajoituksetta kaikesta Käyttöehtojen, mahdollisten muiden sopimusehtojen ja lain tai hyvän tavan vastaisesta Palvelun käytöstä Spes Palvelulle, muille Palvelun käyttäjille ja kolmansille osapuolille aiheuttamastaan vahingosta täysimääräisesti.

## 7 Tekijänoikeus ja muut oikeudet
Palvelun omistusoikeus sekä tekijänoikeus ja kaikki muut Palveluun ja sen sisältämään muuhun kuin Sinun lisäämääsi aineistoon liittyvät immateriaalioikeudet (mukaan lukien mm. tekijänoikeudet, rekisteröimättömät ja rekisteröidyt tavaramerkki- ja mallioikeudet, patentit, domain-nimet, liikesalaisuudet sekä tietokantaoikeudet) kuuluvat Spes Palveluille.  
Kaikki oikeudet, joita ei nimenomaisesti myönnetä näissä Käyttöehdoissa, pidätetään. Spes Palvelut ei myönnä Sinulle mitään muita suoria tai epäsuoria oikeuksia Palvelun käyttöön kuin oikeuden käyttää Palvelua sen tarkoitukseen näiden Käyttöehtojen mukaisesti.
Palvelun sisällön tai sen osan kopioiminen, siirtäminen, jakelu tai tallentaminen missä muodossa tahansa on kiellettyä ilman Spes Palveluiden etukäteistä kirjallista suostumusta. 

## 8 Kolmansien osapuolten aineisto
Palvelussa voi olla linkkejä kolmansien osapuolien omistamille tai ylläpitämille sivuille ja sisältöön. Siirtyessäsi tällaisille sivuille Sinun on tutustuttava ja hyväksyttävä niiden mahdolliset käyttöehdot ennen käytön aloittamista. Linkitetyt sivut eivät ole Spes Palveluiden hallinnassa, eikä Spes Palvelut voi vaikuttaa niillä julkaistun aineiston sisältöön. Spes Palvelut ei ole vastuussa mistään kolmannen osapuolen sivujen sisältämästä aineistosta tai palvelusta.

## 9 Spes Palveluiden oikeus poistaa tai sensuroida Palvelunkäyttäjän Palveluun siirtämää aineistoa
Spes Palvelut pidättää oikeuden poistaa tai sensuroida Palvelusta mitä tahansa Sinun tuottamaasi sisältöä taikka aineistoa oman harkintansa mukaan, jos Spes Palveluiden käsityksen mukaan tällaisen aineiston syöttäminen olisi tai on näiden Käyttöehtojen vastaista. 

## 10 Tietosuojaseloste ja henkilötietoja koskevat muut ehdot
Näihin Käyttöehtoihin sovelletaan Spes Palveluiden Palvelua koskevaa tietosuojaselostetta.
Spes Palvelut voi käyttää Palvelun käyttöön liittyviä henkilötietoja siten, että Sinua ei voida tunnistaa tai yksilöidä. Spes Palvelut voi käyttää tällaisia tietoja anonymisoituina tai pseudonymisoituina mm. seuraaviin käyttötarkoituksiin:
-	tilastojen laatiminen
-	Palvelun kehittäminen
-	Palvelun käyttäjämäärien seuranta ja siihen perustuva markkinointi

Spes Palvelut voi luovuttaa tietoa myös eteenpäin tällaisessa muodossa.
Henkilötietojen käyttöä sekä antamiasi suostumuksia koskevat ehdot ja ohjeet ovat saatavilla tietosuojaselosteessa. Spes Palveluiden käyttämät tietosuojaselosteen ehdot ovat nähtävissä [https://www.spespalvelut.fi/rekisteri-jatietosuojaseloste]. 
Lisäksi Spes Palvelut voi käsitellä ja luovuttaa Palvelun käyttämisen yhteydessä syntyviä tietoja sosiaali- ja terveystietojen toissijaisesta käytöstä annetussa laissa (552/2019) säädettyjen edellytysten puitteissa.  

## 11 Sovellettava lainsäädäntö 
Näihin Käyttöehtoihin sovelletaan Suomen lakia kuitenkin siten, että Yhdistyneiden kansakuntien kansainvälistä tavarakauppaa koskevaa yleissopimusta (CISG) sekä sen lainvalintasäännöksiä ei sovelleta Palvelun käyttöön. Näillä Käyttöehdoilla ei rajata Suomen pakottavan lainsäädännön mukaisia kuluttajan oikeuksia.  
Riitatilanteessa toimivaltainen tuomioistuin on Spes Palveluiden kotipaikan yleinen tuomioistuin.
`
export default TOS
