/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DiaryEntryListModelPagedResponseModel } from "../models/DiaryEntryListModelPagedResponseModel"
import type { DiaryEntryModel } from "../models/DiaryEntryModel"
import type { DiaryEntrySaveModel } from "../models/DiaryEntrySaveModel"
import { request as __request } from "../core/request"

export class DiaryService {
  /**
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public static async diarySaveDiaryEntry(requestBody?: DiaryEntrySaveModel): Promise<any> {
    const result = await __request({
      method: "POST",
      path: `/Diary/saveDiaryEntry`,
      body: requestBody,
    })
    return result.body
  }

  /**
   * @param userId
   * @param date
   * @returns any Success
   * @throws ApiError
   */
  public static async diaryDeleteDiaryEntry(userId?: string, date?: string): Promise<any> {
    const result = await __request({
      method: "DELETE",
      path: `/Diary/deleteDiaryEntry`,
      query: {
        userId: userId,
        date: date,
      },
    })
    return result.body
  }

  /**
   * @param clientUserId
   * @param from
   * @param to
   * @param pageSize
   * @param page
   * @param searchString
   * @returns DiaryEntryListModelPagedResponseModel Success
   * @throws ApiError
   */
  public static async diaryGetDiaryEntries(
    clientUserId?: string,
    from?: string,
    to?: string,
    pageSize?: number,
    page?: number,
    searchString?: string
  ): Promise<DiaryEntryListModelPagedResponseModel> {
    const result = await __request({
      method: "GET",
      path: `/Diary/getDiaryEntries`,
      query: {
        clientUserId: clientUserId,
        from: from,
        to: to,
        pageSize: pageSize,
        page: page,
        searchString: searchString,
      },
    })
    return result.body
  }

  /**
   * Returns single diary entry which has all the details.
   * @param userId GUID of client
   * @param date Date should be in format '2020-12-30'
   * @returns DiaryEntryModel Success
   * @throws ApiError
   */
  public static async diaryGetDiaryEntry(userId: string, date: string): Promise<DiaryEntryModel> {
    const result = await __request({
      method: "GET",
      path: `/Diary/getDiaryEntry`,
      query: {
        userId: userId,
        date: date,
      },
    })
    return result.body
  }
}
