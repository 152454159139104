import { DiaryEntrySaveModel, DiaryService } from "services/openapi"

export const saveDiaryEntry = async (
  newDiaryEntry: DiaryEntrySaveModel,
  onError: (error: any, message: string) => void
) => {
  try {
    await DiaryService.diarySaveDiaryEntry(newDiaryEntry)
  } catch (error) {
    onError(error, "youth-view.diary-entry.snackbar.save-error")
  }
}

export const getDiaryEntry = async (userId: string, date: string, onError: (error: any, message: string) => void) => {
  try {
    const diaryResponse = await DiaryService.diaryGetDiaryEntry(userId, date)
    return diaryResponse
  } catch (error) {
    onError(error, "youth-view.diary-entry.snackbar.fetch-error")
  }
}
