import { userContext } from "contexts/userContext"
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { ActivityHelperModel } from "services/openapi"
import { ActivityModel } from "services/openapi/models/ActivityModel"
import { FeelingModel } from "services/openapi/models/FeelingModel"
import { FeelingTargetModel } from "services/openapi/models/FeelingTargetModel"
import { ActivityService } from "services/openapi/services/ActivityService"
import { FeelingService } from "services/openapi/services/FeelingService"
import ClientGroupId from "utils/clientGroupId"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"

export type ClientGroupDataState = {
  isLoading: boolean
  feelingOptions: FeelingModel[]
  feelingTargetOptions: FeelingTargetModel[]
  activityOptions: ActivityModel[]
  helperOptions: ActivityHelperModel[]
  ErrorSnackbar: () => JSX.Element | null
}

const initialState: ClientGroupDataState = {
  feelingOptions: [],
  feelingTargetOptions: [],
  activityOptions: [],
  helperOptions: [],
  isLoading: true,
  ErrorSnackbar: () => null,
}

export const ClientGroupDataContext = createContext(initialState)

export const ClientGroupDataProvider = ({ children }: any) => {
  const { user } = useContext(userContext)
  const { ErrorSnackbar, handleError } = useApiRequestError()
  const [isLoading, setLoading] = useState<boolean>(true)
  const [feelingOptions, setFeelingOptions] = useState<FeelingModel[]>([])
  const [feelingTargetOptions, setFeelingTargetOptions] = useState<FeelingTargetModel[]>([])
  const [activityOptions, setActivityOptions] = useState<ActivityModel[]>([])
  const [helperOptions, setHelperOptions] = useState<ActivityHelperModel[]>([])

  const clientGroupId = user?.clientGroupId ?? ClientGroupId.ADULTS

  const fetchFeelingOptions = useCallback(async () => {
    try {
      return await FeelingService.feelingGetFeelingsForClientGroup(clientGroupId)
    } catch (error) {
      handleError(error, "youth-view.feeling-selection.fetch_options_error")
    }
  }, [handleError, clientGroupId])

  const fetchFeelingTargetOptions = useCallback(async () => {
    try {
      return await FeelingService.feelingGetFeelingTargetsForClientGroup(clientGroupId)
    } catch (error) {
      handleError(error, "youth-view.feeling-selection.fetch_targets_error")
    }
  }, [handleError, clientGroupId])

  const fetchActivityOptions = useCallback(async () => {
    try {
      return await ActivityService.activityGetActivitiesForClientGroup(clientGroupId)
    } catch (error) {
      handleError(error, "youth-view.feeling-selection.fetch_targets_error")
    }
  }, [handleError, clientGroupId])

  const fetchActivityHelperOptions = useCallback(async () => {
    try {
      return await ActivityService.activityGetActivityHelpersForClientGroup(clientGroupId)
    } catch (error) {
      handleError(error, "elderly-view.helper-selection.fetch-error")
    }
  }, [handleError, clientGroupId])

  useEffect(
    function fetchClientGroupDataOnUserChange() {
      setLoading(true)
      const fetchData = async () => {
        const results = await Promise.all([
          fetchFeelingOptions(),
          fetchFeelingTargetOptions(),
          fetchActivityOptions(),
          fetchActivityHelperOptions(),
        ])
        setFeelingOptions(results[0] ?? [])
        setFeelingTargetOptions(results[1] ?? [])
        setActivityOptions(results[2] ?? [])
        setHelperOptions(results[3] ?? [])

        setLoading(false)
      }
      fetchData()
    },
    [fetchFeelingOptions, fetchFeelingTargetOptions, fetchActivityOptions, fetchActivityHelperOptions]
  )

  const contextValues = useMemo(
    function getUpdatedState() {
      return {
        feelingOptions: feelingOptions,
        feelingTargetOptions: feelingTargetOptions,
        activityOptions: activityOptions,
        helperOptions: helperOptions,
        isLoading: isLoading,
        ErrorSnackbar,
      }
    },
    [activityOptions, feelingTargetOptions, feelingOptions, helperOptions, isLoading, ErrorSnackbar]
  )

  return <ClientGroupDataContext.Provider value={contextValues}>{children}</ClientGroupDataContext.Provider>
}
