import { Box } from "@mui/material"

const FeelingTargetCardsBackground = ({ children }: { children: any }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-evenly",
        flexWrap: "wrap",
        rowGap: 3,
        overflowWrap: "break-word",
        mb: 2,
        mt: 4,
      }}
    >
      {children}
    </Box>
  )
}

export default FeelingTargetCardsBackground
