import { styled, Typography, TypographyProps } from "@mui/material"

const AppName = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontFamily: "SofiaProBold",
  fontSize: "25px",
  margin: "0 auto",
  padding: 10,
  color: theme.palette.secondary.light,
  marginBottom: "300px",
  marginTop: "20px",
  flexGrow: 2,
}))

export default AppName
