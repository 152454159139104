import React, { useCallback, useState } from "react"
import {
  Box,
  Button,
  InputAdornment,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { MoreVert, Search } from "@mui/icons-material"

import { UserListModel } from "services/openapi"
import TablePaginationActions from "utils/TablePaginationActions"
import "./UserTable.sass"
import LoadingIndicatorRow from "components/LoadingIndicatorRow"
import { NavLink } from "react-router-dom"

type Props = {
  isLoading: boolean
  users: UserListModel[]
  menuItems: ((user: UserListModel, handleClose: () => void) => React.ReactNode) | undefined
  headerButton?: React.ReactNode
  header?: string
  roleRowContent: undefined | ((user: any) => React.ReactNode)
}

const UserTable = (props: Props) => {
  const { users, menuItems, headerButton, header, isLoading } = props
  const { t } = useTranslation()

  const [searchTerm, setSearchTerm] = useState("")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [anchorEl, setAnchorEl] = useState(null)
  const [userMenu, setUserMenu] = useState<UserListModel | null>(null)

  const handleClick = useCallback(
    (event: any, user: UserListModel) => {
      setAnchorEl(event.currentTarget)
      setUserMenu(user)
    },
    [setAnchorEl, setUserMenu]
  )

  const handleClose = () => {
    setAnchorEl(null)
    setUserMenu(null)
  }

  const filteredUsers =
    users?.filter((user) =>
      `${user.firstName} ${user.lastName}`.toLocaleUpperCase().includes(searchTerm.toLocaleUpperCase())
    ) || []

  const slicedUsers = filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <Typography variant="h1">{header ?? t("user-list.header")}</Typography>

        {headerButton}
      </Box>

      <TextField
        id="user-search"
        label={t("user-list.search")}
        value={searchTerm}
        onChange={(event) => {
          setSearchTerm(event.target.value)
          setPage(0)
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search role="img" aria-labelledby="user-search" />
            </InputAdornment>
          ),
        }}
        sx={{ width: "100%" }}
      />

      <Table id="user-table" aria-label={t("user-list.table.aria-label")}>
        <TableHead>
          <TableRow>
            <TableCell>{t("user-list.table.header.first-name")}</TableCell>
            <TableCell>{t("user-list.table.header.last-name")}</TableCell>
            <TableCell>{t("user-list.table.header.principal-name")}</TableCell>
            <TableCell>{t("user-list.table.header.role")}</TableCell>
            {menuItems && <TableCell align="right">{t("user-list.table.header.controls")}</TableCell>}
          </TableRow>
        </TableHead>

        <TableBody>
          {isLoading ? (
            <LoadingIndicatorRow colSpan={4} />
          ) : (
            <>
              {slicedUsers.map((user, i) => {
                return (
                  <TableRow key={`user-${i}`}>
                    <TableCell>{user.firstName}</TableCell>
                    <TableCell>{user.lastName}</TableCell>
                    <TableCell>
                      <NavLink to={`/admin/user/${user.role}/${user.id}`}>{user.principalName}</NavLink>
                    </TableCell>
                    <TableCell>
                      {props.roleRowContent ? props.roleRowContent(user) : t(`user-list.user-role.${user.role}`)}
                    </TableCell>

                    {menuItems && (
                      <TableCell align="right">
                        <Button
                          onClick={(e) => handleClick(e, user)}
                          id={`user-delete-button-${i}`}
                          aria-label={t("user-list.table.aria-controls", {
                            name: `${user.firstName} ${user.lastName}`,
                          })}
                        >
                          <MoreVert aria-hidden />
                        </Button>
                        {/* 
                    Check open status here instead of in the menu open property to avoid rendering extra menus unnecessarily.
                    */}
                        {user === userMenu && (
                          <Menu
                            className="user-menu"
                            open
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": `user-button-${i}`,
                            }}
                          >
                            {menuItems(user, handleClose)}
                          </Menu>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                )
              })}
            </>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[10, 25]}
              onRowsPerPageChange={(e: any) => {
                setRowsPerPage(e.target.value)
                setPage(0)
              }}
              page={page}
              count={filteredUsers.length || 0}
              onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage={t("table-pagination.rows-per-page")}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </>
  )
}

export default UserTable
