import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

type Props = {
  closeDialog: Function
  onOk: () => Promise<boolean>
  open: boolean
  header?: string
  message?: string
}

const ConfirmationDialog = (props: Props) => {
  const { closeDialog, onOk, open, header, message } = props
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)

  useEffect(function fixUnload() {
    return () => {
      setLoading(true)
    }
  }, [])

  const handleCancel = () => {
    closeDialog()
  }

  const handleOk = () => {
    setLoading(true)
    onOk().then(() => {
      closeDialog()
      setLoading(false)
    })
  }

  return (
    <Dialog open={open} onBackdropClick={handleCancel}>
      <DialogTitle>{header}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={handleCancel}>
          {t("confirmation-dialog.cancel")}
        </Button>
        <Button disabled={isLoading} onClick={handleOk}>
          {t("confirmation-dialog.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
