import { Button, ButtonProps, styled } from "@mui/material"

const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "secondary.main",
  backgroundColor: "primary.main",
  borderRadius: "4px",
  textTransform: "none",
}))

export default StyledButton
