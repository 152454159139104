import { Box, Card, CardContent, CircularProgress, Typography, useTheme } from "@mui/material"
import { DiaryContent } from "components/DiaryCard/DiaryContent"
import FeelingDoughnut from "components/FeelingDoughnut"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import ActivitySectionCard from "scenes/CareOrganizationUserClientDetails/ActivitySectionCard"
import { getDiaryEntry } from "scenes/CareOrganizationUserClientDetails/apiRequests"
import FeelingBox from "scenes/CareOrganizationUserClientDetails/FeelingBox"
import WideFab from "scenes/FeelingsSelection/styled/WideFab"
import {
  ActivityHelperModel,
  ActivityModel,
  FeelingModel,
  FeelingTargetModel,
  UserActivityModel,
  UserFeelingModel,
  UserRoleModel,
} from "services/openapi"
import { getActivitiesForDate } from "utils/apiRequests/fetchActivities"
import { getFeelingsForDate } from "utils/apiRequests/fetchFeelings"
import ClientGroupId from "utils/clientGroupId"
import { matchFeelingIdWithColor } from "utils/functions/matchFeelingIdWithColor"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import DiaryEdit from "components/DiaryEdit"
import { userContext } from "contexts/userContext"
import DiaryCard from "components/DiaryCard/DiaryCard"

type MobileHistoryDaySummaryProps = {
  clientId: string
  date: string
  onClose: () => void
  activityOptions: ActivityModel[]
  feelingOptions: FeelingModel[]
  feelingTargetOptions: FeelingTargetModel[]
  activityHelperOptions: ActivityHelperModel[]
  clientGroupId: number
}

const MobileHistoryDaySummary = ({
  clientId,
  date,
  onClose,
  activityOptions,
  feelingOptions,
  feelingTargetOptions,
  activityHelperOptions,
  clientGroupId,
}: MobileHistoryDaySummaryProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { handleError } = useApiRequestError()

  const currentUserContext = useContext(userContext)
  const [isLoadingDiary, setLoadingDiary] = useState<boolean>(true)
  const [isLoadingActivities, setLoadingActivities] = useState<boolean>(true)
  const [isLoadingFeelings, setLoadingFeelings] = useState<boolean>(true)
  const [feelings, setFeelings] = useState<UserFeelingModel>({
    date: "",
    feelings: [],
    userId: clientId,
  })
  const [diaryEntry, setDiaryEntry] = useState<DiaryContent>({
    message: "",
    pick1: "",
    pick2: "",
    pick3: "",
  })
  const [activities, setActivities] = useState<UserActivityModel>({
    activities: [],
    activityHelperIds: [],
    date: "",
    userId: "",
    wouldHaveNeededHelp: false,
  })

  const canEditDiary = currentUserContext.user?.role === UserRoleModel.CLIENT

  useEffect(
    function fetchData() {
      getDiaryEntry(clientId, date, setLoadingDiary, setDiaryEntry, handleError)
      getActivitiesForDate(clientId, date, setLoadingActivities, setActivities, handleError)
      getFeelingsForDate(clientId, date, setLoadingFeelings, setFeelings, handleError)
    },
    [clientId, date, handleError]
  )

  const colors = feelings.feelings.map((feeling) => matchFeelingIdWithColor(feeling.feelingId, theme)!)
  const data = feelings.feelings.map((feeling) => feeling.strength || 1)
  const hasDefinedStrength = !!feelings.feelings.find((feeling) => feeling.strength)
  const diaryDefined = !!diaryEntry.message

  return (
    <Box>
      <WideFab color={theme.palette.secondary.dark} onClick={onClose}>
        {t("customer-mobile-view.feeling-history-day.close")}
      </WideFab>
      {isLoadingDiary || isLoadingActivities || isLoadingFeelings ? (
        <CircularProgress size={100} />
      ) : (
        <Box
          sx={{
            pb: 10,
          }}
        >
          <Card
            sx={{
              mx: 2,
            }}
          >
            <CardContent>
              {feelings.feelings.length === 0 ? (
                <Typography variant="body1">{t("customer-mobile-view.feeling-history.no-feelings")}</Typography>
              ) : (
                <Box>
                  <FeelingDoughnut chartColors={colors} chartData={data} showPercentaces={true} />
                  <FeelingBox
                    userFeelings={feelings.feelings}
                    hasRating={hasDefinedStrength}
                    feelingOptions={feelingOptions}
                    feelingTargetOptions={feelingTargetOptions}
                  />
                </Box>
              )}
            </CardContent>
          </Card>

          {canEditDiary ? (
            <>{diaryDefined ? <DiaryEdit selectedDate={date} userId={clientId} /> : <></>}</>
          ) : (
            <>
              {diaryDefined ? (
                <Card
                  sx={{
                    mx: 2,
                    mt: 2,
                  }}
                >
                  <CardContent>
                    <DiaryCard diaryContent={diaryEntry} selectedDate={date} />
                  </CardContent>
                </Card>
              ) : (
                <></>
              )}
            </>
          )}

          {clientGroupId === ClientGroupId.ELDERLY ? (
            <ActivitySectionCard
              activityOptions={activityOptions}
              activityHelperOptions={activityHelperOptions}
              userActivityDay={activities}
            />
          ) : (
            <></>
          )}
        </Box>
      )}
    </Box>
  )
}

export default MobileHistoryDaySummary
