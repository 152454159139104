export const toDateFormat = (date: Date) => {
  return date.toISOString().split("T")[0]
}

export const toFinnishFormat = (isoDate: string) => {
  const split = isoDate.split("-")
  return `${split[2]}.${split[1]}.${split[0]}`
}

export const daysBetween = (date1: Date, date2: Date): number => {
  // Get the time in milliseconds for both dates
  const time1 = date1.getTime();
  const time2 = date2.getTime();

  // Calculate the difference in milliseconds
  const differenceInMs = Math.abs(time2 - time1);

  // Convert milliseconds to days (1000 ms in a second, 60 seconds in a minute, 60 minutes in an hour, 24 hours in a day)
  const msPerDay = 1000 * 60 * 60 * 24;
  return Math.floor(differenceInMs / msPerDay);
}
