import { useState } from "react"

import ConfirmationDialog from "utils/ConfirmationDialog"

const useConfirmationDialog = () => {
  const defaultAction = () => {
    return Promise.resolve(true)
  }

  const [open, setOpen] = useState(false)
  const [submit, setSubmit] = useState<() => Promise<boolean>>(defaultAction)
  const [message, setMessage] = useState("")
  const [header, setHeader] = useState("")

  const showConfirmationDialog = (onSubmit: () => Promise<boolean>, headerText?: string, messageText?: string) => {
    headerText && setHeader(headerText)
    messageText && setMessage(messageText)
    setSubmit(() => onSubmit)
    setOpen(true)
  }

  const Dialog = () => {
    return (
      <ConfirmationDialog
        open={open}
        header={header}
        message={message}
        onOk={() => submit()}
        closeDialog={() => {
          setMessage("")
          setHeader("")
          setSubmit(defaultAction)
          setOpen(false)
        }}
      />
    )
  }

  return { Dialog, showConfirmationDialog }
}

export default useConfirmationDialog
