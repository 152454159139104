import { Box, Button } from "@mui/material"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { toDateFormat } from "utils/functions/toDateFormat"
import CalendarDialog from "components/CalendarDialog"
import { userContext } from "contexts/userContext"
import ActivityDisplay from "./ActivityDisplay"

const ActivityHistory = () => {
  const [open, setOpen] = useState(false)
  const initialDate: string = toDateFormat(new Date())
  const [selectedDate, setSelectedDate] = useState(initialDate)
  const { user } = useContext(userContext)
  const { t } = useTranslation()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (value: string) => {
    setOpen(false)
    setSelectedDate(value)
  }

  return (
    <Box
      sx={{
        marginX: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Button
        variant="contained"
        onClick={handleClickOpen}
        sx={{
          mx: 2,
        }}
      >
        {t("customer-mobile-view.feeling-history.date-picker")}
      </Button>

      <CalendarDialog selectedDate={selectedDate} open={open} onClose={handleClose} />

      {user?.id ? <ActivityDisplay selectedDate={selectedDate} userId={user.id} /> : <></>}
    </Box>
  )
}

export default ActivityHistory
