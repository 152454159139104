import BottomNavigationBar from "components/BottomNavigationBar"
import MobileViewBase from "components/MobileViewBase"
import MobileViewDescription from "components/MobileViewBase/MobileViewDescription"
import { useTranslation } from "react-i18next"
import DidGetHelpOptions from "./DidGetHelpOptions"

const DidGetHelpView = () => {
  const { t } = useTranslation()
  return (
    <>
      <MobileViewBase
        top={<MobileViewDescription header={t("did-get-help-view.header")} text={""} />}
        middle={<DidGetHelpOptions />}
        bottom={<BottomNavigationBar value={2} navLength={5} back={"/client/activity"} to={"/client/feelings"} />}
      />
    </>
  )
}

export default DidGetHelpView
