import { Typography } from "@mui/material"
import { styled, TypographyProps } from "@mui/system"

const CardName = styled(Typography)<TypographyProps>(() => ({
  textAlign: "center",
  fontFamily: "SofiaProBold",
  wordBreak: "break-word",
  fontSize: "0.92rem"
}))

export default CardName
