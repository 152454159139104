import {
  AccountInfo,
  InteractionRequiredAuthError,
  IPublicClientApplication,
  PublicClientApplication,
} from "@azure/msal-browser"

import { loginRequest, msalConfig, protectedResources } from "authConfig"
import { ConfigService } from "services/config"
import { ProfileModel, UserListModel, UserRoleModel } from "services/openapi"

/**
 * Calculate if current page is the last one when doing pagination
 * @param total total number of items
 * @param page current page, starting from 0
 * @param pageSize number of items on page
 * @returns true if on the last page, false otherwise
 */
export const isLastPage = (total = 0, page = 0, pageSize = 0) => {
  const currentLast = (page + 1) * pageSize
  return currentLast < total ? false : true
}

/**
 *
 * @param currentUser Currently logged in user
 * @param targetUser User that's about to be deleted
 * @returns true if user can be deleted, otherwise false
 */
export const canDeactivateUser = (currentUser: ProfileModel | null, targetUser?: UserListModel) => {
  if (!targetUser?.role || targetUser?.id === currentUser?.id) return false

  switch (currentUser?.role) {
    case UserRoleModel.SUPER_ADMIN:
      if ([UserRoleModel.SUPER_ADMIN, UserRoleModel.MANAGEMENT_ORGANIZATION_ADMIN_USER].includes(targetUser.role)) {
        return true
      }
      return false

    case UserRoleModel.MANAGEMENT_ORGANIZATION_ADMIN_USER:
      if ([UserRoleModel.CARE_ORGANIZATION_USER].includes(targetUser.role)) {
        return true
      }
      return false

    case UserRoleModel.CARE_ORGANIZATION_USER:
      if (currentUser.canManageOrganizationUsers) {
        return true
      }
      if (targetUser.role === UserRoleModel.CLIENT) {
        return currentUser.canManageFamilyUnits
      }
      return false

    default:
      return false
  }
}

/***
 * Return current auth token
 */
export const getToken = () => {
  return ConfigService.getConfig()
    .then((config) => {
      const pca = new PublicClientApplication(msalConfig(config))
      const account = pca.getActiveAccount() || undefined
      return pca.acquireTokenSilent({ scopes: protectedResources(config).scopes, account }).then((x) => {
        return x.accessToken
      })
    })
    .catch((x) => {
      return ""
    })
}

// Maybe this could be a hook?
/**
 * Handle error when calling the api
 * @param error Error from api
 * @param instance msal instance
 * @param showSnackbar Function that displays the error message
 * @param t Translation function
 * @param message Optional custom error message
 */
export const handleApiRequestError = (
  error: any,
  instance: IPublicClientApplication,
  showSnackbar?: (message: string, type: "error" | "warning" | "info" | "success") => void,
  t?: (key: string) => string,
  message?: string
) => {
  // Handle errors with msal instance
  if (error instanceof InteractionRequiredAuthError) {
    instance.acquireTokenRedirect({
      ...loginRequest,
      account: instance.getActiveAccount() as AccountInfo,
    })
    // Handle other errors such as bad requests and internal server errors by displaying snackbar
  } else if (showSnackbar && t) {
    // If a custom error message is provided, append that to the end of normal error message
    const customMessage = message ? ` - ${message}` : ""

    switch (error.status) {
      case 400:
        showSnackbar(`${t("error-message.bad-request")}: ${customMessage}`, "error")
        break

      case 401:
        showSnackbar(`${t("error-message.unauthorized")}: ${customMessage}`, "error")
        break

      case 500:
        showSnackbar(`${t("error-message.internal-server-error")}: ${customMessage}`, "error")
        break

      default:
        showSnackbar(`${t("error-message.unknown")}: ${customMessage}`, "error")
        break
    }
  } else {
    console.error("An error occurred: ", error)
  }
}
