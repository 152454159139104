import { Box, Card, CardContent, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import ActivityDetails from "scenes/ElderlyDailySummary/ActivityDetails"
import { useTranslation } from "react-i18next"
import { getActivitiesForDate } from "utils/apiRequests/fetchActivities"
import { toFinnishFormat } from "utils/functions/toDateFormat"
import { UserActivityModel } from "services/openapi"

type ActivityDisplayProps = {
  selectedDate: string
  userId: string
}

const ActivityDisplay = ({ selectedDate, userId }: ActivityDisplayProps) => {
  const { t } = useTranslation()
  const { handleError } = useApiRequestError()
  const [activities, setActivities] = useState<UserActivityModel>({
    activities: [],
    activityHelperIds: [],
    date: selectedDate,
    userId: userId,
    wouldHaveNeededHelp: false,
  })

  useEffect(() => {
    function fetchData() {
      getActivitiesForDate(userId, selectedDate, () => {}, setActivities, handleError)
    }

    fetchData()
  }, [userId, handleError, selectedDate])

  return (
    <Card
      elevation={4}
      sx={{
        borderRadius: 3,
        mb: 2,
        mx: 2,
        backgroundColor: "primary.main",
        mt: 1,
      }}
    >
      {activities.activities.length ? (
        <CardContent>
          <Box sx={{ textAlign: "center", border: 1, p: 1, borderRadius: 1 }}>
            <Typography sx={{ fontWeight: "bold", p: 1 }}>
              {t("hamburger-menu.daily-activities")} {toFinnishFormat(selectedDate)}
            </Typography>
          </Box>
          <Box sx={{ pt: 2 }}>
            <ActivityDetails userActivities={activities} />
          </Box>
        </CardContent>
      ) : (
        <CardContent>
          <Box sx={{ mx: 7, my: 7 }}>
            {t("no-history.no-activities")} {toFinnishFormat(selectedDate)}
          </Box>
        </CardContent>
      )}
    </Card>
  )
}

export default ActivityDisplay
