import { Box, Grid, Typography } from "@mui/material"
import useMountEffect from "utils/hooks/useMountEffect"
import { getFeelingOptionsForClientGroup, getFeelingTargetsForClientGroup } from "utils/apiRequests/fetchFeelings"
import { FeelingModel, GroupedFeelings, FeelingTargetModel, UserFeelingModel } from "services/openapi"
import { useState } from "react"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import { useTranslation } from "react-i18next"
import React from "react"

type FeelingListProps = {
  feelings: GroupedFeelings[],
  targets?: UserFeelingModel[]
}

type OutputObject = {
  [key: number]: number[];
};

function aggregateFeelings(input: UserFeelingModel[]): OutputObject {
  const result: OutputObject = {};

  input.forEach(entry => {
    entry.feelings.forEach(feeling => {
      const { feelingId, feelingTargetIds } = feeling;
      if (!result[feelingId]) {
        result[feelingId] = [];
      }
      // Combine arrays and remove duplicates
      result[feelingId] = Array.from(new Set([...result[feelingId], ...feelingTargetIds]));
    });
  });

  return result;
}

const FeelingList = ({ feelings, targets }: FeelingListProps) => {
  const [feelingOptions, setFeelingOptions] = useState<FeelingModel[]>([])
  const [feelingTargets, setFeelingTargets] = useState<FeelingTargetModel[]>([])
  const [isLoading, setLoading] = useState<boolean>(true)
  const { handleError } = useApiRequestError()
  const { t } = useTranslation()

  /*
  var total = 0
  for (var i in feelings) {
    total += feelings[i].sum > 0 ? feelings[i].sum : feelings[i].total
  }
  */

  useMountEffect(async function fetchData() {
    await getFeelingOptionsForClientGroup(undefined, setLoading, setFeelingOptions, handleError)
    await getFeelingTargetsForClientGroup(undefined, setLoading, setFeelingTargets, handleError)
  })

  const userFeelingTargets = targets ? aggregateFeelings(targets) : [];

  const feelingList = feelings?.map((feeling) => {
    let feelingDetails
    //const amount = total === 0 ? "" : `${(((feeling.sum > 0 ? feeling.sum : feeling.total) / total) * 100).toFixed(0)}%`

    if (feelingOptions !== undefined) {
      feelingDetails = feelingOptions.find((x) => x.id === feeling.feelingId)
    }

    const userFeelingTargetDescription = userFeelingTargets[feeling.feelingId]?.map((targetId) => {
      const feelingTargetDetails = feelingTargets.find((x) => x.id === targetId)
      return t(`youth-view.feeling-selection.feeling-target-options.${feelingTargetDetails?.name}`)
    })

    const feelingElement: JSX.Element = (
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
        <img
          src={`${process.env.PUBLIC_URL}/feelingIcons/${feelingDetails?.imageLocation}`}
          width={20}
          alt={`${feelingDetails?.imageLocation}`}
          aria-hidden="true"
        />
        <Typography variant={"subtitle2"} sx={{ pl: 1 }}>
          {t(`youth-view.feeling-selection.feeling-options.${feelingDetails?.name}`)}
        </Typography>
      </Box>)

    if (targets) {
      return (
        <React.Fragment key={feeling.feelingId.toString()}>
          <Grid key={feeling.feelingId.toString() + "-feeling"} item xs={4} md={2}>
            {feelingElement}
          </Grid>
          <Grid key={feeling.feelingId.toString() + "-target"} item xs={8} md={10}>
            {userFeelingTargetDescription?.length > 0 && (
              <Box>
                <Typography variant={"subtitle2"}>
                  {"("}{userFeelingTargetDescription.join(",")}{")"}
                </Typography>
              </Box>
            )}
          </Grid>
        </React.Fragment>
      )
    }

    return (
      <Box key={feeling.feelingId.toString()} sx={{ width: "95vw" }}>
        {feelingElement}
      </Box>
    )
  })

  if (targets) {
    return (
      <Grid container spacing={1}>
        {isLoading ? <></> : feelingList}
      </Grid>
    )
  }

  return (
    <Box sx={{ mt: 0, display: "flex", justifyContent: "flex-start", flexWrap: "wrap" }}>
      {isLoading ? <></> : feelingList}
    </Box>
  )
}

export default FeelingList
