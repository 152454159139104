import { Box } from "@mui/material"
import { Form, Formik } from "formik"
import { useTranslation } from "react-i18next"
import { DiaryEntrySaveModel } from "services/openapi/models/DiaryEntrySaveModel"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import * as Yup from "yup"
import { saveDiaryEntry } from "../../scenes/DiaryEntry/apiRequests"
import { Action, DiaryState } from "../../scenes/DiaryEntry/DiaryStateReducer"
import GoodThingsTextField from "./styled/GoodThingsTextField"

type GoodThingsState = {
  first: string | null | undefined
  second: string | null | undefined
  third: string | null | undefined
}

type Props = {
  userId: string
  state: DiaryState
  dispatch: React.Dispatch<Action>
}

const GoodThingsForm = ({ userId, state, dispatch }: Props) => {
  const { t } = useTranslation()
  const { ErrorSnackbar, handleError } = useApiRequestError()

  const onAnyBlur = async ({ first, second, third }: GoodThingsState) => {
    const newGoodThings: DiaryEntrySaveModel = {
      userId: userId,
      message: state.diary.message,
      date: state.diary.date,
      isPublic: state.diary.isPublic,
      pick1: first,
      pick2: second,
      pick3: third,
    }

    if (state.diary.pick1 !== first || state.diary.pick2 !== second || state.diary.pick3 !== third) {
      dispatch({ type: "updateDiaryGoodThings", diary: newGoodThings })
      await saveDiaryEntry(newGoodThings, handleError)
    }
  }

  return (
    <Box sx={{ mb: 4, mx: "auto" }}>
      <Formik
        enableReinitialize
        initialValues={{
          firstThing: state.diary.pick1 ?? "",
          secondThing: state.diary.pick2 ?? "",
          thirdThing: state.diary.pick3 ?? "",
        }}
        validationSchema={Yup.object({
          firstThing: Yup.string()
            .trim()
            .max(50, t("youth-view.good-things.validation.length", { length: 50 })),
          secondThing: Yup.string()
            .trim()
            .max(50, t("youth-view.good-things.validation.length", { length: 50 })),
          thirdThing: Yup.string()
            .trim()
            .max(50, t("youth-view.good-things.validation.length", { length: 50 })),
        })}
        onSubmit={(values) => {
          onAnyBlur({ first: values.firstThing, second: values.secondThing, third: values.thirdThing })
        }}
      >
        {(formik) => (
          <Form>
            <Box sx={{ display: "flex", flexDirection: "column", rowGap: 3 }}>
              <GoodThingsTextField formik={formik} id={"firstThing"} />
              <GoodThingsTextField formik={formik} id={"secondThing"} />
              <GoodThingsTextField formik={formik} id={"thirdThing"} />
            </Box>
          </Form>
        )}
      </Formik>
      <ErrorSnackbar />
    </Box>
  )
}

export default GoodThingsForm
