import { Box, Button } from "@mui/material"
import Eng from "images/Eng.png"
import Fin from "images/Fin.png"
import Swe from "images/Swe.png"
import Deu from "images/Deu.png"
import i18n from "i18next"
import { useTranslation } from "react-i18next"

export const LanguageButtons = () => {
  const { t } = useTranslation()

  const handleLanguage = (e: any) => {
    localStorage.setItem("language", e.target.value)
    i18n.changeLanguage(e.target.value)
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        width: "100%",
        padding: "15px 0px",
        paddingRight: "20px",
      }}
    >
      <Button
        aria-label={t("aria-labels.language-eng")}
        onClick={handleLanguage}
        value="en"
        sx={{
          backgroundImage: `url(${Eng})`,
          backgroundRepeat: "no-repeat",
          minWidth: "40px",
          height: "30px",
        }}
      ></Button>
      <Button
        aria-label={t("aria-labels.language-fin")}
        onClick={handleLanguage}
        value="fi"
        sx={{
          backgroundImage: `url(${Fin})`,
          backgroundRepeat: "no-repeat",
          minWidth: "40px",
          height: "30px",
        }}
      ></Button>
      <Button
        aria-label={t("aria-labels.language-swe")}
        onClick={handleLanguage}
        value="se"
        sx={{
          backgroundImage: `url(${Swe})`,
          backgroundRepeat: "no-repeat",
          minWidth: "40px",
          height: "30px",
        }}
      ></Button>
      <Button
        aria-label={t("aria-labels.language-deu")}
        onClick={handleLanguage}
        value="de"
        sx={{
          backgroundImage: `url(${Deu})`,
          backgroundRepeat: "no-repeat",
          minWidth: "40px",
          height: "30px",
        }}
      ></Button>
    </Box>
  )
}
