import { InteractionStatus } from "@azure/msal-browser"
import { useMsal } from "@azure/msal-react"
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  LinearProgress,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material"
import StyledButton from "components/StyledButton"
import UserTable from "components/UserTable"
import { Form, Formik } from "formik"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import CareOrganizationList from "scenes/admin/CareOrganizationList"
import {
  deactivateUser,
  fetchDetails,
  resetManageOrgAdminPassword,
  updateManagementOrganization,
} from "scenes/admin/ManagementOrganizationDetails/apiRequests"
import {
  FeatureStatusModel,
  ManagementOrganizationDetailsModel,
  UserCreateResultModel,
  UserListModel,
} from "services/openapi"
import useConfirmationDialog from "utils/hooks/useConfirmationDialog"
import useSnackbar from "utils/hooks/useSnackbar"
import CreateManagementUser from "./CreateManagementUser"
import * as Yup from "yup"
import PasswordResetResultDialog from "components/PasswordResetResultDialog"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"

type ManagementOrganizationEditForm = {
  organizationName: string
  description: string
  hasFeatureChildren: Array<FeatureStatusModel>
  hasFeatureAdults: Array<FeatureStatusModel>
  hasFeaturePensioners: Array<FeatureStatusModel>
}

const ManagementOrganizationDetails = () => {
  const { organizationId } = useParams<{ organizationId: string }>()
  const { instance, inProgress } = useMsal()
  const { t } = useTranslation()
  const { Snackbar, showSnackbar } = useSnackbar()
  const { ErrorSnackbar, handleError } = useApiRequestError()
  const { Dialog, showConfirmationDialog } = useConfirmationDialog()
  const [isLoadingUsers, setLoadingUsers] = useState<boolean>(true)
  const [isLoadingOrg, setLoadingOrg] = useState<boolean>(true)
  const [isSaving, setSaving] = useState<boolean>(false)

  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false)
  const [passwordResetResult, setPasswordResetResult] = useState<UserCreateResultModel>({
    databaseId: "",
    firstTimePassword: "",
    userLogin: "",
  })

  const onPasswordResetSuccess = (result: UserCreateResultModel) => {
    setPasswordResetResult(result)
    setPasswordDialogOpen(true)
  }

  const [org, setOrg] = useState<ManagementOrganizationDetailsModel>({
    id: "",
    name: "",
    description: "",
    hasFeatureChildren: FeatureStatusModel.UNKNOWN,
    hasFeatureAdults: FeatureStatusModel.UNKNOWN,
    hasFeaturePensioners: FeatureStatusModel.UNKNOWN,
  })
  const [users, setUsers] = useState<UserListModel[]>([])

  /**
   * Run detail fetching when component loads
   */
  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      fetchDetails(organizationId, setLoadingUsers, setLoadingOrg, setOrg, setUsers, instance, showSnackbar, t)
    }
  }, [inProgress, organizationId, instance, showSnackbar, t])

  const onFormSubmit = (value: ManagementOrganizationEditForm, { resetForm }: any) => {
    const organizationModel: ManagementOrganizationDetailsModel = {
      id: org.id,
      name: value.organizationName,
      description: value.description,
      hasFeatureChildren:
        value.hasFeatureChildren.length > 0 ? value.hasFeatureChildren[0] : FeatureStatusModel.DISABLED,
      hasFeatureAdults: value.hasFeatureAdults.length > 0 ? value.hasFeatureAdults[0] : FeatureStatusModel.DISABLED,
      hasFeaturePensioners:
        value.hasFeaturePensioners.length > 0 ? value.hasFeaturePensioners[0] : FeatureStatusModel.DISABLED,
    }

    updateManagementOrganization(organizationModel, instance, setSaving, showSnackbar, t).then((success) => {
      if (success) {
        fetchDetails(organizationId, setLoadingUsers, setLoadingOrg, setOrg, setUsers, instance, showSnackbar, t)
        resetForm()
      }
    })
  }

  const initialValues: ManagementOrganizationEditForm = {
    organizationName: org.name,
    description: org.description,
    hasFeatureChildren: org.hasFeatureChildren === FeatureStatusModel.ENABLED ? [FeatureStatusModel.ENABLED] : [],
    hasFeatureAdults: org.hasFeatureAdults === FeatureStatusModel.ENABLED ? [FeatureStatusModel.ENABLED] : [],
    hasFeaturePensioners: org.hasFeaturePensioners === FeatureStatusModel.ENABLED ? [FeatureStatusModel.ENABLED] : [],
  }

  const isChecked = (value: FeatureStatusModel[]) => {
    return value && value.length > 0 && value[0] === FeatureStatusModel.ENABLED
  }

  const UserMenuItems = (user: UserListModel, handleClose: () => void) => {
    return [
      <MenuItem
        key={`user-${user.id}-deactive`}
        onClick={() => {
          showConfirmationDialog(
            () => deactivateUser(user.id, users, setUsers, showSnackbar, t, handleError),
            t("user-list.deactivate.header"),
            t("user-list.deactivate.message", { name: `${user.firstName} ${user.lastName}` })
          )
          handleClose()
        }}
      >
        {t("user-list.table.delete-user")}
      </MenuItem>,
      <MenuItem
        key={`user-${user.id}-reset`}
        onClick={() => {
          showConfirmationDialog(
            () => resetManageOrgAdminPassword(user.id, onPasswordResetSuccess, handleError),
            t("user-list.reset-password.header"),
            t("user-list.reset-password.message", { name: `${user.firstName} ${user.lastName}` })
          )
          handleClose()
        }}
      >
        {t("user-list.table.reset-password")}
      </MenuItem>,
    ]
  }

  return !org ? null : (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%", overflow: "auto" }} id="org-details">
      <PasswordResetResultDialog
        open={passwordDialogOpen}
        onClose={() => setPasswordDialogOpen(false)}
        createResult={passwordResetResult}
      />

      <Paper sx={{ display: "flex", flexDirection: "column", width: "100%", padding: 4, mb: 4 }} id="org-basic-info">
        {isLoadingOrg ? (
          <LinearProgress />
        ) : (
          <>
            <Typography variant="h1">{initialValues.organizationName}</Typography>

            <Formik
              initialValues={initialValues}
              validationSchema={Yup.object({
                organizationName: Yup.string()
                  .trim()
                  .max(50, t("form-validation.too-long", { length: 500 }))
                  .required(t("form-validation.required")),
                description: Yup.string()
                  .trim()
                  .max(50, t("form-validation.too-long", { length: 2000 }))
                  .required(t("form-validation.required")),
              })}
              onSubmit={onFormSubmit}
              sx={{ alignItems: "stretch" }}
            >
              {(formik) => (
                <Form>
                  <TextField
                    id="organizationName"
                    label={t("management-organization-details.name")}
                    sx={{ my: 2, width: "100%" }}
                    variant="standard"
                    color="primary"
                    disabled={isSaving}
                    {...formik.getFieldProps("organizationName")}
                    helperText={formik.touched.organizationName && formik.errors.organizationName}
                  />

                  <TextField
                    id="description"
                    label={t("management-organization-details.description")}
                    sx={{ my: 2, width: "100%" }}
                    variant="standard"
                    color="primary"
                    disabled={isSaving}
                    {...formik.getFieldProps("description")}
                    helperText={formik.touched.description && formik.errors.description}
                  />

                  <FormControl sx={{ display: "block" }}>
                    <FormGroup>
                      <FormLabel component="legend" sx={{ display: "flex" }}>
                        {t("create-care-organization.features")}
                      </FormLabel>
                      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="hasFeatureChildren"
                              data-testid="organization-children-checkbox"
                              {...formik.getFieldProps("hasFeatureChildren")}
                              disabled={isSaving}
                              value={FeatureStatusModel.ENABLED}
                              checked={isChecked(formik.values.hasFeatureChildren)}
                            />
                          }
                          label={t("create-care-organization.children")}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="hasFeatureAdults"
                              data-testid="organization-adults-checkbox"
                              disabled={isSaving}
                              {...formik.getFieldProps("hasFeatureAdults")}
                              value={FeatureStatusModel.ENABLED}
                              checked={isChecked(formik.values.hasFeatureAdults)}
                            />
                          }
                          label={t("create-care-organization.adults")}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="hasFeaturePensioners"
                              data-testid="organization-pensioners-checkbox"
                              disabled={isSaving}
                              {...formik.getFieldProps("hasFeaturePensioners")}
                              value={FeatureStatusModel.ENABLED}
                              checked={isChecked(formik.values.hasFeaturePensioners)}
                            />
                          }
                          label={t("create-care-organization.pensioners")}
                        />
                      </Box>
                    </FormGroup>
                  </FormControl>

                  <StyledButton
                    sx={{ width: "fit-content", alignSelf: "end" }}
                    variant="contained"
                    id="save-button"
                    disabled={!formik.dirty || !formik.isValid || isSaving}
                    type="submit"
                  >
                    {t("management-organization-details.save-changes")}
                  </StyledButton>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Paper>

      <Box>
        <CareOrganizationList managementOrgId={organizationId} />
      </Box>

      <Paper sx={{ padding: 4, mt: 4 }} id="org-users">
        <UserTable
          isLoading={isLoadingUsers}
          header={t("user-list.management-header")}
          users={users}
          menuItems={UserMenuItems}
          headerButton={
            <CreateManagementUser organizationId={organizationId} setUsers={setUsers} showSnackbar={showSnackbar} />
          }
          roleRowContent={undefined}
        />
      </Paper>

      <Dialog />

      <ErrorSnackbar />
      <Snackbar />
    </Box>
  )
}

export default ManagementOrganizationDetails
