/* istanbul ignore file */
/* tslint:disable */

/* eslint-disable */

/**
 * Feature status.
 *
 * * Unknown - Feature status is not known.
 * This status is not allowed.
 * This may be returned by API if new feature status is added to
 * database and not yet implemented by API.
 * * Enabled - Feature is enabled
 * * Disabled - Feature is disabled
 */
export enum FeatureStatusModel {
  UNKNOWN = "Unknown",
  ENABLED = "Enabled",
  DISABLED = "Disabled",
}
