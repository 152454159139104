import { useEffect, useState } from "react"
import { Box, Paper } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useMsal } from "@azure/msal-react"
import { InteractionStatus } from "@azure/msal-browser"

import { UserCreateResultModel, UserListModel } from "services/openapi"
import useSnackbar from "utils/hooks/useSnackbar"
import UserTable from "components/UserTable"
import CreateSuperAdmin from "scenes/admin/SuperAdminList/CreateSuperAdmin"
import SuperAdminMenuItems from "scenes/admin/SuperAdminList/SuperAdminMenuItems"
import { fetchSuperAdmins } from "scenes/admin/SuperAdminList/apiRequests"
import useConfirmationDialog from "utils/hooks/useConfirmationDialog"
import "./CreateSuperAdmin.sass"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import PasswordResetResultDialog from "components/PasswordResetResultDialog"

const SuperAdminList = () => {
  const { t } = useTranslation()
  const { inProgress } = useMsal()
  const { Snackbar, showSnackbar } = useSnackbar()
  const { Dialog, showConfirmationDialog } = useConfirmationDialog()

  const [isLoading, setLoading] = useState<boolean>(true)
  const [superAdmins, setSuperAdmins] = useState<UserListModel[]>([])
  const { ErrorSnackbar, handleError } = useApiRequestError()
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false)
  const [passwordResetResult, setPasswordResetResult] = useState<UserCreateResultModel>({
    databaseId: "",
    firstTimePassword: "",
    userLogin: "",
  })

  const onPasswordResetSuccess = (result: UserCreateResultModel) => {
    setPasswordResetResult(result)
    setPasswordDialogOpen(true)
  }

  useEffect(
    function fetchSuperAdminsOnLoad() {
      if (inProgress === InteractionStatus.None) {
        fetchSuperAdmins(setLoading, setSuperAdmins, handleError)
      }
      return () => {
        setLoading(true)
      }
    },
    [inProgress, handleError]
  )

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%", overflow: "auto" }}>
      <PasswordResetResultDialog
        open={passwordDialogOpen}
        onClose={() => setPasswordDialogOpen(false)}
        createResult={passwordResetResult}
      />

      <Paper sx={{ padding: 4, mb: 4 }}>
        <UserTable
          isLoading={isLoading}
          users={superAdmins}
          menuItems={(admin, handleClose) =>
            SuperAdminMenuItems(
              admin,
              handleClose,
              superAdmins,
              setSuperAdmins,
              showSnackbar,
              showConfirmationDialog,
              t,
              onPasswordResetSuccess,
              handleError
            )
          }
          header={t("super-admins.table.header")}
          headerButton={<CreateSuperAdmin setSuperAdmins={setSuperAdmins} showSnackbar={showSnackbar} />}
          roleRowContent={undefined}
        />
      </Paper>
      <Snackbar />
      <ErrorSnackbar />
      <Dialog />
    </Box>
  )
}

export default SuperAdminList
