/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SuperAdminUserUpdateModel } from "../models/SuperAdminUserUpdateModel"
import type { UserCreateResultModel } from "../models/UserCreateResultModel"
import type { UserEditModel } from "../models/UserEditModel"
import type { UserListModel } from "../models/UserListModel"
import type { UserListModelPagedResponseModel } from "../models/UserListModelPagedResponseModel"
import { request as __request } from "../core/request"

export class SuperAdminUserService {
  /**
   * Creates a new super admin user for system
   * @param requestBody Created user
   * @returns UserCreateResultModel Success
   * @throws ApiError
   */
  public static async superAdminUserCreateSuperAdmin(requestBody?: UserEditModel): Promise<UserCreateResultModel> {
    const result = await __request({
      method: "POST",
      path: `/SuperAdminUser/createSuperAdmin`,
      body: requestBody,
    })
    return result.body
  }

  /**
   * @param userId
   * @returns UserCreateResultModel Success
   * @throws ApiError
   */
  public static async superAdminUserResetPassword(userId: string): Promise<UserCreateResultModel> {
    const result = await __request({
      method: "POST",
      path: `/SuperAdminUser/resetPassword/${userId}`,
    })
    return result.body
  }

  /**
   * @param userId
   * @returns any Success
   * @throws ApiError
   */
  public static async superAdminUserDeactivateUser(userId: string): Promise<any> {
    const result = await __request({
      method: "DELETE",
      path: `/SuperAdminUser/deactivate/${userId}`,
    })
    return result.body
  }

  /**
   * @param pageSize
   * @param page
   * @param searchString
   * @returns UserListModelPagedResponseModel Success
   * @throws ApiError
   */
  public static async superAdminUserGetSuperAdmins(
    pageSize?: number,
    page?: number,
    searchString?: string
  ): Promise<UserListModelPagedResponseModel> {
    const result = await __request({
      method: "GET",
      path: `/SuperAdminUser/getSuperAdmins`,
      query: {
        pageSize: pageSize,
        page: page,
        searchString: searchString,
      },
    })
    return result.body
  }

  /**
   * @param id
   * @returns UserListModel Success
   * @throws ApiError
   */
  public static async superAdminUserGetDetails(id: string): Promise<UserListModel> {
    const result = await __request({
      method: "GET",
      path: `/SuperAdminUser/get/${id}`,
    })
    return result.body
  }

  /**
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public static async superAdminUserUpdateSuperAdmin(requestBody?: SuperAdminUserUpdateModel): Promise<any> {
    const result = await __request({
      method: "POST",
      path: `/SuperAdminUser/updateSuperAdmin`,
      body: requestBody,
    })
    return result.body
  }
}
