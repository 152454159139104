import { Box, Card, CardContent, CircularProgress, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { DiaryEntryListModelPagedResponseModel, UserActivityListModel, UserFeelingListModel } from "services/openapi"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import { orderBy, union } from "lodash/fp"
import { getFeelingUser } from "utils/apiRequests/fetchFeelings"
import { getActivityEntries, getDiaryEntries } from "scenes/CareOrganizationUserClientDetails/apiRequests"
import { toFinnishFormat } from "utils/functions/toDateFormat"
import { Timespan } from "components/TimeSpanSelectionButtons"

type UserDayListingProps = {
  clientId: string
  timespan: Timespan
  dayClicked: (date: string) => void
}

const UserDayListing = ({ clientId, timespan, dayClicked }: UserDayListingProps) => {
  const [isLoadingFeelings, setLoadingFeelings] = useState<boolean>(true)
  const [isLoadingDiaryEntries, setLoadingDiaryEntries] = useState<boolean>(true)
  const [isLoadingActivities, setLoadingActivities] = useState<boolean>(true)
  const [feelings, setFeelings] = useState<UserFeelingListModel[]>([])
  const [activities, setActivities] = useState<UserActivityListModel[]>([])
  const [diaryEntries, setDiaryEntries] = useState<DiaryEntryListModelPagedResponseModel>({
    items: [],
    page: 0,
    pageSize: undefined,
    total: undefined,
  })
  const { ErrorSnackbar, handleError } = useApiRequestError()

  useEffect(
    function fetchData() {
      getFeelingUser(clientId, timespan.start, timespan.end, setLoadingFeelings, setFeelings, handleError)
      getDiaryEntries(clientId, timespan.start, timespan.end, setLoadingDiaryEntries, setDiaryEntries, handleError)
      getActivityEntries(clientId, timespan.start, timespan.end, setLoadingActivities, setActivities, handleError)
    },
    [clientId, timespan.start, timespan.end, handleError]
  )

  const feelingDates = feelings.map((f) => f.date.split("T")[0])
  const diaryDates = diaryEntries.items.map((d) => d.date.split("T")[0])
  const activityDates = activities.map((d) => d.date.split("T")[0])
  const unionOfDates = union(union(feelingDates, diaryDates), activityDates)
  const sortedDates = orderBy((date) => date, "desc", unionOfDates)

  const cards = sortedDates.map((date) => {
    return (
      <Card
        key={date}
        sx={{
          borderRadius: 3,
          mt: 1,
          mx: 2,
        }}
      >
        <CardContent>
          <Box
            sx={{}}
            onClick={() => {
              dayClicked(date)
            }}
          >
            <Typography sx={{ fontWeight: "bold", pb: 1 }}>{toFinnishFormat(date)}</Typography>
            <ErrorSnackbar />
          </Box>
        </CardContent>
      </Card>
    )
  })

  return (
    <>
      {isLoadingFeelings || isLoadingDiaryEntries || isLoadingActivities ? (
        <CircularProgress size={100} />
      ) : (
        <Box
          sx={{
            pb: 12,
          }}
        >
          {cards}
        </Box>
      )}
      <ErrorSnackbar />
    </>
  )
}

export default UserDayListing
