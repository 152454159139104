import { Box } from "@mui/system"
import { useTranslation } from "react-i18next"
import useToggle from "utils/hooks/useToggle"
import RoundLinkButton from "../RoundLinkButton"
import { useContext } from "react"
import { userContext } from "contexts/userContext"
import ClientGroupId from "utils/clientGroupId"
import { UserRoleModel } from "services/openapi"
import { CloseButton, MenuDrawer, MenuHeader, OpenButton, StyledSignOutButton } from "./styled"
import { LanguageButtons } from "components/LanguageButtons"

const HamburgerMenu = () => {
  const { t } = useTranslation()
  const { user } = useContext(userContext)
  var canSeeActivities = user?.clientGroupId === ClientGroupId.ELDERLY
  var canSeeDiary = user?.clientGroupId !== ClientGroupId.ELDERLY
  var isClient = user?.role === UserRoleModel.CLIENT

  const [drawerOpen, toggleDrawer] = useToggle()

  const icon = (file: string) => (
    <Box sx={{ ml: 1, mt: 1 }}>
      <img src={`${process.env.PUBLIC_URL}/feelingIcons/${file}`} width={32} alt={""} aria-hidden="true" />
    </Box>
  )

  return (
    <>
      <OpenButton onClick={toggleDrawer} />

      <MenuDrawer anchor="right" open={drawerOpen} onClose={toggleDrawer} onOpen={toggleDrawer}>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <LanguageButtons />
          <CloseButton onClick={toggleDrawer} />
        </Box>

        <Box sx={{ position: "relative" }}>
          <MenuHeader />

          {isClient ? (
            <>
              {canSeeActivities ? (
                <RoundLinkButton
                  url={"/client/activity"}
                  text={t("hamburger-menu.daily-activities")}
                  icon={icon("Iloinen.svg")}
                />
              ) : (
                <></>
              )}

              <RoundLinkButton
                url={"/client/feelings"}
                text={t("hamburger-menu.pick-feelings")}
                icon={icon("Toiveikas.svg")}
              />

              {canSeeDiary ? (
                <RoundLinkButton
                  url={"/client/diary"}
                  text={t("hamburger-menu.diary")}
                  icon={icon("Rauhallinen.svg")}
                />
              ) : (
                <></>
              )}

              <RoundLinkButton
                url={"/client/daily-summary"}
                text={t("hamburger-menu.feelings-today")}
                icon={icon("Onnellinen.svg")}
              />

              <RoundLinkButton
                url={"/client/feeling-history"}
                text={t("hamburger-menu.feeling-history")}
                icon={icon("Voimaantunut.svg")}
              />

              {/* <RoundLinkButton
                url={"/client/notification"}
                text={t("hamburger-menu.reminder")}
                icon={icon("Ok.svg")}
              /> */}
            </>
          ) : (
            <>
              <RoundLinkButton url={"/care-org-user"} text={t("hamburger-menu.groups")} icon={icon("Iloinen.svg")} />

              <RoundLinkButton url={"/admin"} text={t("hamburger-menu.admin")} icon={icon("Voimaantunut.svg")} />
            </>
          )}

          <RoundLinkButton
            url={"/terms-of-use"}
            text={t("hamburger-menu.terms-of-use")}
            icon={icon("Kiinnostunut.svg")}
          />

          <RoundLinkButton
            url={"/client/accessibility"}
            text={t("hamburger-menu.accessibility")}
            icon={icon("Ok.svg")}
          />

          <StyledSignOutButton />
        </Box>
      </MenuDrawer>
    </>
  )
}

export default HamburgerMenu
