import { useContext } from "react"
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom"
import { UserRoleModel } from "services/openapi"
import { userContext } from "contexts/userContext"

type PrivateRouteProps = {
  role: UserRoleModel | UserRoleModel[]
} & RouteProps

function PrivateRoute({ role, children, ...rest }: PrivateRouteProps) {
  const { user } = useContext(userContext)
  const location = useLocation()

  return (
    <Route {...rest}>
      {userRoleMatches(role, user?.role!) ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: location },
          }}
        />
      )}
    </Route>
  )
}

export function userRoleMatches(allowedRoles: UserRoleModel | UserRoleModel[], userRole: UserRoleModel) {
  if (Array.isArray(allowedRoles)) {
    return allowedRoles.includes(userRole)
  }
  return userRole === allowedRoles
}

export default PrivateRoute
