import MobileViewBase from "components/MobileViewBase"
import MobileViewDescription from "components/MobileViewBase/MobileViewDescription"
import { userContext } from "contexts/userContext"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import ClientGroupId from "utils/clientGroupId"
import FeelingCards from "./FeelingCards"
import { BottomNavigationStepValue } from "components/BottomNavigationBar"
import BottomNavigationBar from "components/BottomNavigationBar"

const FeelingsSelection = () => {
  const { t } = useTranslation()
  const { user } = useContext(userContext)

  const nextNav = user?.clientGroupId === ClientGroupId.ELDERLY ? `/client/daily-summary` : `/client/diary`
  const backNav = user?.clientGroupId === ClientGroupId.ELDERLY ? `/client/help` : ``
  const step: BottomNavigationStepValue = user?.clientGroupId === ClientGroupId.ELDERLY ? 3 : 1
  const navLength: number = user?.clientGroupId === ClientGroupId.ELDERLY ? 5 : 4


  const descriptionText = `${t("youth-view.feeling-selection.description")} ${user?.clientGroupId !== ClientGroupId.ELDERLY ? t("youth-view.feeling-selection.intensity-option") : ""
    }`

  return (
    <MobileViewBase
      top={<MobileViewDescription header={t("youth-view.feeling-selection.title")} text={descriptionText} />}
      middle={<FeelingCards canSelectTargets={user?.clientGroupId !== ClientGroupId.ELDERLY} />}
      bottom={<BottomNavigationBar value={step} back={backNav} to={nextNav} navLength={navLength} />}
    />
  )
}

export default FeelingsSelection
