import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField,
} from "@mui/material"
import StyledButton from "components/StyledButton"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { ManagementOrganizationCreateModel } from "services/openapi"
import { FeatureStatusModel } from "services/openapi/models/FeatureStatusModel"

type ManagementOrganizationCreateForm = {
  organizationName: string
  description: string
  hasFeatureChildren: Array<FeatureStatusModel>
  hasFeatureAdults: Array<FeatureStatusModel>
  hasFeaturePensioners: Array<FeatureStatusModel>
}

const formValues: ManagementOrganizationCreateForm = {
  organizationName: "",
  description: "",
  hasFeatureChildren: [],
  hasFeatureAdults: [],
  hasFeaturePensioners: [],
}

type CreateOrganizationProps = {
  createOrganization: (data: ManagementOrganizationCreateModel) => Promise<boolean>
}

const CreateManagementOrganization = ({ createOrganization }: CreateOrganizationProps) => {
  const { t } = useTranslation()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [callingApi, setCallingApi] = useState(false)

  const formSubmit = (value: ManagementOrganizationCreateForm) => {
    setCallingApi(true)

    const organizationCreateModel: ManagementOrganizationCreateModel = {
      organizationName: value.organizationName,
      description: value.description,
      hasFeatureChildren:
        value.hasFeatureChildren.length > 0 ? value.hasFeatureChildren[0] : FeatureStatusModel.DISABLED,
      hasFeatureAdults: value.hasFeatureAdults.length > 0 ? value.hasFeatureAdults[0] : FeatureStatusModel.DISABLED,
      hasFeaturePensioners:
        value.hasFeaturePensioners.length > 0 ? value.hasFeaturePensioners[0] : FeatureStatusModel.DISABLED,
    }

    createOrganization(organizationCreateModel).then((success: boolean) => {
      if (success) {
        setDialogOpen(false)
      }
      setCallingApi(false)
    })
  }

  return (
    <>
      <StyledButton
        onClick={() => {
          setDialogOpen(true)
        }}
        variant="contained"
        endIcon={<span>+</span>}
        data-testid="create-management-organization-button"
        sx={{ alignSelf: "center" }}
      >
        {t("management-organization-list.add-organization")}
      </StyledButton>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>{t("create-management-organization.title")}</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
          <Formik
            initialValues={formValues}
            validationSchema={Yup.object({
              organizationName: Yup.string()
                .trim()
                .max(50, t("form-validation.too-long", { length: 50 }))
                .required(t("form-validation.required")),
              description: Yup.string()
                .trim()
                .max(500, t("form-validation.too-long", { length: 500 })),
            })}
            onSubmit={formSubmit}
          >
            {(formik) => (
              <Form>
                <TextField
                  id="organizationName"
                  label={t("create-care-organization.name")}
                  required
                  sx={{ mb: 2, width: "100%" }}
                  inputProps={{ "data-testid": "organization-name-field" }}
                  variant="standard"
                  color="primary"
                  {...formik.getFieldProps("organizationName")}
                  helperText={formik.touched.organizationName && formik.errors.organizationName}
                />

                <TextField
                  id="description"
                  label={t("create-care-organization.description")}
                  multiline
                  sx={{ mb: 2, width: "100%" }}
                  inputProps={{ "data-testid": "organization-description-field" }}
                  variant="standard"
                  color="primary"
                  {...formik.getFieldProps("description")}
                  helperText={formik.touched.description && formik.errors.description}
                />

                <FormControl sx={{ display: "block" }}>
                  <FormGroup>
                    <FormLabel component="legend" sx={{ display: "flex" }}>
                      {t("create-care-organization.features")}
                    </FormLabel>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="hasFeatureChildren"
                            data-testid="organization-children-checkbox"
                            {...formik.getFieldProps("hasFeatureChildren")}
                            value={FeatureStatusModel.ENABLED}
                          />
                        }
                        label={t("create-care-organization.children")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="hasFeatureAdults"
                            data-testid="organization-adults-checkbox"
                            {...formik.getFieldProps("hasFeatureAdults")}
                            value={FeatureStatusModel.ENABLED}
                          />
                        }
                        label={t("create-care-organization.adults")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="hasFeaturePensioners"
                            data-testid="organization-pensioners-checkbox"
                            {...formik.getFieldProps("hasFeaturePensioners")}
                            value={FeatureStatusModel.ENABLED}
                          />
                        }
                        label={t("create-care-organization.pensioners")}
                      />
                    </Box>
                  </FormGroup>
                </FormControl>

                <StyledButton
                  sx={{
                    mt: 2,
                    width: "fit-content",
                    alignSelf: "end",
                    float: "right",
                    clear: "both",
                  }}
                  variant="contained"
                  type="submit"
                  disabled={callingApi}
                >
                  {t("create-care-organization.submit-button")}
                </StyledButton>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default CreateManagementOrganization
