import { Box, Card, CardActionArea, CardContent, List } from "@mui/material"
import CardName from "components/SpesCard/styled/CardName"
import { ReactElement } from "react"
import { Link as RouterLink } from "react-router-dom"
import ClientListingDoughnut from "./ClientListingDoughnut"

type EntityListingProps = {
  entities: { id: string; name: string }[]
  url: string
  icon?: ReactElement
}

const ClientEntityListing = ({ entities, url, icon }: EntityListingProps) => {
  const mapEntityToCardItem = (entity: { id: string; name: string }) => {
    return (
      <Card key={entity.id} elevation={4} sx={{ width: 150, borderRadius: 3, mb: 1 }}>
        <CardActionArea component={RouterLink} to={`${url}/${entity.id}`} sx={{ height: "100%" }}>
          <CardContent>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", rowGap: 2 }}>
              <CardName>{entity.name}</CardName>
              <ClientListingDoughnut clientId={entity.id} />
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    )
  }
  return (
    <List
      sx={{
        display: "flex",
        justifyContent: "space-evenly",
        flexWrap: "wrap",
        rowGap: 1,
        overflowWrap: "break-word",
        mb: 13,
        mt: 0,
      }}
      id="ClientEntityList"
    >
      {entities?.map(mapEntityToCardItem)}
    </List>
  )
}

export default ClientEntityListing
