import { Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useTranslation } from "react-i18next"
import { ActivityModel, SingleActivityModel } from "services/openapi"
import { successOptions } from "utils/successOptions"
import StyledListing from "./styled/StyledListing"

type ActivityBoxProps = {
  activity: SingleActivityModel
  activityOptions: ActivityModel[]
}

const ActivityBox = ({ activity, activityOptions }: ActivityBoxProps) => {
  const { t } = useTranslation()

  const addedActivityDetails = activityOptions.find((activityModel) => activityModel.id === activity.activityId)
  const successRate = successOptions.find((s) => s.value === activity.success)

  return (
    <Box
      sx={{
        ml: 2,
        mt: 2,
        mr: 4,
        display: "flex",
        flexDirection: "column",
        borderBottom: "2px solid rgba(21, 21, 21, 0.04)",
      }}
    >
      <Box
        sx={{
          ml: 2,
          mt: 2,
          mr: 2,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/activityIcons/${addedActivityDetails?.imageLocation}`}
          width={45}
          alt={""}
          aria-hidden="true"
        />
        <StyledListing>
          {t(`elderly-view.activity-selection.activity-options.${addedActivityDetails?.name}`)}
        </StyledListing>
      </Box>

      <Typography
        variant="body1"
        sx={{
          ml: 2,
          mt: 2,
          mr: 4,
          mb: 1,
          display: "flex",
          flexDirection: "row",
          fontFamily: "SofiaProSemiBold",
          fontSize: "20px",
        }}
      >
        {t("elderly-view.activity-selection.success-query")}
      </Typography>

      <Box sx={{ display: "flex", alignItems: "left" }}>
        <Box sx={{ display: "inline" }}>
          <img
            src={`${process.env.PUBLIC_URL}/feelingIcons/${successRate?.icon}`}
            alt=""
            width={38}
            aria-hidden="true"
          />
        </Box>
        <Typography
          variant="body1"
          sx={{
            ml: 2,
            mt: 0,
            mb: 1,
            display: "inline",
            flexDirection: "row",
            fontFamily: "SofiaProLight",
            fontSize: "20px",
          }}
        >
          {t(`elderly-view.activity-selection.success-option.${successRate?.translationKey}`)}
        </Typography>
      </Box>
    </Box>
  )
}

export default ActivityBox
