import { styled, SwipeableDrawer } from "@mui/material"
import mobileBackground from "images/mobileBackground.svg"

export const MenuDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    display: "flex",
    flexDirection: "column",
    width: "75%",
    maxWidth: theme.spacing(37.5),
    overflow: "visible",
    backgroundImage: `url(${mobileBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "80% 0%",
  },
}))
