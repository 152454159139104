import {
  CareOrganizationUserService,
  ClientUserService,
  ManagementOrganizationUserService,
  SuperAdminUserService,
  UserListModel,
} from "services/openapi"

export const fetchSuperAdmin = async (
  userId: string,
  setLoading: (isLoading: boolean) => void,
  onSuccess: (user: UserListModel) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoading(true)
    const response = await SuperAdminUserService.superAdminUserGetDetails(userId)
    onSuccess(response)
  } catch (error) {
    onError(error, "user-details.snackbar.fetch-error")
  }
  setLoading(false)
}

export const fetchManageOrganizationAdmin = async (
  userId: string,
  setLoading: (isLoading: boolean) => void,
  onSuccess: (user: UserListModel) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoading(true)
    const response = await ManagementOrganizationUserService.managementOrganizationUserGetDetails(userId)
    onSuccess(response)
  } catch (error) {
    onError(error, "user-details.snackbar.fetch-error")
  }
  setLoading(false)
}

export const fetchCareOrganizationUser = async (
  userId: string,
  setLoading: (isLoading: boolean) => void,
  onSuccess: (user: UserListModel) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoading(true)
    const response = await CareOrganizationUserService.careOrganizationUserGetDetails(userId)
    onSuccess(response)
  } catch (error) {
    onError(error, "user-details.snackbar.fetch-error")
  }
  setLoading(false)
}

export const fetchClientUser = async (
  userId: string,
  setLoading: (isLoading: boolean) => void,
  onSuccess: (user: UserListModel) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoading(true)
    const response = await ClientUserService.clientUserGetDetails(userId)
    onSuccess(response)
  } catch (error) {
    onError(error, "user-details.snackbar.fetch-error")
  }
  setLoading(false)
}
