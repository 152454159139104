import { Card, CardContent, Typography, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import { userContext } from "contexts/userContext"
import { useContext, useEffect, useState } from "react"
import { getDiaryEntry } from "./apiRequests"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import { toDateFormat } from "utils/functions/toDateFormat"

const DailyDiarySummary = () => {
  const { t } = useTranslation()
  const { user } = useContext(userContext)
  const { handleError } = useApiRequestError()
  const theme = useTheme()
  const [diaryContent, setDiaryContent] = useState({
    message: "",
    pick1: "",
    pick2: "",
    pick3: "",
  })

  useEffect(() => {
    async function fetchData() {
      const today: string = toDateFormat(new Date())
      if (user?.id) {
        const diary = await getDiaryEntry(user.id, today, handleError)
        if (diary) {
          setDiaryContent({
            message: diary.message,
            pick1: diary.pick1,
            pick2: diary.pick2,
            pick3: diary.pick3,
          })
        }
      }
    }

    fetchData()
  }, [handleError, user?.id])

  return (
    <>
      <Card
        elevation={4}
        sx={{
          borderRadius: 3,
          width: "95vw",
          backgroundColor: theme.palette.primary.main,
          mb: 2,
          mx: "auto",
        }}
      >
        <CardContent>
          <Typography sx={{ fontWeight: "bold", pb: 1 }}>{t("youth-view.daily-summary.diary-title")}</Typography>
          <Typography variant="body1">{diaryContent.message}</Typography>

          <Typography sx={{ fontWeight: "bold", pt: 2, pb: 1 }}>
            {t("youth-view.daily-summary.thankful-title")}
          </Typography>
          <Typography variant="body1">{diaryContent.pick1}</Typography>
          <Typography variant="body1">{diaryContent.pick2}</Typography>
          <Typography variant="body1">{diaryContent.pick3}</Typography>
        </CardContent>
      </Card>
    </>
  )
}

export default DailyDiarySummary
