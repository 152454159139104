import { Autocomplete, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material"
import StyledButton from "components/StyledButton"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { UserListModel } from "services/openapi"

type Props = {
  unconnectedUsers: UserListModel[]
  connectUserToFamily: (usedId: string) => Promise<boolean>
}

const ConnectUser = ({ unconnectedUsers, connectUserToFamily }: Props) => {
  const { t } = useTranslation()

  const [dialogOpen, setDialogOpen] = useState(false)
  const [userId, setUserId] = useState<string>("")
  const [callingApi, setCallingApi] = useState(false)

  const options = unconnectedUsers
    .sort((a, b) => {
      return `${a.lastName} ${a.firstName}`.localeCompare(`${b.lastName} ${b.firstName}`)
    })
    .map((x) => {
      return { label: `${x.lastName} ${x.firstName}`, id: x.id }
    })

  return (
    <>
      <StyledButton
        variant="contained"
        onClick={() => {
          setDialogOpen(true)
        }}
      >
        {t("connect-user.connect-user-button")}
      </StyledButton>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        PaperProps={{ sx: { overflow: "visible", width: 400 } }}
      >
        <DialogTitle>{t("connect-user.dialog-header")}</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
          {/* 
            On save, MUI gives a warning about invalid Autocomplete value. This is because on accept the selected value
            is removed from the option list. The warning can be safely ignored.
            */}
          <Autocomplete
            disablePortal
            options={options}
            renderInput={(params) => <TextField {...params} label={t("connect-user.select-label")} />}
            onChange={(e, value) => setUserId(value?.id || "")}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            sx={{ mb: 2 }}
          />
          <StyledButton
            onClick={() => {
              if (userId) {
                setCallingApi(true)
                connectUserToFamily(userId).then((success) => {
                  if (success) {
                    setDialogOpen(false)
                    setUserId("")
                  }
                  setCallingApi(false)
                })
              }
            }}
            variant="contained"
            disabled={callingApi}
            sx={{ alignSelf: "end" }}
          >
            {t("connect-user.connect-confirm")}
          </StyledButton>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ConnectUser
