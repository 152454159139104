import { Switch } from "react-router-dom"
import PrivateRoute from "routing/utils/PrivateRoute"
import CareOrganizationUserClientDetails from "scenes/CareOrganizationUserClientDetails"
import CareOrganizationUserClientListing from "scenes/CareOrganizationUserClientListing"
import CareOrganizationUserDayDetails from "scenes/CareOrganizationUserDayDetails"
import { UserRoleModel } from "services/openapi"

const CareOrganizationUserMobileRoutes = () => {
  return (
    <Switch>
      <PrivateRoute path="/care-org-user/family/:id/client/:clientId/:date" role={UserRoleModel.CARE_ORGANIZATION_USER}>
        <CareOrganizationUserDayDetails />
      </PrivateRoute>

      <PrivateRoute exact path="/care-org-user/family/:id" role={UserRoleModel.CARE_ORGANIZATION_USER}>
        <CareOrganizationUserClientListing />
      </PrivateRoute>

      <PrivateRoute path="/care-org-user/family/:id/client/:clientId" role={UserRoleModel.CARE_ORGANIZATION_USER}>
        <CareOrganizationUserClientDetails />
      </PrivateRoute>
    </Switch>
  )
}

export default CareOrganizationUserMobileRoutes
