import { Box, TextField } from "@mui/material"
import { FormikProps } from "formik"
import { useTranslation } from "react-i18next"

type CreateCareOrgFieldsProps = {
  formik: FormikProps<{ organizationName: string; description: string }>
}

const CreateCareOrgFields = ({ formik }: CreateCareOrgFieldsProps) => {
  const { t } = useTranslation()
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <TextField
        id="organizationName"
        label={t("create-care-organization.name")}
        inputProps={{ "data-testid": "organization-name-field" }}
        sx={{ mb: 2, float: "left", clear: "both" }}
        variant="standard"
        color="primary"
        {...formik.getFieldProps("organizationName")}
        helperText={formik.touched.organizationName && formik.errors.organizationName}
        required
      />
      <TextField
        id="description"
        label={t("create-care-organization.description")}
        inputProps={{ "data-testid": "organization-description-field" }}
        sx={{ mb: 2, clear: "both", float: "left" }}
        variant="standard"
        color="primary"
        {...formik.getFieldProps("description")}
        helperText={formik.touched.description && formik.errors.description}
        multiline
      />
    </Box>
  )
}

export default CreateCareOrgFields
