import { AccountInfo, InteractionRequiredAuthError } from "@azure/msal-browser"
import { useMsal } from "@azure/msal-react"
import { loginRequest } from "authConfig"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import useSnackbar from "./useSnackbar"

const useApiRequestError = () => {
  const { instance } = useMsal()
  // Avoid name clash if this hook is used in the same component with useSnackbar
  const { Snackbar: ErrorSnackbar, showSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const handleError = useCallback(
    (error: any, messageKey: string) => {
      const handleMsalInstanceError = () => {
        instance.acquireTokenRedirect({
          ...loginRequest,
          account: instance.getActiveAccount() as AccountInfo,
        })
      }

      const handleNetworkError = () => {
        let errorMessage
        switch (error.status) {
          case 400:
            errorMessage = `${t("error-message.bad-request")}${t(messageKey)}`
            showSnackbar(errorMessage, "error")
            break

          case 401:
            errorMessage = `${t("error-message.unauthorized")} - ${t(messageKey)}`
            showSnackbar(errorMessage, "error")
            break

          case 500:
            errorMessage = `${t("error-message.internal-server-error")} - ${t(messageKey)}`
            showSnackbar(errorMessage, "error")
            break

          default:
            errorMessage = `${t("error-message.unknown")} - ${t(messageKey)}`
            showSnackbar(errorMessage, "error")
            break
        }
      }

      if (error instanceof InteractionRequiredAuthError) {
        handleMsalInstanceError()
      } else {
        handleNetworkError()
      }
    },
    [instance, showSnackbar, t]
  )

  return { ErrorSnackbar, handleError }
}

export default useApiRequestError
