import { IPublicClientApplication } from "@azure/msal-browser"
import {
  ManagementOrganizationCreateModel,
  ManagementOrganizationService,
  OrganizationListModel,
} from "services/openapi"
import { handleApiRequestError } from "utils"

export const fetchOrganizations = async (
  setLoading: (isLoading: boolean) => void,
  setOrganizations: React.Dispatch<React.SetStateAction<OrganizationListModel[]>>,
  instance: IPublicClientApplication,
  showSnackbar: (message: string, type: "error" | "warning" | "info" | "success") => void,
  t: (key: string) => string
) => {
  try {
    setLoading(true)
    const managementOrgs = await ManagementOrganizationService.managementOrganizationGetAll()
    setOrganizations(managementOrgs.items)
  } catch (e) {
    handleApiRequestError(e, instance, showSnackbar, t, t("management-organization-list.snackbar.fetch-error"))
  }
  setLoading(false)
}

export const createOrganization = async (
  organizationData: ManagementOrganizationCreateModel,
  setOrganizations: React.Dispatch<React.SetStateAction<OrganizationListModel[]>>,
  instance: IPublicClientApplication,
  showSnackbar: (message: string, type: "error" | "warning" | "info" | "success") => void,
  t: (key: string) => string
) => {
  try {
    await ManagementOrganizationService.managementOrganizationCreateManagementOrganization(organizationData)
    const managementOrgs = await ManagementOrganizationService.managementOrganizationGetAll()
    setOrganizations(managementOrgs.items)
    showSnackbar(t("management-organization-list.snackbar.create-success"), "success")
    return true
  } catch (e) {
    handleApiRequestError(e, instance, showSnackbar, t, t("management-organization-list.snackbar.create-error"))
    return false
  }
}

export const deactivateOrganization = async (
  orgId: string,
  organizations: OrganizationListModel[],
  setOrganizations: React.Dispatch<React.SetStateAction<OrganizationListModel[]>>,
  instance: IPublicClientApplication,
  showSnackbar: (message: string, type: "error" | "warning" | "info" | "success") => void,
  t: (key: string) => string
) => {
  try {
    await ManagementOrganizationService.managementOrganizationDeactivateManagementOrganization(orgId)
    const updatedOrgs = organizations?.filter((x) => x.id !== orgId)
    setOrganizations(updatedOrgs)
    showSnackbar(t("management-organization-list.snackbar.deactivation-success"), "success")
    return true
  } catch (e) {
    handleApiRequestError(e, instance, showSnackbar, t, t("management-organization-list.snackbar.deactivation-error"))
    return false
  }
}
