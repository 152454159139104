import MenuRoundedIcon from "@mui/icons-material/MenuRounded"
import { Button } from "@mui/material"
import { useTranslation } from "react-i18next"

type OpenButtonProps = {
  onClick: () => void
}

export const OpenButton = ({ onClick }: OpenButtonProps) => {
  const { t } = useTranslation()
  return (
    <Button
      sx={{
        position: "absolute",
        right: 0,
        width: "fit-content",
        "& svg": {
          fontSize: "34px",
        },
      }}
      onClick={onClick}
      aria-label={t("aria-labels.hamburger-menu-open")}
      title={t("aria-labels.hamburger-menu-open")}
    >
      <MenuRoundedIcon sx={{ color: "text.primary" }} />
    </Button>
  )
}
