import { Box } from "@mui/material"
import { useEffect, useReducer } from "react"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import DiaryForm from "components/MobileDiaryForms/DiaryForm"
import GoodThingsForm from "components/MobileDiaryForms/GoodThingsForm"
import { initialDiaryState, reducer } from "scenes/MobileFeelingHistory/DiaryStateReducer"
import { getDiaryEntry, saveDiaryEntry } from "scenes/MobileFeelingHistory/apiRequests"
import { DiaryEditProps } from "./DiaryEditProps"

const DiaryEdit = ({ selectedDate, userId }: DiaryEditProps) => {
  const [state, dispatch] = useReducer(reducer, initialDiaryState)
  const { handleError } = useApiRequestError()

  useEffect(() => {
    async function fetchData() {
      if (userId && selectedDate !== "") {
        const diary = await getDiaryEntry(userId, selectedDate, handleError)
        if (diary) {
          dispatch({ type: "setInitialDiary", diary: diary })
        }
      }
    }

    fetchData()
  }, [selectedDate, handleError, userId])

  useEffect(
    function postDiaryOnChange() {
      const postData = async () => {
        await saveDiaryEntry(state.diary, handleError)
      }

      const nullId = "00000000-0000-0000-0000-000000000000"
      const dataFetchingReady = state.diary.userId !== "" && state.diary.userId !== nullId
      if (dataFetchingReady) {
        postData()
      }
    },
    [handleError, state.diary]
  )

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        my: 5,
      }}
    >
      <Box sx={{ display: "block", margin: "auto" }}>
        <DiaryForm userId={userId} state={state} dispatch={dispatch} />
      </Box>
      <Box sx={{ display: "block", justifyContent: "center", margin: "auto", mt: 5 }}>
        <GoodThingsForm userId={userId} state={state} dispatch={dispatch} />
      </Box>
    </Box>
  )
}

export default DiaryEdit
