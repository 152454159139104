import { Card, CardContent, useTheme } from "@mui/material"
import { Formik } from "formik"
import { useTranslation } from "react-i18next"
import { DiaryEntrySaveModel } from "services/openapi"
import { toDateFormat } from "utils/functions/toDateFormat"
import * as Yup from "yup"
import { Action, DiaryState } from "../../scenes/DiaryEntry/DiaryStateReducer"
import DiaryTextField from "./styled/DiaryTextField"

type DiaryFormState = {
  message: string | null | undefined
  isPublic: boolean
}

type Props = {
  userId: string
  state: DiaryState
  dispatch: React.Dispatch<Action>
}

const DiaryForm = ({ userId, state, dispatch }: Props) => {
  const today: string = toDateFormat(new Date())
  const { t } = useTranslation()
  const theme = useTheme()

  const onDiaryAnyFieldBlur = async ({ message, isPublic }: DiaryFormState) => {
    const newDiaryEntry: DiaryEntrySaveModel = {
      userId: userId,
      date: today,
      message: message,
      isPublic: isPublic,
      pick1: state.diary.pick1,
      pick2: state.diary.pick2,
      pick3: state.diary.pick3,
    }

    const diaryEntryChanged = state.diary.message !== message || state.diary.isPublic !== isPublic

    if (diaryEntryChanged) {
      dispatch({ type: "updateDiaryForm", diary: newDiaryEntry })
    }
  }

  return (
    <>
      <Card
        elevation={4}
        sx={{
          borderRadius: 3,
          width: theme.diaryView?.contentWidth,
          backgroundColor: "primary.main",
          marginX: "auto",
        }}
      >
        <CardContent>
          <Formik
            enableReinitialize
            initialValues={{ diaryEntry: state.diary.message ?? "", permission: state.diary.isPublic }}
            validationSchema={Yup.object({
              diaryEntry: Yup.string()
                .trim()
                .max(2000, t("youth-view.diary-entry.validation.length", { length: 2000 })),
            })}
            onSubmit={(values) => {
              if (userId !== null) {
                onDiaryAnyFieldBlur({ message: values.diaryEntry, isPublic: values.permission })
              }
            }}
            validateOnBlur
          >
            {(formik) => <DiaryTextField formik={formik} />}
          </Formik>
        </CardContent>
      </Card>
    </>
  )
}

export default DiaryForm
