import { Typography } from "@mui/material"
import { styled, TypographyProps } from "@mui/system"

const StyledListing = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: "23px",
  display: "flex",
  padding: theme.spacing(2),
  fontFamily: "SofiaProMedium",
}))

export default StyledListing
