import { InteractionStatus } from "@azure/msal-browser"
import { useMsal } from "@azure/msal-react"
import { LinearProgress, MenuItem, Paper, TextField, Typography } from "@mui/material"
import UserTable from "components/UserTable"
import { userContext } from "contexts/userContext"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import {
  deactivateUser,
  fetchOrganizationDetails,
  fetchOrganizationUsers,
  resetCareOrgUserPassword,
  updateOrganizationDetails,
} from "scenes/admin/CareOrganizationDetails/apiRequests"
import CreateCareOrgUser from "scenes/admin/CareOrganizationDetails/CreateCareOrgUser"
import FamilyList from "scenes/admin/FamilyList"
import {
  CareOrganizationDetailsModel,
  CareOrganizationUserListModel,
  ProfileModel,
  UserCreateResultModel,
  UserListModel,
  UserRoleModel,
} from "services/openapi"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import useConfirmationDialog from "utils/hooks/useConfirmationDialog"
import useSnackbar from "utils/hooks/useSnackbar"
import StyledButton from "components/StyledButton"
import "./CareOrganizationDetails.sass"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import PasswordResetResultDialog from "components/PasswordResetResultDialog"

type CareOrganizationEditForm = {
  organizationName: string
  description: string
}

const CareOrganizationDetails = () => {
  const { organizationId } = useParams<{ organizationId: string }>()
  const { inProgress } = useMsal()
  const { t } = useTranslation()
  const { user: currentUser } = useContext(userContext)
  const { Snackbar, showSnackbar } = useSnackbar()
  const { Dialog, showConfirmationDialog } = useConfirmationDialog()
  const { ErrorSnackbar, handleError } = useApiRequestError()

  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false)
  const [passwordResetResult, setPasswordResetResult] = useState<UserCreateResultModel>({
    databaseId: "",
    firstTimePassword: "",
    userLogin: "",
  })

  const onPasswordResetSuccess = (result: UserCreateResultModel) => {
    setPasswordResetResult(result)
    setPasswordDialogOpen(true)
  }

  const [isLoadingUsers, setLoadingUsers] = useState<boolean>(true)
  const [isLoadingDetails, setLoadingDetails] = useState<boolean>(true)
  const [isSaving, setSaving] = useState<boolean>(false)
  const [org, setOrg] = useState<CareOrganizationDetailsModel>({
    id: "",
    name: "",
    description: "",
  })
  const [users, setUsers] = useState<UserListModel[]>([])

  // Only ManagementOrganizationAdmin has full access to everything on the details page
  const canEditCareOrg = currentUser?.role === UserRoleModel.MANAGEMENT_ORGANIZATION_ADMIN_USER

  const initialValues: CareOrganizationEditForm = {
    organizationName: org.name,
    description: org.description,
  }

  useEffect(
    function fetchOrganizationDetailsOnMount() {
      if (inProgress === InteractionStatus.None) {
        fetchOrganizationDetails(organizationId, setLoadingDetails, setOrg, handleError)
        fetchOrganizationUsers(organizationId, setLoadingUsers, setUsers, handleError)
      }
      return () => {
        setLoadingUsers(true)
      }
    },
    [inProgress, currentUser, handleError, organizationId]
  )

  const onFormSubmit = (value: CareOrganizationEditForm, { resetForm }: any) => {
    const organizationModel: CareOrganizationDetailsModel = {
      id: org.id,
      name: value.organizationName,
      description: value.description,
    }

    updateOrganizationDetails(
      organizationModel,
      setSaving,
      () => {
        resetForm()
        showSnackbar(t("care-organization-details.snackbar.update-success"), "success")
        fetchOrganizationDetails(organizationId, setLoadingDetails, setOrg, handleError)
      },
      handleError
    )
  }

  const roleChooser = (user: CareOrganizationUserListModel) => {
    return user.canManageOrganizationUsers || user.canManageFamilyUnits
      ? t(`user-list.user-role.foreman`)
      : t(`user-list.user-role.worker`)
  }

  const careOrgUserMenuItemCallback = (user: UserListModel, handleClose: () => void) => {
    const onUserDeactivationSuccess = () => {
      setUsers((previousUsers) => {
        return previousUsers.filter((previousUser) => previousUser.id !== user.id)
      })
      showSnackbar(t("care-organization-details.snackbar.deactivate-user-success"), "success")
    }

    return [
      <MenuItem
        key={`user-${user.id}-deactive`}
        onClick={() => {
          showConfirmationDialog(
            () => deactivateUser(user.id, onUserDeactivationSuccess, handleError),
            t("user-list.deactivate.header"),
            t("user-list.deactivate.message", { name: `${user.firstName} ${user.lastName}` })
          )
          handleClose()
        }}
      >
        {t("user-list.table.delete-user")}
      </MenuItem>,
      <MenuItem
        key={`user-${user.id}-reset`}
        onClick={() => {
          showConfirmationDialog(
            () => resetCareOrgUserPassword(user.id, onPasswordResetSuccess, handleError),
            t("user-list.reset-password.header"),
            t("user-list.reset-password.message", { name: `${user.firstName} ${user.lastName}` })
          )
          handleClose()
        }}
      >
        {t("user-list.table.reset-password")}
      </MenuItem>,
    ]
  }

  return (
    <>
      <div id="care-org-details">
        <Paper id="care-org-basic-info">
          {isLoadingDetails ? (
            <LinearProgress />
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={Yup.object({
                organizationName: Yup.string()
                  .trim()
                  .max(50, t("form-validation.too-long", { length: 500 }))
                  .required(t("form-validation.required")),
                description: Yup.string()
                  .trim()
                  .max(50, t("form-validation.too-long", { length: 2000 }))
                  .required(t("form-validation.required")),
              })}
              onSubmit={onFormSubmit}
            >
              {(formik) => (
                <Form>
                  <Typography variant="h1">{org.name}</Typography>
                  <TextField
                    id="name"
                    disabled={!canEditCareOrg || isSaving}
                    label={t("care-organization-details.name")}
                    sx={{ my: 2, width: "100%" }}
                    variant="standard"
                    color="primary"
                    {...formik.getFieldProps("organizationName")}
                    helperText={formik.touched.organizationName && formik.errors.organizationName}
                  />

                  <TextField
                    id="description"
                    disabled={!canEditCareOrg || isSaving}
                    label={t("care-organization-details.description")}
                    sx={{ my: 2, width: "100%" }}
                    variant="standard"
                    color="primary"
                    {...formik.getFieldProps("description")}
                    helperText={formik.touched.description && formik.errors.description}
                  />

                  {canEditCareOrg && (
                    <StyledButton
                      sx={{ width: "fit-content", alignSelf: "end" }}
                      variant="contained"
                      id="save-button"
                      disabled={!formik.dirty || !formik.isValid || isSaving}
                      type="submit"
                    >
                      {t("care-organization-details.save-changes")}
                    </StyledButton>
                  )}
                </Form>
              )}
            </Formik>
          )}
        </Paper>
        {isAllowedToSeeUsers(currentUser) && (
          <Paper id="care-org-users">
            <PasswordResetResultDialog
              open={passwordDialogOpen}
              onClose={() => setPasswordDialogOpen(false)}
              createResult={passwordResetResult}
            />
            <UserTable
              isLoading={isLoadingUsers}
              users={users}
              menuItems={careOrgUserMenuItemCallback}
              headerButton={
                <CreateCareOrgUser organizationId={organizationId} setUsers={setUsers} setLoading={setLoadingUsers} />
              }
              roleRowContent={roleChooser}
            />
          </Paper>
        )}

        {(currentUser?.canManageFamilyUnits || currentUser?.canBeConnectedToFamily) && (
          <Paper id="care-org-families">
            <FamilyList organizationId={organizationId} />
          </Paper>
        )}
      </div>

      <ErrorSnackbar />
      <Snackbar />

      <Dialog />
    </>
  )
}

const isAllowedToSeeUsers = (user: ProfileModel | null) =>
  user?.canManageOrganizationUsers || user?.role === UserRoleModel.MANAGEMENT_ORGANIZATION_ADMIN_USER

export default CareOrganizationDetails
