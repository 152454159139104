import { CircularProgress } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { ActivityHelperModel, ActivityModel, FeelingModel, FeelingTargetModel } from "services/openapi"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import { userContext } from "contexts/userContext"
import MobileHistoryDaySummary from "scenes/MobileHistoryDaySummary"
import {
  getActivitiesOptionsForClientGroup,
  getActivityHelperOptionsForClientGroup,
} from "utils/apiRequests/fetchActivities"
import { getFeelingOptionsForClientGroup, getFeelingTargetsForClientGroup } from "utils/apiRequests/fetchFeelings"
import ClientGroupId from "utils/clientGroupId"
import { useHistory, useParams } from "react-router-dom"
import { toFinnishFormat } from "utils/functions/toDateFormat"
import { useTranslation } from "react-i18next"
import MobileViewDescription from "components/MobileViewBase/MobileViewDescription"
import MobileViewBase from "components/MobileViewBase"

const MobileHistoryDayContainer = () => {
  const history = useHistory()
  const { date } = useParams<{ date: string }>()
  const { handleError } = useApiRequestError()
  const { user } = useContext(userContext)
  const { t } = useTranslation()
  const [isLoadingFeelingOptions, setLoadingFeelingOptions] = useState<boolean>(true)
  const [isLoadingFeelingTargets, setLoadingFeelingTargets] = useState<boolean>(true)
  const [activityOptions, setActivityOptions] = useState<ActivityModel[]>([])
  const [activityHelperOptions, setActivityHelperOptions] = useState<ActivityHelperModel[]>([])
  const [feelingOptions, setFeelingOptions] = useState<FeelingModel[]>([])
  const [feelingTargetOptions, setFeelingTargetOptions] = useState<FeelingTargetModel[]>([])

  useEffect(
    function fetchData() {
      getActivityHelperOptionsForClientGroup(ClientGroupId.ELDERLY, () => { }, setActivityHelperOptions, handleError)
      getActivitiesOptionsForClientGroup(ClientGroupId.ELDERLY, () => { }, setActivityOptions, handleError)
      getFeelingOptionsForClientGroup(undefined, setLoadingFeelingOptions, setFeelingOptions, handleError)
      getFeelingTargetsForClientGroup(undefined, setLoadingFeelingTargets, setFeelingTargetOptions, handleError)
    },
    [handleError]
  )

  const closeDialog = () => history.goBack()

  return (
    <MobileViewBase
      top={
        <MobileViewDescription
          header={t("customer-mobile-view.feeling-history-day.title")}
          text={toFinnishFormat(date)}
        />
      }
      middle={
        isLoadingFeelingOptions || isLoadingFeelingTargets ? (
          <CircularProgress size={100} />
        ) : (
          <MobileHistoryDaySummary
            clientId={user?.id ?? ""}
            clientGroupId={user?.clientGroupId ?? 0}
            date={date}
            onClose={closeDialog}
            activityOptions={activityOptions}
            feelingOptions={feelingOptions}
            feelingTargetOptions={feelingTargetOptions}
            activityHelperOptions={activityHelperOptions}
          />
        )
      }
      bottom={<></>}
    />
  )
}

export default MobileHistoryDayContainer
