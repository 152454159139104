import { styled, Typography, TypographyProps } from "@mui/material"

const DescriptionTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontFamily: "SofiaProBold",
  fontSize: "30px",
  textAlign: "center",
  marginBottom: theme.spacing(2),
}))

export default DescriptionTitle
