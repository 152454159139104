import { CircularProgress } from "@mui/material"
import { Timespan, TimeSpanSelectionButtons } from "components/TimeSpanSelectionButtons"
import { CareOrgUserContext } from "contexts/careOrgUserContext"
import { useCallback, useContext, useEffect, useState } from "react"
import { GroupedFeelings, UserFeelingModel } from "services/openapi"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import { getAggregated } from "./apiRequests"
import { useHistory, useLocation } from "react-router-dom"
import { getFeelingUserAllDetailed } from "utils/apiRequests/fetchFeelings"
import FeelingDisplay from "scenes/MobileFeelingHistory/FeelingDisplay"

type FeelingAggregateCardProps = {
  clientId: string
  clientGroupId: number
}

const FeelingAggregateCard = ({ clientId, clientGroupId }: FeelingAggregateCardProps) => {
  const { handleError } = useApiRequestError()
  const [isLoadingFeelings, setLoadingFeelings] = useState(true)
  const [isLoadingFeelingsAll, setLoadingFeelingsAll] = useState<boolean>(true)
  const [feelings, setFeelings] = useState<GroupedFeelings[]>([])
  const [feelingsAll, setFeelingsAll] = useState<UserFeelingModel[]>([])
  const { state, dispatch } = useContext(CareOrgUserContext)
  const history = useHistory()
  const location = useLocation()

  const updateSpan = useCallback(
    (span: Timespan) => {
      dispatch({ type: "updateCurrentTimespan", timespan: span })
      history.push(
        `${location.pathname}${span.name ? "?timespan=" + span.name + "&start=" + span.start + "&end=" + span.end : ""}`
      )
    },
    [dispatch, history, location.pathname]
  )

  useEffect(() => {
    const asyncWrapper = async () => {
      await getAggregated(
        clientId,
        state.selectedTimespan.start,
        state.selectedTimespan.end,
        setLoadingFeelings,
        setFeelings,
        handleError
      )
      await getFeelingUserAllDetailed(
        clientId,
        state.selectedTimespan.start,
        state.selectedTimespan.end,
        setLoadingFeelingsAll,
        setFeelingsAll,
        handleError
      )
    }

    asyncWrapper()
  }, [clientId, handleError, state.selectedTimespan.end, state.selectedTimespan.start])

  return (
    <>
      <TimeSpanSelectionButtons onSpanSelected={updateSpan} />
      {isLoadingFeelings || isLoadingFeelingsAll ? (
        <CircularProgress size={"100%"} sx={{ display: "block" }} />
      ) : (
        <FeelingDisplay userFeelings={feelings} userFeelingsAll={feelingsAll} timespan={state.selectedTimespan} elder={clientGroupId === 2} />
      )}
    </>
  )
}
export default FeelingAggregateCard
