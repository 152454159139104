import {
  CareOrganizationDetailsModel,
  CareOrganizationService,
  CareOrganizationUpdateModel,
  CareOrganizationUserCreateModel,
  CareOrganizationUserListModel,
  CareOrganizationUserService,
  UserCreateResultModel,
} from "services/openapi"

export const fetchOrganizationDetails = async (
  organizationId: string,
  setLoadingDetails: (isLoadingDetails: boolean) => void,
  onSuccess: (organizationDetails: CareOrganizationDetailsModel) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoadingDetails(true)
    const orgResponse = await CareOrganizationService.careOrganizationGetDetails(organizationId)
    onSuccess(orgResponse)
  } catch (error) {
    onError(error, "care-organization-details.snackbar.fetch-error")
  }
  setLoadingDetails(false)
}

export const fetchOrganizationUsers = async (
  organizationId: string,
  setLoading: (isLoading: boolean) => void,
  onSuccess: (users: CareOrganizationUserListModel[]) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoading(true)
    const userResponse = await CareOrganizationUserService.careOrganizationUserGetOrganizationUsers(organizationId)
    onSuccess(userResponse.items)
  } catch (error) {
    onError(error, "care-organization-details.snackbar.fetch-users-error")
  }
  setLoading(false)
}

export const updateOrganizationDetails = async (
  org: CareOrganizationDetailsModel,
  setLoading: (isLoading: boolean) => void,
  onSuccess: () => void,
  onError: (error: any, message: string) => void
) => {
  try {
    setLoading(true)
    const updateModel: CareOrganizationUpdateModel = {
      id: org.id,
      description: org.description,
      organizationName: org.name,
    }
    await CareOrganizationService.careOrganizationUpdateOrganization(updateModel)
    onSuccess()
    setLoading(false)
    return true
  } catch (error) {
    onError(error, "care-organization-details.snackbar.update-error")
    setLoading(false)
    return false
  }
}

export const createNewCareOrgUser = async (
  newUser: CareOrganizationUserCreateModel,
  onSuccess: (createResult: UserCreateResultModel) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    const response = await CareOrganizationUserService.careOrganizationUserCreateOrganizationUser(newUser)
    onSuccess(response)
    return true
  } catch (error) {
    onError(error, "care-organization-details.snackbar.create-user-error")
    return false
  }
}

export const deactivateUser = async (
  userId: string,
  onSuccess: () => void,
  onError: (error: any, message: string) => void
) => {
  try {
    await CareOrganizationUserService.careOrganizationUserDeactivateUser(userId)
    onSuccess()
    return true
  } catch (error) {
    onError(error, "care-organization-details.snackbar.deactivate-user-error")
    return false
  }
}

export const resetCareOrgUserPassword = async (
  adminId: string,
  onSuccess: (createResult: UserCreateResultModel) => void,
  onError: (error: any, message: string) => void
) => {
  try {
    const result = await CareOrganizationUserService.careOrganizationUserResetPassword(adminId)
    onSuccess(result)
    return true
  } catch (e) {
    onError(e, "care-organization-details.snackbar.reset-password-error")
    return false
  }
}
