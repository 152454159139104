import * as React from "react"
import { styled } from "@mui/system"
import DailyActivitySummary from "./DailyActivitySummary"
import TabsUnstyled from "@mui/base/TabsUnstyled"
import TabsListUnstyled from "@mui/base/TabsListUnstyled"
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled"
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled"
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled"
import DailyFeelingSummary from "scenes/DailyFeelingSummary/DailyFeelingSummary"
import { useTranslation } from "react-i18next"

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
}
const white = {
  500: "#FFFFFF",
}

const Tab = styled(TabUnstyled)`
  font-family: SofiaProMedium;
  color: #000000;
  cursor: pointer;
  font-size: 20px;
  background-color: transparent;
  width: 80%;
  padding: 15px 16px;
  margin: 0px auto;
  border: none;
  border-radius: 1px;
  display: flex;
  justify-content: center;

  &.${buttonUnstyledClasses.focusVisible} {
    color: #000;
    background-color: ${blue[900]};
  }

  &.${tabUnstyledClasses.selected} {
    background-color: ${blue[900]};
    color: ${white[500]};
    border-radius: 5px;
    display: flex;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
    color: #000;
  }
`

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: SofiaProMedium;
  font-size: 14px;
`

const TabsList = styled(TabsListUnstyled)`
  min-width: 300px;
  background-color: ${white[500]};
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`

export default function DailySummaryTabs() {
  const { t } = useTranslation()
  return (
    <TabsUnstyled defaultValue={0}>
      <TabsList
        sx={{
          marginX: 2,
        }}
      >
        <Tab>{t("elderly-view.daily-summary.tab.activities")}</Tab>
        <Tab> {t("elderly-view.daily-summary.tab.feelings")}</Tab>
      </TabsList>
      <TabPanel value={0}>
        {" "}
        <DailyActivitySummary />{" "}
      </TabPanel>
      <TabPanel value={1}>
        {" "}
        <DailyFeelingSummary />{" "}
      </TabPanel>
    </TabsUnstyled>
  )
}
