import { Timespan } from "components/TimeSpanSelectionButtons"
import produce from "immer"
import { isEmpty } from "lodash/fp"
import { createContext, useMemo, useReducer } from "react"
import { ClientUserDetailsModel, FamilyUnitListModel, UserListModel } from "services/openapi"
import { timeSpanOptions } from "../utils/timeSpanUtils"

export type CareOrgUserState = {
  families: FamilyUnitListModel[]
  currentFamilyId: string | undefined
  clientsOfCurrentFamily: UserListModel[]
  currentClient: ClientUserDetailsModel | undefined
  selectedTimespan: Timespan
}

export const initialCareOrgUserState: CareOrgUserState = {
  families: [],
  currentFamilyId: undefined,
  clientsOfCurrentFamily: [],
  currentClient: undefined,
  selectedTimespan: timeSpanOptions.week,
}

export type Action =
  | { type: "updateUserFamilies"; families: FamilyUnitListModel[] | undefined }
  | { type: "updateClientsOfCurrentFamily"; clients: UserListModel[] }
  | { type: "updateCurrentFamily"; familyId: string | undefined }
  | { type: "updateCurrentClient"; client: ClientUserDetailsModel | undefined }
  | { type: "updateCurrentTimespan"; timespan: Timespan }

export const careOrgUserStateReducer = produce((draft: CareOrgUserState, action: Action) => {
  switch (action.type) {
    case "updateUserFamilies":
      if (isEmpty(action.families)) {
        break
      }
      draft.families = action.families ?? []
      break

    case "updateClientsOfCurrentFamily":
      draft.clientsOfCurrentFamily = action.clients
      break

    case "updateCurrentFamily":
      draft.currentFamilyId = action.familyId
      break

    case "updateCurrentClient":
      draft.currentClient = action.client
      break

    case "updateCurrentTimespan":
      draft.selectedTimespan = action.timespan
      break

    default:
      break
  }
})

export const CareOrgUserContext = createContext({
  state: initialCareOrgUserState,
  dispatch: function Dispatcher(_value: Action) {},
})

export function CareOrgUserStateProvider({ children }: any) {
  const [state, dispatch] = useReducer(careOrgUserStateReducer, initialCareOrgUserState)

  const careOrgUserContextValues = useMemo(
    function getUpdatedState() {
      return {
        state,
        dispatch,
      }
    },
    [state, dispatch]
  )

  return <CareOrgUserContext.Provider value={careOrgUserContextValues}>{children}</CareOrgUserContext.Provider>
}
