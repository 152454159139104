import MobileViewBase from "components/MobileViewBase"
import MobileViewDescription from "components/MobileViewBase/MobileViewDescription"
import { userContext } from "contexts/userContext"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import HelperCards from "./HelperCards"
import { BottomNavigationStepValue } from "components/BottomNavigationBar"
import BottomNavigationBar from "components/BottomNavigationBar"
import ClientGroupId from "utils/clientGroupId"



const HelperSelection = () => {
  const { t } = useTranslation()
  const { user } = useContext(userContext)

  const step: BottomNavigationStepValue = user?.clientGroupId === ClientGroupId.ELDERLY ? 2 : 1

  return (
    <MobileViewBase
      top={<MobileViewDescription header={t("elderly-view.helper-selection.title")} text={""} />}
      middle={<HelperCards />}
      bottom={<BottomNavigationBar value={step} back={"/client/help"} to={"/client/feelings"} navLength={5} />}
    />
  )
}

export default HelperSelection
