import BottomNavigationBar from "components/BottomNavigationBar"
import MobileViewBase from "components/MobileViewBase"
import MobileViewDescription from "components/MobileViewBase/MobileViewDescription"
import { userContext } from "contexts/userContext"
import { useContext, useEffect, useReducer } from "react"
import { useTranslation } from "react-i18next"
import { toDateFormat } from "utils/functions/toDateFormat"
import useApiRequestError from "utils/hooks/useApiRequestErrorHandling"
import { getDiaryEntry, saveDiaryEntry } from "./apiRequests"
import DiaryForm from "components/MobileDiaryForms/DiaryForm"
import { initialDiaryState, reducer } from "./DiaryStateReducer"
import GoodThingsSection from "./GoodThingsSection"

const DiaryEntry = () => {
  const { user } = useContext(userContext)
  const { t } = useTranslation()
  const { ErrorSnackbar, handleError } = useApiRequestError()
  const [state, dispatch] = useReducer(reducer, initialDiaryState)

  useEffect(() => {
    async function fetchData() {
      const today: string = toDateFormat(new Date())
      if (user?.id) {
        const diary = await getDiaryEntry(user.id, today, handleError)
        if (diary) {
          dispatch({ type: "setInitialDiary", diary: diary })
        }
      }
    }

    fetchData()
  }, [handleError, user?.id])

  useEffect(
    function postDiaryOnChange() {
      const postData = async () => {
        await saveDiaryEntry(state.diary, handleError)
      }

      const nullId = "00000000-0000-0000-0000-000000000000"
      const dataFetchingReady = state.diary.userId !== "" && state.diary.userId !== nullId
      if (dataFetchingReady) {
        postData()
      }
    },
    [handleError, state.diary]
  )

  return (
    <MobileViewBase
      top={
        <MobileViewDescription
          header={t("youth-view.diary-entry.title")}
          text={t("youth-view.diary-entry.description")}
        />
      }
      middle={
        <>
          <DiaryForm userId={user?.id ?? ""} state={state} dispatch={dispatch} />
          <GoodThingsSection state={state} dispatch={dispatch} />
          <ErrorSnackbar />
        </>
      }
      bottom={<BottomNavigationBar value={2} back={"/client/feelings"} to={"/client/daily-summary"} navLength={4} />}
    />
  )
}
export default DiaryEntry
