import { Box, styled } from "@mui/system"

const Puller = styled(Box)(({ theme }) => ({
  width: 75,
  height: 4,
  backgroundColor: theme.palette.text.primary,
  borderRadius: 4,
}))

const DrawerHandle = () => {
  return (
    <Box
      sx={{
        display: "flex",
        position: "fixed",
        alignItems: "center",
        borderBottom: "2px solid rgba(21, 21, 21, 0.04)",
        bgcolor: "primary.main",
        width: "100%",
        height: "15px",
        zIndex: 2,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        pl: "calc(50% - 37.5px)",
        py: 2,
      }}
    >
      <Puller />
    </Box>
  )
}

export default DrawerHandle
