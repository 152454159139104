import { TextField, Typography } from "@mui/material"
import { Form, Formik } from "formik"
import { useTranslation } from "react-i18next"
import { UserListModel } from "services/openapi"
import * as Yup from "yup"

type UserEditForm = {
  firstName: string
  lastName: string
  login: string
}

type Props = {
  user: UserListModel
}

const UserFormFields = ({ user }: Props) => {
  const { t } = useTranslation()

  const initialValues: UserEditForm = {
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    login: user.principalName || "",
  }

  const onFormSubmit = (value: UserEditForm, { resetForm }: any) => {}

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        organizationName: Yup.string()
          .trim()
          .max(50, t("form-validation.too-long", { length: 500 }))
          .required(t("form-validation.required")),
        description: Yup.string()
          .trim()
          .max(50, t("form-validation.too-long", { length: 2000 }))
          .required(t("form-validation.required")),
      })}
      onSubmit={onFormSubmit}
    >
      {(formik) => (
        <Form>
          <Typography variant="h1">
            {user.lastName}, {user.firstName}
          </Typography>
          <TextField
            id="login"
            disabled={true}
            label={t("user-details.login")}
            sx={{ my: 2, width: "100%" }}
            variant="standard"
            color="primary"
            {...formik.getFieldProps("login")}
          />

          <TextField
            id="firstName"
            disabled={true}
            label={t("user-details.firstName")}
            sx={{ my: 2, width: "100%" }}
            variant="standard"
            color="primary"
            {...formik.getFieldProps("firstName")}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />

          <TextField
            id="lastName"
            disabled={true}
            label={t("user-details.lastName")}
            sx={{ my: 2, width: "100%" }}
            variant="standard"
            color="primary"
            {...formik.getFieldProps("lastName")}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </Form>
      )}
    </Formik>
  )
}

export default UserFormFields
